import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import Loader from "components/molecules/Loader";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles";
import { EventCard } from "components/molecules/EventCard";
import { HistoryEventDetails, UserPhotos } from "interfaces/User";
import { addDays, format, isSameDay } from "date-fns";
import { getClientHistory } from "api/clients";
import EmptySearch from "components/molecules/EmptySearch";
import { fr } from "date-fns/locale";
import CustomIconButton from "components/atoms/Buttons/IconButton";
import EventsFilters from "../EventsFilters";
import { Event, FilterList } from "@mui/icons-material";
import { theme } from "utils/theme";
import EventsList from "../EventsList";
import ActivitiesHistory from "../ActivitiesHistory";


export default function ClientHistory(){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.clients.profile)
    const pending = useSelector((state:RootState)=> state.clients.requests.getClientHistory === "pending")
    const history:HistoryEventDetails[] = useSelector((state:RootState) => state.clients.clientHistory)
    const [filter, setFilter] = useState<string>('3')
    const [range, setRange] = useState<{start:Date, end: Date}>({start: addDays(new Date(), -3), end: new Date()})
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
    const filters = useSelector((state:RootState)=> state.clients.clientHistoryFilters)

   

    const handleChangeFilter = (event: SelectChangeEvent) =>{
        setFilter(event.target.value as string)
    }


    /********************************
     * Récupération de l'historique
     ********************************/

    useEffect(()=>{
        const startDate = addDays(new Date(range.end), -(parseInt(filter)-1))
        if(user?._id){
            dispatch(getClientHistory({
                user, 
                start: startDate, 
                end: new Date()
            }))
        }
    },[filter, user])


    /********************** 
     * FILTRAGE DES EVENTS 
     **********************/

    const filteredEvents:HistoryEventDetails[] = useMemo(() => {
        
        var workouts:HistoryEventDetails[] = []
        var checkups:HistoryEventDetails[] = []
        var measures:HistoryEventDetails[] = []
        var meals:HistoryEventDetails[] = []
        var userPhotos:HistoryEventDetails[] = []

    
        workouts = history.filter((elem)=> {
            return elem.action === "workout"
        })
        

        checkups = history.filter((elem)=> {
            return elem.action === "questionnaire"
        })
        

        measures = history.filter((elem)=> {
            return elem.action === "measure"
        })

        meals= history.filter((elem)=> {
            return elem.action === "meal"
        })

        // Images non triées
                var photosList = history.filter((elem)=> {
                    return elem.action === "photo"
                })
        
                var albums:UserPhotos[] = []
        
                photosList.forEach((event:HistoryEventDetails)=> {
                    const elemIndex = albums.findIndex((elem)=> isSameDay(new Date(elem.date), new Date(event.date)))
        
                     // Ajout d'une photo à un élément déja en place
                     if(elemIndex> -1){
                        albums[elemIndex].photos.push(event.image)
                        albums[elemIndex].photos = albums[elemIndex].photos.reverse()
        
                    } else{
                        albums.push({
                            date: new Date(event.image.date),
                            photos: [{...event.image}],
                            user: event.user
                            
                        })
                    }
                })
        
                albums.forEach((listElem)=>{
                    userPhotos.push({
                        date: listElem.date,
                        user: listElem.user,
                        action: "userPhotos",
                        _id: null,
                        photos: listElem.photos
        
                    })
                })
        

        const events = workouts?.concat(checkups).concat(measures).concat(meals).concat(userPhotos)

        const sortedEvents = [...events.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())]

        // Filtrage selon les choix users
        var filtered:HistoryEventDetails[] = []

        if(filters.length === 0){
            filtered = [...sortedEvents]
        } else {

            filters.forEach((elem)=> {
                filtered = filtered.concat([...sortedEvents].filter((event) => event.action === elem))
            })
        }


        return filtered


    },[history,user, filters])


    /**************************************** 
     * REGROUPEMENT DES EVENEMENTS PAR JOUR
     * ***************************************/


    const SORTED_EVENTS_BY_DAY = useMemo(()=>{

        const eventsByDate:{date: Date, events: HistoryEventDetails[]}[] = []
        const parsedFilter = parseInt(filter)
        Array.from(Array(parsedFilter)).forEach((_, index)=> {
            const date = addDays(new Date(range.end), -index)
            const eventsFound = filteredEvents.filter((event)=> isSameDay(new Date(event.date), date))
            if(eventsFound.length>0){
                eventsByDate.push({
                    date,
                    events: eventsFound
                })
            }
        })

        return eventsByDate
    },[filteredEvents, filter])


    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget)
    }


    return(
        <>
            <Grid 
                container 
                justifyContent="center" 
                spacing={0}
            >
                <Grid 
                    item xs={12} 
                    sm={12} 
                    md={12} 
                    lg={10} 
                    xl={8}
                >
                   <ActivitiesHistory 
                        forClient={true}
                    />
                </Grid>
            </Grid>

        </>
    )
}