import { theme } from "utils/theme"


const classes = {
    container : {
        position: "fixed",
        display:"flex",
        width: {xs : '100%', sm: "calc(100% - 60px)"},
        bottom: {xs: 0, sm: theme.spacing(3)},
        justifyContent:"center",
        alignItems:"center",
        borderRadius: {
            xs: 0, sm:3
        },
        left: {xs: 0, sm: 60}
    },

    macros : {
        backgroundColor: "white",
        width:{
            xs: "100%",
            sm: "auto"
        },
        justifyContent:"center",
        alignItems:"center",
        display:"inline-flex",
        paddingLeft: 3,
        paddingRight: 3,
        borderRadius: {
            xs: 0, sm:3
        },
    }
}
export default classes