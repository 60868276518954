import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import { updateWorkout } from "api/workouts";
import { AppDispatch, RootState } from "app/store";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Workout from "interfaces/Workout";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import DialogTitleCustom from "../DialogTitleFullScreen";
import { MOBILE } from "utils/constants";

/***** INTERFACES ****** */

interface Props{
    open: boolean,
    onClose: ()=> void,
    workout: Workout
}



export default function DialogActivateWorkout({open, onClose, workout}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.workouts.requests.update === "pending")
    const mobile = useMediaQuery(MOBILE)
    
    /***** CURRENT STATUS ****** */
    const enabled = useMemo(()=>{
        if(workout?.status === "enabled") return true
        return false
    },[workout])


    /***** SUBMIT ****** */
    const onSubmit = () => {
        const status = enabled ? "disabled" : "enabled"
        dispatch(updateWorkout({workout: {...workout, status}})).unwrap().then((res:any)=>{
            if(res.workout){
                //toast.success(`Séance ${status === "enabled" ? "activée" : "désactivée"}`)
            }else{
                toast.error("Impossible de modifier cette séance")
            }
            onClose()
        })
  
    }

    return(
        <Dialog 
            open={open}
            disableScrollLock={true}
        >
            <DialogTitle>
                {enabled ? "Désactiver la séance" : "Activer la séance"}
            </DialogTitle>
                
      
            <DialogContent>
                Souhaites-tu {enabled ? "désactiver" : "activer"} la séance <strong>{workout?.name}</strong> du <strong>{workout && format(new Date(workout?.schedule), "dd MMM yyyy", {locale: fr})}</strong> ? {enabled ? ("Cette dernière ne pourra plus être éffectuée sur l'application mobile et passera à l'état Brouillon.") : "Cette dernière apparaîtra dans le planning de ton client et pourra être éffectuée via l'application mobile."}
            </DialogContent>
           
            <DialogActions>
       
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
                
                <LoadingButton variant="contained" onClick={onSubmit} loading={pending} disabled={pending}>
                    Confirmer
                </LoadingButton>
            </DialogActions>
        
        </Dialog>
    )
}