import { Box, InputAdornment, styled, TextField } from "@mui/material"
import { AppDispatch } from "app/store"
import { handleWorkoutBlockName } from "features/workoutSlice"
import debounce from "lodash.debounce"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { INPUT_DEBOUNCE_TIMER } from "utils/constants"
import { theme } from "utils/theme"


const BlockNameTextfield = styled(TextField)(() => ({
    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 600,

        fieldset: {
            border: 0
        },
        color: "#000000d2",
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
      
        }
    },
  }))


/******** INTERFACES ******* */

interface Props{
    name: string,
    blockId: string,
    className: string,
    blockIndex: number,
    disabled?:boolean
}


/******** MAIN COMPONENT ******* */

export function WorkoutBlockName({name, blockId, disabled}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [value, setValue] = useState<string>("")

    /************** VALEUR PAR DEFAULT ********* */

    useEffect(()=>{
        setValue(name)
    },[])



    /************** CHANGEMENT DU TITRE ********* */

    const debounceOnChange = useRef(debounce((data)=> {
        const {name, blockId} = data
        dispatch(handleWorkoutBlockName({ name, blockId}))
    },INPUT_DEBOUNCE_TIMER)).current

    
    const onChangeBlockName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTitle:string = event.target.value
        setValue(newTitle)
        debounceOnChange({name: newTitle, blockId})
    }


    /********* JSX **********/

    return(
        
        <BlockNameTextfield
            value={value}
            sx={{display:"flex", flex: 1}}
            onChange={onChangeBlockName}
            variant="standard"
            className={"block-name"}
            disabled={disabled}
        />
    )
}


function areEqual(prev:Props,next:Props){
    return prev.blockId === next.blockId
}

export default React.memo(WorkoutBlockName, areEqual)