

import { useMemo, useState } from "react"
// MUI
import { Box, Card, Grid,  IconButton, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
// REDUCERS
import classes from "./styles"
import { Delete, Email, MoreHorizOutlined } from "@mui/icons-material";
import User from "interfaces/User";
import { useHistory } from "react-router-dom";
import Invitation from "interfaces/ClientInvitation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { handleSubscriptionPresentation } from "features/appSlice";
import { differenceInCalendarDays } from "date-fns";
import RowCard from "components/atoms/Box/RowCard";



interface Props {
    client:User, 
    invitation?: Invitation,
    onSelectClientToDelete?:(client:User)=> void,  
    onSelectInvitationToDelete?:(invitation:Invitation)=> void,
    onOpenInvitationDescription?:(name:string, email:string) => void
}

export default function ClientRow({client, invitation, onSelectClientToDelete, onSelectInvitationToDelete, onOpenInvitationDescription}:Props){

    let navigate = useHistory();
    const dispatch = useDispatch<AppDispatch>()
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const user = useSelector((state:RootState)=> state.user.data)

    const navigateTo = ()=> {
        navigate.push(`clients/${client._id}`)
    }

    const selectClient = (client:User) => ()=> {
        onSelectClientToDelete(client)
        setAnchorEl(null)
    }

    const selectInvitation = (invitation:Invitation) => ()=> {
        onSelectInvitationToDelete(invitation)
        setAnchorEl(null)

    }

    const onClick = ()=>{
        if(!invitation) {
            // Vérification de l'abonnement 
            // Aucun abonnement en cours
            if(!user.subscription || !user.subscription?.current){
                dispatch(handleSubscriptionPresentation({open:true}))
                return
            }

            navigateTo()
        } else {
            onOpenInvitationDescription(
                `${client.firstname} ${client.lastname}`, 
                client.email
            )
        }
    }


    const workouts = useMemo(()=>{
        return client.workoutsScheduled?.length
    },[client.workoutsScheduled])


    const onOpenMenu = (e:any)=> {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        
    }


    const onCloseMenu = (e:any) => {
        e.stopPropagation()
        setAnchorEl(null)
        
    } 


    const programProgress = useMemo(()=>{
        if(client?.programs?.length > 0){
            const program = client.programs[0]
            const diff = differenceInCalendarDays(new Date(), new Date(program.startDate))
            const duration = differenceInCalendarDays(new Date(program.endDate), new Date(program.startDate))
            const part = (diff/duration) * 100
            const total = Math.round(part)
            return total
        }else{
        return 0
        }
    },[client.programs])



   

    return(
  <>
            <RowCard
                onClick={onClick}>
                    <Box 
                        sx={[classes.row, {opacity: workouts > 0 ? 1 : 1}]}
                    >

                        {/*****************
                         * NAME CONTAINER
                         ****************/}

                        <Box
                            sx={classes.nameContainer}
                        >

                        <Box sx={classes.avatarContainer}>
                            {client?.avatar?.url && (
                                <Box 
                                    component={"img"} 
                                    src={client?.avatar?.url} 
                                    sx={classes.avatar} 
                                />
                            )}

                            {!client?.avatar?.url && (
                               
                                    <Typography sx={classes.avatarLetters}>
                                        {client.firstname[0]} {client.lastname[0]}
                                    </Typography>
                                    
                               
                            )}
                        </Box>

                            <Box>
                                <Typography
                                    sx={classes.name}
                                >
                                    {client.firstname} {client.lastname}
                                </Typography>
                                <Typography
                                    sx={classes.email}
                                >
                                    {client.email}
                                </Typography>
                            </Box>
                        </Box>


                        {/***************
                         * STATUS
                         ***************/}

                         <Box
                            sx={classes.statusContainer}
                         >

                            {/**************** 
                             * ACTIF/INACTIF 
                             * *************/}

                            {(!invitation) && (
                                <Tooltip title={workouts > 0 ? "" : "Ce client n'a aucune séance programmée"}>
                                    <Box sx={[
                                        classes.status, 
                                        workouts < 1 && classes.inactif
                                    ]}>
                                        <Typography
                                        sx={classes.nutritionLabel}
                                    >
                                        {workouts > 0 ? "Actif" : "Inactif"}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            )}

                            
                            {client?.mealPlan?.status === "enabled" && (
                                <Box
                                    sx={[
                                        classes.status,
                                        classes.nutritionContainer
                                    ]}
                                >
                                    <Typography
                                        sx={classes.nutritionLabel}
                                    >
                                        Nutrition
                                    </Typography>
                                </Box>
                            )}

                            {invitation && (
                                <Box
                                sx={[
                                    classes.status,
                                    classes.invitationStatus
                                ]}
                            >
                                <Typography
                                    sx={classes.nutritionLabel}
                                >
                                    Invitation
                                </Typography>
                            </Box>
                            )}

                        </Box>

                        {/***************
                         * PROGRAMME
                         ***************/}
                        {!invitation && (
                         <Box
                            sx={classes.programContainer}>

                            <Box
                                sx={{display:"flex"}}
                            >
                                {client?.programs?.length === 0 && (
                                    <Box
                                        sx={classes.program}>
                                            <Typography
                                                sx={{color: "gray", marginBottom: 1}}
                                            >
                                                Aucun programme
                                            </Typography>
                                            <LinearProgress 
                                                variant="determinate" 
                                                sx={{height: 10, borderRadius: 1, backgroundColor: "#ebebeb",width: 200}}
                                                value={0}
                                            />
                                    </Box>
                                )}

                                {client?.programs?.length > 0 && (
                                    <Box
                                        sx={classes.program}>
                                            <Typography
                                                sx={{marginBottom: 1}}
                                            >
                                                {client.programs[0].name}
                                            </Typography>
                                            <LinearProgress 
                                                variant="determinate" 
                                                sx={{height: 10, borderRadius: 1, backgroundColor: "#ebebeb", width: 200}}
                                                value={programProgress}
                                            />
                                    </Box>
                                )}
                            </Box>

                            <Box
                                sx={{
                                    display:{
                                        xs: "flex",
                                        xl: "none"
                                    }
                                }}
                            >

                                {!invitation && (
                                    <Typography sx={[classes.programmed, {marginTop: 0.5}]}>
                                        {client?.workoutsScheduled && (Boolean(client?.workoutsScheduled[0]?.records) )? 
                                            `${client?.workoutsScheduled[0]?.records} séance${client?.workoutsScheduled[0]?.records> 1 ? "s" :""} programmée${client?.workoutsScheduled[0]?.records> 1 ? "s" :""}` 
                                            : "Aucune séance programmée"}
                                    </Typography>
                                )}

                            </Box>
                        </Box>
                        )}

                        {/***************
                         * SEANCES
                         ***************/}
                    
                        <Box
                            sx={{display:{
                                xs: "none",
                                xl: "flex"
                            }}}
                        >
                            {!invitation && (
                                <Typography sx={classes.programmed}>
                                    {client?.workoutsScheduled && (Boolean(client?.workoutsScheduled[0]?.records) )? 
                                        `${client?.workoutsScheduled[0]?.records} séance${client?.workoutsScheduled[0]?.records> 1 ? "s" :""} programmée${client?.workoutsScheduled[0]?.records> 1 ? "s" :""}` 
                                        : "Aucune séance programmée"}
                                </Typography>
                            )}

                            {invitation && (
                                <Typography sx={[classes.programmed, {marginLeft: 3}]}>
                                    Invitation en attente
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    {/****** MORE BUTTON ****** */}
                    <Box sx={classes.moreButton}>
                        <IconButton 
                            sx={{borderRadius: "100px !important", width: 48, height: 48}} 
                            onClick={onOpenMenu}
                        >
                            <MoreHorizOutlined sx={{color: "black"}} />
                        </IconButton>
                    </Box>
            </RowCard>
             <Menu 
             disableScrollLock={true} 
             open={Boolean(anchorEl)}
             onClose={onCloseMenu}
             anchorEl={anchorEl}
             
         >
             {!invitation && (
                 <MenuItem onClick={selectClient(client)}>
                     <ListItemIcon>
                         <Delete />
                     </ListItemIcon>
                     <ListItemText>
                         Supprimer ce client
                     </ListItemText>
                 </MenuItem>
             )}
            

             {invitation && (
                 <MenuItem onClick={selectInvitation(invitation)}>
                     <ListItemIcon>
                         <Delete />
                     </ListItemIcon>
                     <ListItemText>
                         Annuler l'invitation
                     </ListItemText>
                 </MenuItem>
             )}

         </Menu>
         </>
      
    )
}