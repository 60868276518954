import { CheckBox, CheckBoxOutlineBlank, CheckOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { addClientHistoryEventFilter, removeClientHistoryEventFilter } from "features/clientsSlice";
import { addDashboardEventFilter, removeDashboardEventFilter } from "features/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { BORDER_COLOR, CARD_BORDER_RADIUS, HOVER_COLOR } from "utils/constants";

interface Props{
    label: string,
    eventType: "workout" | "measure" | "questionnaire" | "meal" | "photo" | "all"
    checked: boolean,
    forClient?: boolean
}



export default function FilterButtonCheckbox({label, eventType, checked, forClient = false}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const filters = useSelector((state:RootState)=> forClient ? state.clients.clientHistoryFilters : state.dashboard.filters)

    const handleFilter = (newFilter:string) => ()=>{
            if(newFilter === "all"){
                if(!forClient){
                    dispatch(removeDashboardEventFilter({newFilter}))
                }else{
                    dispatch(removeClientHistoryEventFilter({newFilter}))
    
                }
                return
            }

            const exist = filters.find((elem)=> elem === newFilter)
    
            if(!exist){ 
                if(!forClient){
                    dispatch(addDashboardEventFilter({newFilter}))
                }else{
                    dispatch(addClientHistoryEventFilter({newFilter}))
    
                }
            } else {
                if(!forClient){
                    dispatch(removeDashboardEventFilter({newFilter}))
                }else{
                    dispatch(removeClientHistoryEventFilter({newFilter}))
    
                }
            }
        }

    const onClickButton = () => {
        dispatch(handleFilter(eventType))
    }

    return(
        <Box
            sx={{
                height:"40px", 
                display:"inline-flex", 
                flexDirection: "row", 
                alignItems:"center", 
                backgroundColor: "white", 
                borderRadius: CARD_BORDER_RADIUS, 
                paddingLeft: 1.5, 
                paddingRight: 1.5, 
                border: `solid 1px ${BORDER_COLOR}`,
                marginRight: 1.5,
                cursor: "pointer",
                "&:hover" : {
                    backgroundColor: HOVER_COLOR
                }
            }}
            onClick={onClickButton}
        >
            {/*<Icon
                icon={ICON[eventType as keyof typeof ICON]}
                size={18}
                color={color}
                style={{marginRight:8}}
            />*/}

            {checked && (
                <CheckBox 
                    color="primary"
                    sx={{marginRight: 1.5}}
                />
            )}

            {!checked && (
                <CheckBoxOutlineBlank 
                    color="primary"
                    sx={{marginRight: 1.5}}
                />
            )}
            <Typography
                sx={{fontSize: 15}}
            >
                {label}
            </Typography>
        </Box>
    )
}