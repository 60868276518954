import { ArrowDownward } from "@mui/icons-material"
import { Box, Icon, Typography } from "@mui/material"
import defineEvolutionColor from "function/defineEvolutionColor"
import classes from "./styles"


function ComparisonIcon({comparisonValue}:{comparisonValue:"-"|"+"|"="}){

    if(comparisonValue === "-"){
        return(
            <Icon
                sx={[
                    classes.comparatorIconContainer, 
                    {
                        backgroundColor: defineEvolutionColor("-"), 
                        rotate: "-45deg" 
                    }
                ]}
            >
                <ArrowDownward 
                    sx={[classes.comparatorIcon]}
                />
            </Icon>
        )
    }
    else if(comparisonValue === "+"){
        return(
            <Icon
                sx={[
                    classes.comparatorIconContainer, 
                    {backgroundColor: defineEvolutionColor("+") }
                ]}
            >
                 <ArrowDownward 
                    sx={[
                        classes.comparatorIcon, 
                        {
                            rotate: "-135deg"
                        }
                    ]}
                />
            </Icon>
        )
    }
    else if(comparisonValue === "="){
        return(
            <Box
                sx={[
                    classes.comparatorIconContainer, 
                    {backgroundColor:defineEvolutionColor("=") }
                ]}
            >
                <Typography
                    sx={[
                        classes.comparatorIcon,{
                            fontSize: 18, 
                            marginTop: "-2px", 
                            marginLeft: "-1px"
                        }]}
                >
                    =
                </Typography>
            </Box>
        )
    }
}

export default ComparisonIcon