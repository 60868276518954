import { Box, Button, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Tooltip, Typography, useMediaQuery } from "@mui/material";
import classes from "./styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import Meal from "../Meal";
import calcMacros from "function/calcMacros";
import debounce from "lodash.debounce";
import { AUTOSAVE_TIMER, DESKTOP, LARGE_DESKTOP, MOBILE } from "utils/constants";
import { updateClientMealPlan } from "api/clients";
import { Add, BarChart,ContentCopy, CopyAll,Download, KeyboardArrowDown, ModeEdit, MoreHoriz, NotificationsActive, Save } from "@mui/icons-material";
import DialogActivateMealPlan from "components/molecules/DialogActivateMealPlan";
import NutritionHeaderDays from "components/molecules/NutritionHeaderDays";
import MealPlan from 'interfaces/MealPlan';
import { toast } from "react-toastify";
import DialogPasteMealPlan from "../DialogPasteMealPlan";
import DialogSaveMealPlanTemplate from "../DialogSaveMealPlanTemplate";
import DialogImportMealPlan from "../DialogImportMealPlan";
import { mealPlanIsSaving, onAddMeal, onCopyDayMeals, onCopyMealPlan, onPasteDayMeals, onPastMeal } from "features/mealPlanSlice";
import { updateMealPlan } from "api/mealPlans";
import { differenceInCalendarYears } from "date-fns";
import { calcMb } from "function/calcMb";
import DialogMetabolism from "components/molecules/DialogMetabolism";
import DialogCaloriesNeeded from "components/molecules/DialogCaloriesNeeded";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import MacrosListMobile from "../MacrosListMobile";
import DialogMicrosNutriments from "../DialogMicrosNutriments";
import MacrosGoal from "../MacrosGoal";
import DialogCalories from "components/molecules/DialogCalories";
import SwitchButton from "components/molecules/SwitchButton";
import DialogSendMealPlanNotification from "../DialogSendMealPlanNotification";
import AutosaveIndicator from "components/molecules/AutosaveIndicator";
import FixedMacrosIndicator from "components/molecules/FixedMacrosIndicator";

const days = ['monday', "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
const trads = ['Lundi', "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

interface Props{
    template?: boolean
}



export default function Nutrition({template}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const client = useSelector((state:RootState) => state.clients?.profile)
    const [dayIndex, setDayIndex] = useState<number>(0)
    const mealPlan = useSelector((state:RootState) => state.mealPlans?.mealPlan)
    const mealPlanCopied = useSelector((state:RootState) => state.mealPlans.mealPlanCopied)
    const mealCopied = useSelector((state:RootState) => state.mealPlans.mealCopied)
    const dayCopied = useSelector((state:RootState) => state.mealPlans.dayCopied)
    const [openActivateMealPlan, setOpenActivateMealPlan] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const [openPasteMealPlan, setOpenPasteMealPlan] = useState<boolean>(false)
    const [openTemplateSave, setOpenTemplateSave] = useState<boolean>(false)
    const [openImportTemplate, setOpenImportTemplate] = useState<boolean>(false)
    const [openDialogMetabolism, setOpenDialogMetabolism] = useState<boolean>(false)
    const [openDialogCalories, setOpenDialogCalories] = useState<boolean>(false)
    const [openMenuDaysAnchor, setOpenMenuDaysAnchor] = useState<any>(null)
    const [openDialogMicroNutriments, setOpenDialogMicrosNutriments] = useState<boolean>(false)
    const [openCalories,setOpenCalories] = useState<boolean>(false)
    const [openSendNotification, setOpenSendNotification] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const desktop = useMediaQuery(DESKTOP)
    const largeDesktop = useMediaQuery(LARGE_DESKTOP)
    const [showFixedIndicator, setShowFixedIndicator] = useState<boolean>(false)
    const macrosRef = useRef(null)
    


    const copyMealsDay = () => {
        dispatch(onCopyDayMeals(mealPlan[SELECTED_DAY]))
        toast.success('Journée copiée')
        setAnchorEl(null)
    }

    const copyMealPlan = () => {
        dispatch(onCopyMealPlan())
        toast.success('Programme alimentaire copié')
        setAnchorEl(null)
    }

    const pasteMealPlan = () => {
        setOpenPasteMealPlan(true)
        setAnchorEl(null)
    }


    const saveAsTemplate = () => {
        setOpenTemplateSave(true)
        setAnchorEl(null)

    }


    const importTemplate = () => {
        setOpenImportTemplate(true)
        setAnchorEl(null)

    }





    useEffect(()=>{
        if(macrosRef?.current){
            // Changement de la position du Header planning
            const handleScroll = () => {
                if(macrosRef.current.getBoundingClientRect().y <= -145){
                    if(!showFixedIndicator){
                        setShowFixedIndicator(true)
                    }
                    
                } else {
                    if(showFixedIndicator){
                    setShowFixedIndicator(false)
                    }

                }
            }

            if(macrosRef.current){
                window.addEventListener("scroll", handleScroll);
                return () => {
                    window.removeEventListener("scroll", handleScroll);
                };
            }
        }

       
    },[macrosRef?.current, showFixedIndicator])





    /***************
     *  AUTOSAVE
     ***************/

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((mealPlanData,isTemplate)=> {
        if(!isTemplate){
            dispatch(updateClientMealPlan({
                mealPlan:mealPlanData, 
                clientId: client._id
            }))
        } else if(isTemplate){
            dispatch(updateMealPlan({
                mealPlan:mealPlanData, 
            }))
        }
       
    }, AUTOSAVE_TIMER)).current


    // Appel du debounce à chaque changement
    useEffect(() => {
        if(mealPlan){
            const emptyMealPlan:MealPlan = {
                name: template? undefined: "",
                status: "disabled",
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: []
            } 

            const updatedMealPlan:MealPlan = mealPlan? mealPlan : emptyMealPlan
            
            dispatch(mealPlanIsSaving())
            debounceAutoSave(updatedMealPlan, template)
        }
       
        // eslint-disable-next-line
    }, [JSON.stringify(mealPlan), template])






    /********************
     * Ajout d'un repas
     *******************/

    const addMeal = () => {
        dispatch(onAddMeal({day: SELECTED_DAY}))
    }

    const pasteMeal = () => {
        dispatch(onPastMeal({day: SELECTED_DAY}))
    }

    const pasteDay = () => {
        dispatch(onPasteDayMeals({day: SELECTED_DAY}))
    }


    const SELECTED_DAY = useMemo(()=>{
        return days[dayIndex] as 'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday"
    },[dayIndex])





    /*******************************
     * METABOLISME DE BASE
     *******************************/

    const METABOLISM = useMemo(()=>{
        if(!mealPlan?.template && client.birthday && client.gender && client.size && client.measures['weight'] && client.measures["weight"]?.length >0 ){
            var lastMeasure:number = client?.measures?.weight[0]?.value
            const age = differenceInCalendarYears(new Date(), new Date(client.birthday))
            const size = client.size/100
            const weight=(Boolean(lastMeasure) ? lastMeasure : null)

            const metabolism = calcMb(client.gender, size, weight, age)
            return Math.round(metabolism)

        } else {
            return null
        }
    },[client, mealPlan])


    const onCloseMenuDays = (day:number) => {
        setOpenMenuDaysAnchor(null)
        setDayIndex(day)

    }


    
    return(
        <Box 
            sx={classes.nutrition}>

                    <Grid 
                        container 
                        spacing={3}
                        justifyContent="center" 
                    >
                        <Grid item xs={12} lg={11} xl={8}>
                            <Box
                            sx={classes.header}
                        >

                                {/********************************
                                * ACTIVATION DU PLAN ALIMENTAIRE 
                                ********************************/}
                            
                                {!template && (
                                    <Box
                                        sx={classes.headerButtonContainer}
                                    >   
                                        <SwitchButton
                                            activated={mealPlan?.status === "enabled"}
                                            onClick={()=> setOpenActivateMealPlan(true)}
                                        />
                                    </Box>
                                )}

                                {template && (
                                    <Box>

                                    </Box>
                                )}

                                

                                <Box>
                                
                                    {(mealPlan && !template) && (
                                        <Box
                                            sx={{display:"flex",flexDirection:"row", alignItems:"center"}}
                                        >

                                            <AutosaveIndicator/>

                                            {!mobile && (
                                                <Box
                                                    sx={{marginLeft: 3}}
                                                >
                                                    <Tooltip
                                                        title="Notifier"
                                                    >
                                                        <IconButton 
                                                            sx={classes.actionButton} 
                                                            onClick={()=>setOpenSendNotification(true)}
                                                        >
                                                            <NotificationsActive 
                                                                
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title="Sauvegarder comme modèle"
                                                    >
                                                        <IconButton 
                                                            sx={classes.actionButton} 
                                                            onClick={()=>saveAsTemplate()}
                                                        >
                                                            <Save 
                                                                
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title="Importer un modèle"
                                                    >
                                                        <IconButton 
                                                            sx={classes.actionButton}  
                                                            onClick={()=>importTemplate()}
                                                        >
                                                            <Download 
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title="Copier le plan alimentaire"
                                                    >
                                                        <IconButton 
                                                            sx={classes.actionButton} 
                                                            onClick={()=>copyMealPlan()}
                                                        >
                                                            <CopyAll />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title="Coller le plan alimentaire"
                                                    >
                                                        <IconButton 
                                                            sx={classes.actionButton} 
                                                            onClick={()=>pasteMealPlan()}
                                                            disabled={!Boolean(mealPlanCopied)}
                                                        >
                                                            <ContentCopy />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            )}

                                        
                                            
                                        
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Grid>

                    </Grid> 

                    
                     {/*****************************************
                    * ACTIONS SUR LE PLAN ALIMENTAIRE (CLIENT)
                    ******************************************/}

                    <Menu disableScrollLock={true} 
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={()=>setAnchorEl(null)}
                                >
                                    <MenuItem 
                                        onClick={copyMealsDay}
                                    >
                                        <ListItemIcon>
                                            <CopyAll />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Copier cette journée
                                        </ListItemText>
                                    </MenuItem> 
                                    <MenuItem 
                                        onClick={copyMealPlan}
                                    >
                                        <ListItemIcon>
                                            <CopyAll />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Copier ce plan alimentaire
                                        </ListItemText>
                                    </MenuItem>  
                                    <MenuItem 
                                        onClick={pasteMealPlan}
                                        disabled={!Boolean(mealPlanCopied)}
                                    >
                                        <ListItemIcon>
                                            <ContentCopy />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Coller un plan alimentaire
                                        </ListItemText>
                                    </MenuItem>  
                                    <MenuItem 
                                        onClick={importTemplate}
                                    >
                                        <ListItemIcon>
                                            <Download 
                                                fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Importer un modèle
                                        </ListItemText>
                                    </MenuItem>  
                                    <MenuItem 
                                        onClick={saveAsTemplate}
                                    >
                                        <ListItemIcon>
                                            <Save 
                                                fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Enregistrer comme modèle
                                        </ListItemText>
                                    </MenuItem>  
                    </Menu>

                

                {/******************
                 * LISTE DES JOURS
                 *******************/}

                    {!mobile && (
                        <NutritionHeaderDays
                            dayIndex={dayIndex}
                            daySelected={SELECTED_DAY}
                            onChangeDaySelected={(index)=> ()=> setDayIndex(index)}
                        />
                    )}


                    {/******************************
                     * BOUTON SELECTION JOUR MOBILE
                     ******************************/}

                    {mobile && (
                        <Box
                            sx={classes.mobileDaysHeader}
                        >
                        


                            <SecondaryButton 
                                endIcon={<KeyboardArrowDown/>}
                                onClick={(event: React.MouseEvent<HTMLElement>) => {setOpenMenuDaysAnchor(event.currentTarget)}}
                            >
                                {trads[days.findIndex((elem)=> elem === SELECTED_DAY)]} - {mealPlan ? calcMacros(mealPlan[SELECTED_DAY]).kcal : 0} kcal
                            </SecondaryButton> 

                            {mobile && (
                                <IconButton 
                                    sx={classes.buttonMore} 
                                    onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
                                >
                                    <MoreHoriz />
                                </IconButton>
                            )}


                            

                            
                        </Box>
                        
                    )}

                    {/***************************
                     * LISTE DES JOURS (MOBILE)
                     ***************************/}

                    <Menu 
                        disableScrollLock={true} 
                        anchorEl={openMenuDaysAnchor}
                        open={Boolean(openMenuDaysAnchor)}
                        onClose={()=>setOpenMenuDaysAnchor(null)}
                    >
                        {days.map((elem,index)=> {

                            const day = elem as 'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday"

                            return(
                                <MenuItem 
                                    key={elem}
                                    onClick={()=>onCloseMenuDays(index)}
                                >
                                    
                                    <ListItemText>
                                        {trads[days.findIndex((elem)=> elem === day)]} - {mealPlan ? calcMacros(mealPlan[day]).kcal : 0} kcal
                                    </ListItemText>
                                </MenuItem>
                        )
                    })}

                        
                    </Menu>


                <Box
                    sx={classes.content}>

                        <Grid 
                            container 
                            spacing={3}
                            justifyContent="center" 
                        >

                            {/******************
                             * Liste des repas
                             ******************/}

                            <Grid 
                                item xs={12} 
                                lg={11}
                                xl={8}
                                order={1}
                            >

                                {/************************
                                 * APPORTS ENERGETIQUES
                                 ***********************/}

                                <Grid 
                                    container 
                                    spacing={3}
                                    justifyContent="center" 
                                    sx={{marginBottom: 3}}
                                >
                                    <Grid 
                                        item
                                        xs={12}
                                        md={!template ? 6 : 12}
                                    >
                                        <Box
                                            sx={classes.chartContainer}
                                            ref={macrosRef}
                                        >
                                                <Typography
                                                    sx={classes.statsTitle}
                                                >
                                                    Apports énergétiques
                                                </Typography>
                                                
                                                <MacrosListMobile 
                                                    meals={mealPlan ? mealPlan[SELECTED_DAY] : []}
                                                    fullWidth={desktop && !largeDesktop}
                                                />
                                                <Box
                                                    sx={{marginTop: 2}}
                                                >
                                                    <SecondaryButton
                                                        startIcon={<BarChart />}
                                                        onClick={()=>setOpenDialogMicrosNutriments(true)}
                                                    >
                                                        Répartition des micronutriments
                                                    </SecondaryButton>
                                                </Box>
                                        </Box>
                                        
                                    </Grid>

                                    {!template && (
                                        <Grid 
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Box
                                                sx={classes.chartContainer}>
                                                    <Box
                                                        sx={{display:"flex", flexDirection:"row", alignItems:"center"}}
                                                    >
                                                        <Typography
                                                                sx={classes.statsTitle}
                                                            >
                                                            Objectifs
                                                        </Typography>
                                                        <IconButton
                                                            sx={{marginLeft: 1.5}}
                                                            onClick={()=> setOpenCalories(true)}
                                                        >
                                                            <ModeEdit sx={{fontSize: 16}}/>
                                                        </IconButton>
                                                    </Box>
                                                    <MacrosGoal 
                                                        meals={mealPlan ? mealPlan[SELECTED_DAY] : []}
                                                    />
                                                    
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>

                                
                                {mealPlan && mealPlan[SELECTED_DAY]?.map((_, mealIndex) =>{
                                    return(
                                        <Meal 
                                            day={SELECTED_DAY}
                                            mealIndex={mealIndex}
                                            key={mealIndex}
                                        />
                                    )
                                })}
                            

                                {(!mealPlan || !mealPlan[SELECTED_DAY] || mealPlan[SELECTED_DAY].length === 0 ) && (

                                    <Box
                                        sx={classes.noMealContainer}
                                    >
                                        <Typography
                                            sx={classes.noMealContainerLabel}
                                        >
                                            Aucun repas programmé
                                        </Typography>
                                    </Box>

                                )}

                                <Box
                                    sx={classes.button}>

                                    <Button 
                                        onClick={()=> addMeal()} 
                                        sx={{textTransform: "none", width: {xs: "100%", sm: 200}, marginBottom: {xs: 1.5, sm: 0}}} 
                                        variant="contained"
                                        disableElevation={true}
                                        startIcon={<Add/>}
                                    >
                                        Ajouter un repas
                                    </Button>

                                    <Button 
                                        disabled={!mealCopied}
                                        onClick={()=> pasteMeal()} 
                                        sx={{textTransform: "none", width: {xs: "100%", sm: 200}, marginLeft: {xs: 0, sm: 3}, marginBottom: {xs: 1.5, sm: 0}}} 
                                        variant="contained"
                                        disableElevation={true}
                                        startIcon={<ContentCopy/>}

                                    >
                                        Coller un repas
                                    </Button>

                                    <Button 
                                        disabled={!dayCopied}
                                        onClick={()=> pasteDay()} 
                                        sx={{textTransform: "none", width: {xs: "100%", sm: 200}, marginLeft: {xs: 0, sm: 3}, marginBottom: {xs: 1.5, sm: 0}}} 
                                        variant="contained"
                                        disableElevation={true}
                                        startIcon={<ContentCopy/>}
                                    >
                                        Coller une journée
                                    </Button>
                                </Box>

                               
                            </Grid>

                            {/****************
                             * Statistiques
                             ***************

                            {!mobile && (
                                <Grid 
                                    item 
                                    xs={12} 
                                    lg={4}
                                    xl={4}
                                    order={2}
                                >

                                

                                    <Box
                                        sx={classes.chartContainer}>
                                            <Typography
                                                    sx={classes.statsTitle}
                                                >
                                                Apports énergétiques
                                            </Typography>
                                            
                                            <MacrosListMobile 
                                                meals={mealPlan ? mealPlan[SELECTED_DAY] : []}
                                                fullWidth={desktop && !largeDesktop}
                                            />
                                    </Box>

                                {!template && (<Box
                                        sx={classes.chartContainer}>
                                            <Box
                                                sx={{display:"flex", flexDirection:"row", alignItems:"center"}}
                                            >
                                                <Typography
                                                        sx={classes.statsTitle}
                                                    >
                                                    Objectifs
                                                </Typography>
                                                <IconButton
                                                    sx={{marginLeft: 1.5}}
                                                    onClick={()=> setOpenCalories(true)}
                                                >
                                                    <ModeEdit sx={{fontSize: 16}}/>
                                                </IconButton>
                                            </Box>
                                            <MacrosGoal 
                                                meals={mealPlan ? mealPlan[SELECTED_DAY] : []}
                                            />
                                            
                                    </Box>)}
                                    

                                    {/************
                                     * MICRONUTRIMENTS
                                     ************

                                    {!mobile && (
                                        <Box
                                            sx={classes.chartContainer}
                                        >
                                            <MicroNutriments 
                                                meals={mealPlan && mealPlan[SELECTED_DAY]}
                                                template={template}
                                                noPadding={true}
                                            />
                                        </Box>
                                    )}

                
                                </Grid>
                            )}
                                */}
                        </Grid>

                        
                </Box>

                {!template && (
                    <DialogPasteMealPlan
                        open={openPasteMealPlan}
                        onClose={()=>setOpenPasteMealPlan(false)}
            
                    />
                )}

                {!template && (
                    <DialogSaveMealPlanTemplate
                        open={openTemplateSave}
                        onClose={()=> setOpenTemplateSave(false)}
                    />
                )}

                {!template && (
                    <DialogImportMealPlan
                        open={openImportTemplate}
                        onClose={()=>setOpenImportTemplate(false)}
                    />
                )}

                {!template && (
                    <DialogActivateMealPlan
                        open={openActivateMealPlan}
                        onClose={()=> setOpenActivateMealPlan(false)}
                    />
                )}

               <DialogMetabolism
                    open={openDialogMetabolism}
                    onClose={()=>setOpenDialogMetabolism(false)}
                />

                <DialogCaloriesNeeded
                    open={openDialogCalories}
                    onClose={()=>setOpenDialogCalories(false)}

                />


                {!template && (
                    <DialogCalories 
                        open={openCalories}
                        onClose={()=>setOpenCalories(false)}
                        metabolism={METABOLISM}
                    />
                )}


                <DialogMicrosNutriments
                    open={openDialogMicroNutriments}
                    onClose={()=> setOpenDialogMicrosNutriments(false)}
                    meals={mealPlan && mealPlan[SELECTED_DAY]}
                    template={template}
                />

                {!template && (
                <DialogSendMealPlanNotification
                    open={openSendNotification}
                    onClose={()=>setOpenSendNotification(false)}
                />
                )}

                <FixedMacrosIndicator
                    show={showFixedIndicator}
                    day={SELECTED_DAY}
                />



                

       
        </Box>
    )
}