

export default function formatMinutes(minutes:number){

    if(minutes<10){
        return `0${minutes}:00`
    }
    
    return `${minutes}:00`
    
}