import { Box, Grid, Typography } from "@mui/material";
import { getClientsPhotos } from "api/clients";
import { AppDispatch, RootState } from "app/store";
import Loader from "components/molecules/Loader";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles";
import { format, isSameDay } from "date-fns";
import { fr } from "date-fns/locale";
import EmptySearch from "components/molecules/EmptySearch";
import { ViewportList } from "react-viewport-list";

const EmptyPhoto = ({view}:{view:string}) => {
    return(
        <Box
            sx={classes.emptyPhoto}
        >
            <Typography
                sx={classes.view}
            >
            {view}
            </Typography>
            <Typography
                sx={classes.labelNoPhoto}
            >
                Aucune photo postée
            </Typography>
        </Box>

    )
}



export default function ClientPhotos(){

    const clientId = useSelector((state:RootState)=> state.clients.profile?._id)
    const photos = useSelector((state:RootState)=> state.clients.profile?.photos)
    const pending = useSelector((state:RootState) => state.clients.requests.getClientPhotos === "pending")
    const dispatch = useDispatch<AppDispatch>()
    const listRef = useRef(null);

    useEffect(()=>{
        dispatch(getClientsPhotos({clientId}))
    },[])


    return(
        <Grid 
            container 
            justifyContent="center" 
            spacing={0}
        >
            <Grid 
                item xs={12} 
                sm={12} 
                md={10} 
                lg={8} 
                xl={6}
            >
                {/*** pending clients photos, show loader */}
                {pending && (
                    <Loader />
                )}

                {photos?.length ===0 && (
                    <EmptySearch
                        description={`Ton client n'a posté aucune photo de son évolution`}
                        title="Aucune photo"
                    />
                )}

                {(!pending && photos?.length> 0) && (

                <ViewportList
                    ref={listRef}
                    items={photos}
                    initialPrerender={5}
                >
                    {(item,index) => (
                        <Box
                            sx={classes.album}
                            key={index}
                        >
                        <Box
                            sx={classes.header}
                        >
                            <Typography
                                sx={classes.date}
                            >
                                {isSameDay(new Date(item.date), new Date()) ? `Aujourd'hui à ${format(new Date(item.date), "HH:mm")}` : format(new Date(item.date), "eeee dd MMM à HH:mm", {locale: fr})}
                            </Typography>
                            {item.weight && (
                                <Typography
                                    sx={classes.weight}
                                >
                                    {item.weight} kg
                                </Typography>
                            )}
                        </Box>

                        <Box
                            sx={classes.photosContainer}
                        >
                            <Box
                                sx={classes.photosList}
                            >
                                {/* face */}
                                {item.list.find((elem)=> elem.viewingAngle ==="face") ? (
                                    <Box
                                        component="img"
                                        src={item.list.find((elem)=> elem.viewingAngle ==="face").url}
                                        sx={classes.photo}
                                    />
                                ) : <EmptyPhoto 
                                    view={"De face"}
                                />}

                                {/* side */}
                                {item.list.find((elem)=> elem.viewingAngle ==="side") ? (
                                    <Box
                                        component="img"
                                        src={item.list.find((elem)=> elem.viewingAngle ==="side").url}
                                        sx={classes.photo}
                                    />
                                ) : <EmptyPhoto 
                                    view={"De côté"}
                                />}

                                {/* back */}
                                {item.list.find((elem)=> elem.viewingAngle ==="back") ? (
                                    <Box
                                        component="img"
                                        src={item.list.find((elem)=> elem.viewingAngle ==="back").url}
                                        sx={classes.photo}
                                    />
                                ) : <EmptyPhoto 
                                    view={"De dos"}
                                />}
                            </Box>
                            
                        </Box>
                        

                    </Box>
                    )}
                </ViewportList>

                )}

               

            </Grid>
        </Grid>
    )
}