
import { Box, Grid, useMediaQuery } from "@mui/material";
import PageTitle from "components/atoms/Typography/PageTitle";
import Header from "components/molecules/Header";
import Tabs from "components/molecules/Tabs";
import Toolbar from "components/molecules/Toolbar";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DESKTOP, MOBILE, TABLET } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import classes from "./styles";
import MainContent from "components/molecules/MainContent";
import { ViewportList } from "react-viewport-list";
import FoodRow from "components/molecules/FoodRow";
import EmptySearch from "components/molecules/EmptySearch";
import FoodType from "interfaces/Food";
import DialogFood from "components/organisms/DialogFood";
import DialogAddFood from "components/organisms/DialogAddFood";
import FoodInterface from "interfaces/Food";
import DialogDeleteFood from "components/molecules/DialogDeleteFood";
import { searchFood } from "features/foodSlice";
import { selectDrawerItem } from "features/appSlice";
import DialogSimplifiedFood from "components/organisms/DialogSimplifiedFood";
import { MealContent } from "interfaces/Meal";
import FixedSearchHeader from "components/molecules/FixedSearchHeader";
import Navbar from "components/organisms/NavBar";

const LISTS: string[] = ["app", "ciqual", "custom"];


export default function Food(){
    
    const dispatch = useDispatch<AppDispatch>()
    const [_, setSearch] = useState<string>("");
    const [tabs, setTabs] = useState(0);
    const food = useSelector((state:RootState) => state.food.filtered)
    const listRef = useRef(null);
    const [foodSelected, setFoodSelected] = useState<MealContent>(null)
    const [foodSelectedToDelete, setFoodSelectedToDelete] = useState<FoodType>(null)
    const [openFoodDialog, setOpenFoodDialog] = useState<boolean>(false)
    const [openAddFood, setOpenAddFood] = useState<boolean>(false)
    const [openDeleteFood, setOpenDeleteFood] = useState<boolean>(false)
    const pending = useSelector((state:RootState) => state.food.requests.getAllFood === "pending")
    const [foodToSimplify, setFoodToSimplify] = useState<FoodType>(null)
    const[openSimplifyFood, setOpenSimplifyFood] = useState<boolean>(false)
    const IS_DESKTOP = useMediaQuery(DESKTOP);

    
    useEffect(()=>{
        dispatch(selectDrawerItem(3))
    },[dispatch])


    const onOpenDialogDelete = (food:FoodInterface) => () => {
        setFoodSelectedToDelete(food)
    }


    useEffect(()=>{
        if(Boolean(foodSelectedToDelete)) {
            setOpenDeleteFood(true)
        }
    },[foodSelectedToDelete])


    useEffect(()=>{
        if(!openDeleteFood && foodSelectedToDelete){
            setFoodSelectedToDelete(null)
        }
    },[openDeleteFood])


    {/***********************
        Fermeture du dialog
    ***********************/}

    useEffect(()=>{
        if((!openFoodDialog && foodSelected)){
            setTimeout(()=>{
                setFoodSelected(null)
            },200)
        }
    },[openFoodDialog,openDeleteFood])


    /*************** 
     * TABS LABELS 
     * ************* */

    const tabsLabels = useMemo(() => {
        return [
            `Simplifié (${food.app.length})`, 
            `Table Ciqual (${food.ciqual.length})`,
            `Mes aliments (${food.custom.length})`
        ];
    }, [food]);

    /************** 
     * RECHERCHE 
     * ********** */

    const onSearch = (e: any) => {
        setSearch(e.currentTarget.value);
        dispatch(searchFood({ search: e.currentTarget.value }));
    };


    /******************* 
     * GESTION DES TABS 
     * **************** */

    const onChangeTabs = (_: React.SyntheticEvent, newValue: number) => {
        setTabs(newValue);
    };


    const FOOD_LIST = useMemo(()=>{
        const tabSelected = LISTS[tabs] as keyof typeof food
        return food[tabSelected]
    },[tabs, JSON.stringify(food), pending])



    const onClickFood = (food:FoodType) => ()=>{

        const fakeMealContent:MealContent = {
            food: food,
            type: "food",
            portion: 100,
            label: "grammes",
            grammage: 100

        } 

        setFoodSelected(fakeMealContent)
        setOpenFoodDialog(true)
    }


    const onSelectFoodToSimplify = (food:FoodType) =>{
        setFoodToSimplify(food)
    }


    useEffect(()=>{
        if(foodToSimplify){
            setOpenSimplifyFood(true)
        }else{
            setOpenSimplifyFood(false)
        }
    },[foodToSimplify])

    



    return(
        <React.Fragment>
            <Navbar
                pageTitle="Liste des aliments"
            />

             {/********************** 
             * FIXED HEADER SEARCH 
             * ********************/}

            <FixedSearchHeader 
                onSearch={onSearch}
                onSubmit={() => setOpenAddFood(true)}    
                submitLabel={"Ajouter un aliment"}
                searchLabel={"Rechercher un aliment"}
                filter={false}

            />

            <MainContent
                withSearch={true}
            >
                {/***************************************************************
                *          LISTE DES ALIMENTS AVEC MENU DE FILTRAGE
                ***************************************************************/}

                    
                        <Grid
                            container
                            justifyContent="center"
                            sx={{ paddingBottom: 10 }}
                        >

                            


                            {/********************* 
                             * LISTE DES ALIMENTS 
                             * *******************/}

                            <Grid 
                                item 
                                xs={12} 
                                sm={12} 
                                md={12} 
                                lg={10} 
                                xl={8}
                            >

                                {/******* 
                                 * TABS
                                 * *****/}


                                <Tabs 
                                    tabs={tabsLabels} 
                                    value={tabs} 
                                    onChange={onChangeTabs} 
                                    sx={{marginBottom: 2}}
                                />

                                {(FOOD_LIST.length > 0 && !pending) && (





                                <Box 
                                    ref={listRef}
                                    sx={classes.container}
                                >
                                    <ViewportList
                                        ref={listRef}
                                        items={FOOD_LIST}
                                        initialPrerender={15}
                                        itemSize={95}
                                    >
                                        {(item) => (
                                            <Box
                                                key={item._id}
                                                onClick={onClickFood(item)}>

                                                <FoodRow
                                                    item={item}
                                                    hideButton={true}
                                                    showBackground={true}
                                                    canDelete={tabs === 2}
                                                    openDialogDelete={onOpenDialogDelete(item)}
                                                    onSelectFoodToSimplify={onSelectFoodToSimplify}
                                                />
                                            </Box>
                                        )}
                                    </ViewportList>
                                </Box>
                                )}
                            

                                
                            </Grid>
                        </Grid>
                    

                        {/**************** 
                         * AUCUN RESULTAT 
                         * ************* */}

                        {(!pending && FOOD_LIST.length === 0) && (
                            <EmptySearch 
                                title={"Aucun aliment"}
                                description={"Aucun aliment trouvé"} 
                            />
                        )}
                    
         
            </MainContent>


            <DialogAddFood
                open={openAddFood}
                onClose={()=> setOpenAddFood(false)}
            />


            <DialogFood
                open={openFoodDialog}
                onClose={()=>setOpenFoodDialog(false)}
                item={foodSelected}
            />



            <DialogDeleteFood
                open={openDeleteFood}
                onClose={()=> setOpenDeleteFood(false)}
                item={foodSelectedToDelete}
            />

            {foodToSimplify && (
                <DialogSimplifiedFood
                    open={openSimplifyFood}
                    onClose={()=>setFoodToSimplify(null)}
                    originalFood={foodToSimplify}
                />

            )}

        </React.Fragment>
    )
}