import { Add, Close, Delete, Save, YouTube} from "@mui/icons-material"
import { Box, Grid, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import TitleTextField from "components/molecules/TitleTextField"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Prompt, useHistory, useParams } from "react-router-dom"
import classes from "./styles"
import debounce from "lodash.debounce"
import { AUTOSAVE_TIMER, BORDER_COLOR, DESKTOP, INPUT_DEBOUNCE_TIMER, MOBILE, RECIPE_TAG, RECIPE_TAGS } from "utils/constants"
import MainContent from "components/molecules/MainContent";
import styled from "@emotion/styled"
import { theme } from "utils/theme"
import RecipeItem from "components/organisms/RecipeItem"
import { getRecipe, manualSaveRecipe, updateRecipe, uploadRecipeImage } from "api/recipes"
import SecondaryButton from "components/atoms/Buttons/Secondary"
import { onAddStep, onChangeRecipeBuilderPortions, onChangeRecipeDescription, onChangeRecipeName, onChangeStep, onDeleteStep, onHandleRecipeTag, recipeHasUnsavedChanges, recipeIsSaving, cleanRecipeBuilder } from "features/recipesSlice"
import DialogFoodList from "components/organisms/DialogFoodList"
import Meal from "interfaces/Meal"
import { ChipSelector } from "components/molecules/ChipSelector"
import Recipe from "interfaces/Recipe"
import Loader from "components/molecules/Loader"
import DialogLoader from "components/molecules/DialogLoader"
import { selectDrawerItem } from "features/appSlice"
import DialogSaveRecipe from "components/molecules/DialogSaveRecipe"
import DialogDeleteRecipeImage from "components/molecules/DialogDeleteRecipeImage"
import ContainedButton from "components/atoms/Buttons/ContainedButton"
import Navbar from "components/organisms/NavBar"
import MacrosListMobile from "components/organisms/MacrosListMobile"
import ImageVideoSelector from "components/organisms/ImageVideoSelector"
import checkVideoLink from "function/checkVideoLink"
import Kcal from "components/atoms/Kcal"




const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
           
        }
    },
}))



export default function RecipeBuilder(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const params:{recipeid:string} = useParams()
    const createdBy = useSelector((state:RootState) => state.user.data._id)
    const pending = useSelector((state:RootState)=> state.recipes.requests.update === "pending")
    const pendingManualSave = useSelector((state:RootState)=> state.recipes.requests.manualSave === "pending")
    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)
    const [localDescription, setLocalDescription] = useState<string>('')
    const [localName, setLocalName] = useState<string>('')
    const [formIsReady, setFormIsReady] = useState<boolean>(false)
    const recipeBuilderName = useSelector((state:RootState) => state.recipes.builder.name.fr)
    const recipeBuilderDescription = useSelector((state:RootState) => state.recipes.builder.description.fr)
    const recipeBuilderTags = useSelector((state:RootState) => state.recipes.builder.tags)
    const recipeBuilderIngredients = useSelector((state:RootState) => state.recipes.builder.ingredients)
    const recipeBuilderSteps = useSelector((state:RootState) => state.recipes.builder.steps)
    const recipeBuilderPortions = useSelector((state:RootState) => state.recipes.builder.portions)
    const [openDialogFood, setOpenDialogFood] = useState<boolean>(false)
    const [videoUrl, setVideoUrl] = useState<string>('')
    const [imageSelected, setImageSelected] = useState<{url:string, cloudFlareId?:string}>({url:null, cloudFlareId: null})
    const uploadPending:boolean = useSelector((state:RootState) => state.recipes.requests.uploadImage === "pending")
    const [openDeleteImage, setOpenDeleteImage] = useState<boolean>(false)
    const hasUnsavedChanges = useSelector((state:RootState) => state.recipes.hasUnsavedChanges)
    const mobile = useMediaQuery(MOBILE)
    const desktop = useMediaQuery(DESKTOP)
    const isDraft: boolean = useSelector((state:RootState) => state.recipes.builder?.draft, shallowEqual)
    const [openSaveRecipe, setOpenSaveRecipe] = useState<boolean>(false)



   /******************************* 
    * CHANGEMENT DE LA DESCRIPTION 
    * *************************** */

    const debounceOnChangeDescription = useRef(debounce((data)=> {
        dispatch(onChangeRecipeDescription({description: data.description}))
    },500)).current


    const onChangeLocalDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDescription:string = event.target.value
        setLocalDescription(newDescription)
        debounceOnChangeDescription({description: newDescription})
    }



    /***********************
        ETAPES
    ***********************/

    // Ajout
    const addStep = () => {
        dispatch(onAddStep())
    }

    // SUPPRESSION
    const deleteStep = (index:number) => ()=> {
        dispatch(onDeleteStep({stepIndex: index}))
    }

    // Changement
    const changeStep =  (index:number) => (event: React.ChangeEvent<HTMLInputElement>)=> {
        dispatch(onChangeStep({
            stepIndex: index, 
            step: event.target.value
        }))
    }

    // Portions
    const changeRecipeBuilderPortions = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeRecipeBuilderPortions({portions:event.target.value}))
    }


    /****************************************** 
     * FERMETURE, LA RECETTE N'EST PLUS READY 
     * ************************************** */

    useEffect(()=>{
        return function cleanup() {
            setFormIsReady(false)
            dispatch(cleanRecipeBuilder())
        };
    },[dispatch])



    /***************************************** 
     * MISE EN FORME DES DONNES DU FORMULAIRE 
     * ***************************************/

    const recipeForm = useMemo(()=>{
        const recipe:Recipe={
            _id: params?.recipeid,
            name:{
                fr: localName,
                en:""
            },
            description:{
                fr: localDescription,
                en: ""
            },
            tags: recipeBuilderTags,
            ingredients: recipeBuilderIngredients,
            steps:recipeBuilderSteps,
            portions: recipeBuilderPortions,
            publishForClient: false,
            createdBy,
            cover:imageSelected,
            video: {
                url: videoUrl
            },
            draft: isDraft
        }
        return recipe
    },[recipeBuilderName,recipeBuilderDescription, recipeBuilderTags, recipeBuilderIngredients, recipeBuilderSteps, recipeBuilderPortions, imageSelected, videoUrl])


    /******************** 
     *      AUTOSAVE 
     * ************** */

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((recipe)=> {
        dispatch(updateRecipe(recipe))
    }, AUTOSAVE_TIMER)).current


    // Appel du debounce à chaque changement
    useEffect(() => {
        if(formIsReady && isDraft){
            dispatch(recipeIsSaving())
            debounceAutoSave(recipeForm)
        }else if(formIsReady && !isDraft){
            dispatch(recipeHasUnsavedChanges())
        }
        // eslint-disable-next-line
    }, [recipeForm,isDraft])


    /******************** 
     * CHANGEMENT DU NOM 
     * **************** */

    const debounceOnChangeName = useRef(debounce((data)=> {
        const {newName} = data
        dispatch(onChangeRecipeName({ name : {fr: newName, en: ""}}))
    },INPUT_DEBOUNCE_TIMER)).current


    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>)=> {
        const newName:string = event.target.value
        setLocalName(newName)
        debounceOnChangeName({newName: newName})
    }


    /****************************** 
     * RETOUR A l'ECRAN PRECEDENT 
     * ***************************/

    const backToPreviousScreen = () =>{
        if(pending){
            setOpenWaitinUpdate(true)
            return
        }
        else{
            history.push(`/recipes`)
        }
        
    }

    const addFood = () => {
        setOpenDialogFood(true)
    }




    /****************************************** 
     * RECUPERATION DES DONNEES LE CAS ECHEANT 
     * **************** ************************/

    useEffect(()=>{
        dispatch(selectDrawerItem(4))
        //dispatch(definePageTitle({pageTitle: isDraft? "Création d'une recette (brouillon)" : "Modifier une recette"}))
        dispatch(getRecipe(params.recipeid)).unwrap().then((res:any)=>{
            
            setLocalName(res.recipe.name.fr)
            setLocalDescription(res.recipe.description.fr)
            if(res.recipe.video){
                setVideoUrl(res.recipe.video.url)
            }

            
            setTimeout(()=>{
                if(res.recipe.cover){
                    setImageSelected(res.recipe.cover)
                }
                setFormIsReady(true)
            },500)
        })
        
    },[params.recipeid])


    /*************************************
     * FAKE MEALS POUR CALCUL DES MACROS
     *************************************/

    const MEALS = useMemo(()=>{
    
        const meals:Meal[] = [{
            name:"recipe",
            time:"00:00",
            content:[]
        }]

        recipeBuilderIngredients.forEach((elem)=> {
            meals[0].content.push({
                type: "food",
                food: elem.ingredient.food,
                portion: elem.ingredient.portion,
                label: elem.ingredient.label,
                grammage: elem.ingredient.grammage
            })
        })

        return meals
  
    },[recipeBuilderIngredients])



    const selectTag = (elem:RECIPE_TAG) => ()=> {
        dispatch(onHandleRecipeTag({tag:elem.id}))
    }


    const onChangeLink = (e:React.ChangeEvent<HTMLInputElement>) => {
        setVideoUrl(e.target.value)
    }





    /******************************************
     * Sélection d'une miniature
     ******************************************/

    const onSelectThumbnail = (thumbnail:{url:string, cloudFlareId?:string}) => {
        setImageSelected(thumbnail)
    }


    /*********************************************************** 
     * SELECTION D'UN FICHIER LOCAL - Téléchargement de l'image 
     * **********************************************************/


    const uploadImage = (newFile:Blob) => {
        dispatch(uploadRecipeImage({
            recipeId: params.recipeid, 
            file: newFile
        })).unwrap().then((res:any)=>{
            if(res.image){
                setImageSelected(res.image)
                if(!isDraft){
                    dispatch(manualSaveRecipe({...recipeForm, cover: res.image}))
                }
               
            }
            
        })
    }

     /*************************************************** 
      * VERIFICATION DU STATUS DE SAUVEGARDE DU WORKOUT 
      * **************************************************/

     useEffect(()=>{
        // Si la recipe a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)

            
            history.push(
                {
                    pathname: `/recipes`,
                   
                }
            )
           
            
        }
    },[openWaitinUpdate, pending])



    const onSave = () =>{
        debounceAutoSave.cancel()
        dispatch(manualSaveRecipe(recipeForm))
    }




    if (formIsReady) {
    return(
        <React.Fragment>

            <Navbar
                pageTitle={"Créateur de recette"}
                backButton={backToPreviousScreen}
                showAutoSave={isDraft}
                showDraft={isDraft}
                /*action={{
                    Icon : Save,
                    callback: isDraft? ()=> setOpenSaveRecipe(true) : onSave
                }}*/
            />


            <MainContent 
                padding={mobile? false : true}
            >

                {/**************************
                 * IMAGE + PRESENTATION
                 ***************************/}

                <Grid 
                    container 
                    justifyContent="center" 
                    sx={{paddingBottom: 10,}}
                    spacing={3}
                >

                    <Grid
                        item
                        xs={12} 
                        sm={12} 
                        md={12} 
                        lg={10} 
                        xl={8}
                    >
                            {(mobile && isDraft) && (
                                <Box
                                    sx={{display:"flex", alignItems:"center", justifyContent:"flex-end", pl:2,pr:2,marginBottom: 3}}
                                >
                                    <SecondaryButton
                                        startIcon={<Save/>}
                                        onClick={()=> setOpenSaveRecipe(true)} 
                                    >
                                        Publier la recette
                                    </SecondaryButton>
                                </Box>
                            )}

                            {(mobile && !isDraft) && (
                                <Box
                                    sx={{display:"flex", alignItems:"center", justifyContent:"flex-end", pl:2,pr:2,marginBottom: 3}}
                                >
                                    <SecondaryButton
                                        startIcon={<Save/>}
                                        onClick={onSave} 
                                    >
                                        Sauvegarder les modifications
                                    </SecondaryButton>
                                </Box>
                            )}

                            <Box
                                sx={{paddingLeft: {xs:2, sm:0} ,paddingRight:{xs:2, sm:0}}}
                            >
                                <Box
                                    sx={{display:"flex", flexDirection: "row", justifyContent:"space-between", alignItems:"flex-start",  marginBottom: 4, }}
                                >

                                    {/*************************
                                     * RECIPE NAME
                                     **************************/}

                                    <Box>
                                        <TitleTextField 
                                            fullWidth 
                                            value={localName} 
                                            onChange={onChangeName}
                                            variant="standard" 
                                            sx={{
                                            
                                                "& .MuiInput-root": {
                                                    fontSize: "1.6rem !important",
                                                    width: {xs: "auto", sm: 500}
                                            }}}

                                        />

                                    
                                        <Box
                                            sx={classes.portions}
                                            >
                                            <Typography
                                            sx={{fontSize: 18}}
                                            >
                                                Recette pour 
                                            </Typography>
                                            <TextField
                                                value={recipeBuilderPortions}
                                                onChange={changeRecipeBuilderPortions}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 60, 
                                                    marginLeft: 2, 
                                                    marginRight: 2, 
                                                    "& .MuiInputBase-root": {
                                                        fontWeight: 500,
                                                        textAlign: "center" 
                                                    }
                                                }}
                                                
                                                type="number"
                                                onFocus={event => {
                                                    event.target.select();
                                                }}
                                            />
                                            <Typography
                                                sx={{fontSize: 18}}
                                            >
                                                portion(s)
                                            </Typography>
                                            
                                        </Box>
                                    </Box>

                                    {/*************************
                                     * BOUTON SAVE
                                     **************************/}

                                    {!mobile && (

                                        <Box 
                                            sx={{display:"flex", justifyContent:"flex-end"}}
                                        >
                                            {isDraft && (
                                                <ContainedButton 
                                                    onClick={()=> setOpenSaveRecipe(true)} 
                                                    startIcon={<Save />}
                                                    sx={{position: "relative", top:5}}
                                                    variant="contained"
                                                >
                                                    Publier la recette
                                                </ContainedButton> 
                                            )}


                                            {!isDraft && (
                                                <ContainedButton 
                                                    variant="contained"
                                                   sx={{position: "relative", top:5}}
                                                    onClick={onSave}
                                                    startIcon={<Save/>}
                                                    disableElevation={true}
                                                >
                                                    Sauvegarder
                                                </ContainedButton>
                                            )}

                                        </Box>

                                    )}
                                </Box>

                                {/*************************
                                 * TAGS
                                 **************************/}

                                <Box
                                    sx={classes.chips}
                                >

                                    <Typography 
                                        variant="body1" 
                                        sx={[classes.sectionLabel, {marginBottom: {xs: 0, sm: 2, lg: 2}}]}
                                    >
                                        Tags
                                    </Typography>

                                    
                            
                                    <Grid 
                                        container 
                                        spacing={2}
                                    >
                                        {RECIPE_TAGS.map((elem,index)=> {
                                            return(
                                                <ChipSelector
                                                    label={elem.label.fr} 
                                                    key={index} 
                                                    selected={Boolean(recipeBuilderTags.find((tag)=> tag === elem.id))} 
                                                    onClick={selectTag(elem)} 
                                                />
                                            )
                                        })}
                                    </Grid>
                                </Box>



                                {/**********************
                                 * BLOCK PRESENTATION
                                 ************************/}

                                <Box 
                                    component="section"
                                    sx={{
                                        border: `solid 1px ${BORDER_COLOR}`,
                                        marginTop: 5,
                                        marginBottom: 3,
                                        paddingBottom: 2,
                                        backgroundColor:"white",
                                        paddingLeft: 3,
                                        paddingRight: 3
                                }}
                                >

                          

                                <Grid
                                    container
                                    spacing={6} 
                                   
                                >

                                    {/******************
                                     * IMAGE / VIDEO
                                     ******************/}

                                    <Grid 
                                        item 
                                        xs={12}
                                        sm={6}
                                        lg={6}
                                        xl={5}
                                        order={{xs: 2, sm: 1}}
                                        sx={{ paddingTop: {xs: "0px !important", sm: "48px !important"}}}
                                    >
                                        <DescriptionTextField 
                                            fullWidth
                                            value={videoUrl}
                                            label={"Vidéo Youtube"}
                                            onChange={onChangeLink}
                                            sx={{mt:{xs: 5, sm :3}, mb: {xs:3, sm: 3}}}
                                            variant="standard" 
                                            placeholder="Copie le lien de ta vidéo Youtube"
                                            
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><YouTube sx={{color: "#ff0000"}} /></InputAdornment>,
                                                endAdornment: <InputAdornment position="end"> {checkVideoLink(videoUrl) ? "" : videoUrl !== "" ? <Close sx={{color: "red"}}/> : ""}</InputAdornment>
                                            }}
                                        />

                                        <ImageVideoSelector 
                                            formIsReady={formIsReady}
                                            imageSelected={imageSelected}
                                            onSelectThumbnail={onSelectThumbnail}
                                            uploadImage={uploadImage}
                                            openDeleteConfirmation={()=>setOpenDeleteImage(true)}
                                            videoUrl={videoUrl}
                                        />

                                        

                                       
                                    </Grid>

                                     {/****************************
                                     * PRESENTATION + LIEN YOUTUBE
                                     *******************************/}

                                    <Grid 
                                        item 
                                        xs={12} 
                                        sm={6}
                                        lg={6}
                                        xl={7}
                                     

                                        
                                    >
                                        <Box 
                                            sx={[
                                                classes.container, 
                                                classes.presentation
                                            ]}
                                        >


                                         {/*************************
                                         * PRESENTATION RECETTE
                                         **************************/}

                                        <Typography 
                                            variant="body1" 
                                            sx={classes.sectionLabel}
                                        >
                                            Présentation de la recette
                                        </Typography>

                                        <DescriptionTextField
                                            multiline={true}
                                            rows={6}
                                            maxRows={6}
                                            fullWidth
                                            value={localDescription}
                                            onChange={onChangeLocalDescription}
                                            placeholder={`(Optionnel) Ajoute un court texte pour présenter ta recette ou pour donner des conseils supplémentaires à tes clients`}
                                            variant="standard" 
                                        
                                        />

                                        
                                        


                                                    

                                                    
                                                    
                                        </Box>

                                        {/*************************
                                         * MACROS (DESKTOP)
                                         **************************/}
                                        {desktop && (
                                            <Box>
                                               
                                            <MacrosListMobile
                                                meals={MEALS}
                                            />
                                            </Box>
                                        )}
                                        
                                    </Grid>
                                
                                </Grid>

                                </Box>
                        </Box>

                                        

                        <Grid 
                            container
                            spacing={3}
                           
                        >
                            {/*************
                            * INGREDIENTS
                            **************/}

                            <Grid
                                item
                                xs={12}
                                lg={12}
                                xl={6}
                                sx={{
                                    
                                    //paddingLeft:{xs: 2, sm:0}, 
                                    //paddingRight: {xs: 2, sm:3}, 
                                    //borderRight: `solid 2px ${BORDER_COLOR}`,
                                    //paddingTop: 2
                                }}
                                
                            >
                                <Box 
                                    sx={[
                                        classes.container,
                                    ]}
                                >
                                    <Typography 
                                        variant="body1" 
                                        sx={[classes.sectionLabel, {marginBottom: {xs: 0, sm: 2, lg: 2}}]}
                                    >
                                        Ingrédients
                                    </Typography>


                                    {mobile && (
                                        <Box
                                            sx={{marginBottom: 2}}
                                        >
                                            <MacrosListMobile
                                                meals={MEALS}
                                            />
                                        </Box>
                                    )}
                                    
                                    
                                    
                                    {/************************
                                     * LISTE DES INGREDIENTS
                                     ************************/}

                                    {recipeBuilderIngredients.map((_,index)=> {
                                        return(
                                            <RecipeItem
                                                key={index}
                                                ingredientIndex={index}
                                            />
                                        )
                                    })}

                                    {recipeBuilderIngredients.length === 0 && (
                                        <Box>
                                            <Typography>
                                                Aucun ingrédient ajouté à la recette
                                            </Typography>
                                        </Box>
                                    )}

                                  


                                    {/***************************
                                     * Boutons AJOUT D'INGREDIENT
                                     ***************************/}

                                    <Box
                                        sx={classes.mealButtons}
                                    >
                                        <SecondaryButton 
                                            onClick={()=> addFood()} 
                                            sx={{textTransform: "none", marginRight: 1}} 
                                            disableElevation={true}
                                            startIcon={<Add/>}
                                        >
                                            Ajouter un ingrédient
                                        </SecondaryButton>
                                    </Box>
                                </Box>
                            </Grid>
                           
                            {/*************
                            * ETAPES
                            **************/}

                            <Grid
                                item
                                xs={12}
                                lg={12}
                                xl={6}
                                sx={{paddingTop: 2}}
                            >
                                <Box
                                    sx={[
                                        classes.container, 
                                        
                                    ]}
                                >
                                    <Typography 
                                        variant="body1" 
                                        sx={classes.sectionLabel}
                                    >
                                        Etapes de conception
                                    </Typography>

                                    {recipeBuilderSteps.length === 0 && (
                                        <Box>
                                            <Typography>
                                                Aucune étape ajoutée à la recette
                                            </Typography>
                                        </Box>
                                    )}

                            
                            
                                    {recipeBuilderSteps.map((elem, index)=>{
                                        return(
                                            <Box
                                                sx={classes.step}
                                                key={index}
                                            >
                                                <Typography
                                                    sx={classes.stepIndex}
                                                >
                                                    {index+1} - 
                                                </Typography>
                                                <DescriptionTextField
                                                    value={elem}
                                                    multiline={true}
                                                    fullWidth
                                                    variant="standard"
                                                    placeholder={`Description de l'étape`}
                                                    onChange={changeStep(index)}
                                                />
                                                <IconButton
                                                    onClick={deleteStep(index)}
                                                    sx={{marginLeft: 1}}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </Box>
                                        )
                                    })}


                                    {/*****************************
                                     * Ajouter une étape (BOUTON)
                                     ******************************/}

                                    <Box
                                        sx={{marginTop: 3}}
                                    >
                                        <SecondaryButton 
                                            onClick={()=> addStep()} 
                                            sx={{textTransform: "none", marginRight: 1}} 
                                            disableElevation={true}
                                            startIcon={<Add/>}
                                        >
                                                Ajouter une étape
                                        </SecondaryButton>
                                    </Box>
                                </Box>
                            </Grid>

                           
                           
                        </Grid>
                    </Grid>
                </Grid>

                

            </MainContent>



            <DialogFoodList
                    open={openDialogFood}
                    onClose={()=> setOpenDialogFood(false)}
                    fromRecipe={true}
                />


            <DialogLoader
                open={openWaitinUpdate || (pendingManualSave && !openSaveRecipe)}
                text={"Sauvegarde en cours ..."}
            />


            {/************************* 
             * LOADER ENREGISTREMENT 
             * ***********************/}

            <DialogLoader
                open={uploadPending}
                text={"Téléchargement de l'image ..."}
            />


            <DialogSaveRecipe 
                open={openSaveRecipe}
                onClose={()=> setOpenSaveRecipe(false)}
                recipe={recipeForm}
                debounce={debounceAutoSave}
            />


            <DialogDeleteRecipeImage
                open={openDeleteImage}
                onClose={()=> setOpenDeleteImage(false)}
                onSelectThumbnail={onSelectThumbnail}
            />


            <Prompt
                when={hasUnsavedChanges}
                message="Tu n'as pas sauvegardé tes changements, veux-tu quitter la page ?"
            />


        </React.Fragment>
    )
    }else{
        return(
            <Loader />
        )
    }
}