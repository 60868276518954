
import { theme } from "utils/theme"

const classes ={


    feedHeader : {
        display:"flex",
        flexDirection: {
            xs: "column",
            sm: "row",
        },
        alignItems: {
            xs: "flex-start",
            sm: "flex-end"
        },
        justifyContent:"flex-end",
        marginBottom: {
            xs: 3,
            sm: 6
        }
    },


    sectionTitle: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        marginBottom: {
            xs: 1.5,
            sm: 0
        }
        
    },

    
  
    
}

export default classes