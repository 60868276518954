import { BORDER_COLOR, CARD_BORDER_RADIUS, HOVER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


export const classes = {

    customWorkoutHeader : {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        marginBottom: 3
    },



    section: {
        backgroundColor: "white",
        padding: {
            xs: 1,
            sm: 3
        },
        paddingTop: "24px !important",
        borderRadius: CARD_BORDER_RADIUS,
        border: `solid 1px ${BORDER_COLOR}`,
        marginBottom: 3,
        display:"flex",
        flexDirection:"row",
        width: "100%"
    },



    pageHeader:{
        display:"flex", 
        flexDirection:"row", 
        justifyContent:"space-between", 
        alignItems:"center",
        marginBottom: 2.5,
        width: "100%",
    },


    headerDay : {
        textAlign: "center",
        flex: 1,
        backgroundColor: "white",
        border: `solid 1px ${BORDER_COLOR}`,
        paddingTop: 1,
        paddingBottom: 1,
        cursor: "pointer",
        position: "relative",
        jusifyContent:"center",
        alignItems:"center",
        minHeight: 85,

        "&:hover" : {
            backgroundColor: HOVER_COLOR
        }
    },


    programBar: {
        width: "100%",
        height: 8,
        display:"flex",
        alignItems:"center",
        border: `solid 1px ${BORDER_COLOR}`,
        borderTop: 0,
        flexDirection: "row"


    },

    program :{
        height: 8,
        display:"flex",
        alignItems:"center",
        border: `solid 1px ${BORDER_COLOR}`,
        borderBottom: 0,
        flexDirection: "row"
    },

    programName: {
        fontSize: 12,
        fontWeight: 500,
        marginLeft: 1,
        overflow: "hidden",
        textOverflow: "ellipsis", 
        //whiteSpace: "nowrap",
        display:"block",
        width: '100%',
        textWrap: "nowrap",

    },


    programPart : {
        display:"flex",
        position: "relative",
        flex: 1,
        backgroundColor: "blue",
        height: 26,
        alignItems:"center",
        overflow: "hidden"
    },


    headerDaysButton : {
        width: 70,
        //backgroundColor: "white",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        "&:hover":{
            cursor: "pointer",
            backgroundColor: HOVER_COLOR
        }
       
    },


    iconArrow: {
         borderRadius: 100, 
         width: "48px", 
         height: "100%",
         //backgroundColor: "white",
         display:"flex",
         alignItems:"center",
         justifyContent:"center",
         "&:hover":{
            //cursor: "pointer",
            backgroundColor: "transparent"
        }
        
    },

    headerDaysButtonLeft: {
        border: `solid 1px ${BORDER_COLOR}`,
        borderRight: 0,
    },

    headerDaysButtonRight: {
        border: `solid 1px ${BORDER_COLOR}`,
        borderLeft: 0,

    },


    tags : {
        display:"flex",
        flexDirection: "row",
        overflowX: {
            xs: "scroll",
            md: "hidden"
        },
        overflowY: {
            xs:"hidden"
        },
        paddingLeft: {xs: 2, sm: 0},
        paddingRight: {xs: 2, sm: 0},
        //borderBottom: `dashed 2px #a8a8a8`,
        marginBottom: 3
    },


    wodButtonsList : {
        display:"flex",
        flexDirection: "row",
      
    },

    wodButton: {
        backgroundColor: "white",
        border: `solid 1px ${BORDER_COLOR}`,
        padding: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        borderRadius: 2,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",

        "&:hover": {
            cursor: "pointer",
            backgroundColor: HOVER_COLOR
        }
    },
    

    wodButtonSelected:{
        backgroundColor: `${HOVER_COLOR}`
    },

    wodButtonTypo : {
        fontSize: 15,
        fontWeight: 500,
        color:'rgba(0,0,0,0.65)'
    },

    wodButtonTypoSelected : {
        fontSize: 15,
        fontWeight: 700,
        color:'rgba(0,0,0,0.85)'
    },

    tagInfo: {
        backgroundColor: "white",
        padding: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        marginBottom: 2,
        display:"inline-flex",
        border: `solid 1px ${BORDER_COLOR}`,
        flexWrap: "no-wrap",
        marginRight: 1,
        flexDirection: "row",
        alignItems:"center",
        height: 32,
        //borderRadius: 1,
        borderRadius: CARD_BORDER_RADIUS,
    },

    tagInfoDisabled:{
        backgroundColor: "#ffe1e8",
        "&:hover" : {
            backgroundColor: "#fce9ed !important",
        }

    },

    enabled:{
        backgroundColor: "#c7eac2"
    },

    disabled:{
        backgroundColor: "whitesmoke"
    },

    tagInfoLabel :{
        fontSize: "15px",
        flexWrap: "nonwrap",
        whiteSpace: "nowrap"
    },

    tagInfoClickable:{
        //backgroundColor: LIGHT_BUTTON_COLOR,
        cursor:"pointer",
        "&:hover":{
            backgroundColor: LIGHT_BUTTON_COLOR
        }
    },

    tagInfoEnabled: {
        backgroundColor: "#d5f1d7",
        border: "solid 1px #c8e1ca",

        "&:hover" : {
            backgroundColor: "#bbe4bd"
        }
    },

    tagInfoIcon : {
        marginRight: 0.5,
        color: "primary.main",
        fontSize: 18
    },

    disabledIcon: {
        color: "red"
    },

    tagInfoIconEnabled:{
        marginRight: 0.5,
        color: "#24c02b",
    },

    tagInfoValue: {
        fontWeight: 500,
        marginLeft: 1,
        fontSize: "15px",
        flexWrap: "nonwrap",
        whiteSpace: "nowrap"
    },

    activated : {
        backgroundColor : "#b6e2b6",
        color: "white",
        marginLeft: 2
    },

    pageHeaderWithMargin:{
        marginTop: 4
    },

    buttonsContainer :{
        display: "flex", 
        alignItems: "center", 
        justifyContent: "flex-end", 
        marginBottom: 2,
        marginTop: 2
    },

    chips:{
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center" 
    },

    chipContained:{
        marginRight: 1, 
        fontWeight: 700, 
        backgroundColor: "warning.main", 
        color: "white" 
    },

    btActions:{
        marginLeft: 3, 
        display: "flex", 
        flexDirection: "row"
    },

    addBlockButtonContainer:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    selectedHeaderDay : {
        backgroundColor: HOVER_COLOR
    },


    emptyWod : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection: "column"
    },

    restDayTitle : {
        fontSize: 30,
        fontWeight: 600,
        marginBottom: 2
    },

    headerTitle:{
        display:"flex", 
        flexDirection:"row", 
        justifyContent:"space-between", 
        alignItems:"center",
        paddingLeft: {xs:2, sm:0} ,
        paddingRight:{xs:2, sm:0}
    },

    iconsContainer : {
        display:{xs: "none", sm:"flex"}, 
        flexDirection:"row", 
        alignItems:"center", 
        backgroundColor:"whitesmoke",
        border: `solid 1px ${BORDER_COLOR}`,
        borderRadius: 3,
        padding: 1
    },

    iconButton : {
        //borderRadius: "30px !important",
        //backgroundColor: "white",
        //border: `solid 1px ${BORDER_COLOR}`
    },

    dayLabel : {
        fontSize: theme.typography.pxToRem(10),
        textTransform: "uppercase"
    },

    dayNumber : {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 700,
        color: "black",
        textTransform: "uppercase"
    },

    btDisabled : {
        backgroundColor: "white"
    }

   

   

}

export default classes