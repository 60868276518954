import { AssignmentOutlined, Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import { RootState } from "app/store";
import RowCard from "components/atoms/Box/RowCard";
import RowCardSubTitle from "components/atoms/Typography/RowCardSubTitle";
import RowCardTitle from "components/atoms/Typography/RowCardTitle";
import Checkup from "interfaces/Checkup";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ViewportList } from "react-viewport-list";
import classes from "./style"
import MoreButton from "components/atoms/Buttons/MoreButton";
import { useHistory } from "react-router-dom";
import DialogDeleteCheckup from "components/molecules/DialogDeleteCheckup";
import QuestionnaireStatus from "components/molecules/QuestionnaireStatus";
import checkQuestionnaireFormIsValid from "function/checkQuestionnaireFormIsValid";

export default function TemplateCheckup(){

    const navigate = useHistory()
    const listeRef = useRef(null);
    const checkups:Checkup[] = useSelector((state:RootState) => state.checkups.templates.filtered)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [checkupSelected, setCheckupSelected] = useState<Checkup>(null)

    /********** SUPPRESSION ********** */

    const selectCheckupToDelete = (program:Checkup) => ()=>{
        //e.stopPropagation()
        setOpenDeleteDialog(true)
        setCheckupSelected(program)
    }

    /********** REDIRECTION ********** */

    const navigateTo = (checkup: Checkup) => {
        navigate.push(`/templates/checkup/${checkup._id}`)
    }


    return(
        <Box ref={listeRef}>
            <ViewportList
                ref={listeRef}
                items={checkups}
                initialPrerender={15}
                itemSize={95}
            >
                {(item)=> (
                    <RowCard 
                        key={item._id}
                        onClick={()=>navigateTo(item)}
                    >
                        <Box sx={classes.rowInfos}>
                            <Box sx={{flexDirection:"row", display:"flex", alignItems:"center"}}>
                                <AssignmentOutlined sx={{marginRight: 2, color: "primary.main", fontSize:30}}/>
                                <Box sx={{marginRight: 2}}>
                                    <RowCardTitle>
                                        {item.name || "Aucun nom"}
                                    </RowCardTitle>
                                    <RowCardSubTitle>
                                        {item.questions.length} questions
                                    </RowCardSubTitle>
                                </Box>
                            </Box>
                            <Box sx={{marginRight: {xs: 0, sm: 3}, marginTop: {xs: 1.5, sm: 0}}}>
                                <QuestionnaireStatus 
                                    valid={checkQuestionnaireFormIsValid(item)}
                                />
                            </Box>
                        </Box>
                        <MoreButton
                            menuList={[
                                {
                                    label:"Supprimer",
                                    icon:<Delete/>,
                                    callback: selectCheckupToDelete(item)
                                }
                            ]}
                        />
                    </RowCard>
                        
                )}
            </ViewportList>


            {/*** DIALOG DELETE PROGRAM **** */}

            <DialogDeleteCheckup
                open={openDeleteDialog}
                onClose={()=> setOpenDeleteDialog(false)}
                checkup={checkupSelected}

            />
        </Box>
    )
}