import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import { postNotification } from 'api/notifications'



/************** INIT SLICE ****************/

interface NotificationSlice {
    list: Notification[],
    requests:{
        get:"pending"|"idle",
        create:"pending"|"idle",
        update: "pending" | "idle",
    },
}


/******** INIT STATE ********** */
const initialState:NotificationSlice = {
    list: [],
    requests:{
        get:"idle",
        create:"idle",
        update: "idle",
    },
} 




/*********************************************** */


// Slice
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,

  /*********************** REDUCERS ******************** */

  reducers: {
  },

  extraReducers(builder) {
    builder

    .addCase(postNotification.pending, (state) => {
        state.requests.create = "pending"
    })

    .addCase(postNotification.fulfilled, (state) => {
        state.requests.create = "idle"
    })
  }
})



export const {
 } = notificationsSlice.actions

export default notificationsSlice.reducer