import { lighten } from "@mui/material"
import { BORDER_COLOR, BORDER_WORKOUT_TEXTFIELD_COLOR, WORKOUT_BUILDER_TEXTFIELD_BGCOLOR, WORKOUT_BUILDER_TEXTFIELD_RADIUS } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    prescriptionValue:{
        flex: 1,
        width: 200,
        maxWidth: 200,
        height: 45,
        paddingLeft: 1,
        paddingRight: 1,
        //borderRight: "solid 1px gainsboro",

        "&:hover":{
            backgroundColor: lighten(theme.palette.primary.main, 0.95)
        }
    },

    prescriptionTextField:{
        backgroundColor: WORKOUT_BUILDER_TEXTFIELD_BGCOLOR,
        borderRadius: "10px !important",
        width: 190,
        height: 45,
        
    
        "& .MuiOutlinedInput-root": {
            fieldset: {
                //border: "solid 1px #e2e2e2",
                border: `solid 1px ${BORDER_COLOR}`,
                borderRadius: "10px !important",

            },
            fontWeight: 400,
            fontSize: "1.05rem",
            height: 50,
            color: "#202020",
            textAlign: "center",

            "&.Mui-disabled":{
                fieldset: {
                    //border: "solid 1px #e2e2e2",
                    border: "none",
                },
                fontWeight: 500,
                fontSize: "1rem",
            }
        },
    },

    noRest: {
        "& .MuiOutlinedInput-root": {
            opacity: 0.3,
            "&.Mui-focused":{
                opacity: 1
            }
        }
    }
}

export default classes