import { useEffect, useMemo, useState } from "react";
// MUI
import {  TextField, Box, Dialog, DialogTitle, DialogActions, Button, DialogContent, Stack, Chip, useMediaQuery, IconButton, Typography, styled } from "@mui/material";
// DATE PICKER
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// DATE FNS
import { addDays, addWeeks, differenceInCalendarDays, isBefore } from "date-fns";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// TOAST
import { toast } from "react-toastify";
// INterfaces
import Program from "interfaces/Program";
import { AppDispatch, RootState } from "app/store";

// Styles
import classes from "./styles"
// Shared components
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { checkProgramPlanningDate, createNewProgram } from "api/programs";
import { MOBILE, PROGRAM_COLORS, TABLET } from "utils/constants";
import { fr } from "date-fns/locale";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useHistory } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { theme } from "utils/theme";
import ProgramDescription from "components/molecules/ProgramDescription";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";

//***************** INTERFACES ********* */

interface Props {
    open: boolean,
    onClose: () => void,
    startDate?: Date,
    program?: Program,
    template?:boolean
}


const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            //borderBottom: "2px dashed rgb(169 169 169 / 42%)"
            borderBottom: 0
        }
    },
}))


//***************** COMPONENT ********* */

export default function DialogCreateProgram({ open, onClose, startDate, program, template }: Props) {

    const navigate = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.planning.user)
    const [name, setName] = useState<string>('')
    const [duration, setDuration] = useState<number>(1)
    const [start, setStart] = useState<Date>(new Date())
    const [end, setEnd] = useState<Date>(addDays(addWeeks(new Date(),1), -1))
    const [color, setColor] = useState<string>(PROGRAM_COLORS[0])
    const [errors, setErrors] = useState<boolean>(false)
    const pending = useSelector((state:RootState) => state.programs.requests.create === "pending")
    const checking = useSelector((state:RootState) => state.programs.requests.check === "pending")
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)
    const desktop = !mobile && !tablet
    const [description, setDescription] = useState<string>("")


    const handleChangeStartDate = (value:Date) => {
        setStart(value)
    }

    const handleChangeEndDate = (value:Date) => {
        setEnd(value)
    }


    const onAddWeeks = (value:number) => () => {
        setEnd(new Date(addDays(new Date(addWeeks(start,value)),-1)))
    }


    useEffect(()=>{

        setEnd(new Date(addDays(new Date(addWeeks(start,duration)),-1)))
    },[duration])


    useEffect(()=>{
        setStart(startDate)
        setEnd(addDays(addWeeks(startDate,1), -1))
    },[open])


    const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value)
    }



    /********** CREATiON DU PROGRAMME ************* */

    const onSubmit = () => {
 

        if(name === ""){
            toast.error("Ce programme n'a pas de nom")
            return
        }

        if(isBefore(end, start)){
            toast.error('La date de fin ne peut pas être antérieur à la date de début')
            return
        }

        if(differenceInCalendarDays(end, start) < 6){
            toast.error("Le programme doit durer au moins 1 semaine")
            return
        }

        if(!template){
            // Vérification de la plage de date
            dispatch(checkProgramPlanningDate({start, end, createdFor: user._id })).unwrap().then((res:any)=>{

                if(res.programs?.length>0){
                    toast.error('Un autre programme est compris dans cette plage de date')
                    return 
                    
                } 
                
                else {
                    const newProgram:Program = {
                        _id: undefined,
                        name,
                        startDate: start.toDateString(),
                        endDate: end.toDateString(),
                        template: template? template: false,
                        createdFor: user ? user._id : undefined,
                        color: color,
                        description
                    }
            
                    dispatch(createNewProgram({program:newProgram})).unwrap().then((res:any) => {
                        if(res.program){
                            toast.success('Programme ajouté au planning')
                        }
                        onClose()
                    })
                }
            })  
        } else {
            const newProgram:Program = {
                _id: undefined,
                name,
                startDate: start.toDateString(),
                endDate: end.toDateString(),
                template: template? template: false,
                createdFor: user ? user._id : undefined,
                color: color,
                description
            }
    
            dispatch(createNewProgram({program:newProgram})).unwrap().then((res:any) => {
                if(res.program.template){
                    navigate.push(`/templates/program/${res.program._id}`) 
                }
                onClose()
            })
        }

        
   
       
    }


    /********** JSX ************* */

    return (

        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={'md'}
            fullScreen={mobile}
            disableScrollLock={desktop}
            TransitionComponent={DialogTransition}
        >

            <DialogAppBar
                title={`Nouveau ${template ? "modèle" : "programme"}`}
                onClose={onClose}
            />

           
            <DialogContent>

                {/**** Programme description **** */}
                <Typography 
                        variant="body1" 
                        sx={classes.sectionLabel}
                    >
                        Configuration
                    </Typography>
     
                {/**** TEXTFIELDs **** */}
                <Stack>
                        <TextField
                            value={name}
                            label={`Nom du programme`}
                            sx={{ mb: 1, mt:1 }}
                            fullWidth={true}
                            onChange={(e)=> setName(e.target.value)}
                        />

                        {template && (
                            <>
                            <TextField
                                value={duration}
                                label={`Durée du programme en semaines`}
                                sx={{ mb: 1, mt:1.5 }}
                                fullWidth={true}
                                type={"number"}
                                onChange={(e)=> setDuration(parseInt(e.target.value))}
                                onFocus={event => {
                                    event.target.select();
                                }}
                            />


                            <Box sx={{display:"flex", flexDirection: "row", mt: 2, mb: 2.5}}>
                                <Chip clickable label={desktop ?"1 semaine" : "1 sem."} sx={{mr:1}} onClick={()=>setDuration(1)}/>
                                <Chip clickable label={desktop ?"4 semaines" : "4 sem."} sx={{mr:1}} onClick={()=>setDuration(4)}/>
                                <Chip clickable label={desktop ?"8 semaines" : "8 sem."} sx={{mr:1}} onClick={()=>setDuration(8)}/>
                                <Chip clickable label={desktop ?"12 semaines" : "12 sem."} sx={{mr:1}} onClick={()=>setDuration(12)}/>
                            </Box>
                        </>
                        )}

                        <Box sx={classes.colorChoice}>
                            {PROGRAM_COLORS.map((colorElem:string)=> {

                                const selected:boolean = color === colorElem
                                
                                return(
                                    <Box 
                                        key={colorElem}
                                        sx={classes.color(colorElem, selected)} 
                                        onClick={()=> setColor(colorElem)}
                                    />
                                )
                            })}
                            
                        </Box>


                        

                        {!template && (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                <DesktopDatePicker
                                    label="Début du programme"
                                    inputFormat="eeee dd MMMM yyyy"
                                    value={start}
                                    onChange={handleChangeStartDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            

                                <Box sx={{display:"flex", flexDirection: "row", mt: 2, mb: 2.5}}>
                                    <Chip clickable label={desktop ?"1 semaine" : "1 sem."} sx={{mr:1}} onClick={onAddWeeks(1)}/>
                                    <Chip clickable label={desktop ?"4 semaines" : "4 sem."} sx={{mr:1}} onClick={onAddWeeks(4)}/>
                                    <Chip clickable label={desktop ?"8 semaines" : "8 sem."} sx={{mr:1}} onClick={onAddWeeks(8)}/>
                                    <Chip clickable label={desktop ?"12 semaines" : "12 sem."} sx={{mr:1}} onClick={onAddWeeks(12)}/>
                                </Box>

                                <DesktopDatePicker
                                    label="Fin du programme"
                                    inputFormat="eeee dd MMMM yyyy"
                                    value={end}
                                    onChange={handleChangeEndDate}
                                    renderInput={(params) => <TextField {...params} />}
                                    
                                />

                                

                            </LocalizationProvider>
                        )}


                        
                    
                        
                </Stack>
                    <ProgramDescription
                        value={description}
                        onChange={onChangeDescription}
                    />
            </DialogContent>

     
            <DialogActions>
                {!mobile && (
                <Button onClick={onClose} disabled={pending || errors}>
                    Annuler
                </Button>
                )}

                {/********* CREATION ****** */}
                {!program && (
                    <LoadingButton 
                        onClick={onSubmit} 
                        disabled={pending || errors || checking} 
                        loading={pending || checking}
                    >
                        {pending ? "Création ..." : "Créer le programme"}
                    </LoadingButton>
                )}
            </DialogActions>
     
        </Dialog>
    )
}