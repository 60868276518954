
import { Avatar, Box, Card, Typography, useMediaQuery } from "@mui/material";
import { format, isSameDay } from "date-fns";
import React, { useMemo, useState } from "react";
import { BODY_MEASURES, MOBILE, SATISFACTION } from "utils/constants";
import classes from "./styles"
import DialogChartMeasure from "components/organisms/DialogChartMeasure";
import DialogQuestionnaireResume from "components/organisms/DialogQuestionnaireResume";
import { HistoryEventDetails } from "interfaces/User";
import { fr } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { handleSubscriptionPresentation } from "features/appSlice";
import DialogWorkoutResults from "components/organisms/DialogWorkoutResults";
import calcTonnage from "function/calcTonnage";
import { theme } from "utils/theme";
import Icon from "components/atoms/Icon";
import calcMacros from "function/calcMacros";
import DialogMealConsumed from "components/organisms/DialogMealConsumed";
import { Grade, StarBorderPurple500 } from "@mui/icons-material";
import DialogUserPhoto from "../DialogUserPhoto";
import Image from "interfaces/Image";
import { EVENTS_COLOR } from "utils/constants";




const ICON:any = {
    "workout": "dumbbell-filled",
    "measure": "balance",
    "questionnaire": "survey",
    "meal" : "meal",
    "userPhotos" : "camera"
}


//****** INTERFACES ******* */


interface Props{
    event: HistoryEventDetails,
}

//****** MAIN COMPONENT ******* */

export function EventCard({event}:Props) {

    const dispatch = useDispatch<AppDispatch>()
    const coach = useSelector((state:RootState) => state.user.data)
    const {user,action, date, type, questions, name} = event
    const [image, setImage] = useState<Image>(null)
    const [openImageDialog, setOpenImageDialog] = useState<boolean>(false)

   
    //let navigate = useHistory();
    const [openStatsChart, setOpenStatsChart] = useState<boolean>(false)
    const [openQuestionnaire, setOpenQuestionnaire] = useState<boolean>(false)
    const [openWorkout, setOpenWorkout] = useState<boolean>(false)
    const [openMeal, setOpenMeal] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)


    const onOpenImageDialog = (image:Image)=>{
        setImage(image)
        setOpenImageDialog(true)
    }

    const generateEventDescription = (event: HistoryEventDetails) => {

        switch(event.action){

            /*** WORKOUT ***** */
            case "workout" : {
                return(
                    <>
                        <Typography sx={{ml:0.5}}>
                            a effectué la séance <strong>{event.name}</strong>
                        </Typography>
                        <Box
                            sx={{display:"inline-flex", flexDirection:{xs: "column", sm: "row"}}}
                        >
                            <Box sx={
                                [classes.measureContainer, {marginRight:{xs:0, sm: 2}}]
                            }>
                                Difficulté: &nbsp;<strong>{event.workout.performed.rpe}</strong>
                            </Box>
                            <Box 
                                sx={classes.measureContainer}
                            >
                                Tonnage: &nbsp;<strong>{calcTonnage(event.workout)} kg</strong>
                            </Box>
                        </Box>

                        {/*** rating ****/}
                        <Box
                            sx={classes.rating}
                        >
                            {event.workout.performed?.satisfaction && (
                                    <>
                                        {Array.from(Array(event.workout.performed.satisfaction)).map((elem,index)=> {
                                            return(
                                                <Grade
                                                    sx={{marginRight: 2, color: theme.palette.primary.main}}
                                                    key={index}
                                                />
                                            )
                                        })}
                                        {event.workout.performed.satisfaction < 5 && (
                                            <>
                                                {Array.from(Array(5 - event.workout.performed.satisfaction)).map((_,emptyRateindex)=>(
                                                        <StarBorderPurple500
                                                            sx={{marginRight: 2, color: "#aaaaaa"}}
                                                            key={emptyRateindex}
                                                        />
                                                    )
                                                )}
                                                </>
                                            )}
                                        </>
                            )}
                            
                        </Box>
                        {event.workout.performed?.satisfaction && (
                            <Box
                                sx={{display:"flex", flexDirection:"row", alignItems:"center", marginTop: 1}}
                            >
                            <Typography
                                sx={classes.satisfaction}
                            >
                                    Évaluation :
                            </Typography>
                            <Typography sx={classes.satisfactionValue}>
                                {SATISFACTION[event.workout.performed?.satisfaction - 1]}
                            </Typography>
                            </Box>
                        )}
                        {event.workout.performed.note !== "" && (
                            <Typography
                                sx={classes.userNote}
                            >
                                "{event.workout.performed.note}"
                            </Typography>
                        )}
                    </>
                        
                    
                )
            }

            /*** MEASURE ***** */
            case "measure" : {
                if(event.value){
                    const measure = BODY_MEASURES.find((elem)=> elem.label === event.type)
                    return(
                        <>
                        <Typography>
                            a ajouté une nouvelle donnée corporelle
                        </Typography>
                        <Box sx={classes.measureContainer}>
                            <Typography sx={classes.measure}>
                                {measure.fr} :&nbsp; 
                            </Typography>
                            <Typography 
                                sx={[classes.measure,{fontWeight: 700}]}
                            >
                                {event.value}{measure.units}
                            </Typography>
                        </Box>
                        
                        </>
                    )
                }
                break;
            }

            /*** MEASURE ***** */
            case "questionnaire" : {
                return(
                    <Typography>
                        a répondu à un questionnaire : <strong>{event.name}</strong>
                    </Typography>
                )
            }

            case "meal" : {
                return(
                    <>
                        <Typography sx={{ml:0.5}}>
                            a ajouté le repas <strong>{event.name}</strong> à son journal
                        </Typography>
                        <Box
                            sx={{display:"flex", flexDirection:"column", justifyContent: "flex-start", alignItems:"flex-start"}}
                        >

                            <Box 
                                sx={classes.measureContainer}
                            >
                                Total calorique: &nbsp;<strong>{calcMacros([{...event.meal}]).kcal} kcal</strong>
                            </Box>

                            {event.meal.photo?.url && (
                                <Box sx={classes.photoContainer}>
                                    <Box
                                        component={"img"}
                                        src={event.meal.photo.url}
                                        sx={classes.mealPhoto}
                                        
                                    />
                                </Box>
                            )}
                            
                           
                        </Box>
                        
                    </>
                        
                    
                )
            }

            case "userPhotos" : {
                return(
                <>
                <Typography sx={{ml:0.5}}>
                    a ajouté {event.photos?.length} photo{event.photos?.length>1 ? "s" :""}
                </Typography>
                <Box
                    sx={classes.photosContainer}
                >
                    {event.photos.map((photo,index)=> {
                        return(
                            <Box
                                key={index}
                                sx={{position:"relative", marginRight: 2, display:"flex"}}
                            >
                                <Box
                                    component={"img"}
                                    src={photo.url}
                                    sx={classes.photo}
                                    onClick={()=>onOpenImageDialog(photo)}

                                />
                            </Box>
                        )
                    })}
                    
                    
                </Box>
                
                </>
                )
            }
        }
    }


    const onClickCard = (action:"questionnaire"|"workout"|"measure"|'meal'|"photo" | "userPhotos") => ()=> {
        // Aucun abonnement en cours
        if(!coach.subscription || !coach.subscription?.current){
            dispatch(handleSubscriptionPresentation({open:true}))
            return
        }


        if(action === "measure"){
            setOpenStatsChart(true)
        } 
        else if(action === "workout"){
            setOpenWorkout(true)
        }
        else if(action === "questionnaire"){
            setOpenQuestionnaire(true)
        }
        else if(action === "meal"){
            setOpenMeal(true)
        }
    }


    const TODAY = useMemo(()=>{
        return isSameDay(new Date(event.date), new Date())
    },[event.date])


    /******* JSX ******** */
    
    return(
        <Box sx={classes.container}>

            <Box 
                sx={classes.eventIndicatorContainer}>
                <Box 
                    sx={[
                        classes.eventIndicator,
                        {backgroundColor: EVENTS_COLOR[event.action as keyof typeof EVENTS_COLOR]}
                    ]} 
                >
                    <Icon
                        icon={ICON[event.action]}
                        size={18}
                        color="white"
                    />

                   

                </Box>
            </Box>

            <Box
                sx={{marginRight: 2, marginLeft: 1, fontWeight: 600, display:{xs: "none", sm: "block"}}}
            >
                {format(new Date(event.date), "HH:mm")}
            </Box>

            <Card 
                sx={classes.card} 
                elevation={0} 
                onClick={onClickCard(action)}
                
            >

                <Box 
                    sx={classes.cardInfos}>

                    {/******** CARD HEADER ******** */}

                    <Box sx={classes.cardHeader}>
                        <Avatar 
                            src={user?.avatar?.url} 
                            sx={{marginRight: 1.5, width: 50, height: 50}}

                        />
                    
                        <Box sx={{display:"flex", flexDirection:"column"}}>
                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"}}>
                                {mobile && (
                                    <Typography sx={classes.name}>
                                        {user?.firstname} {user?.lastname} - {TODAY ? `Aujourd'hui à  ${format(new Date(event.date), "HH:mm")}`: format(new Date(event.date), mobile ? "dd/MM/yyyy" : "dd MMM yyyy à HH:mm", {locale: fr})}
                                    </Typography>
                                )}
                                {!mobile && (
                                    <Typography sx={classes.name}>
                                        {user?.firstname} {user?.lastname}
                                    </Typography>
                                )}
                                <Typography variant={mobile ? "body2" : "body1"}>
                                    {generateEventDescription(event)}
                                 </Typography>
                            </Box>
                        </Box>
                    </Box>

                   

                </Box>
            </Card>
            

            {/********** CHART DIALOG ********** */}

            {(action === "measure") && (
                <DialogChartMeasure
                    open={openStatsChart}
                    onClose={()=>setOpenStatsChart(false)}
                    type={type}
                    user={user}
                />
            )}

            {(action === "questionnaire") && (
                <DialogQuestionnaireResume
                    open={openQuestionnaire}
                    onClose={()=>setOpenQuestionnaire(false)}
                    questions={questions}
                    name={name}
                    date={date}
                />
            )}


            {(action === "workout") && (
                <DialogWorkoutResults
                    open={openWorkout}
                    onClose={()=>setOpenWorkout(false)}
                    workout={event.workout}
                />
            )}


            {(action === "meal") && (
                <DialogMealConsumed
                    open={openMeal}
                    onClose={()=> setOpenMeal(false)}
                    meal={event.meal}
                />
            )}

            {(action === "userPhotos") && (
                <DialogUserPhoto
                    open={openImageDialog}
                    onClose={()=> setOpenImageDialog(false)}
                    image={image}
                    client={user}
                />
            )}


        </Box>
    )
}

export default React.memo(EventCard)
    