import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes= {

    title: {
        fontWeight: 700,
        
    },

    content : {
        backgroundColor: "#f6f6f6", 
        /*overflowY: {
            xs: "auto",
            lg: "hidden", 
        },*/
        //padding: 0,
    },

    evolutionSubTitle : {
        fontSize: 15,
        fontWeight: 500,
        marginBottom: 3
    },

    scrollBar: {
        height:"calc(100vh - 90px - 64px - 53px)", 
        paddingRight: 3
    },


    addComparaisonButton : {
        border: "dashed 4px #cfcfcf",
         width: "100%",
        height:"calc(100vh - 230px)",
    },

    buttonContainer:{

    },

    resume:{
        paddingBottom: 3,
        borderBottom: "solid 1px whitesmoke",
        backgroundColor: "white",
        padding: 2,

        /*marginLeft: {
            xs: 1, 
            sm: 3, 
            md:3
        },*/
        /*marginRight: {
            xs: 1,
            sm: 3
        }*/
    },


    comparatorIconContainer: {
        borderRadius: 100, 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center",
        width: 18,
        height: 18,
        marginLeft: 1.5,
        marginRight: 1
    },


    difference : {
        color: "green"
    },


    comparatorIcon:{
        color: "white",
        fontSize: 18
    },

    resumeValue : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"row"
    },


    workoutName : {
        fontSize: "24px",
        fontWeight: 700
    },

    row: {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        paddingTop: 1,
        paddingBottom: 1
    },

    rowLabel : {
        display:"flex",
        alignItems:"center",
        flexDirection:"row",
        justifyContent:"center"
    },

    value: {
        fontWeight: 700
    },

    block: {
        backgroundColor: "white",
        marginTop: 3,
        padding: {
            xs: 2,
            sm: 3
        },
        /*marginRight: {
            xs: 1,
            sm: 3,
            md: 3
        },
        padding: {
            xs: 2,
            sm: 3
        },
        marginLeft: {
            xs: 1, 
            sm: 3,
            lg: 0
        },*/
    },
    

    blockName: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 700,
        
    },


    blockTrack : {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 400,
    },

    exercice:{
        display:"flex",
        flexDirection: "column",
        position: "relative",
        paddingBottom: 6
    },

    imageContainer:{
        width: {
            xs: 70,
            sm: 110
        },
        marginLeft: {
            xs: 1.5,
            sm: 0
        },
        height: 70,
        borderRadius: 3,
        overflow: "hidden",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position: "relative",
    },

    exerciceImage:{
        width: 110,
    },

    replacedIndicator:{
        //position: "absolute",
        //top: "48px",
        //left: "55px",
        width: 30,
        height: 30,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 100,
        justifyContent:"center",
        alignItems:"center",
        display:"inline-flex",
        marginLeft: 2
    },

    header: {
        display:"flex",
        alignItems: {
            xs: "flex-start",
            sm: "center",
        },
        flexDirection: {
            xs: "column",
            sm: "row"
        },
        justifyContent:{
            xs: "flex-start",
            sm: "space-between",
        },
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: "white",
        paddingLeft: {
            xs: 0,
            sm: 2,
        },
        paddingRight:2
    },

    exerciceHeaderInfos:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    exerciceName: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 700,
        marginLeft: 2
    },

    exerciceSetsNumber: {
        marginLeft: 2,
        fontSize: theme.typography.pxToRem(14)
    },

    exerciceContent:{
        paddingLeft: {
            xs: 0,
            sm: 2,
        },
        paddingRight: {
            xs: 0,
            sm: 2,
        },
        paddingTop: 2
    },

    setHeader:{
        display:"flex",
        flexDirection: "row",
        width: "100%",
        justifyContent:"space-between",
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        paddingBottom: 1.5,
    },

    setHeaderLabel : {
        color: "rgba(0,0,0,.85)",
        fontWeight: 800,
        //textTransform: "uppercase",
        fontSize: theme.typography.pxToRem(15),
        display:"flex",
        flexGrow: 1,
        flex: 1,
        justifyContent:"center"
    },

    set : {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        paddingTop: 1,
        paddingBottom: 1,
        borderBottom: `solid 1px ${BORDER_COLOR}`,
    },

    withoutBorder : {
        borderBottom: 0,
    },

    setNumber: {
        width: {
            xs: "auto",
            sm: 100
        },
        maxWidth:{
            xs: "auto",
            sm: 100
        },
        minWidth: {
            xs: "auto",
            sm: 100
        },
        flexGrow: {
            xs: 1,
            sm:0,
        },
        justifyContent:"center",
        textAlign: "center",
        display:"flex",
        borderRight: `solid 1px ${BORDER_COLOR}`
    },

    perf: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        flex: 1,
        justifyContent:"center",
    },

    perfValueContainer:{
        display: "flex",
        flexDirection: "row",
        //maxWidth: 150,
        //minWidth: 90,
        justifyContent:"flex-start",
        
    },

    perfValue:{
        
        fontWeight: 700,
        color: "rgba(0,0,0,0.85)",
    },

    perfValueLarge: {
        minWidth: 90,
    },


    exerciceHeaderCompletion : {
        borderRadius: "100%",
        height: 48,
        justifyContent:"center",
        alignItems:"center",
        display:"flex"
    },


    exerciceCompletion: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(15)
    },

    groupContent : {
        backgroundColor: "white",
        marginTop: 3,
        border: "solid 1px whitesmoke",
        
    },

    superset: {
        borderLeft: `solid 5px ${theme.palette.primary.main}`,
        marginTop: 0,

    },


    supersetIndicator : {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        display:"flex",
        width: 90,
        textTransform: "uppercase",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        textAlign: "center",
        justifyContent:"center",
        alignItems:"center",
        marginTop: 3,
    },

    note: {
        backgroundColor: "white",
        marginTop: 3,
        padding: {
            xs: 2, 
            sm: 2
        },
        marginLeft: {
            xs: 1, 
            sm: 3, 
            md:3
        },
        marginRight: {
            xs: 1, 
            sm: 3, 
            md: 3
        },
    },

    clientNoteTitle: {
        fontSize: theme.typography.pxToRem(14)
    },

    clientNote: {
        fontSize: theme.typography.pxToRem(16),
        fontStyle: "italic",
    },

    bubbleComment : {
        backgroundColor: "whitesmoke",
        display:"flex",
        padding: 3,
        paddingTop: 1,
        paddingBottom:1,
        flex: 1,
        borderRadius: {
            xs:`0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
            sm:`0 ${theme.spacing(10)} ${theme.spacing(10)} ${theme.spacing(10)}`,
        },
        alignItems:"flex-start",
        flexDirection:"column",
        marginTop: 2,


    },

    clientNoteContent:{
        marginTop: 3,
        backgroundColor: "white",
        paddingBottom: 0,
        position: "relative",
        display:"flex",
        flexDirection: "row",
        marginLeft: {
            xs: 1.5,
            sm: 0
        },
        marginRight: {
            xs: 1.5,
            sm: 0
        }
    },


    noteIndicator: {
        display:"none",
        position: "absolute", 
        top: 0, 
        left: 70, 
        //backgroundColor: "whitesmoke",
        transform: "rotate(45deg)",
        //borderRight: `solid 1px ${BORDER_COLOR}`,
        //borderTop:`solid 1px ${BORDER_COLOR}`,
        width: 26, 
        height: 26, 
        zIndex: 3
    },

    drop: {
        fontSize: theme.typography.pxToRem(13),
        fontStyle: "italic"
    },


    labels :{
        display:"flex", 
        flexDirection:"row",
        justifyContent:"center", 
        alignItems:"center", 
        marginBottom: 3
    },

    labelContainer:{
        display:"flex",
        flexDirection:"row",
        marginLeft: 2,
        marginRight: 2
    },

    labelIndicator : {
        borderRadius: 100,
        width: 20,
        height: 20,
        backgroundColor: "whitesmoke",
        marginRight: 1
    },

    axisLegend : {
        display:"flex",
        flexDirection:"row",
        justifyContent: "space-between",
        paddingLeft: 3,
        paddingRight: 3,
        marginTop: 3,
        marginBottom: 2
    },

    axisLabel:{
        fontSize: 15,
        fontWeight: 600
    },

    comparator: {
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center"
    },


    coachComment : {
        backgroundColor: "#cad5ea",
    },

    coachCommentInactive : {
        backgroundColor: "#f0f0f0"
    },

    avatarContainer : {
        borderRadius: 100,
        width: 50,
        height: 50,
        overflow: "hidden",
        marginRight: 3
    },

    avatar: {
        width: 50,
        height: 50,
    },


    comparisonIndicator : {
        backgroundColor: theme.palette.primary.main,
        marginLeft: 2,
        display:"inline-block",
        paddingLeft: 1.5,
        paddingRight: 1.5,
        borderRadius: 3,
        fontSize: 14,
        fontWeight: 600,
        width: "fit-content",
        color :"white",
        marginTop: {
            xs: 1.5,
            sm: 0
        }
    },

    notFound: {
        backgroundColor: "#ebebeb",
    }




}


export default classes