import { SwapHoriz } from "@mui/icons-material"
import { Box, Card, styled, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material"
import Workout, { WorkoutBlock, WorkoutExercice } from "interfaces/Workout"
import React, { useMemo, useRef, useState } from "react"
import { EVOLUTION_COLORS, MOBILE } from "utils/constants"
import classes from "./styles"
import ComparisonIcon from "components/molecules/ComparisonIcon"
import { theme } from "utils/theme"
import convertFormatedTimeToSeconds from "function/convertFormatedTimeToSeconds"
import debounce from "lodash.debounce"
import { useSelector } from "react-redux"
import { RootState } from "app/store"
import User from "interfaces/User"
import convertSecondsToMinutes from "function/conertSecondsToMinutes"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
           
        }
    },
}))

interface Props{
    exercice: WorkoutExercice,
    block: WorkoutBlock,
    setsDone:number,
    setsLength: number,
    exerciceForComparison: WorkoutExercice,
    defineEvolutionColor: (evolution:"-"|"+"|"=")=>void,
    note?:string,
    client?:User,
    blockIndex?:number,
    exerciceIndex?: number,
    contentIndex?:number,
    onUpdateWorkoutForm?: (wod:Workout)=>void,
    workoutForm: Workout,
    coachNoteDefault:string,
    comparisonMode: boolean,
    blockTrack: boolean
}

export default function WorkoutResultExercice({
    exercice, 
    block, 
    onUpdateWorkoutForm, 
    blockIndex, 
    exerciceIndex, 
    contentIndex, 
    setsDone, 
    setsLength, 
    exerciceForComparison, 
    note, 
    client,
    workoutForm,
    coachNoteDefault,
    comparisonMode,
    blockTrack
}:Props){

    const mobile = useMediaQuery(MOBILE)
    const [coachNote, setCoachNote] = useState<string>(Boolean(coachNoteDefault) ? coachNoteDefault : "")
    const user = useSelector((state:RootState)=> state.user.data)
   


    const defineDifference = (current:number, old:number, label:string, isTime?:boolean) => {
        const currentValue = isNaN(current) ? 0 : current
        const oldValue = isNaN(old) ? 0 : old

        if(currentValue === 0 && oldValue === 0){
            return(
                <Box sx={{display:"inline-flex", flexDirection:"row"}}>
                    <Typography
                        sx={{color: EVOLUTION_COLORS.more}}
                    >
                        
                    </Typography>
                </Box>
            )
        }

        if((currentValue - oldValue) >0){
            return (
                <Box sx={{display:"inline-flex", flexDirection:"row"}}>
                    <ComparisonIcon comparisonValue="+"/>
                    <Typography
                        sx={{color: EVOLUTION_COLORS.more}}
                    >
                        +{isTime ? convertSecondsToMinutes(currentValue - oldValue) : currentValue - oldValue}
                    </Typography>
                </Box>
            )
        } else if((currentValue - oldValue) < 0){
            return (
                <Box sx={{display:"inline-flex", flexDirection:"row"}}>
                    <ComparisonIcon comparisonValue="-"/>
                    <Typography
                        sx={{color: EVOLUTION_COLORS.less}}
                    >
                        {isTime ? convertSecondsToMinutes(currentValue - oldValue) : currentValue - oldValue}
                    </Typography>
                    </Box>
            )
        } else {
            return (
            <Box sx={{display:"inline-flex", flexDirection:"row"}}>
                <ComparisonIcon 
                    comparisonValue="="
                />
                <Typography
                    sx={{color: EVOLUTION_COLORS.equal}}
                >
                    
                </Typography>
           
            </Box>
            )
        }
    }



        /******************************** 
     * CHANGEMENT DE LA DESCRIPTION 
     * *******************************/

        const debounceOnChangeDescription = useRef(debounce((data:string, workoutForm)=> {
            const newForm = {
                ...workoutForm
            }
            newForm.blocks[blockIndex].content[contentIndex].exercices[exerciceIndex].coachNote = data
            onUpdateWorkoutForm(newForm)
        },300)).current
    
    
        const onChangeLocalComment = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newDescription:string = event.target.value
            setCoachNote(newDescription)
            debounceOnChangeDescription(newDescription, workoutForm)
        }



    return(
        <Box 
        sx={classes.exercice}
    >
        <Box 
            sx={classes.header}
        >
            <Box 
                sx={classes.exerciceHeaderInfos}
            >
                {/*** IMAGE *** */}

            
                    <Box sx={classes.imageContainer}>
                        <Box 
                            component={"img"} 
                            src={exercice.exercice.image?.url} 
                            sx={classes.exerciceImage} 
                        />
                    </Box>
                

                

                {/***************** 
                 * EXERCICE NAME 
                 * ***************/}

                <Box sx={{display:"flex", flexDirection: "column"}}>
                    <Box
                        sx={{display:"flex", flexDirection:"row"}}
                    >
                        <Typography 
                            sx={classes.exerciceName}
                        >
                            {exercice.replaced? exercice.replaced.name.fr : exercice.exercice.name.fr } 
                         </Typography>
                        {(exercice.replaced && !mobile) && (
                            <Tooltip
                                title="Exercice remplacé par le client">
                                <Box
                                    sx={classes.replacedIndicator}>
                                        <SwapHoriz
                                            sx={{color:"white"}}/>
                                </Box>
                            </Tooltip>
                        )}
                    </Box>
        

                    {block.track && (
                        <Typography sx={classes.exerciceSetsNumber}>
                            {setsDone}/{setsLength} séries éffectuées
                        </Typography>
                    )}
                </Box>
            </Box>

            {(comparisonMode && Boolean(exerciceForComparison)) && (
                        <Box
                            sx={classes.comparisonIndicator}
                        >
                            Comparaison trouvée
                        </Box>
                    )}

                    {(comparisonMode && !Boolean(exerciceForComparison)) && (
                        <Box
                            sx={[classes.comparisonIndicator, classes.notFound]}
                        >
                            Aucune correspondance
                        </Box>
                    )}

           
            
        </Box>
        
        
            <Box sx={classes.exerciceContent}>
                {setsDone > 0 && (
                    <TableContainer component={Box}>
                        <Table aria-label="simple table">
                            {/******** TABLE HEADER ******** */}
                            <TableHead>
                                <TableRow
                                    
                                >
                                    <TableCell sx={{fontWeight: 600}} align="center">{mobile ? "#" : "Série"}</TableCell>
                                    <TableCell align="center"></TableCell>

                                    {/* Musculation */}
                                    {exercice.exercice.type?._id === "63e2583d4634b611780b1f9a" && (
                                        <>
                                            <TableCell align="center" sx={{fontWeight: 600}}>Reps</TableCell>
                                            {!mobile && (
                                                <TableCell align="left" sx={{textWrap: "nowrap"}} >
                                                    {comparisonMode ? "/séance préc" : "/Série préc."}
                                                </TableCell>
                                            )}
                                            {mobile && (
                                                <TableCell align="left" sx={{textWrap: "nowrap"}} >
                                                
                                                </TableCell>
                                            )}
                                            <TableCell align="center" sx={{fontWeight: 600}}>Poids {!mobile ?"(kg)" :""}</TableCell>
                                            {!mobile && (
                                            <TableCell align="left" sx={{textWrap: "nowrap"}} >
                                                {comparisonMode ? "/séance préc" : "/Série préc."}
                                            </TableCell>
                                            )}
                                            {mobile && (
                                                <TableCell align="left" sx={{textWrap: "nowrap"}} >
                                                
                                                </TableCell>
                                            )}

                                        </>
                                    )}

                                    {/* Autre */}
                                    {exercice.exercice.type?._id !== "63e2583d4634b611780b1f9a" && (
                                        <>
                                            <TableCell align="center">{mobile ? "Temps" : "Temps (min)"}</TableCell>
                                                {!mobile && (
                                                <TableCell align="left" sx={{textWrap: "nowrap"}} >
                                                    {comparisonMode ? "/séance préc" : "/Série préc."}
                                                </TableCell>
                                                )}
                                            {mobile && (
                                                <TableCell align="left" sx={{textWrap: "nowrap"}} >
                                                
                                                </TableCell>
                                            )}
                                            <TableCell align="center">
                                                {exercice.exercice.type?._id === "63e25db34634b611780b1fab" && ( mobile ? "Km" : "Distance (km)")}
                                            </TableCell>
                                            {exercice.exercice.type?._id === "63e25db34634b611780b1fab" ? (
                                                !mobile ? (
                                                    <TableCell align="left" sx={{textWrap: "nowrap"}} >
                                                        {comparisonMode ? "/séance préc" : "/Série préc."}
                                                    </TableCell>
                                                ):
                                                (<TableCell align="left" sx={{textWrap: "nowrap"}} >
                                                
                                                </TableCell>)
                                                )
                                            : <TableCell align="left" sx={{textWrap: "nowrap"}} ></TableCell>}
                                        </>
                                    )}
                                    
                                </TableRow>
                            </TableHead>

                            {/******** TABLE BODY ******** */}
                            <TableBody>
                                {exercice.sets.filter((set)=> set.performances.performed).map((set, setIndex)=>{

                                    const comparisonSets= exerciceForComparison?.sets[setIndex]
                                    const noComparaisonSets = !comparisonSets && exerciceForComparison
                                    const previousSet = setIndex !== 0 ? exercice.sets[setIndex - 1].performances : null
                                    const hasDropSet = Boolean(set.performances.dropset?.length >0)
                                    const borderBottom = "1px solid rgb(241 241 241)"

                                    return(
                                        <>
                                        <TableRow
                                            key={setIndex}
                                            hover 
                                            sx={{height: "40px !important", maxHeight: "40px !important"}}
                                            
                                            //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" sx={{borderBottom: hasDropSet ? 0 : borderBottom, }}>
                                                {setIndex + 1}
                                            </TableCell>

                                            <TableCell align="center" sx={{borderBottom: hasDropSet ? 0 :borderBottom}}>

                                            </TableCell>

                                                {/* exercice de musculation */}
                                                {exercice.exercice.type?._id === "63e2583d4634b611780b1f9a" && (
                                                    <>
                                                        {/* répétitions */}
                                                        <TableCell align="center" sx={{borderBottom: hasDropSet ? 0 : borderBottom, textWrap: "nowrap"}}>
                                                            <Typography
                                                                sx={[
                                                                    classes.perfValue, 
                                                                ]}
                                                            >
                                                                {comparisonSets ? 
                                                                    `${comparisonSets?.performances.reps ? comparisonSets?.performances.reps : "-"} =>` : 
                                                                    noComparaisonSets ? 
                                                                        `0 => ` 
                                                                        : ``
                                                                } {set.performances.reps ? set.performances.reps : "-"} 
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{borderBottom: hasDropSet ? 0 : borderBottom, textWrap: "nowrap"}}>

                                                            {/**** COMPARAISON SEANCE PRECEDENTE**** */}
                                                            { comparisonSets && Boolean(comparisonSets?.performances) ?  
                                                                defineDifference(parseFloat(set.performances.reps), parseFloat(comparisonSets?.performances.reps) ,"")
                                                                :""
                                                            }

                                                            {noComparaisonSets ?
                                                                defineDifference(parseFloat(set.performances.reps), 0, "reps" )
                                                            :  ""}

                                                            {/***** COMPARAISON SERIE PRECEDENTE ***** */}
                                                            {!comparisonMode && !comparisonSets && !noComparaisonSets && setIndex !== 0 &&  previousSet ?  
                                                                defineDifference(parseFloat(set.performances.reps),parseFloat(previousSet.reps), "") 
                                                                :""
                                                            }
                                                        </TableCell>

                                                        {/* poids */}
                                                        <TableCell align="center" sx={{borderBottom: hasDropSet ? 0 : borderBottom, textWrap: "nowrap"}}>
                                                            <Typography
                                                                sx={[classes.perfValue, (comparisonSets || noComparaisonSets) && classes.perfValueLarge]}
                                                            >
                                                                {(!set.performances.weight && !comparisonSets?.performances?.weight) && "-"} {/* Aucune donnée */}

                                                                {(set.performances.weight || comparisonSets?.performances?.weight) && (
                                                                    <>
                                                                        {comparisonSets ? comparisonSets?.performances?.weight? `${comparisonSets?.performances.weight} =>` : "- =>" : ""} {set.performances.weight ? set.performances.weight : "-"} 
                                                                    </>
                                                                )}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{borderBottom: hasDropSet ? 0 : borderBottom, textWrap: "nowrap"}}>

                                                            {/**** COMPARAISON SEANCE PRECEDENTE**** */}
                                                            { comparisonSets && Boolean(comparisonSets?.performances) ?  
                                                                defineDifference(parseFloat(set.performances.weight),parseFloat(comparisonSets?.performances.weight), "kg") 
                                                                :""
                                                            }

                                                            {noComparaisonSets ?
                                                                defineDifference(parseFloat(set.performances.weight), 0, "kg" )
                                                            :  ""}

                                                            {/***** COMPARAISON SERIE PRECEDENTE ***** */}
                                                            {!comparisonMode && !comparisonSets && !noComparaisonSets && setIndex !== 0 && previousSet ?  
                                                                defineDifference(parseFloat(set.performances.weight),parseFloat(previousSet.weight),"kg") 
                                                                :"" 
                                                            }
                                                        </TableCell>
                                                    </>
                                                )}

                                                {/* autres exercices */}
                                                {exercice.exercice.type?._id !== "63e2583d4634b611780b1f9a" && (
                                                    <>
                                                        {/* temps */}
                                                        <TableCell align="center" sx={{borderBottom: hasDropSet ? 0 : borderBottom, textWrap: "nowrap"}}>
                                                            <Typography
                                                                sx={[classes.perfValue]}
                                                            >
                                                                {(!set.performances.min && !comparisonSets?.performances?.min) && "-"} {/* Aucune donnée */}

                                                                {(set.performances.min || comparisonSets?.performances?.min) && (
                                                                    <>
                                                                        {comparisonSets ? comparisonSets?.performances?.min? `${comparisonSets?.performances.min} =>` : "- =>" : ""} {set.performances.min ? set.performances.min : "-"} 
                                                                    </>
                                                                )}
                                                            </Typography>

                                                        </TableCell>
                                                        <TableCell align="left" sx={{borderBottom: hasDropSet ? 0 : borderBottom, textWrap: "nowrap"}}>

                                                            {/**** COMPARAISON SEANCE PRECEDENTE**** */}
                                                            { comparisonSets && Boolean(comparisonSets?.performances) ?  
                                                                defineDifference(convertFormatedTimeToSeconds(set?.performances?.min), convertFormatedTimeToSeconds(comparisonSets?.performances?.min), "s", true) :
                                                                ""
                                                            }
                                                            {/***** COMPARAISON SERIE PRECEDENTE ***** */}
                                                            {!comparisonMode && !comparisonSets && setIndex !== 0 &&  previousSet ?  
                                                                defineDifference(convertFormatedTimeToSeconds(set.performances.min),convertFormatedTimeToSeconds(previousSet.min),"s", true) 
                                                                :"" 
                                                            }
                                                        </TableCell>

                                                        {/* distance */}
                                                        
                                                        <TableCell align="center" sx={{borderBottom: hasDropSet ? 0 : borderBottom, textWrap: "nowrap"}}>
                                                            {exercice.exercice.type?._id === "63e25db34634b611780b1fab" && (
                                                            
                                                                <Typography
                                                                    sx={[classes.perfValue]}
                                                                >
                                                                    {(!set.performances.km && !comparisonSets?.performances?.km) && "-"} {/* Aucune donnée */}

                                                                    {(set.performances.km || comparisonSets?.performances?.km) && (
                                                                        <>
                                                                            {comparisonSets ? comparisonSets?.performances?.km? `${comparisonSets?.performances.km} =>` : "- =>" : ""} {set.performances.km ? set.performances.km : "-"} 
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left" sx={{borderBottom: hasDropSet ? 0 : borderBottom, textWrap: "nowrap"}}>
                                                            {exercice.exercice.type?._id === "63e25db34634b611780b1fab" && (
                                                                <>
                                                                    {/**** COMPARAISON SEANCE PRECEDENTE**** */}
                                                                    { comparisonSets && Boolean(comparisonSets?.performances) ?   defineDifference(parseFloat(set.performances.km),parseFloat(comparisonSets?.performances.km),"km") :""}
                                                                    {/***** COMPARAISON SERIE PRECEDENTE ***** */}
                                                                    {!comparisonMode && !comparisonSets && setIndex !== 0 &&  previousSet ?  defineDifference(parseFloat(set.performances.km),parseFloat(previousSet.km),"km") :"" }
                                                                </>
                                                            )}
                                                        </TableCell>
                                                        
                                                    
                                                    </>
                                                )}
                                        </TableRow>

                                        {/**** DROPSET **** */}
                                        {hasDropSet && set.performances.dropset.map((dropset, dropsetIndex) => {

                                            const previousDropset = dropsetIndex === 0 ? set.performances : set.performances.dropset[dropsetIndex - 1]
                                            const last = dropsetIndex === set.performances.dropset.length - 1
                                        

                                            return(
                                                <TableRow
                                                    key={dropsetIndex}
                                                    hover 
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center" sx={{borderBottom: last ? borderBottom : 0}}>
                                                        Dropset
                                                    </TableCell>
                                                    <TableCell sx={{borderBottom: last ? borderBottom : 0}}></TableCell>
                                                    <TableCell align="center" sx={{borderBottom: last ? borderBottom : 0}}>
                                                        {dropset.reps}
                                                    </TableCell>
                                                    <TableCell sx={{borderBottom: last ? borderBottom : 0}}>
                                                        {!comparisonMode ? defineDifference(parseFloat(dropset.reps),parseFloat(previousDropset.reps),"reps") : ""}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{borderBottom: last ? borderBottom : 0}}>
                                                        {dropset.weight}
                                                    </TableCell>
                                                    <TableCell sx={{borderBottom: last ? borderBottom : 0}}>
                                                        {!comparisonMode ? defineDifference(parseFloat(dropset.weight),parseFloat(previousDropset.weight),"kg") : ""}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        </>
                                    )

                                    
                                })}
                            </TableBody>
                        </Table>

                    </TableContainer>
                )}

                {(setsDone === 0 && blockTrack) && (
                    <Typography
                        sx={{marginLeft: {xs: 1.5, sm: 0}}}
                    >
                        {client.firstname} n'a pas effectué cet exercice
                    </Typography>
                )}
                {(!blockTrack) && (
                    <Typography
                        sx={{marginLeft: {xs: 1.5, sm: 0}}}
                    >
                        Aucun suivi disponible
                    </Typography>
                )}



                {/************************
                 * COMMENTAIRE DU CLIENT
                 ************************/}
                {note && (
                     <Card
                        elevation={0}
                        sx={[
                            
                            classes.clientNoteContent,
                            { marginBottom: 3}
                        ]}>

                            <Box
                                sx={classes.avatarContainer}
                            >
                                {client.avatar?.url && (
                                    <Box
                                        component={"img"}
                                        src={client.avatar.url}
                                        sx={classes.avatar}
                                    >

                                    </Box>
                                )}
                            </Box>
                            
                            <Box
                                sx={classes.bubbleComment}
                            >
                                <Typography
                                    sx={{fontSize: 13, fontWeight: 400}}
                                >
                                    Commentaire de {client.firstname}
                                </Typography>
                                <Typography
                                    sx={classes.clientNote}
                                >
                                    {note}
                                </Typography>
                            </Box>

                       
                     </Card>
                )}
                
                {/************************
                 * COMMENTAIRE DU COACH
                 ************************/}
                 <Box

                        sx={[
                            classes.clientNoteContent,
                           {marginTop: note ? 0 : 3, marginBottom: 6}
                        ]}>

                            <Card
                                sx={classes.avatarContainer}
                                elevation={2}
                            >
                                {user.avatar?.url && (
                                    <Box
                                        component={"img"}
                                        src={user.avatar.url}
                                        sx={classes.avatar}
                                        
                                    >

                                    </Box>
                                )}
                            </Card>
                            
                            <Card
                                sx={[
                                    classes.bubbleComment,
                                    classes.coachCommentInactive,
                                    //(Boolean(coachNote) && coachNote !== "") && classes.coachComment,
                                    
                                ]}
                                elevation={1}
                            >
                               
                                <DescriptionTextField 
                                    fullWidth
                                    value={coachNote}
                                    onChange={onChangeLocalComment}
                                    sx={{paddingBottom: 1}}
                                    variant="standard"
                                    multiline={true} 
                                    placeholder="Ajoute un commentaire sur cet exercice pour ton client"
                                />
                            </Card>

                        <Box 
                            sx={[classes.noteIndicator, classes.coachComment]} 
                        />
                </Box>
            </Box>
        
        
    </Box>
    )
}