export const TIREDNESS = [
    'Très fatigué',
    'Fatigué',
    "En forme",
    "Très en forme",
]

export default function defineTiredness(tiredness:any){

    if(tiredness && !isNaN(tiredness)){
        return TIREDNESS[tiredness]
    } else {
        return "-"
    }

}