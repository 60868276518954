import React from "react";
import { useState } from "react";
import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import ContextMenuCategory from "components/atoms/Typography/ContextMenuCategory";


export interface MenuElem{
    icon: JSX.Element,
    label: string,
    callback: (e?:any)=> void,
    disabled?:boolean,
}


interface Props{
    menuList: MenuElem[],
    disabled?: boolean,
    contextLabel?: string
}


export default function MoreButton({menuList,disabled, contextLabel = null}: Props){

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);

    const openMenu = (e:any) =>{
        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(e.currentTarget)
    }

    const closeMenu = (e:any) => {
        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(null)
    }

    const onClick = (callback:Function) => (e:any)=>{
        e.stopPropagation()
        e.preventDefault()
        callback()
        setAnchorEl(null)
    }

    
    return(
        <React.Fragment>
            <IconButton 
                onClick={openMenu} 
                disabled={disabled} 
                sx={{borderRadius:"100px !important",width: 35, height: 35}}
            >
                <MoreHoriz sx={{color: "rgba(0,0,0,0.75)"}} />
            </IconButton>
        
            <Menu disableScrollLock={true}  anchorEl={anchorEl} open={open} onClose={closeMenu}>
                {contextLabel && (
                <ContextMenuCategory>
                    {contextLabel}
                </ContextMenuCategory>
                )}
                {menuList.map((item:MenuElem, index: number)=>(
                    <MenuItem key={index} onClick={onClick(item.callback)} disabled={item.disabled? true:false}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText>
                            {item.label}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}