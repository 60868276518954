import Exercice from "interfaces/Exercice"
import Workout, { WorkoutExercice } from "interfaces/Workout"


export default function findExerciceForComparison(exerciceId:string, workoutForComparison:Workout){
    if(workoutForComparison){
        const workout = workoutForComparison
        var toReturn:WorkoutExercice = null

        workout.blocks.filter((elem)=> elem.track === true).forEach((block)=> {
            block.content.forEach((group)=> {
                group.exercices.forEach((exercice)=> {
                    if(exercice.exercice._id === exerciceId){
                        toReturn = exercice
                    }
                })
            })
        })

        return toReturn
    }else{
        return null
    }
}