

export default function convertFormatedTimeToSeconds(formated:string){

    console.log('formated', formated)

    const minutes = formated?.split(":")[0] || "00"
    const seconds = formated?.split(":")[1] || "00"

    const total = parseInt(seconds) + parseInt(minutes) *60

    return total

}