import { Box, Card, Slide } from "@mui/material";
import classes from "./styles";
import MacrosListMobile from "components/organisms/MacrosListMobile";
import { useSelector } from "react-redux";
import { RootState } from "app/store";

interface Props{
    show: boolean,
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"
}

export default function FixedMacrosIndicator({show, day}:Props){

    const mealPlan = useSelector((state:RootState) => state.mealPlans?.mealPlan)
    

    return(
        <Slide 
            direction="up" 
            in={show} 
            mountOnEnter 
            unmountOnExit
        >
            <Box
                sx={classes.container}
            >
                <Card
                    sx={classes.macros}
                    elevation={3}
                    
                >
                        <MacrosListMobile 
                            meals={mealPlan ? mealPlan[day] : []}
                            
                        />
                </Card>

            </Box>
        </Slide>
    )
}