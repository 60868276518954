import React, { useEffect, useMemo, useRef, useState } from "react";
// MUI
import { Box,Grid, useMediaQuery} from "@mui/material";
// REDUX
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// Interfaces
import { AppDispatch, RootState } from "app/store";
import Checkup,{CheckupQuestion} from "interfaces/Checkup";
// Apis
import { checkupIsSaving, resetCheckupBuilder} from "features/checkupSlice";
// REDUCERS
import { handleName, addQuestion} from "features/checkupSlice";
// DEBOUNCE
import debounce from "lodash.debounce"
// LOCAL COMPONENTS
import Question from "components/organisms/CheckupQuestion";
// SHARED COMPONENTS
import TitleTextField from "components/molecules/TitleTextField";
import Loader from "components/molecules/Loader";
// Styles
import classes from "./styles";
import { AUTOSAVE_TIMER, MOBILE } from "utils/constants";
import { selectDrawerItem } from "features/appSlice";
import {getCheckup, updateCheckup } from "api/checkups";
import DialogLoader from "components/molecules/DialogLoader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import checkQuestionnaireFormIsValid from "function/checkQuestionnaireFormIsValid";
import QuestionnaireStatus from "components/molecules/QuestionnaireStatus";
import Navbar from "components/organisms/NavBar";
import MainContent from "components/molecules/MainContent";
import { Add } from "@mui/icons-material";




/********* CHECKUP Builder ********** */

export default function CheckupBuilder() {

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const params:{checkupid:string} = useParams()
    const location = useLocation<{planningRange: {start:Date, end: Date},openModal: {date:Date,type: "punctual" | "recurrence"}, clientProfileId: string}>()
    const checkupId= params.checkupid
    
    // SELECTORS
    const name: string = useSelector((state:RootState)=> state.checkups.builder.name)
    const isTemplate:boolean = useSelector((state:RootState)=> state.checkups.builder.template)
    const questions:CheckupQuestion[] = useSelector(((state:RootState)=> state.checkups.builder.questions))
    const status:"enabled" | "disabled" | "completed" = useSelector(((state:RootState)=> state.checkups.builder.status))
    const pending = useSelector((state:RootState)=> state.checkups.requests.update === "pending")

    // LOcal state
    const [client, setClient] = useState<string>(null)
    const [builderIsReady, setbuilderIsReady] = useState<boolean>(false)
    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)
    const [localName, setLocalName] = useState<string>('')
    const mobile = useMediaQuery(MOBILE)
    const [photos, setPhotos] = useState<boolean>(false)

    /************* INITIALISATION **************** */

    useEffect(()=>{
        if(isTemplate){
            dispatch(selectDrawerItem(3)) // Checkup de planning
        }

        return function cleanup() {
            dispatch(resetCheckupBuilder())
        }
    },[dispatch])


    /****** FERMETURE, LE WORKOUT N'EST PLUS READY ******** */
    useEffect(()=>{
        return function cleanup() {
            setbuilderIsReady(false)
        };
    },[dispatch])


    /************* RECUPERATION DES DONNEES LE CAS ECHEANT **************** */
    useEffect(()=>{
        dispatch(getCheckup(checkupId)).unwrap().then((res:any)=>{
            setbuilderIsReady(true)
            setLocalName(res.checkup.name)
            if(res.checkup.createdFor){
                setClient(res.checkup.createdFor)
            }
        })
    },[checkupId])

    
    /******** MISE EN form DES DONNES DU builder ********/

    const checkupbuilder:Checkup = useMemo(()=>{
        const builder:Checkup={
            _id: checkupId,
            name,
            template:isTemplate,
            questions,
            status,
            photos
        }
        return builder
    },[name, questions, status, photos])



     /*********** AUTOSAVE  ************** */

     // DEBOUNCE
    const debounceAutoSave = useRef(debounce((checkup)=> {
        dispatch(updateCheckup({checkup}))
    }, AUTOSAVE_TIMER)).current


    // Appel du debounce à chaque changement
    useEffect(() => {
        if (builderIsReady) {
            dispatch(checkupIsSaving())
            debounceAutoSave(checkupbuilder)
        }
        // eslint-disable-next-line
    }, [name, questions, status])



    const goBack = () =>{
        if(pending){
            setOpenWaitinUpdate(true)
            return
        }

        if(location.state?.clientProfileId){
            const {clientProfileId, openModal} = location.state
            history.push({
                pathname:`/clients/${clientProfileId}`,
                state: {
                    tabs: 2,
                    openModal,
                    planningRange:{
                        start: location.state?.planningRange?.start,
                        end: location.state?.planningRange?.end,
                    }
                }
            })
        }

        else{
            history.push({
                pathname:`/templates`,
                state: {
                    tabs: 1, 
                }
            })
        }
    }


    /********* VERIFICATION DU STATUS DE SAUVEGARDE DU CHECKUP */

    useEffect(()=>{
        // Si le workout a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)
            goBack()
        }
    },[openWaitinUpdate, pending])



    const questionnaireIsValid = useMemo(()=>{
        return checkQuestionnaireFormIsValid(checkupbuilder)
    },[checkupbuilder])



    if (builderIsReady) {
        return (

            <React.Fragment>

                <Navbar
                    pageTitle={"Créateur de formulaire"}
                    backButton={goBack}
                    showAutoSave={true}
                />
                

                <MainContent>

                    <Grid container justifyContent="center" sx={{paddingBottom: 10}}>
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
                            
                            <Box sx={classes.mobileStatus}>
                                
                                    <QuestionnaireStatus 
                                        valid={questionnaireIsValid}
                                    />
                                
                            </Box>

                            {/*********************************
                             * TITLE  + DESCRIPTION DE SEANCE
                            ***********************************/}

                            <Box
                                sx={{paddingLeft: 0 ,paddingRight:0}}
                            >
                                <TitleTextField 
                                    fullWidth 
                                    label={''} 
                                    value={name} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        dispatch(handleName({ value: event.currentTarget.value }))
                                    }} 
                                    variant="standard" 
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    sx={{
                                        marginBottom: 3, 
                                        "& .MuiInput-root": {
                                            fontSize: "1.6rem !important"
                                    }}}
                                    
                                />


                               
                            </Box>
                            
                            {/*}
                            <Box
                                sx={{display:"flex", alignItems:"center", flexDirection:"row", marginBottom: 2}}
                            >
                            
                                <Checkbox 
                                    sx={{marginRight: 0.5}} 
                                    checked={photos}
                                    onClick={()=>setPhotos(!photos)}
                                    value={photos}
                                /> 
                                <Typography
                                    sx={{fontSize: 15}}
                                >
                                    Demander des photos d'évolution (profil, dos, côté)
                                </Typography>
                                
                            </Box>
                            */}


                            {/********************** 
                             * LISTE DES QUESTIONS 
                             * **********************/}
                            
                            <Box>
                                {questions.map((checkupQuestion:CheckupQuestion, index:number)=> {
                                    return(
                                        <Question 
                                            key={checkupQuestion.questionId}
                                            questionId={checkupQuestion.questionId} 
                                            questionIndex={index}
                                        />
                                    )
                                })}
                            </Box>


                            {/********* BOUTON AJOUTER UNE QUESTION ******** */}
                            
                            <Box sx={classes.buttonContainer}>
                                <SecondaryButton 
                                    onClick={()=>dispatch(addQuestion())} 
                                    startIcon={<Add />}
                                >
                                    Ajouter une question
                                </SecondaryButton>
                            </Box>
                        </Grid>
                    </Grid>
                </MainContent>


                {/****** INDICATEUR DE SAUVEGARDE **********/}

                <DialogLoader
                    open={openWaitinUpdate}
                    text={"Sauvegarde en cours ..."}
                />
            </React.Fragment>

        )
    } else {
        return (
            <Loader />
        )
    }

}