import { EVOLUTION_COLORS } from "utils/constants"


export default function defineEvolutionColor(evolution:"-"|"+"|"="){
        switch (evolution){
            case "+" : return EVOLUTION_COLORS.more
            case "-" : return EVOLUTION_COLORS.less
            case "=" : return EVOLUTION_COLORS.equal
        }
    }
