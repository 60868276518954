
import { Box, Grid } from "@mui/material";
import { getMealPlanTemplate } from "api/mealPlans";
import { AppDispatch, RootState } from "app/store";
import DialogLoader from "components/molecules/DialogLoader";
import Loader from "components/molecules/Loader";
import MainContent from "components/molecules/MainContent";
import TitleTextField from "components/molecules/TitleTextField";
import Navbar from "components/organisms/NavBar";
import Nutrition from "components/organisms/Nutrition";
import { onChangeMealPlanName, resetMealPlanBuilder } from "features/mealPlanSlice";
import debounce from "lodash.debounce";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { INPUT_DEBOUNCE_TIMER } from "utils/constants";



export default function MealPlanBuilder(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const params:{mealplanid:string} = useParams()
    const pending = useSelector((state:RootState)=> state.mealPlans.requests.update === "pending")
    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)
    const [localName, setLocalName] = useState<string>('')
    const builderIsReady = useSelector((state:RootState)=> state.mealPlans.builderIsReady)


    
    useEffect(()=>{
        return function cleanup() {
            dispatch(resetMealPlanBuilder())
        };
    },[dispatch])

    /*************************************** 
     * RECUPERATION DES DONNEES DU TEMPLATE 
     * *************************************/

    useEffect(()=>{
        if(params?.mealplanid){
            dispatch(getMealPlanTemplate(params.mealplanid)).unwrap().then((res:any)=>{
                if(res.mealPlan?.template){
                    setLocalName(res.mealPlan?.name)
                }else{
                    history.push('/templates')
                    toast.error('Impossible de modifier ce plan alimentaire')
                }
                
            })
        }
    },[params?.mealplanid])



    useEffect(()=>{
        // Si le mealPlan a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)
            history.push({
                pathname:`/templates`,
                state: {
                    tabs: 3, 
                }
            })
        }
    },[pending, openWaitinUpdate])


        /*********************************** 
         * RETOUR A LA LISTE DES TEMPLATES 
         * ******************************* */

        const backTo = () =>{


            if(pending){
                setOpenWaitinUpdate(true)
                return
            }

            history.push({
                pathname:`/templates`,
                state: {
                    tabs: 3, 
                }
            })
            return
        }


        /**********************
         * CHANGEMENT DU NOM 
         * ************* *****/

        const debounceOnChangeName = useRef(debounce((data)=> {
            const {newName} = data
            dispatch(onChangeMealPlanName({ name : newName}))
        },INPUT_DEBOUNCE_TIMER)).current
    
    
        const onChangeName = (event: React.ChangeEvent<HTMLInputElement>)=> {
            const newName:string = event.target.value
            setLocalName(newName)
            debounceOnChangeName({newName: newName})
        }

    if(builderIsReady){

          
    return(
        <React.Fragment>
            <Navbar
                pageTitle={"Modèle de plan alimentaire"}
                fullWidth={true}
                backButton={backTo}
                showAutoSave={true}
            />

            <MainContent>

                

                {/*******
                 * TITLE
                *********/}

                <Grid
                    container
                    justifyContent={'center'}
                >

                    <Grid item
                    xs={12}
                    lg={11}
                    xl={8}>

                        <Box
                            sx={{paddingLeft: 0 ,paddingRight:0}}
                        >
                            <TitleTextField 
                                fullWidth 
                                value={localName} 
                                onChange={onChangeName}
                                variant="standard" 
                                placeholder="Donne un nom à ton modèle"
                                sx={{
                                    marginBottom: 1, 
                                    "& .MuiInput-root": {
                                        fontSize: "1.6rem !important"
                                }}}

                            />
                        </Box>
                    </Grid>
                </Grid>
                <Nutrition 
                    template={true}
                />
            </MainContent>

            <DialogLoader
                open={openWaitinUpdate}
                text={"Sauvegarde en cours ..."}
            />         
        </React.Fragment>
    )
    }else{
        return(
            <Loader />
        )
    }
    
}