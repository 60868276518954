import { Box, MenuItem, Select, SelectChangeEvent, Typography, useMediaQuery } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import CustomIconButton from "components/atoms/Buttons/IconButton"
import FilterButtonCheckbox from "components/molecules/FilterButtonCheckbox"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { DESKTOP } from "utils/constants"
import EventsFilters from "../EventsFilters"
import { useEffect, useMemo, useState } from "react"
import Loader from "components/molecules/Loader"
import EventsList from "../EventsList"
import { HistoryEventDetails, UserPhotos } from "interfaces/User"
import { addDays, isSameDay } from "date-fns"
import EmptySearch from "components/molecules/EmptySearch"
import { getClientsHistory } from "api/clients"
import { FilterList } from "@mui/icons-material"
import classes from "./styles"

interface Props{
    forClient?:boolean
}

export default function ActivitiesHistory({forClient}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> forClient? state.clients.requests.getClientHistory === "pending" : state.dashboard.requests.get === "pending")
    const openDemoUser:boolean = useSelector((state:RootState) => state.app.openUserDemoPresentation, shallowEqual)
    const history:HistoryEventDetails[] = useSelector((state:RootState) => forClient? state.clients.clientHistory : state.dashboard.history)
    const filters = useSelector((state:RootState)=> forClient ? state.clients.clientHistoryFilters : state.dashboard.filters)
    const [range, _] = useState<{start:Date, end: Date}>({start: addDays(new Date(), -3), end: new Date()})
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
    const [dayRange, setDayRange] = useState<string>('3') // days
    const desktop = useMediaQuery(DESKTOP)


     /***** MISE A JOUR DE L'HISTORIQUE SUITE A L'AJOUT DE DEMO USER ***** */
     
    useEffect(()=>{
        if(openDemoUser){
            const startDate = addDays(new Date(range.end), -parseInt(dayRange))
            dispatch(getClientsHistory({start: startDate, end: new Date()}))
        }
    },[openDemoUser])


    const handleChangeFilter = (event: SelectChangeEvent) =>{
        setDayRange(event.target.value as string)
    }

    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget)
    }

    /********************************
     * Récupération de l'historique
     ********************************/
    
    useEffect(()=>{
        const startDate = addDays(new Date(range.end), -(parseInt(dayRange)+1))
        dispatch(getClientsHistory({start: startDate, end: new Date()}))
    },[dayRange])
    
 

    /*********************** 
     * FILTRAGE DES EVENTS 
     * *********************/
    
    const filteredEvents:HistoryEventDetails[] = useMemo(() => {

        console.log('on trie')
        
        var workouts:HistoryEventDetails[] = []
        var checkups:HistoryEventDetails[] = []
        var measures:HistoryEventDetails[] = []
        var meals:HistoryEventDetails[] = []
        var userPhotos:HistoryEventDetails[] = []
        

    
        workouts = history.filter((elem)=> {
            return elem.action === "workout"
        })

        
        checkups = history.filter((elem)=> {
            return elem.action === "questionnaire"
        })
        

        
        measures = history.filter((elem)=> {
            return elem.action === "measure"
        })


        meals = history.filter((elem)=> {
            return elem.action === "meal"
        })

        // Photos
        // Création d'un album pour toutes les photos récupérées

        // Images non triées
        var photosList = history.filter((elem)=> {
            return elem.action === "photo"
        })

        var albums:UserPhotos[] = []

        photosList.forEach((event:HistoryEventDetails)=> {
            const elemIndex = albums.findIndex((elem)=> isSameDay(new Date(elem.date), new Date(event.date)))

                // Ajout d'une photo à un élément déja en place
                if(elemIndex> -1){
                albums[elemIndex].photos.push(event.image)
                albums[elemIndex].photos = albums[elemIndex].photos.reverse()

            } else{
                albums.push({
                    date: new Date(event.image.date),
                    photos: [{...event.image}],
                    user: event.user
                    
                })
            }
        })

        albums.forEach((listElem)=>{
            userPhotos.push({
                date: listElem.date,
                user: listElem.user,
                action: "userPhotos",
                _id: null,
                photos: listElem.photos

            })
        })


        const events = workouts?.concat(checkups).concat(measures).concat(meals).concat(userPhotos)

        const sortedEvents = [...events.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())]

        // Filtrage selon les choix users
        var filtered:HistoryEventDetails[] = []

        if(filters.length === 0){
            filtered = [...sortedEvents]
        } else {

            filters.forEach((elem)=> {
                filtered = filtered.concat([...sortedEvents].filter((event) => event.action === elem))
            })
        }

        return filtered


    },[history, filters])


    /**************************************** 
     * REGROUPEMENT DES EVENEMENTS PAR JOUR
     * ***************************************/
    
    const SORTED_EVENTS_BY_DAY = useMemo(()=>{

        const eventsByDate:{date: Date, events: HistoryEventDetails[]}[] = []
        const parsedFilter = parseInt(dayRange)
        Array.from(Array(parsedFilter)).forEach((_, index)=> {
            const date = addDays(new Date(range.end), -index)
            const eventsFound = filteredEvents.filter((event)=> isSameDay(new Date(event.date), date))
            if(eventsFound.length>0){
                eventsByDate.push({
                    date,
                    events: eventsFound
                })
            }
        })

        return eventsByDate
    },[filteredEvents, dayRange, filters])
    


    return(
        <Box 
            sx={{display:"flex", flexDirection: "column", flex: 1}}
        >
            <Box 
                sx={classes.feedHeader}
            >
           
                <Box
                    sx={{display:"flex", flexDirection: "row", alignItems:"center"}}
                >

                    {/***************************
                     * BOUTON DE FILTRE (MOBILE)
                     ***************************/}

                    {!desktop && (
                        <CustomIconButton
                            Icon={FilterList}
                            onClick={onOpenFilterList}
                            white={true}
                            darkerBorder={true}
                            marginRight={1}
                            badge={filters.length}
                            showLabel={true}
                        />
                    )}


                    {/***************************************
                     * BOUTONS DE FILTRE (DESKTOP/TABLETTES)
                     ****************************************/}

                    {desktop  && (
                    <>
                        <FilterButtonCheckbox
                            label="Tout"
                            eventType="all"
                            checked={Boolean(filters.length ===0)}
                            forClient={forClient}
                        />
                        <FilterButtonCheckbox
                            label="Séances"
                            eventType="workout"
                            checked={Boolean(filters.find((elem)=> elem === "workout"))}
                            forClient={forClient}
                        />
                        <FilterButtonCheckbox
                            label="Questionnaires"
                            eventType="questionnaire"
                            checked={Boolean(filters.find((elem)=> elem === "questionnaire"))}
                            forClient={forClient}

                        />
                        <FilterButtonCheckbox
                            label="Mesures"
                            eventType="measure"
                            checked={Boolean(filters.find((elem)=> elem === "measure"))}
                            forClient={forClient}

                        />
                        <FilterButtonCheckbox
                            label="Photos"
                            eventType="photo"
                            checked={Boolean(filters.find((elem)=> elem === "photo"))}
                            forClient={forClient}

                        />
                        <FilterButtonCheckbox
                            label="Repas"
                            eventType="meal"
                            checked={Boolean(filters.find((elem)=> elem === "meal"))}
                            forClient={forClient}

                        />
                        
                    </>
                    )}



                    {/*********************
                     * LISTE DES FILTRES
                     *********************/}

                    <EventsFilters
                        open={Boolean(anchorFilterList)}
                        onClose={()=>setAnchorFilterList(null)}
                        anchorEl={anchorFilterList}
                        forClient={forClient}
                    />


                    {/*********************
                     * SELECTION DES JOURS
                     *********************/}

                    <Select 
                        value={dayRange}
                        size="small"
                        onChange={handleChangeFilter}
                        sx={{backgroundColor: "white"}}
                        MenuProps={{
                            disableScrollLock: true
                        }}
                        inputProps={{
                            sx:{
                                border: `none`,
                            }
                        }}
                    >
                        <MenuItem value={3}>3 derniers jours</MenuItem>
                        <MenuItem value={7}>7 derniers jours</MenuItem>
                        <MenuItem value={15}>15 derniers jours</MenuItem>
                    </Select>
                </Box>

            </Box>


            {/************ 
             * LOADER 
             * ******** */}

            {pending && (
                <Loader />
            )}

            {/************************ 
             * LISTE DES EVENNEMENTS 
             * *********************/}

            {!pending && SORTED_EVENTS_BY_DAY?.map((eventDate, index) => {
                return(
                    <EventsList 
                        key={index}
                        date={eventDate.date}
                        events={eventDate.events}
                        pending={pending}
                    />
                )
            })}
            


            {/***** AUCUN EVENEMENT ***** */}
            {!pending && SORTED_EVENTS_BY_DAY?.length ===0 && (
                <EmptySearch
                    description={forClient? `Ton client n'a aucune activité enregistrée sur les ${dayRange} derniers jours` : `Aucune activité de tes clients sur les ${dayRange} derniers jours`}
                />
            )}

        </Box>  
    )
}