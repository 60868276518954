import { Event } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { EventCard } from "components/molecules/EventCard"
import { format, isSameDay } from "date-fns"
import { fr } from "date-fns/locale"
import { HistoryEventDetails } from "interfaces/User"
import classes from "./styles"


interface Props{
    pending: boolean,
    events: HistoryEventDetails[],
    date:Date
}

export default function EventsList({pending, events, date}:Props){
    return(
        <Box
            sx={classes.blockEvents}
        >
            <Box
                sx={classes.date}
            >
                <Box
                    sx={classes.dateIcon}
                >
                    <Event 
                        sx={{color:"white", fontSize: 18}}
                    />
                </Box>

                <Typography
                    sx={classes.dateLabel}
                >
                    {isSameDay(date, new Date()) ? "Aujourd'hui" : format(date, "eeee dd MMMM", {locale: fr})}
                </Typography>
            </Box>


            <Box>
                {!pending && events?.map((event, historyIndex:number) => {

      

                    return(
                        <Box 
                            key={historyIndex} 
                            sx={[
                                classes.eventContainer, 
                                {paddingBottom: historyIndex === events.length -1 ? 10 : 0}
                            ]}
                        >
                            <Box 
                                sx={classes.eventsTimeline}
                            />
                            <EventCard 
                                event={event} 
                            />
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}