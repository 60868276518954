import { createSlice, current } from '@reduxjs/toolkit'
import User, { HistoryEventDetails } from 'interfaces/User'
import MealPlan from 'interfaces/MealPlan'
import ClientInvitation from 'interfaces/ClientInvitation'
import fuseSearch from 'function/fuseSearch'
// API
import {
  getClients, 
  deleteClient, 
  getInvitations, 
  postInvitation, 
  deleteInvitation,
  getUserData,
  postQuestionnaire,
  deleteQuestionnaire,
  getClientHistory,
  updateClientMealPlan,
  updateClientData,
  getClientsPhotos
} from "api/clients"
import { toast } from 'react-toastify'
import { ScheduledQuestionnaire } from 'interfaces/Checkup'
import { updateWorkout } from 'api/workouts'
import { isSameDay } from 'date-fns'
import { createDemoUser } from 'api/user'
import Meal from 'interfaces/Meal'


/************** SLICE ****************/

interface ClientsSlice {
  list: {
    clients: User[],
    invitations: ClientInvitation[]
  },
  profile:User,
  requests:{
    getUserProfile:  "idle" | "pending",
    postInvitation: "idle" | "pending",
    deleteInvitation: "idle" | "pending",
    deleleteClient: "idle" | "pending",
    postQuestionnaire: "idle" | "pending",
    deleteQuestionnaire: "idle" | "pending",
    getClientHistory: "idle" | "pending",
    updateMealPlan: "idle" | "pending",
    updateClientProfile : "idle" | "pending",
    getClientPhotos: "idle" | "pending"
  },
  search :string,
  searchResults: {
    clients: User[],
    invitations: ClientInvitation[]
  },
  filters:string[],
  sortOptions: string[],
  sort: "firstname" | "lastname",
  orderOptions: string[],
  order: "alphabetical" | "reverseAlphabetical",
  clientHistory: HistoryEventDetails[],
  clientHistoryFilters: string[],
  mealCopied : Meal,
  mealPlanCopied: MealPlan

}


/************** INIT ****************/

const initialState = {
  list: {
    clients: [],
    invitations: []
  },
  profile:null,
  requests:{
    getUserProfile: "idle",
    postInvitation: "idle",
    deleteInvitation: "idle",
    deleleteClient: "idle",
    postQuestionnaire: "idle",
    getClientHistory: "idle",
    updateMealPlan: "idle"
  },
  searchResults: {
    clients: [],
    invitations:[]
  },
  search :"",
  filters:[],
  sortOptions: ["firstname","lastname"],
  sort: "firstname",
  orderOptions: ["alphabetical","reverseAlphabetical"],
  order: "alphabetical",
  clientHistory: [],
  clientHistoryFilters: []

} as ClientsSlice



/************** SLICE ****************/

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {

  

    resetClientHistory: (state) => {
      state.clientHistory = []
    },

     // FUSE SEARCH
     searchClients: (state, action:{payload:{search?:string }}) => {
      const {search} = action.payload
      

      // Si nouvelle recherche
      state.search = search === null ? state.search : search
      

      const keysClients:string[] = [
        "firstname",
        "lastname",
        "email"
      ]

      const keysInvitations:string[] = [
        "to.firstname",
        "to.lastname",
        "to.email"
      ]

      // nouvelle recherche ou recherche courrant
      const newSearch = search === null ?  state.search : search

      // Filtrage des données
      const clients = newSearch !== "" ? fuseSearch(current(state.list.clients), newSearch, keysClients) : [...state.list.clients]
      const invitations = newSearch !== "" ? fuseSearch(current(state.list.invitations), newSearch, keysInvitations) : [...state.list.invitations]


      state.searchResults = {
        clients,
        invitations,
      }
    },


    onSelectClientSort : (state, action:{payload:{sort:"firstname" | "lastname"}}) => {
      state.sort = action.payload.sort
    },

    onSelectClientOrder : (state, action:{payload:{order: "alphabetical" | "reverseAlphabetical"}}) => {
      state.order = action.payload.order
    },

    resetSearcClients: (state) => {
      state.searchResults = {
        clients: state.list.clients,
        invitations: state.list.invitations
      }
    },
    defineMealPlan : (state, {payload}) => {
      const mealPlan:MealPlan = payload
      state.profile.mealPlan = mealPlan
    },

    resetClientProfile: (state)=> {
      state.profile = null
    },

    clearClientsFilter:  (state)=> {
      state.filters = []
    },

    addClientFilter : (state,action) => {
      const filter = action.payload.newFilter
      state.filters.push(filter)
    },

    removeClientFilter : (state,action) => {
      const filter = action.payload.newFilter
      const index = state.filters.findIndex((elem)=> filter===elem)
      state.filters.splice(index,1)
    },

    // Client history

    addClientHistoryEventFilter : (state,action) => {
      const newFiler:string = action.payload.newFilter
      state.clientHistoryFilters.push(newFiler)
    },

    removeClientHistoryEventFilter : (state,action) => {
      const filter = action.payload.newFilter
      if(filter === "all"){
        state.clientHistoryFilters = []
        return
      }
      const index = state.clientHistoryFilters.findIndex((elem)=> elem === filter)
      if(index !== -1){
          state.clientHistoryFilters.splice(index,1)
      }
    },

    clearClientHistoryEventsFilters : (state) => {
      state.clientHistoryFilters = []
    }



  },

  extraReducers(builder) {
    builder

    /********* RECUPERATION DES CLIENTS ********** */
    .addCase(getClients.fulfilled, (state, action) => {
      const clients:User[] = action.payload?.clients
      if (clients) {
        state.list.clients = clients
        state.searchResults.clients = clients
      }
    })

    /******** RECUPERATION DES INVITATIONS ******************* */

    .addCase(getInvitations.fulfilled,(state,action) => {
        const invitations:ClientInvitation[]= action.payload?.invitations
        if(invitations){
          state.list.invitations = invitations
          state.searchResults.invitations = invitations
        }
    })

    /******** ENVOIE D'UNE INVITATION ******************* */
    .addCase(postInvitation.pending,(state) => {
      state.requests.postInvitation = "pending"
    })
    .addCase(postInvitation.fulfilled,(state,action) => {
      state.requests.postInvitation = "idle"
      const {invitation} = action.payload
      if(invitation){
        state.list.invitations.push(invitation)
        state.searchResults.invitations.push(invitation)
        toast.success('Invitation envoyée')
      }
    })
    .addCase(postInvitation.rejected,(state) => {
      state.requests.postInvitation = "idle"
      toast.error("Impossible d'envoyer cette invitation")
    })


    /******** SUPPRIMER UNE INVITATION ******************* */
    .addCase(deleteInvitation.pending,(state) => {
      state.requests.deleteInvitation = "pending"
    })
    .addCase(deleteInvitation.fulfilled,(state,action) => {
      state.requests.deleteInvitation = "idle"
      const {invitationId} = action.payload
      if(invitationId){
        state.list.invitations = state.list.invitations.filter((elem:ClientInvitation) => elem._id !== invitationId)
        state.searchResults.invitations = state.searchResults.invitations.filter((elem:ClientInvitation) => elem._id !== invitationId)
        toast.success('Invitation supprimée')
      }else{
        toast.error(action.payload.error)
      }
    })
    .addCase(deleteInvitation.rejected,(state) => {
      state.requests.deleteInvitation = "idle"
      toast.error("Impossible de supprimer cette invitation")
    })


    /******** SUPPRIMER UN CLIENT ******************* */
    .addCase(deleteClient.pending,(state) => {
      state.requests.deleleteClient = "pending"
    })
    .addCase(deleteClient.fulfilled,(state,action) => {
      state.requests.deleleteClient = "idle"
      const {clientDeleted} = action.payload
      if(clientDeleted){
        state.list.clients = state.list.clients.filter((elem: User) => elem._id !== clientDeleted)
        state.searchResults.clients = state.searchResults.clients.filter((elem: User) => elem._id !== clientDeleted)
        toast.success('Client supprimé')
      }else{
        toast.error(action.payload.error)
      }
    })
    .addCase(deleteClient.rejected,(state) => {
      state.requests.deleleteClient = "idle"
      toast.error("Impossible de supprimer ce client")
    })



    /********* CONSULTER UN CLIENT *********** */
    .addCase(getUserData.pending,(state) =>{
        state.requests.getUserProfile = "pending"
    })

    .addCase(getUserData.fulfilled,(state, {payload }) =>{
        state.requests.getUserProfile = "idle"
        if(payload.client){
          const client:User = payload.client
          const checkupsCompleted = [...client.checkups].sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          state.profile = {
            ...client, 
            scheduledQuestionnaires: client.scheduledQuestionnaires? client.scheduledQuestionnaires : [], 
            checkups: checkupsCompleted
          }
        }
    })

    .addCase(getUserData.rejected,(state) =>{
        state.requests.getUserProfile = "idle"
        toast.error("Impossible de récupérer les données du client")
    })

    /********* PROGRAMMER UN QUESTIONNAIRE *********** */
    .addCase(postQuestionnaire.pending,(state) =>{
      state.requests.postQuestionnaire = "pending"
    })

  .addCase(postQuestionnaire.fulfilled,(state, {payload }) =>{
      state.requests.postQuestionnaire = "idle"
      const questionnaire:ScheduledQuestionnaire = payload.questionnaire
      if(questionnaire){
        state.profile.scheduledQuestionnaires.push(questionnaire)
        toast.success('Questionnaire programmé')
      }else{
        toast.error(payload.error)
      }
  })

  .addCase(postQuestionnaire.rejected,(state) =>{
      state.requests.postQuestionnaire = "idle"
      toast.error("Impossible de programmer ce nouveau questionnaire")
  })


    /********* SUPPRIMER UN QUESTIONNAIRE *********** */

    .addCase(deleteQuestionnaire.pending,(state) => {
      state.requests.deleteQuestionnaire = "pending"
    })

    .addCase(deleteQuestionnaire.fulfilled,(state,action) => {
      state.requests.deleteQuestionnaire = "idle"
      if(action.payload.deleted){
          const {questionnaireId} = action.payload
          const index:number = state.profile.scheduledQuestionnaires.findIndex((elem)=> elem._id === questionnaireId)
          state.profile.scheduledQuestionnaires.splice(index,1)
      }else{
        toast.error('Impossible de supprimer ce questionnaire')
      }
    })

    .addCase(deleteQuestionnaire.rejected,(state) =>{
      state.requests.postQuestionnaire = "idle"
      toast.error('Impossible de supprimer ce questionnaire')
  })


  /******** UPDATE WORKOUT ******* */

  .addCase(updateWorkout.fulfilled,(state,{payload})=>{
    const {workout} = payload
    if(workout && (!workout.template || workout.programTemplate)){ // Séance personnalisée
        // Si le workout est a la même date qu'aujour'hui
        if(isSameDay(new Date(workout.schedule), new Date()) && workout.status === "enabled"){
            const clientId = workout.createdFor
            const clientIndex = state.list.clients.findIndex((elem)=> elem._id === clientId)
            if(clientIndex !== -1 && state.list.clients[clientIndex].workouts){ // Si pas de clientIndex, le workout a été modifié sur le planning Coach

              state.list.clients[clientIndex].workouts.push(workout)
            }
        }
    }

  })

    /******** CREATE DEMO USER ******* */
    .addCase(createDemoUser.pending,(state)=>{
      state.requests.postInvitation = "pending"
    })
     


  /******** CREATE DEMO USER ******* */
  .addCase(createDemoUser.fulfilled,(state,{payload})=>{
        const {client} = payload
        if(client){
          state.requests.postInvitation = "idle"
          state.list.clients.push(client)
          state.searchResults.clients.push(client)
        }
       
  })


  /******** GET CLIENT HISTORY ******* */

  .addCase(getClientHistory.pending, (state)=>{
    state.requests.getClientHistory = "pending"
  })
  .addCase(getClientHistory.fulfilled, (state, {payload})=>{
    var currentHistory:HistoryEventDetails[] = payload.history
    // Trie par date
    currentHistory = currentHistory.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())
    state.clientHistory = currentHistory
    state.requests.getClientHistory = "idle"
   
  })



  .addCase(updateClientMealPlan.fulfilled, (state, {payload}) => {
    const currentStatus = state.profile.mealPlan?.status
    const newStatus = payload.client.mealPlan?.status
    if(currentStatus !== newStatus && state.profile.mealPlan){
      state.profile.mealPlan.status = payload.client.mealPlan?.status
    }
  })


  .addCase(updateClientData.pending, (state) => {
    state.requests.updateClientProfile = "pending"
})


  .addCase(updateClientData.fulfilled, (state, {payload}) => {
      const client = payload.client
      if(client){
        state.profile = {...state.profile, ...client}
      }
      state.requests.updateClientProfile = "idle"
  })

  .addCase(getClientsPhotos.pending, (state) => {
    state.requests.getClientPhotos = "pending"
  })

  .addCase(getClientsPhotos.fulfilled, (state, {payload}) => {
    state.requests.getClientPhotos = "idle"

    if(payload.photos){
      state.profile.photos = payload.photos
    }
  })


  }
})




export const { 
  searchClients, 
  resetSearcClients, 
  onSelectClientSort,
  onSelectClientOrder,
  resetClientProfile,
  resetClientHistory,
  addClientFilter,
  removeClientFilter,
  addClientHistoryEventFilter,
  removeClientHistoryEventFilter,
  clearClientHistoryEventsFilters
} = clientsSlice.actions

export default clientsSlice.reducer