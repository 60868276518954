import { BorderClear } from "@mui/icons-material"
import { BORDER_COLOR, HOVER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes= {

    
    content : {
        backgroundColor: "white", 
        padding: 0,
        /*overflowY: {
            xs: "auto",
            lg: "hidden", 
        },*/
        //padding: 0,
    },

    workout : {
        padding: 3,
        paddingLeft: 0,
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        alignItems:"center",
        display:"flex",


        "&:hover":{
            backgroundColor: "#fafafa",
            cursor: "pointer"
        }
    },

    workoutDate : {
        display:"flex",
        flexDirection: "column",
        width: 120,
        alignItems:"center"
    },

    workoutDateNumber:{
        fontSize: 20,
        fontWeight: 700
    },

    workoutTags : {
        display:"flex",
        flexDirection:"row"
    },

    workoutInfos : {
        display:"flex",
        flexDirection: "column",
        flex: 1
    },

    selected : {
        backgroundColor: HOVER_COLOR,
    },

    workoutName:{
        fontSize: 16,
        fontWeight: 600
    },

    measureContainer:  {
        display:"inline-flex",
        flexDirection: {xs: "column",sm:"row"},
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingLeft:1.5,
        paddingRight: 1.5,
        marginTop: 2,
        backgroundColor: "#edf5ff",
        borderRadius: 3,
        fontSize: 14
    },

    measureContainerLabel : {
        fontSize: {xs: 13, sm:14}
    }

}


export default classes