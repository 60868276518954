

import { BORDER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {



    header:{
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom: 1.5,
    },


    headerButtonContainer:{
        display:"flex", 
        flexDirection: "row", 
        alignItems:"center",
    },


    mobileDaysHeader : {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between"
    },


    buttonsHeader : {
        display:"flex",
        flexDirection: {xs: "column", sm :"row"},
        marginBottom: 3
    },


    buttonsInfosClient : {
        display:"flex",
        flex: 1,
        flexDirection: {
            xs: "column",
            sm: "row"
        },
        marginLeft: {
            sm: 1.5
        }
    },

    tagInfo: {
        backgroundColor: "#ffe1e8",
        padding: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        fontSize: "0.9rem",
        display:"inline-flex",
        border: `solid 1px ${BORDER_COLOR}`,
        flexWrap: "no-wrap",
        marginRight: 1,
        flexDirection: "row",
        alignItems:"center",
        height: 38,
        //borderRadius: 1,
        borderRadius: 10,

        "&:hover" : {
            backgroundColor: "#fce9ed !important",
        }
    },

    tagInfoIconEnabled:{
        marginRight: 0.5,
        color: "#24c02b"
    },


    tagInfoClickable:{
        //backgroundColor: LIGHT_BUTTON_COLOR,
        cursor:"pointer",
        fontSize: 20,
        "&:hover":{
            backgroundColor: LIGHT_BUTTON_COLOR
        }
    },

    tagInfoEnabled: {
        backgroundColor: "#d5f1d7",
        border: "solid 1px #c8e1ca",

        "&:hover" : {
            backgroundColor: "#bbe4bd"
        }
    },

    tagInfoIcon : {
        marginRight: 0.5,
        color: "primary.main",
        fontSize: 20
    },

    tagInfoValue: {
        fontWeight: 500,
        marginLeft: 1,
        fontSize: 15,
        flexWrap: "nonwrap",
        whiteSpace: "nowrap"
    },

    disabledIcon: {
        color: "red"
    },

    nutrition: {
        width: "100%",
        position:"relative",
    },


    
    content:{
        width: "100%",
        paddingTop: 3,
        minHeight: 300,
        paddingBottom: "200px"
    },

    button: {
        display:"flex",
        alignItems:"flex-start",
        justifyContent:"flex-start",
        flexDirection: {
            xs: "column",
            sm: "row"
        }
    },

    stats: {
        backgroundColor: "white",
        width: "100%",
        border: `solid 1px ${BORDER_COLOR}`,
        padding: 3,
        marginBottom: 3
    },

    statsTitle: {
        fontWeight: 700
    },

    chartContainer: {
        display:"flex",
        justifyContent:"flex-start",
        alignContent: "center",
        //marginBottom: 3,
        backgroundColor: "white",
        flexDirection: "column",
        padding: 3,
        border: `solid 1px ${BORDER_COLOR}`,
        flex: 1,
        height: "100%"
    },

    noMealContainer:{
        padding: 3,
        //border: "solid 2px #dfdfdf",
        borderRadius: 1,
        //backgroundColor: "white",
        border: `dashed 8px ${BORDER_COLOR}`,
        marginBottom: 3,
        minHeight: 300,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },


    noMealContainerLabel : {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 500,
        color: "#b7b7b7"
    },

    buttonMore : {
        borderRadius: "100px !important",
        marginLeft: 3,
        backgropundColor: "white",

    },

    actionButton : {
        borderRadius: "100px !important",
        marginLeft: 1.5,
        backgroundColor: "white",
        border: `solid 1px ${BORDER_COLOR}`
        
    },


    mb : {
        backgroundColor: "white",
        height: 32,
        paddingLeft:1.5,
        paddingRight: 1.5,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        //borderRadius: 1,
        marginRight: 1,
        marginBottom:{
            xs: 1,
            sm: 0
        },
        border: `solid 1px ${BORDER_COLOR}`,
        borderRadius: 10
    },

    mbLabel : {
        color: "rgba(0,0,0,0.85)",
        fontSize: 15,
        display:"flex",
        alignItems:"center",
        flexDirection: "row",
        justifyContent:"center"
    },


    macrosMobile : {
        backgroundColor: "white",
        padding: 3,
        border: `solid 1px ${BORDER_COLOR}`,
        marginTop: 3
    },


    

}

export default classes