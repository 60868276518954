import React, { useMemo, useState } from "react";
import { Box, Card,Grow,ListItemIcon,ListItemText,Menu,MenuItem,Tooltip,Typography } from "@mui/material";
import { ScheduledQuestionnaire } from "interfaces/Checkup";
import classes from "./styles" 
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { Delete, Edit, Event, EventRepeat, Warning } from "@mui/icons-material";
import DialogDeletePlanningQuestionnaire from "../DialogDeletePlanningQuestionnaire";
import checkQuestionnaireFormIsValid from "function/checkQuestionnaireFormIsValid";
import { useHistory } from "react-router-dom";


interface Props{
    questionnaire: ScheduledQuestionnaire
}





export function PlanningQuestionnaire({questionnaire}:Props){
    const navigate = useHistory()
    const checkups = useSelector((state:RootState) => state.checkups.templates.list)
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState<boolean>(false)
    const clientProfileId:string = useSelector((state:RootState) => state.clients.profile?._id)
    const planningStart:string = useSelector((state:RootState) => state.planning.builder.startDate)
    const planningEnd:string = useSelector((state:RootState) => state.planning.builder.endDate)

    /******** GESTION DU MENU ******* */
    const onOpenMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget)
    }


    const onDeleteQuestionnaire = () => {
        setAnchorEl(null)
        setOpen(true)
    }


    const TEMPLATE = useMemo(()=>{
        return checkups.find((elem)=> elem._id === questionnaire.templateId)
    },[questionnaire])


    const QUESTIONNAIRE_IS_VALID = useMemo(()=>{
        if(TEMPLATE){
            return checkQuestionnaireFormIsValid(TEMPLATE)
        }
    },[TEMPLATE])


    const onEditTemplate = () => {
        navigate.push(
            `/checkup/${questionnaire.templateId}`, {
                clientProfileId,
                openModal:false,
                planningRange:{
                    start: new Date(planningStart),
                    end: new Date(planningEnd),
                }
            })
    }
    
    
    return(
        <>

           
            <Grow 
                in={true}
            >
                <Card 
                    sx={[
                        classes.checkup, 
                        questionnaire.recurrence==="month" && classes.monthlyCheckup, 
                        questionnaire.recurrence === "none" && classes.punctualCheckup,
                        !QUESTIONNAIRE_IS_VALID && classes.invalid,
                    ]} 
                    onClick={onOpenMenu}
                    elevation={0}
                >
                    {/****** INFOS ******* */}

            

                        {!QUESTIONNAIRE_IS_VALID && (
                            <Warning 
                                sx={[classes.icon]}
                            />
                        )}

                        {/*** ICONE *** */}
                        {(questionnaire.recurrence === "none" && QUESTIONNAIRE_IS_VALID) && (
                            <Event 
                                sx={classes.icon}
                            />
                        )}

                        {(questionnaire.recurrence !== "none" && QUESTIONNAIRE_IS_VALID) && (
                            <EventRepeat 
                            sx={classes.icon}
                            />
                        )}

                        {/*** NOM *** */}
                        <Typography 
                            sx={classes.checkupName}
                        >
                            {TEMPLATE?.name}
                        </Typography>
                        
                
                    
                </Card>
            </Grow>
                
          
        
            <Menu 
                disableScrollLock={true} 
                id="day-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={()=>setAnchorEl(null)}
            >
                {!QUESTIONNAIRE_IS_VALID && (
                <MenuItem 
                    onClick={onEditTemplate} 
                >
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText>
                        Réparer le modèle
                    </ListItemText>
                </MenuItem>
                )}
                <MenuItem 
                    onClick={onDeleteQuestionnaire} 
                >
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>
                        Supprimer {questionnaire.recurrence === "none" ? "le questionnaire" : "la récurrence"}
                    </ListItemText>
                </MenuItem>
            </Menu>

            <DialogDeletePlanningQuestionnaire
                open={open}
                onClose={()=> setOpen(false)}
                questionnaire={questionnaire}
            />
        </>

    )
}

function areEqual(prev:Props, next:Props){
    return prev.questionnaire === next.questionnaire
}

export default React.memo(PlanningQuestionnaire, areEqual)