import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { isSameDay } from "date-fns";
import Image from "interfaces/Image";
import User from "interfaces/User";
import { useMemo } from "react";


interface Props{
    open: boolean,
    onClose: ()=> void,
    image: Image,
    client: User
}


export default function DialogUserPhoto({open, onClose, image}:Props){


    /*const WEIGHT = useMemo(()=>{
        
        const weightFound = client.measures?.weight.filter((elem)=> isSameDay(elem.date, image.date))
        console.log("weight", weightFound)
    },[client]) */


    return(
        <Dialog
            open={open}
            disableScrollLock={true}
        >
            <DialogContent>
                <Box>
                    <Box
                        component={"img"}
                        src={image?.url}
                        sx={{maxWidth: "100%", maxHeight: "auto"}}
                    />
                    
                    <Box>
                        <Typography>
                            
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}