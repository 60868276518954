

export default function convertSecondsToMinutes(totalSeconds:number){

    const secondsFormated = parseInt((totalSeconds).toFixed(0))
    const totalMinutes = (secondsFormated / 60)
    const seconds = secondsFormated % 60;
    const minutes = totalMinutes.toString().split(".")[0]
    
    return `${minutes.length === 1 ? '0'+minutes : minutes}:${seconds < 10 ? '0'+seconds : seconds}`
}