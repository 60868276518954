import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from "@mui/material";
import { deleteWorkout } from "api/workouts";
import { AppDispatch, RootState } from "app/store";
import Workout from "interfaces/Workout";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { toast } from "react-toastify";


/****** INTERFACES ****** */

interface Props{
    open: boolean,
    workout: Workout,
    onClose: ()=> void,
    fromWorkout?: boolean,
    onDeleteWorkoutFromBuilder?: ()=> void,
    createdFor?: string,
}

export default function DialogDeleteWorkout({workout,open, fromWorkout, onDeleteWorkoutFromBuilder, onClose}:Props){


    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.workouts.requests.delete === "pending")
    
    const [data, setData] = useState({
        name: workout?.name,
        template: workout?.template && !workout.programTemplate
    })


    useEffect(()=>{
        if(open){
            setData({
                name: workout?.name,
                template: workout?.template && !workout.programTemplate
            })
        }
    },[open])

    /********** SUPPRESSION DE LA SEANCE ******** */

    const onSubmit = () => {
        dispatch(deleteWorkout({workout})).unwrap().then(()=>{
            onClose()
            toast.success('Séance supprimée')
            if(fromWorkout){
                onDeleteWorkoutFromBuilder()
            }
        })
        
    }

    return(
        <Dialog 
            open={open} 
            disableScrollLock={true}
        >
            <DialogTitle>
                Supprimer {data.template ? "un modèle de séance" : "une séance"}
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer {data.template ? "le modèle de séance" : "la séance"} <strong>{data.name}</strong> ?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
                <LoadingButton onClick={onSubmit} variant="contained" disabled={pending} loading={pending}>
                    {pending ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}