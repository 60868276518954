import axios from "utils/axios"
import { createAsyncThunk } from '@reduxjs/toolkit'
import sleep from "function/sleep"
import Workout from "interfaces/Workout"
import { toast } from "react-toastify"
import {v4 as uuid} from "uuid"
import removePerformedSets from "function/removePerformedSets"
import User from "interfaces/User"
import { isString } from "@amcharts/amcharts5/.internal/core/util/Type"

/************** API ****************/

export const getWorkout = createAsyncThunk('workout/get', async (data:{id: string, sleep?:boolean}) => {

    try {
      const response = await axios.get(`workouts/${data.id}`)
      const workout:Workout = response.data.workout
      await sleep(sleep? 200:0)
      return { workout }
  
    } catch (err: any) {
  
      return {
        error: err.response.data?.message[0]
      }
    }
  })

  export const getWorkoutResult = createAsyncThunk('workout/getResult', async (data:{id: string, sleep?:boolean}) => {

    try {
      const response = await axios.get(`workouts/${data.id}`)
      const workout:Workout = response.data.workout
      await sleep(sleep? 200:0)
      return { workout }
  
    } catch (err: any) {
  
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  export const getWorkoutByDate = createAsyncThunk('workout/getByDate', async (data:{date: Date, sleep?:boolean}) => {

    try {
      const response = await axios.get(`workouts/date/${data.date}`)
      const workout:Workout = response.data.workout
      await sleep(sleep? 200:0)
      return { workout }
  
    } catch (err: any) {
  
      return {
        error: err.response.data?.message[0]
      }
    }
  })



  export const getWorkouts = createAsyncThunk('workouts/get', async(data:{start:string, end: string, createdFor?: string, sleep?:boolean, programTemplate?: string}) => {
    const {start, end, createdFor, programTemplate} = data 
    // Création de la query URL
    var url:string = `workouts/?start=${start}&end=${end}`
    if(createdFor){
      url = url + `&createdFor=${createdFor}`
    }
    if(programTemplate){
      url= url + `&programTemplate=${programTemplate}`
    }
    

    try {
      const result = await axios.get(url)
      const workouts:Workout[] = result.data.workouts
      await sleep(sleep? 200:0)
      return { workouts }

    } catch (err: any) {
      return {
        message: err.response.data?.message[0] || "Impossible de récupérer le planning de l'utilisateur"
      }
    }
  })


  export const deleteWorkout = createAsyncThunk('workout/delete', async (data:{workout:Workout}) => {

    try {
      const result = await axios.delete(`workouts/${data.workout._id}`)
      const deleted:Workout = result.data.workout 
      await sleep(500)
      return { workout: deleted }
  
    } catch (err: any) {
  
      return {
        error: err.response.data?.message[0]
      }
    }
  })



  export const deleteMultipleWorkouts = createAsyncThunk('workout/delete/multiple', async (data:{workouts:Workout[]}) => {

    try {
      const result = await axios.delete(`workouts/planning/multiple`, { data: {workouts: data.workouts}})
      const workouts:Workout[] = result.data.workouts
      await sleep(500)
      return { workouts }
  
    } catch (err: any) {
  
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  export const createWorkout = createAsyncThunk('workout/create', async (data:{workout:Workout, sleep?:boolean, editNow?:boolean }) => {

    const {workout} = data

    const newWorkout:Workout = {
      ...workout,
      createdFor: workout.createdFor,
      status: workout.status ? workout.status : "disabled",
      videoWorkout: workout.videoWorkout,
      video: workout.video
    }


    if(!workout.videoWorkout){
      newWorkout.blocks = [{
        name: "Echauffement",
        content: [],
        blockId: uuid(),
        track: false,
        type: "builder",
        blockType: "classic"
      },
      {
        name: "Corps de séance",
        content: [],
        blockId: uuid(),
        track: true,
        type: "builder",
        blockType: "classic"
      },
      ]
    }

    try {
      const response = await axios.post('workouts', {...newWorkout})
      const workout:Workout = response.data.workout
      if(workout.template || data.sleep){
        await sleep(500)
      }
      return { workout, editNow: data.editNow }
  
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  export const duplicateWorkoutTemplate = createAsyncThunk('workout/duplicate/template', async (data:{workout:Workout}) => {

    const newWorkout:Workout = {
      ...removePerformedSets(data.workout), 
      name: data.workout.name + " (copie)",
      _id:undefined
    }

    try {
      const response = await axios.post('workouts', {...newWorkout})
      const workout:Workout = response.data.workout
      return { workout }
  
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  export const pasteWorkout = createAsyncThunk('workout/paste', async (data:{workout:Workout}) => {

    const {workout} = data

    try {
      const response = await axios.post('workouts', {...workout})
      const workoutCreated = response.data.workout
      return { workout: workoutCreated }
  
    } catch (err: any) {
      console.log('erreur')
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  export const pasteMultiplesWorkouts = createAsyncThunk('workout/paste/multiple', async (data:{workouts:Workout[]}) => {

    const {workouts} = data

    try {
      const response = await axios.post('workouts/multiple', {workouts:workouts})
      var newWorkouts:Workout[] = response.data.workouts

      await sleep(500)
      return { workouts: newWorkouts}
  
    } catch (err: any) {
  
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  export const duplicateWorkout = createAsyncThunk('workout/duplicate', async (data:{workout:Workout}) => {

    const {workout} = data
    const noti: any = toast.loading("Copie en cours ...")
    var createdBy = workout.createdBy

     // convert created by object to string
     if(!isString(workout.createdBy)){
        createdBy = workout.createdBy._id
    }

    try {
      const response = await axios.post('workouts', {...workout, _id:undefined, createdBy, createdAt:new Date(), name: workout.name + " (copie)"})
      const newWorkout:Workout = response.data.workout
      await sleep(600)
      toast.update(noti, { render: "Séance dupliquée", type: "success", isLoading: false, autoClose: 1500 });
      return { workout: newWorkout }
  
    } catch (err: any) {
      toast.dismiss()
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  /********************     Update WORKOUT    *************************** */

export const updateWorkout  = createAsyncThunk('workout/update', async (payload:{workout:Workout}) => {
  const data:Workout = payload.workout
  var createdBy = data.createdBy
  var createdFor = data.createdFor

 
  try {

     // convert created by object to string
    if(!isString(createdBy)){
      createdBy = createdBy._id
    }

    if(!data.template && !isString(createdFor)){
      createdFor = createdFor._id
    }


      const response = await axios.put(`workouts/${data._id}`, {...data, createdBy, createdFor})
      const workout: Workout = response.data.workout
      return { workout }

  } catch (err: any) {
      console.log('err', err)
      return {
          error: err.response.data?.message[0]
      }
  }
})


export const updateMultipleWorkoutsStatus = createAsyncThunk('workouts/update', async (payload:{workouts:Workout[], status: "enabled" | "disabled"}) => {

  try{
    const result = await axios.put('workouts/planning/multiple/status', {workouts:payload.workouts, status: payload.status})
    const workouts:Workout[] = result.data.workouts
    return { workouts, status: payload.status}

  } catch (err: any) {
      return {
          error: err.response.data?.message[0]
      }
  }
})


export const updateWorkoutResults = createAsyncThunk('workout/update/results', async (payload:{workout:Workout}) => {
  const data:Workout = payload.workout
  console.log('savinggggg')

  try {
      const response = await axios.put(`workouts/${data._id}`, data)
      const workout: Workout = response.data.workout
      console.log('saved', workout)
      return { workout }

  } catch (err: any) {
      return {
          error: err.response.data?.message[0]
      }
  }
})


export const addCoachNoteToWorkout = createAsyncThunk('workouts/addCoachNote', async (payload:{workoutId:string, note:string, tokens: {FCM:string}}) => {
  const data = payload

  try {
      const response = await axios.post(`workouts/coachNote`, data)
      const workout: Workout = response.data.workout
      return { workout }

  } catch (err: any) {
      return {
          error: err.response.data?.message[0]
      }
  }
})

