/**
 * Ajout des Key sur les exercices/groupes et des blocks de séances (Workouts créés avant la mise à jour V2)
 * 
 * @params {Workout} La séance nécessitant des Keys uniques
 * @return {Workout} La séance avec les clés 
 * 
 * */



import Workout, {WorkoutExercice, WorkoutBlock, ExerciceSet, GroupExercice } from "interfaces/Workout"
import { v4 as uuid } from "uuid"



export function addKeysToWorkout(workout: Workout) {

    const workoutWithKeys:any = { ...workout }
    const blocksList = [...workout.blocks]

    // ON PARCOURT L'ENSEMBLE DES BLOCKS POUR AJOUTER LES CLES UNIQUES
    workoutWithKeys.blocks.forEach((block: WorkoutBlock, blockIndex:number ) => {

        // ETAPE 1 : AJOUT DE LA CLE UNIQUE POUR LE BLOCK
        const blockWithId:WorkoutBlock = {
            ...block,
            content: [],
            blockId: uuid(),
            instructions: block.instructions
        }
        
        // ETAPE 2 : AJOUT DES CLES POUR LES ELEMENTS DU BLOCK
        block.content.forEach((group: GroupExercice) => {

            // L'ELEMENT N'A PAS DE CLE UNIQUE
            if (!group.groupId) {

                // Ajout de la clé au groupe
                 const exercicesWithKeys: WorkoutExercice[] = []

                    // AJOUT DES CLES POUR CHAQUE EXERCICE
                    group.exercices.forEach((workoutExercice: WorkoutExercice) => {

                        // Ajout des ids sur les sets
                        const setsWithId:ExerciceSet[] = []
                            workoutExercice.sets.forEach((set:ExerciceSet)=> {
                            setsWithId.push({...set, exerciceSetId: uuid()})
                        })

                        // Ajout de l'ID à l'exercice
                        const newExercice:WorkoutExercice = {
                            ...workoutExercice,
                            sets: setsWithId,
                            exerciceId: uuid()
                        }

                        
                        // ON REMET L'EXERCICE DANS LE GROUPE
                        exercicesWithKeys.push(newExercice)
                    })

                    // puis on ajoute le groupe au schedule
                    blockWithId.content.push({
                        exercices: exercicesWithKeys,
                        groupId: uuid(),
                    })
            }

            // Si l'élément a déja une key, on l'ajoute directement au schedule
            else {
                blockWithId.content.push(group)
            }

        })

        // Ajout du block avec les clés ajoutés
        blocksList[blockIndex] = {...blockWithId}
        
    })
    workoutWithKeys.blocks = blocksList

    // ON renvoie la séance avec les clés ajoutées
    return workoutWithKeys

}
