import { Box, Button, Dialog, DialogActions, DialogContent,Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { MOBILE } from "utils/constants";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import User from "interfaces/User";
import { toast } from "react-toastify";
import { postNotification } from "api/notifications";

interface Props{
    open: boolean,
    onClose: ()=> void,
}



export default function DialogSendMealPlanNotification({open,onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState)=> state.user.data)
    const client = useSelector((state:RootState) => state.clients?.profile)
    const mobile = useMediaQuery(MOBILE)
    const pending = useSelector((state:RootState)=> state.notifications.requests.create === "pending")

    

    /******************************
     * ENVOIE DE NOTIFICATION
     *****************************/
  
       const onSendNotification = () => {
        const tokenData = client.notificationsToken

       

        dispatch(postNotification({
            title: "Plan alimentaire",
            body: `Ton coach ${user.firstname} a modifié ton plan alimentaire. Consulte le dès maintenant pour rester à jour !`,
            data:{
                type: 'mealplan',
                //workout: currentWorkout._id,
                deeplink:`/nutrition`
            },
            android: {
                channelId: 'default',
                smallIcon: 'ic_small_icon',
                color: '#2979FF',
            },
            tokens: [{
                FCM: tokenData.FCM,
                user: client._id
            }]
        })).unwrap().then((res)=>{
            if(res.notification){
                toast.success('Notification envoyée')
                onClose()
            }
            else {
                toast.error("Erreur d'envoie de la notification")
            }
        })
    }

    
    


    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"sm"}
            fullScreen={mobile? true:false}
            TransitionComponent={DialogTransition}
            disableScrollLock={!mobile}

        >
            <DialogAppBar
                title={"Envoyer une notification"}
                onClose={onClose}
            />

            <DialogContent>
                    <Typography>
                        Souhaites-tu envoyer une notification à {client.firstname} pour l'informer que tu as modifié son plan alimentaire ? 
                    </Typography>

                    <Box>

                    </Box>
                        
            </DialogContent>

         
                <DialogActions>
                    
                    {!mobile && (
                        <Button 
                        onClick={onClose}
                        disabled={pending}
                        >
                            Annuler
                        </Button>
                     )}
                     <LoadingButton
                        onClick={onSendNotification}
                        loading={pending}
                    >
                        Envoyer
                    </LoadingButton>
                </DialogActions>
           
        </Dialog>
    )
}