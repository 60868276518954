import { BORDER_COLOR, CARD_BORDER_RADIUS } from "utils/constants"
import { theme } from "utils/theme"


const classes= {

    title: {
        fontWeight: 700,
        
    },

    content : {
        backgroundColor: "#f6f6f6", 
        /*overflowY: {
            xs: "auto",
            lg: "hidden", 
        },*/
        //padding: 0,
    },


    headerButtons : {
        display:{xs: "none", sm: "flex"}
    },

    evolutionSubTitle : {
        fontSize: 15,
        fontWeight: 500,
        marginBottom: 3
    },

    scrollBar: {
        height:"calc(100vh - 90px - 64px - 53px)", 
        paddingRight: 3
    },


    addComparaisonButton : {
        border: "dashed 4px #cfcfcf",
         width: "100%",
        height:"calc(100vh - 230px)",
    },

    buttonContainer:{

    },

    titleContainer : {
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between"
    },

    moreButton : {
        marginLeft: 2,
        width: 48,
        height: 48,
        display:{
            xs: "flex", sm: "none"
        }
    },

    resume:{
        paddingBottom: 3,
        borderBottom: "solid 1px whitesmoke",
        backgroundColor: "white",
        padding: 2,

        /*marginLeft: {
            xs: 1, 
            sm: 3, 
            md:3
        },*/
        /*marginRight: {
            xs: 1,
            sm: 3
        }*/
    },


   

    resumeValue : {
        display:"flex",
        alignItems:"flex-end",
        justifyContent:"flex-end",
        flexDirection:"row",

    },


    workoutName : {
        fontSize: "24px",
        fontWeight: 700
    },

    row: {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        paddingTop: 1,
        paddingBottom: 1
    },

    rowLabel : {
        fontSize: {
            xs: 14,
            sm: 16
        }
    },

    value: {
        fontWeight: 600,
        fontSize: {
            xs: 14,
            sm: 16
        }
    },

    block: {
        //backgroundColor: "white",
        marginTop: 6,
        borderRadius: CARD_BORDER_RADIUS,
        padding: 0,

        
        /*marginRight: {
            xs: 1,
            sm: 3,
            md: 3
        },
        padding: {
            xs: 2,
            sm: 3
        },
        marginLeft: {
            xs: 1, 
            sm: 3,
            lg: 0
        },*/
    },
    

    blockName: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 700,
        marginLeft: {
            xs: 1.5,
            sm: 0
        }
        
    },


    blockTrack : {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 400,
        marginLeft: {
            xs: 1.5,
            sm: 0
        }
    },

    exercice:{
        display:"flex",
        flexDirection: "column",
        position: "relative"
    },

    imageContainer:{
        width: 60,
        height: 60,
        borderRadius: 100,
        overflow: "hidden",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position: "relative",
    },

    exerciceImage:{
        width: 110,
    },

    replacedIndicator:{
        position: "absolute",
        top: "48px",
        left: "55px",
        width: 30,
        height: 30,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 100,
        justifyContent:"center",
        alignItems:"center",
        display: "flex"
    },

    header: {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: "#f9f9f9",
        paddingLeft: {
            xs: 0,
            sm: 2,
        },
        paddingRight:2
    },

    exerciceHeaderInfos:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    exerciceName: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 700,
        marginLeft: 2
    },

    exerciceSetsNumber: {
        marginLeft: 2,
        fontSize: theme.typography.pxToRem(14)
    },

    exerciceContent:{
        paddingLeft: {
            xs: 0,
            sm: 2,
        },
        paddingRight: {
            xs: 0,
            sm: 2,
        },
        paddingTop: 2
    },

    setHeader:{
        display:"flex",
        flexDirection: "row",
        width: "100%",
        justifyContent:"space-between",
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        paddingBottom: 1.5,
    },

    setHeaderLabel : {
        color: "black",
        fontWeight: 800,
        textTransform: "uppercase",
        fontSize: theme.typography.pxToRem(14),
        display:"flex",
        flexGrow: 1,
        flex: 1,
        justifyContent:"center"
    },

    set : {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        paddingTop: 1,
        paddingBottom: 1,
        borderBottom: `solid 1px ${BORDER_COLOR}`,
    },

    withoutBorder : {
        borderBottom: 0,
    },

    setNumber: {
        width: {
            xs: "auto",
            sm: 100
        },
        maxWidth:{
            xs: "auto",
            sm: 100
        },
        minWidth: {
            xs: "auto",
            sm: 100
        },
        flexGrow: {
            xs: 1,
            sm:0,
        },
        justifyContent:"center",
        textAlign: "center",
        display:"flex",
        borderRight: `solid 1px ${BORDER_COLOR}`
    },

    perf: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        flex: 1,
        justifyContent:"center"
    },

    perfValue:{
        fontWeight: 500,
        color: "rgba(0,0,0,0.85)"
    },


    exerciceHeaderCompletion : {
        borderRadius: "100%",
        height: 48,
        justifyContent:"center",
        alignItems:"center",
        display:"flex"
    },


    exerciceCompletion: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(15)
    },

    groupContent : {
        backgroundColor: "white",
        marginTop: 3,
        border: "solid 1px whitesmoke",
        marginBottom: 8,
        borderRadius: CARD_BORDER_RADIUS
        
    },

    superset: {
        borderLeft: `solid 5px #969696`,
        marginTop: 0,

    },


    supersetIndicator : {
        backgroundColor: "#2592fc",
        color: "white",
        display:"flex",
        width: 110,
        textTransform: "uppercase",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        textAlign: "center",
        justifyContent:"center",
        alignItems:"center",
        marginTop: 3,
        marginBottom: 2,
        borderRadius: "0 16px 16px 0px",
        paddingRight: 1.5
    },

    note: {
        backgroundColor: "white",
        position: "relative",
        padding: {
            xs: 2, 
            sm: 2
        },
        marginTop: 3
       
    },



    clientNoteTitle: {
        fontSize: theme.typography.pxToRem(14)
    },

    coachNoteTitle : {
        fontSize:  theme.typography.pxToRem(18),
        fontWeight: 600
    },

    bubbleComment : {
        backgroundColor: "whitesmoke",
        display:"flex",
        padding: 3,
        paddingTop: 1,
        paddingBottom:1,
        flex: 1,
        borderRadius: {
            xs:`0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
            sm:`0 ${theme.spacing(10)} ${theme.spacing(10)} ${theme.spacing(10)}`,
        },
        alignItems:"flex-start",
        flexDirection:"column",
        marginTop: 2,


    },

    clientNoteContent:{
        marginTop: 3,
        backgroundColor: "white",
        paddingBottom: 0,
        position: "relative",
        display:"flex",
        flexDirection: "row"
    },

    
    clientNote: {
        fontSize: theme.typography.pxToRem(16),
        fontStyle: "italic",
    },


    noteIndicator: {
        display:"none",
        position: "absolute", 
        top: 0, 
        left: 70, 
        //backgroundColor: "whitesmoke",
        transform: "rotate(45deg)",
        //borderRight: `solid 1px ${BORDER_COLOR}`,
        //borderTop:`solid 1px ${BORDER_COLOR}`,
        width: 26, 
        height: 26, 
        zIndex: 3
    },


    comparisonIndicator : {
        backgroundColor: theme.palette.primary.main,
        display:"inline-block",
        paddingLeft: 1.5,
        paddingRight: 1.5,
        borderRadius: 3,
        fontSize: 14,
        fontWeight: 600,
        width: "fit-content",
        marginTop: 1,
        marginRight: 1,
        color: "white"
    },


    drop: {
        fontSize: theme.typography.pxToRem(13),
        fontStyle: "italic"
    },

    coachComment : {
        //backgroundColor: "#cad5ea",
    },

    avatarContainer : {
        borderRadius: 100,
        width: 50,
        height: 50,
        overflow: "hidden",
        marginRight: 3
    },

    avatar: {
        width: 50,
        height: 50,
    },


   

    rating : {
        marginTop: 1
    },

    satisfaction: {
        fontSize: theme.typography.pxToRem(15),
        fontStyle: "italic",
        marginTop: 1
    }





}


export default classes