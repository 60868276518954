import Workout from "interfaces/Workout"
import { Box, Typography, ListItemIcon, ListItemText, Menu, MenuItem, styled, TextField, Card, FormGroup, FormControlLabel, Switch} from "@mui/material";
import calcSets from "function/calcSets";
import calcSetsDone from "function/calcSetsDone";
import calcTonnage from "function/calcTonnage";
import classes from "./styles";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React, {useEffect, useMemo, useRef, useState } from "react";
import { AUTOSAVE_TIMER, SATISFACTION } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { Close, FactCheck, Grade, Send, StarBorderPurple500 } from "@mui/icons-material";
import { CalendarMonth, Today } from "@mui/icons-material";
import ContainedButton from "components/atoms/Buttons/ContainedButton";
import DialogWorkoutsComparison from "../DialogWorkoutsComparison";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import defineEvolutionColor from "function/defineEvolutionColor";
import { theme } from "utils/theme";
import User from "interfaces/User";
import { updateWorkoutResults } from "api/workouts";
import debounce from "lodash.debounce";
import DialogSendWorkoutResultsNotification from "../DialogSendWorkoutResultsNotification";
import MoreButton, { MenuElem } from "components/atoms/Buttons/MoreButton";
import defineTiredness from "function/defineTiredness";
import WorkoutResultsBlock from "../WorkoutResultsBlock";




interface Props{
    workout: Workout,
    workoutsList: Workout[],
    workoutComparision?: Workout,
    onSelectWorkoutForComparison?: (workout:Workout)=> void,
    isSelected?: boolean,
    lastWeekWorkout?: Workout,
    onCloseComparison?: ()=>void
}



const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
           
        }
    },
}))



export default function WorkoutResults({
    workout, 
    lastWeekWorkout, 
    workoutsList, 
    onSelectWorkoutForComparison , 
    isSelected, 
    onCloseComparison, 
    workoutComparision
}:Props){
    const user = useSelector((state:RootState) => state.user.data)
    const pending = useSelector((state:RootState)=> state.workouts.requests.get === "pending")
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const [openWorkoutsComparisonSelection, setOpenWorkoutsComparisonSelection] = useState<any>(null)
    const [coachNote, setCoachNote] = useState<string>("")
    const dispatch = useDispatch<AppDispatch>()
    const [canSave, setCanSave] = useState<boolean>(false)
    const [workoutForm, setWorkoutForm] = useState<Workout>({...workout})
    const [openSendNotification, setOpenSendNotification] = useState<boolean>(false)
    const [showOnlyTracked, setShowOnlyTracked] = useState<boolean>(true)

    useEffect(()=>{
        if(workout && Boolean(workout.performed?.coachNote)){
            setCoachNote(workout.performed.coachNote.note)
        }
    },[workout])

    const onCloseMenu = (e:any) => {
        e.stopPropagation()
        setAnchorEl(null)
        
    } 
    
    const onOpenMenu = (e:any)=> {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        
    }


    const COMPARISON = useMemo(()=>{

        const resume:{
            tonnage: {
                value: number,
                evolution: "=" | "+" | "-"
            },
            sets:  {
                value: number,
                evolution: "=" | "+" | "-"
            },
            completion:  {
                value: number,
                evolution: "=" | "+" | "-"
            },
        } = {
            tonnage: {
                value: 0,
                evolution: "="
            },
            sets:  {
                value: 0,
                evolution: "="
            },
            completion:  {
                value: 0,
                evolution: "="
            },
        }


        if(workoutComparision){

            // TONNAGE
            const currentTonnage = calcTonnage(workout)
            const comparisonTonnage = calcTonnage(workoutComparision)

            resume.tonnage = {
                value : currentTonnage - comparisonTonnage,
                evolution: currentTonnage - comparisonTonnage < 0 ? "-" : currentTonnage === comparisonTonnage  ? "=" : "+"
            }

             // SETS
             const currentSets = calcSetsDone(workout)
             const comparisionSets = calcSetsDone(workoutComparision)
 
             resume.sets = {
                 value : currentSets - comparisionSets,
                 evolution: currentSets - comparisionSets < 0 ? "-" : currentSets === comparisionSets  ? "=" : "+"
             }


             // COMPLETION
             const currentCompletion = Math.round(calcSetsDone(workout)/calcSets(workout) * 100)
             const comparisonCompletion = Math.round(calcSetsDone(workoutComparision)/calcSets(workoutComparision) * 100)

 
             resume.completion = {
                 value : currentCompletion - comparisonCompletion,
                 evolution: currentCompletion - comparisonCompletion < 0 ? "-" : currentCompletion === comparisonCompletion  ? "=" : "+"
             }

            return resume

        }else{
            return null
        }
    },[workoutComparision])


    const selectWorkout = (lastWeekWorkout:Workout) => {
        onSelectWorkoutForComparison(lastWeekWorkout)
        setAnchorEl(null)
    }

    const openWorkoutSelection = () => {
        setOpenWorkoutsComparisonSelection(true)
        setAnchorEl(null)

    }


    const CLIENT = useMemo(()=>{
        if(workout){
            const client = workout.createdFor as User
            return client
        }

        return null
    },[workout])



    const onUpdateWorkoutForm = (form:Workout)=> {
        setWorkoutForm({...form})
    }


    /*******************************************
     * MISE EN FORME DES DONNES DU FORMULAIRE 
     * *****************************************/

    useEffect(()=>{
        const createdBy:User = workout.createdBy as User
        const createdFor:User = workout.createdFor as User
        setWorkoutForm({
            ...workout,
            createdBy: createdBy._id,
            createdFor: createdFor._id,
            performed:{
                ...workout.performed,
                coachNote: {
                    note: coachNote,
                    date: new Date()
                }
            }
        })
    },[workout])

   


    useEffect(()=>{
        if(Boolean(workout) && workout._id && !canSave){
            setTimeout(()=>{
                setCanSave(true)
            },1500)
        }
    },[workout])

    
    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((workout)=> {
        dispatch(updateWorkoutResults({workout}))
    }, AUTOSAVE_TIMER)).current

    useEffect(()=>{
        if(workoutForm && workoutForm._id && canSave){
            debounceAutoSave(workoutForm)
        }
    },[workoutForm])


    useEffect(()=>{
        const createdBy:User = workout.createdBy as User
        const createdFor:User = workout.createdFor as User
        setWorkoutForm({
            ...workoutForm,
            createdBy: createdBy._id,
            createdFor: createdFor._id,
            performed:{
                ...workoutForm.performed,
                coachNote: {
                    note: coachNote,
                    date: new Date()
                }
            }
        })
    },[coachNote])


    /******************************** 
     * CHANGEMENT DE LA DESCRIPTION 
     * *******************************/


    const onChangeLocalComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDescription:string = event.target.value
        setCoachNote(newDescription)
    }

    const MENU:MenuElem[] = useMemo(()=>{
        var menu:MenuElem[] = []

       
        menu.push(
            {
                label: `Semaine dernière : ${lastWeekWorkout ? lastWeekWorkout.name : "Aucune séance trouvée"}`,
                icon: <Today />,
                callback: ()=> selectWorkout(lastWeekWorkout),
                disabled: !lastWeekWorkout
            },
            {
                label: `Choisir une séance`,
                icon: <CalendarMonth />,
                callback: openWorkoutSelection
            },
            {
                label: `Envoyer une notification`,
                icon: <Send />,
                callback: ()=>setOpenSendNotification(true)
            }
        )

        if(workoutComparision){
            menu.push(
                {
                    label: `Annuler la comparaison`,
                    icon: <Close />,
                    callback: ()=>onSelectWorkoutForComparison(null)
                },
            )
        }

        
        return menu

       
    },[workoutComparision])

    return(
        <>
        <Box sx={classes.resume}>

            <Box
                sx={{display:"flex", justifyContent:"flex-end", alignitems:"center"}}
            >
                {isSelected && (
                    <>
                    <Box
                        sx={classes.headerButtons}
                    >
                        
                        <ContainedButton
                            sx={{marginBottom: 1}}
                            variant="contained"
                            onClick={()=>setOpenSendNotification(true)}
                            startIcon={<Send />}
                            disableElevation={true}
                            
                        >
                            Notifier
                        </ContainedButton>
                       
                        {!workoutComparision && (
                            <ContainedButton
                                sx={{marginBottom: 1}}
                                variant="contained"
                                onClick={onOpenMenu}
                                startIcon={<FactCheck />}
                                disableElevation={true}
                            >
                                Comparer les résultats
                            </ContainedButton>  
                        )}

                        {workoutComparision && (
                            <ContainedButton
                                sx={{marginBottom: 1}}
                                variant="contained"
                                onClick={()=>onSelectWorkoutForComparison(null)}
                                startIcon={<Close />}
                                disableElevation={true}
                            >
                                Annuler
                            </ContainedButton>  
                        )}
                    </Box> 

                        <Menu 
                            disableScrollLock={true} 
                            open={Boolean(anchorEl)}
                            onClose={onCloseMenu}
                            anchorEl={anchorEl}
                        >

                        <MenuItem
                            disabled={!lastWeekWorkout}
                            onClick={()=>selectWorkout(lastWeekWorkout)}
                        >
                            <ListItemIcon>
                                <Today />
                            </ListItemIcon>
                            <ListItemText>
                                Semaine dernière : {lastWeekWorkout ? lastWeekWorkout.name : "Aucune séance trouvée"}
                            </ListItemText>
                        </MenuItem>

                        <MenuItem
                            onClick={openWorkoutSelection}
                            disabled={pending}
                            
                        >
                            <ListItemIcon>
                                <CalendarMonth />
                            </ListItemIcon>
                            <ListItemText>
                                Choisir une séance
                            </ListItemText>
                        </MenuItem>
                        </Menu>
                    </> 
                    
                )}

                {!isSelected && (
                    <SecondaryButton
                        onClick={onCloseComparison}
                    >
                        Fermer
                    </SecondaryButton>
                )}
            </Box>
   
        {/*********
         * NAME
         ********/}
        <Box
            sx={{marginBottom: 3}}
        >

            <Box
                sx={classes.titleContainer}
            >
                <Typography
                    sx={classes.workoutName}
                >
                    {workout?.name}
                </Typography>

                <Box
                    sx={classes.moreButton}
                >
                    <MoreButton 
                        menuList={MENU}
                        contextLabel="Comparer les résultats"
                    />
                </Box>

            </Box>
            <Box
                sx={classes.rating}
            >
                {workout.performed?.satisfaction && (
                        <>
                            {Array.from(Array(workout.performed.satisfaction)).map((_,index)=> {
                                return(
                                    <Grade
                                        sx={{marginRight: 2, color: theme.palette.primary.main}}
                                        key={index}
                                    />
                                )
                            })}
                            {workout.performed.satisfaction < 5 && (
                                <>
                                {Array.from(Array(5 - workout.performed.satisfaction)).map((_,emptyRateindex)=>(
                                        <StarBorderPurple500
                                            sx={{marginRight: 2, color: "#aaaaaa"}}
                                            key={emptyRateindex}
                                        />
                                    )
                                )}
                                </>
                            )}
                        </>
                )}
                
            </Box>
            {workout.performed?.satisfaction && (
                <Typography
                    sx={classes.satisfaction}
                >
                        Évaluation du client : <strong>{SATISFACTION[workout.performed?.satisfaction - 1]}</strong>
                </Typography>
            )}

            {workoutComparision && (
                <Box
                    sx={[classes.evolutionSubTitle,{marginTop: 1.5}]}
                >
                    <Box sx={classes.comparisonIndicator}>Mode comparaison</Box> avec <strong>{workoutComparision.name}</strong> du <strong>{format(new Date(workoutComparision.performed.date), "eee dd MMM à HH:mm", {locale:fr})}</strong>
                </Box>
            )}
        </Box>

        {/****************
         * DATE Schedule
         ****************/}

        <Box sx={classes.row}>
            <Typography
                sx={classes.rowLabel}
            >
                Programmée le
            </Typography>
        
            <Typography sx={classes.value}>
                {(!pending && workout) && format(new Date(workout?.schedule), "dd MMM yyyy", {locale: fr})}
            </Typography>
        </Box>



        {/*********
         * DATE DONE
         ********/}

        <Box sx={classes.row}>
        
            <Typography
                sx={classes.rowLabel}
            >
                Effectuée le
            </Typography>
        
            <Typography sx={classes.value}>
                {(!pending && workout) && format(new Date(workout?.performed.date), "dd MMM yyyy à HH:mm", {locale: fr})}
            </Typography>
        </Box>



        {/*********
         * DUREE
         ********/}
        <Box sx={classes.row}>

            <Typography
                sx={classes.rowLabel}
            >
                Durée
            </Typography>
        
            <Typography sx={classes.value}>
                {workout?.performed.duration}
            </Typography>
        </Box>

         {/*********
         * FATIGuE PRE SEANCE
         ********/}
        <Box sx={classes.row}>
        <Typography
                sx={classes.rowLabel}
            >
                Fatigue pré-séance
            </Typography>
            <Box
                sx={classes.resumeValue}
            >
                <Typography sx={classes.value}>
                    {workoutComparision ? defineTiredness(workoutComparision.performed.tiredness?.preWorkout) + " => " : ""}{defineTiredness(workout.performed.tiredness?.preWorkout)}
                </Typography>
            </Box>
        </Box>

        {/*********
         * FATIGuE POST SEANCE
         ********/}
        <Box sx={classes.row}>
        <Typography
                sx={classes.rowLabel}
            >
                Fatigue post-séance
            </Typography>
            <Box
                sx={classes.resumeValue}
            >
                <Typography sx={classes.value}>
                    {workoutComparision ? defineTiredness(workoutComparision.performed.tiredness?.postWorkout) + " => " : ""}{defineTiredness(workout.performed.tiredness?.postWorkout)}
                </Typography>
            </Box>
        </Box>

        {/*********
         * TONNAGE
         ********/}

        <Box sx={classes.row}>
        <Typography
                sx={classes.rowLabel}
            >
                Tonnage
            </Typography>
            <Box
                sx={classes.resumeValue}
            >
                <Typography sx={classes.value}>
                    {workoutComparision ? calcTonnage(workoutComparision) + " => " : ""}{calcTonnage(workout)} kg
                </Typography>

                {(COMPARISON && isSelected) && (
                    <Box sx={{display:"inline-flex", flexDirection: "row", justifyContent:"flex-end", marginLeft: 1.5}}>
                        
                        <Typography
                            sx={[
                                    {
                                        color:defineEvolutionColor(COMPARISON?.tonnage.evolution ) 
                                    }
                            ]}
                        >
                            {(isSelected && COMPARISON?.tonnage.evolution !=="=") && `${COMPARISON?.tonnage.evolution ==="+" ? "+" : ""} ${COMPARISON?.tonnage.value}kg`}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>

        {/*********
         * SERIES
         ********/}
        <Box sx={classes.row}>
        <Typography
                sx={classes.rowLabel}
            >
                Séries
            </Typography>
            <Box
                sx={classes.resumeValue}
            >
                <Typography sx={classes.value}>
                    {workoutComparision ? calcSetsDone(workoutComparision) + " => " : ""}{calcSetsDone(workout)}
                </Typography>

                {(COMPARISON && isSelected) && (
                     <Box sx={{display:"inline-flex", flexDirection: "row", justifyContent:"flex-end", marginLeft: 1.5}}>
                        
                        <Typography
                            sx={[{color:defineEvolutionColor(COMPARISON?.sets.evolution ) }]}
                        >
                            {(COMPARISON?.sets.evolution !=="=") && ` ${COMPARISON?.sets.evolution ==="+" ? "+": ""} ${COMPARISON?.sets.value} série${COMPARISON?.sets.value> 1 ? "s" : ""}`}
                        </Typography>
                    </Box>
                )}
                
            </Box>
        </Box>

        

         {/*********
         * COMPLETION
         ********/}

        <Box sx={classes.row}>
        <Typography
                sx={classes.rowLabel}
            >
                Complétion
            </Typography>
            <Box
                sx={classes.resumeValue}
            >
                <Typography sx={classes.value}>
                    {Math.round(calcSetsDone(workout)/calcSets(workout) * 100)}%
                </Typography>

                {(COMPARISON && isSelected) && (
                    <Box sx={{display:"inline-flex", flexDirection: "row", justifyContent:"flex-end", marginLeft: 1.5}}>
                        
                        <Typography
                            sx={[{color:defineEvolutionColor(COMPARISON?.completion.evolution ) }]}
                        >
                            {(COMPARISON?.completion.evolution !=="=") ? `${COMPARISON?.completion.evolution ==="+" ? "+" :""} ${COMPARISON?.completion.value}%` : ""}
                        </Typography>
                    </Box>
                )}
                
            </Box>
        </Box>

        {/*********
         * DIFFICULTE
         ********/}

        <Box sx={classes.row}>
            <Typography
                sx={classes.rowLabel}
            >
                Difficulté
            </Typography>
            <Typography 
                sx={classes.value}
            >
                {workoutComparision ? workoutComparision?.performed.rpe + " => " : ""}{workout?.performed.rpe}
            </Typography>
        </Box>

        {/****************** 
         * NOTE DU CLIENT 
         * ************** */}
        {(Boolean(workout?.performed?.note) && workout?.performed.note !== "") && (

        <Card
        elevation={0}
        sx={[
            
            classes.clientNoteContent
        ]}>

            <Box
                sx={classes.avatarContainer}
            >
                {CLIENT.avatar?.url && (
                    <Box
                        component={"img"}
                        src={CLIENT.avatar?.url}
                        sx={classes.avatar}
                    >

                    </Box>
                )}
            </Box>
            
            <Box
                sx={classes.bubbleComment}
            >
                    <Typography
                        sx={{fontSize: 13, fontWeight: 400}}
                    >
                        Commentaire de {CLIENT.firstname}
                    </Typography>
                <Typography
                    sx={classes.clientNote}
                >
                    {workout?.performed?.note}
                </Typography>
            </Box>

        
        </Card>


        )}

        {/****************** 
         * NOTE DU COACH 
         * ************** */}

        <Box

        sx={[
            
            classes.clientNoteContent,
        {marginBottom: 6}
        ]}>

            <Card
                sx={classes.avatarContainer}
                elevation={2}
            >
                {user.avatar?.url && (
                    <Box
                        component={"img"}
                        src={user.avatar.url}
                        sx={classes.avatar}
                        
                    >

                    </Box>
                )}
            </Card>
    
            <Card
                sx={[
                    classes.bubbleComment,
                    classes.coachComment
                ]}
                elevation={1}
            >
            
                <DescriptionTextField 
                    fullWidth
                    value={coachNote}
                    onChange={onChangeLocalComment}
                    sx={{paddingBottom: 1}}
                    variant="standard"
                    multiline={true} 
                    placeholder="Ajoute un commentaire sur cette séance ou un message d'encouragement pour ton client"
                />
            </Card>

            <Box 
                sx={[classes.noteIndicator, classes.coachComment]} 
            />
        </Box>

       
</Box>







{/****************** 
 * CONTENU DE LA SEANCE
 * ************** */}

<Box 
    style={{
        height:"calc(100vh - 90px - 64px - 53px)",
    }}
>


    {workout?.blocks.map((block,blockIndex)=> {

        return(
            <WorkoutResultsBlock
                key={blockIndex}
                block={block}
                workoutComparision={workoutComparision}
                blockIndex={blockIndex}
                workout={workout}
                onUpdateWorkoutForm={onUpdateWorkoutForm}
                workoutForm={workoutForm}
                defaultDisplay={block.track}
            />
        )

       
        
    })}
</Box>
    {isSelected && (
        <DialogWorkoutsComparison
            open={openWorkoutsComparisonSelection}
            onClose={()=>setOpenWorkoutsComparisonSelection(false)}
            workouts={workoutsList}
            onSelectWorkout={onSelectWorkoutForComparison}
            currentWorkout={workout}
        />
    )}


    <DialogSendWorkoutResultsNotification
        open={openSendNotification}
        onClose={()=>setOpenSendNotification(false)}
        currentWorkout={workout}
    />
</>
    )
}