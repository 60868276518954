import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from "@mui/material";
import calcTonnage from "function/calcTonnage";
import Workout from "interfaces/Workout";
import { useMemo, useState } from "react";
import classes from "./styles";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {  MOBILE } from "utils/constants";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { theme } from "utils/theme";

interface Props{
    workouts: Workout[],
    open: boolean,
    onClose: ()=> void,
    onSelectWorkout: (workout:Workout)=> void,
    currentWorkout?: Workout
}



export default function DialogWorkoutsComparison({open,onClose, workouts, onSelectWorkout, currentWorkout}:Props){

    const mobile = useMediaQuery(MOBILE)
    const [workoutSelected, setWorkoutSelected] = useState<Workout>(null)

    const onSubmit = () => {
        onSelectWorkout(workoutSelected)
        onClose()
    }


    const workouts_filtered = useMemo(()=>{
        return workouts?.filter((wod) => wod.performed && wod._id !== currentWorkout?._id).sort((a,b) =>  new Date(b.performed.date).getTime() - new Date(a.performed.date).getTime() )
    },[workouts])
    


    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"sm"}
            fullScreen={mobile? true:false}
            TransitionComponent={DialogTransition}
            disableScrollLock={!mobile}

        >
            <DialogAppBar
                title={"Sélectionne une séance"}
                onClose={onClose}
            />

            <DialogContent 
                sx={classes.content}>
                    <Box
                        sx={{padding: 3}}
                    >
                        <Typography>
                            Séances des 30 derniers jours
                        </Typography>
                    </Box>
                    {workouts_filtered?.map((elem,index)=>{
                        return(
                            <Box
                                key={index}
                                sx={[
                                    classes.workout, 
                                    //workoutSelected?._id === elem._id && classes.selected
                                ]}
                                onClick={()=>setWorkoutSelected(elem)}
                            > 
                                    <Box
                                        sx={{marginLeft: 3}}
                                    >
                                        {workoutSelected?._id === elem._id ? <RadioButtonChecked sx={{color: theme.palette.primary.main}} /> :<RadioButtonUnchecked />}
                                        
                                    </Box>
                                    <Box
                                        sx={classes.workoutDate}
                                    >
                                        <Typography
                                            sx={{fontSize: 13}}
                                        >
                                            {format(new Date(elem.performed.date), "eee", {locale: fr})} 
                                        </Typography>
                                        <Typography
                                            sx={classes.workoutDateNumber}
                                        >
                                            {format(new Date(elem.performed.date), "dd", {locale: fr})} 
                                        </Typography>
                                        <Typography
                                            sx={{fontSize: 13}}
                                        >
                                            {format(new Date(elem.performed.date), "MMM", {locale: fr})} 
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={classes.workoutInfos}
                                    >

                                        <Typography
                                            sx={classes.workoutName}
                                        >
                                            {elem.name}
                                        </Typography>
                                        <Box
                                            sx={classes.workoutTags}
                                        >
                                            <Box sx={
                                                [classes.measureContainer, {marginRight: 2}]
                                            }>
                                                    <Typography
                                                        sx={classes.measureContainerLabel}
                                                    >
                                                    Difficulté :
                                                    </Typography>
                                                    <Typography
                                                        sx={classes.measureContainerLabel}
                                                    >
                                                        &nbsp;<strong>{elem.performed.rpe} / 10</strong>
                                                    </Typography>
                                                </Box>
                                            <Box 
                                                sx={classes.measureContainer}
                                            >
                                                 <Typography
                                                        sx={classes.measureContainerLabel}
                                                    >
                                                    Tonnage :
                                                </Typography>
                                                <Typography
                                                        sx={classes.measureContainerLabel}
                                                    >
                                                    &nbsp;<strong>{calcTonnage(elem)} kg</strong>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    
                                
                            </Box>
                        )
                    })}

                    {workouts_filtered?.length === 0 && (
                        <Typography
                            sx={{marginLeft: 3}}
                        >
                            Aucune séance trouvée
                        </Typography>
                    )}
                        
            </DialogContent>

       
            <DialogActions>
                <LoadingButton
                    onClick={onSubmit}
                >
                    Valider
                </LoadingButton>
                {!mobile && (
                <Button onClick={onClose}>
                    Fermer
                </Button>
                )}
            </DialogActions>
        
        </Dialog>
    )
}