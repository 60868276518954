import { IMaskInput } from 'react-imask';
import React from "react";

/******** INTERFACE ******* */

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }


/******** MASKED INPUT ******* */

const TimeMaskInput = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          onChange={()=>{return;}}
          mask={"10:10"}
          placeholder={"00:00"}
          inputRef={ref}
          onAccept={(value: string) =>  onChange({ target: { name: props.name, value } })}
          lazy={false}
          definitions= {{
            '1': /[0-5]/,
            "0": /[0-9]/
          }}
        />
      );
    },
  );


  export default TimeMaskInput