import { useEffect, useMemo, useRef, useState } from "react"
// MUI
import { Box, Grid, Dialog, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material"
import { Check, Delete, Upload } from "@mui/icons-material"
// REDUX
import { useDispatch, useSelector } from "react-redux"
// FUNCTIONS
import Image from "interfaces/Image"
import { AppDispatch, RootState } from "app/store"
// STYLES
import classes from "./styles"
import Workout from "interfaces/Workout"
import { toast } from "react-toastify"
import { MOBILE, TABLET, WORKOUT_COVERS } from "utils/constants"
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import SecondaryButton from "components/atoms/Buttons/Secondary"
import { deleteCustomCover, getCustomCovers, uploadCover } from "api/cover"
import LoadingButton from "components/atoms/Buttons/LoadingButton"
import { updateWorkout } from "api/workouts"
import getCroppedImg from "function/getCroppedImage"
import DialogAppBar from "components/molecules/DialogAppBar"
import DialogTransition from "components/molecules/DialogTransition"
import DialogLoader from "components/molecules/DialogLoader"



/**************** INTERFACES ************* */

interface Props{
    open: boolean,
    onClose: ()=> void,
    workout: Workout
}


/****************************** MAIN COMPONENT ********************************* */

export default function DialogUpdateCover({open,onClose, workout}: Props){

    const dispatch = useDispatch<AppDispatch>()
    const [cover, setCover] = useState<Image>(null)
    const [tabs, setTabs] = useState(0)
    const pending = useSelector((state:RootState)=> state.workouts.requests.update === "pending")
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)
    const desktop = !mobile
    const [file, setFile] = useState<Blob>(null)
    const [preview, setPreview] = useState<string>(null)
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [customCovers, setCustomCovers] = useState<{url: string, cloudFlareId: string}[]>([])
    const uploading = useSelector((state:RootState) => state.planning.requests.uploadCover === "pending")
    const deleting = useSelector((state:RootState) => state.planning.requests.deleteCover === "pending")
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const uploadInput = useRef<any>(null)


    const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    };


    useEffect(()=>{
        if(open){
            dispatch(getCustomCovers()).unwrap().then((res:any)=>{
                setCustomCovers(res.covers || null)
                var allCovers:Image[] = []

                WORKOUT_COVERS.forEach((url:string)=> {
                    allCovers.push({url})
                })

                res.covers.filter((elem:Image)=> elem.cover).forEach((elem:Image)=> {
                    allCovers.push({
                        url: elem.url, 
                        custom: true, 
                        cloudFlareId: elem.cloudFlareId
                    })
                })
                
                const findCover = allCovers.find((elem)=> elem.url === workout.cover?.url)


                setCover({
                    url: findCover.url, 
                    custom: findCover.custom, 
                    cloudFlareId: findCover.cloudFlareId
                })
            })
            
        }
    },[open])




    /*********** Selection d'une image ********* */

    const selectCover = (image:Image) => {
        setCover(image)
    }

    /*********** CHOIX DE LA COUVERTURE ********* */

    const onDefineCover = () => {

        const updatedWorkout = {...workout, cover: cover}
        dispatch(updateWorkout({workout: updatedWorkout})).unwrap().then((res:any)=>{
            onClose()
        })
    }


    {/*******************************
        SUPPRESSION D'UNE COUVERTURE
    *********************************/}

    const onDeleteCover = () => {
        dispatch(deleteCustomCover(cover.cloudFlareId)).unwrap().then((res:any)=>{
            var allCovers:Image[] = []


            WORKOUT_COVERS.forEach((url:string)=> {
                allCovers.push({url})
            })

            customCovers.forEach((elem:Image)=> {
                allCovers.push({
                    url: elem.url, 
                    custom: true, 
                    cloudFlareId: elem.cloudFlareId
                })
            })
            
            const index = allCovers.findIndex((elem)=> elem.url === workout.cover.url)
            allCovers.splice(index,1)
            var toDelete = customCovers.findIndex((elem) => elem.cloudFlareId === cover.cloudFlareId)
            var filtered = [...customCovers]
            filtered.splice(toDelete,1)

            const updatedWorkout = {...workout, cover: allCovers[0]}
            dispatch(updateWorkout({workout: updatedWorkout}))

            setCustomCovers(filtered)
            setCover({...allCovers[0], custom: true})
            toast.success('Image supprimée')
        })
    }


    /********** LISTE DES IMAGES DE L'APPLICATION ****** */

    const imagesList:Image[] = useMemo(()=>{
        const list:Image[] = []

        customCovers.forEach((cover)=> {
            list.push({
                url: cover.url,
                custom: true,
                cloudFlareId: cover.cloudFlareId
            })
        })


        WORKOUT_COVERS.forEach((url:string)=>{
            list.push({
                url: url
            })
        })

        return list
    },[WORKOUT_COVERS, customCovers])




    

   


    /*******************************
     *      GESTION DES IMAGES
     ******************************/

    const onFileChange = (e:React.ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        const file:Blob = target.files[0]

        setFile(file)
        if (file.size > 5 * 1024 * 1024) {
            toast.error('Le fichier est trop volumineux. Limite : 5mo')
            setFile(null)
            return;
        } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
            toast.error('Format non pris en charge')
            setFile(null)
            return;
        }
    }


    useEffect(()=>{
        if(Boolean(file)) {
            setPreview(URL.createObjectURL(file))
        }
        else {
            setPreview(null)
        }
    },[file])



    /******************
        Upload cover
    *******************/

    const onUploadCover = async () => {
        const croppedImage:Blob = await getCroppedImg(
            URL.createObjectURL(file),
            croppedAreaPixels,
            0
        )

        dispatch(uploadCover(croppedImage)).unwrap().then((res:any)=>{
            if(res.cover){
                const currentCovers = [...customCovers]
                currentCovers.splice(0,0,res.cover)
                setCustomCovers([...currentCovers])
                setTabs(0)
                setFile(null)
                setPreview(null)
                setCover(res.cover)
                toast.success('Image de couverture ajoutée')
            }
        })
    }




    /*********** RENDER ********* */

    return(
        <>
        <Dialog
            open={open} 
            fullWidth 
            maxWidth={"md"}
            fullScreen={mobile || tablet}
            TransitionComponent={DialogTransition}
        >
            <DialogAppBar
                title={"Photo de couverture"}
                onClose={onClose}
               


            />
            <DialogContent>

                <Box 
                    sx={{paddingTop: 3, paddingBottom: 3, backgroundColor: "white", position: "relative"}}
                >

                        {/******************
                         * INPUT UPLOAD
                         *****************

                        {(tabs === 1 && !preview) && (
                            <>
                               
                                <Box 
                                    component="label"
                                    htmlFor="uploader"
                                    sx={classes.inputLabel}
                                >
                                    <ImageIcon 
                                        sx={classes.labelImage}
                                    />
                                    <Typography
                                        sx={classes.uploaderLabel}
                                    >
                                        Choisir une image
                                    </Typography>
                                    <Typography
                                        sx={classes.uploaderSubLabel}
                                    >
                                        JPG/PNG - 1280 * 720 recommandé
                                    </Typography>
                                    
                                </Box>


                            
                                <Box 
                                    id="uploader"
                                    component="input"
                                    type="file"
                                    sx={classes.fakeInput}
                                    onChange={onFileChange}
                                />
                            </>
                        )}
                            */}


                        {/******************
                         * IMAGES UPLOADEES
                         ******************/}

                        {(tabs === 1 && preview) && (
                            <>
                               

                                {/***************************
                                 * VALIDER LE TELECHARGEMENT
                                 **************************

                                <Box
                                    sx={classes.cropperButtons}
                                >
                                   
                                    <LoadingButton
                                        variant="contained"
                                        onClick={onUploadCover}
                                        loading={uploading}
                                        disabled={uploading}
                                    >
                                        Télécharger l'image
                                    </LoadingButton>

                                </Box>
                                */}
                            </>

                        )}

                        {/*(tabs === 0 && !preview) && (
                            <Box
                                sx={classes.filters}
                            >
                                <Tabs
                                    value={filters}
                                    onChange={(e,value)=>setFilters(value)}
                                    tabs={filtersLabel}
                                />
                                    
                                
                            </Box>
                        )*/}


                            {preview && (
                                <Box
                                    sx={classes.cropper}
                                >

                                        {/***************
                                         * ICONE VALIDER
                                         ***************/}

                                        <Box
                                            sx={classes.validIcon}
                                            onClick={onUploadCover}
                                        >
                                            <Check 
                                                sx={classes.valid}
                                                
                                            />
                                        </Box>

                                        {/***************
                                         * ICONE DELETE
                                         ***************/}

                                         <Box
                                            sx={classes.deleteIcon}
                                            onClick={()=> !uploading? setPreview(null) : null}
                                        >
                                            <Delete 
                                                sx={classes.delete}
                                                
                                            />
                                        </Box>
                                
                                        <Cropper
                                            image={preview}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={16 / 9}
                                            onCropChange={setCrop}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                        />
                                </Box>)}

                        <Grid 
                            container 
                            spacing={3}
                        >

                        {/********************
                         * BOUTON UPLOAD
                         ********************/}

                        <Grid 
                            item 
                            xs={12} 
                            sm={4} 
                            sx={{position: "relative"}}
                        >
                            <Box
                                sx={{display:"flex", flexDirection: "column", backgroundColor: "whitesmoke", height: "100%"}}
                            >
                                <SecondaryButton
                                    startIcon={<Upload/>}
                                    sx={{height: "100%"}}
                                    onClick={()=> uploadInput.current?.click()}
                                    disabled={Boolean(preview)}
                                >
                                    <input 
                                        type="file" 
                                        ref={uploadInput}
                                        id="upload-image" 
                                        style={{display:"none", height: "100%", width: "100%"}} 
                                        accept="image/png, image/jpeg" 
                                        onChange={onFileChange}
                                        disabled={Boolean(preview)}
                                    />
                                        Télécharger une image
                                    </SecondaryButton>
                                
                                
                            </Box>
                        </Grid>

                        {/********************
                         * LISTE DES IMAGES
                         ********************/}

                         
                        
                        {tabs === 0 && imagesList.map((image:Image, _: number)=> {

                            const selected = cover?.url === image?.url

                            return(
                                <Grid 
                                    item 
                                    xs={12} 
                                    sm={4} 
                                    key={image?.url} 
                                    sx={{position: "relative"}}
                                >
                                    {/*selected && (
                                        <Box sx={classes.selectedIndicator}>
                                            <CheckCircle sx={classes.checkCircle} />
                                        </Box>
                                    )*/}

                                    {(selected && image.cloudFlareId) &&  (
                                         <Box
                                            sx={[classes.deleteIcon,{top: 24}]}
                                            onClick={onDeleteCover}
                                        >
                                            <Delete 
                                                sx={classes.delete}
                                                
                                            />
                                        </Box>
                                    )}

                                    <Box 
                                        component="img" 
                                        src={image?.url} 
                                        sx={classes.coverChoiceItem(selected)}
                                        
                                        onClick={()=>(!pending && !deleting) ? selectCover(image) : ()=> toast.error('Veuillez patienter')}
                                    />
                                </Grid>
                            )
                        })}



                        

                            
                        
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                {desktop && (
                    <Button 
                        disabled={pending || uploading || deleting} 
                        onClick={onClose}>
                            Fermer
                    </Button>
                )}


                
                <LoadingButton 
                    variant="contained" 
                    loading={pending} 
                    disabled={pending || !cover || uploading || deleting || tabs === 1} 
                    onClick={onDefineCover}
                >
                    Valider
                </LoadingButton>





            </DialogActions>
        </Dialog>
        
        <DialogLoader
            text="Upload en cours"
            open={open && uploading}
        />

        <DialogLoader
            text="Suppression en cours"
            open={open && deleting}
        />
        </>
        
        
    )
}