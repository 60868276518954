import { Box, TextField, Typography, styled } from "@mui/material"
import { theme } from "utils/theme"
import classes from "./styles"


const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            //borderBottom: "2px dashed rgb(169 169 169 / 42%)"
            borderBottom: 0
        }
    },
}))


interface Props{
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    noMargin?: boolean
}

export default function ProgramDescription({value,onChange, noMargin = false}:Props){

    return(
         <Box
            sx={{marginBottom: 3, marginTop: noMargin? 0 :3}}
        >

         <Typography 
             variant="body1" 
             sx={classes.sectionLabel}
         >
             Description
         </Typography>
         <DescriptionTextField
             multiline={true}
             fullWidth
             value={value}
             onChange={onChange}
             placeholder={`(Optionnel) Entre une description pour ce programme`}
             variant="standard" 
         />
     </Box>
    )
}