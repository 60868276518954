import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { duplicateWorkout } from "api/workouts";
import { AppDispatch, RootState } from "app/store";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import removePerformedSets from "function/removePerformedSets";
import Workout from "interfaces/Workout";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface Props{
    open: boolean,
    onClose: ()=>void,
    workout: Workout
}

export default function DialogSaveWorkoutAsTemplate({open,onClose, workout}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.workouts.requests.duplicate === "pending")
    const [name, setName] = useState<string>(workout.name)

    const onSaveAsTemplate = () => {

        const newWorkout:Workout = {
            ...removePerformedSets(workout),
            _id: undefined,
            name: name, 
            template: true, 
            createdFor: undefined, 
            schedule: undefined,
            status:"disabled",
            cover: workout.cover,
            programTemplate: undefined,
            performed: undefined
        }

        dispatch(duplicateWorkout({workout: newWorkout})).unwrap().then(()=>{
            onClose()
        })
    }


    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }

    return(
        <Dialog open={open} fullWidth maxWidth={"xs"}>
            <DialogTitle>
                Dupliquer comme modèle
            </DialogTitle>
            <DialogContent>
                <TextField
                    label={"Nom du modèle"}
                    value={name}
                    onChange={onChangeName}
                    fullWidth={true}
                    sx={{marginTop: 1}}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={pending}
                    onClick={onClose}>
                        Annuler
                </Button>
                <LoadingButton 
                    loading={pending} 
                    disabled={pending}
                    onClick={onSaveAsTemplate}
                >
                    Enregistrer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}