import { createTheme } from '@mui/material/styles';
import { blue, blueGrey, cyan, deepOrange, deepPurple, indigo, lightBlue, orange, teal } from '@mui/material/colors';


declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const defaultTheme = createTheme({

  typography: {

      fontFamily: "Lexend,Roboto,sans-serif"
    
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  mixins: {
    toolbar: {
      minHeight: "60px !important",
    }
  },
  components: {

    MuiCssBaseline: {
      styleOverrides: () => ({
        
        body: {
          //overflowY: "hidden",
          backgroundColor: "#fbfbfb",
          overflowY: "scroll",
          //paddingRight: "0px !important"
        },
      }),
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          //borderRadius: 15
        },

        elevation0:{
          //border: `solid 1px ${BORDER_COLOR}`,
          borderRadius: "5px",
        },

        elevation1: {
          boxShadow: "0 10px 30px rgb(80 86 104 / 6%)",
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          //fontWeight: "700 !important",
          fontSize: "14px",
          borderRadius: "6px !important",
          textTransform: "none"
        }
      }
    },
    MuiButton:{
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "14px",
          borderRadius: "6px !important",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: "rgb(244 244 244)"
        },
        head: {
          //textTransform: "uppercase",
          //color: "#888888",
          fontWeight: 500,
          color: "rgb(48 48 48 / 87%)",
          //fontSize: 13,
          borderBottomColor: "rgb(244 244 244)"
        },
        body: {
          height: 75,
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: 0
          //boxSizing: "borderBox"
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 14,
          color: "silver",
          width: 150
          //textTransform: "none"
        },

      }
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          top: 40,
          //width: "50px !important"

        }
      }
    },

    MuiBackdrop:{
      styleOverrides:{
        root:{
          //backgroundColor:"rgb(135,135,135,0.86)"
          //backgroundCOlor: "#f7f7f7"
        }
      }
    }
  }
})

//theme.shadows[1] = "0 10px 30px rgb(80 86 104 / 15%)"
//theme.shadows[1] = "0px 2px 4px rgb(80 86 104 / 9%)"


export const primaryColor: string = blue["A400"]
export const secondaryColor: string = "#343438"


export const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    background: {
      default: "white"
    }

  },

});





