import { Button, darken, styled } from '@mui/material';
import { ButtonProps as MuiButtonProps } from "@mui/material/Button"



const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    height: 40,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    textTransform: "none",
    flexWrap: "nowrap",
    borderRadius: "24px !important",
    //background: theme.palette.primary.main,
    background: `linear-gradient(80deg, ${theme.palette.primary.main} 0%, #06b6d4 100%)`,
    transition: "all .2s ease-in-out",
    "&:hover":{
        background: `linear-gradient(80deg, ${theme.palette.primary.main} 0%, #15d3f5 100%)`,
    
    }
 }))




export default function ContainedButton(buttonProps:MuiButtonProps){

        return(
            <StyledButton 
                {...buttonProps} 
            >
                {buttonProps.children}
            </StyledButton>
        )
    
}