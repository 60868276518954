import { Box, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material"
import WorkoutResultExercice from "../WorkoutResultExercice"
import Workout, { WorkoutBlock } from "interfaces/Workout"
import classes from "./styles"
import React, { useEffect, useState } from "react"
import findExerciceForComparison from "function/findExerciceForComparison"
import defineEvolutionColor from "function/defineEvolutionColor"
import User from "interfaces/User"


interface Props{
    block: WorkoutBlock,
    workoutComparision: Workout,
    blockIndex: number,
    workout: Workout,
    onUpdateWorkoutForm: (form:Workout)=> void,
    workoutForm: Workout,
    defaultDisplay: boolean
}

export default function WorkoutResultsBlock({
    block, 
    blockIndex, 
    workoutComparision, 
    workout, 
    onUpdateWorkoutForm, 
    workoutForm,
    defaultDisplay = true
}:Props){


    const [show, setShow] = useState<boolean>(false)

    useEffect(()=>{
        if(show !== defaultDisplay){
            setShow(defaultDisplay)
        }
    },[defaultDisplay])


    return(
        <Box 
            sx={classes.block}
        >
            <Box
                sx={{display:"flex", flexDirection: "row", justifyContent: "space-between"}}
            >
                <Box>
                    <Typography 
                        sx={classes.blockName}>
                            {block.name}
                    </Typography>
                    <Typography 
                        sx={classes.blockTrack}>
                            {block.track ? "Avec suivi des performances" : "Aucun suivi des performances"}
                    </Typography>
                </Box>

               
                <FormGroup>
                    <FormControlLabel control={
                        <Switch 
                            checked={show}
                            onChange={()=> setShow(!show)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        
                        />
                    } label="Afficher" />
                </FormGroup>
            
            </Box>

            {show && (
            <Box>
                {block.content.map((blockContent, blockContentIndex)=>{

                    return(

                        <React.Fragment  
                            key={blockContentIndex} 
                        >
                        
                        <Box 
                            sx={[
                                classes.groupContent
                            ]}
                        >

                            {blockContent.exercices.length>1 && (
                                <Box sx={classes.supersetIndicator}>
                                    Superset
                                </Box>
                            )}

                            {blockContent.exercices.map((exercice, exerciceIndex)=> {

                                const setsLength = exercice.sets.length
                                const setsDone = exercice.sets.filter((set)=> set.performances.performed).length
                                
                                const exerciceForComparison = findExerciceForComparison(exercice.replaced? exercice.replaced._id : exercice?.exercice?._id, workoutComparision)
                                const comparisonMode = Boolean(workoutComparision)

                                return(
                                   <WorkoutResultExercice
                                        exercice={exercice}
                                        block={block}
                                        blockIndex={blockIndex}
                                        contentIndex={blockContentIndex}
                                        exerciceIndex={exerciceIndex}
                                        setsDone={setsDone}
                                        setsLength={setsLength}
                                        key={exerciceIndex}
                                        exerciceForComparison={exerciceForComparison}
                                        defineEvolutionColor={defineEvolutionColor}
                                        note={exerciceIndex === blockContent.exercices.length -1 ? blockContent.note : null}
                                        client={workout.createdFor as User}
                                        onUpdateWorkoutForm={onUpdateWorkoutForm}
                                        workoutForm={workoutForm}
                                        coachNoteDefault={exercice.coachNote}
                                        comparisonMode={comparisonMode}
                                        blockTrack={block.track}
                                   />
                                )
                            })}
                        </Box>

                        </React.Fragment>
                    )
                })}
            </Box>
            )}
          
        </Box>
    )
}