import { Dialog, DialogActions, Button, DialogContent, Typography, useMediaQuery } from "@mui/material";
import { MOBILE } from "utils/constants";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import DialogTransition from "../DialogTransition";
import DialogAppBar from "../DialogAppBar";
import { updateUserData } from "api/user";
import { useEffect, useState } from "react";


interface Props{
    open: boolean,
    onClose: ()=>void
}

export default function DialogHandleCoachExercicesDisplay({open, onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.user.requests.update === "pending")
    const showOnlyCoachExercices = useSelector((state:RootState) => state.user.data.settings?.showOnlyCoachExercices)
    const [disable, setDisable] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const exercices = useSelector((state:RootState)=> state.exercices.list.custom)

    useEffect(()=>{
        if(open){
            if(showOnlyCoachExercices && !disable){
                setDisable(true)
            } else if(!showOnlyCoachExercices && disable) {
                setDisable(false)
            }
        }
    },[open])


    const onSubmit = () => {
       
            dispatch(updateUserData({
                settings : {
                    showOnlyCoachExercices: !showOnlyCoachExercices
                }
            })).unwrap().then((res)=>{
                if(res.user){
                    onClose()
                }
            })
        
    
    }

    return(
        <Dialog
            open={open}
            fullWidth
            maxWidth={"xs"}
            TransitionComponent={DialogTransition}
            fullScreen={mobile}
            disableScrollLock={!mobile}
        >
            <DialogAppBar 
                title={"Affichage des exercices"}
                onClose={onClose}
            />
           
            <DialogContent>
                <Typography>
                {(disable && exercices.length>9) && ("Souhaites-tu afficher tous les exercices de l'application (Traener + tes propres exercices) sur l'application mobile de tes clients ?")}
                {(!disable && exercices.length>9) &&("Souhaites-tu afficher uniquement tes exercices dans la liste des exercices de l'application mobile ? Si tu as créé des séances avec des exercices créés par Traener, ces derniers resteront disponibles dans ces séances. Ce paramètre n'impacte que l'affichage de la liste des exercices visible par tes clients sur l'écran d'accueil.")}
                {exercices.length<10 && (
                    "Tu dois avoir au moins 10 exercices personnalisés pour activer cette fonctionnalité"
                )}
                </Typography>
                
            </DialogContent>
            <DialogActions>
                {!mobile && (
                    <Button
                        onClick={onClose}
                        disabled={pending}
                    >
                        Fermer
                    </Button>
                )}
                {exercices.length>9 && (
                    <LoadingButton
                        onClick={onSubmit}
                        loading={pending}
                    >
                        Valider
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    )
}