

const classes = {
    cover:{
        //backgroundColor: "whitesmoke",
        display:{
            xs: "none",
            sm: "none",
            md: "block"
        },
        width: {
            md: 150,
            lg: 250,
            xl: 330
        },
        
        

    },

    image : {
        width: "100%",
        objectFit: "cover",
        borderRadius: 3,
        transform :"scale(1)",
        transition: "all 0.2s",
        "&:hover":{
            cursor: "pointer",
            transform :"scale(1.02)",
            transition: "all 0.2s"
        }
    }
}

export default classes