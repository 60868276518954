import React, { useEffect, useMemo, useRef, useState } from "react";
// MUI
import { Box, Button, Grid, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
// REDUX
import { useSelector } from "react-redux";
import { shallowEqual, useDispatch } from "react-redux";
// DEBOUNCE
import debounce from "lodash.debounce"
// API
import {  getWorkout, updateWorkout} from "api/workouts"
// REDUCERS
import { selectDrawerItem } from "features/appSlice";
import { handleName, workoutIsSaving, addBlockToWorkout, reorderWorkout, resetWorkoutBuilder, handleLevel, onChangeDescription, defineNewWorkout } from "features/workoutSlice"
// INTERFACES
import Workout, {WorkoutBlock as WorkoutBlockType} from "interfaces/Workout";
import Image from "interfaces/Image";
import { AppDispatch, RootState } from "app/store";
// LOCAL COMPONENTS
import DialogActivateWorkout from "components/molecules/DialogActivateWorkout";
import WorkoutBlock from "components/organisms/WorkoutBlock";
// DND
import { DragDropContext } from "react-beautiful-dnd";
// SHARED COMPONENTS
import TitleTextField from "components/molecules/TitleTextField";
import Loader from "components/molecules/Loader";
import DialogLoader from "components/molecules/DialogLoader";
// STYLES
import classes from "./styles";
import { AUTOSAVE_TIMER, INPUT_DEBOUNCE_TIMER, MOBILE, TRAD_LEVEL } from "utils/constants";
import { format, isSameDay} from "date-fns";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fr } from "date-fns/locale";
import MainContent from "components/molecules/MainContent";
import { AccessTime, Add,  Check,  ExpandMore, Save, SignalCellularAlt, SignalCellularAlt1Bar, SignalCellularAlt2Bar, Today, Weekend } from "@mui/icons-material";
import DialogSaveWorkoutAsTemplate from "components/molecules/DialogSaveWorkoutAsTemplate";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WorkoutDescription from "components/molecules/WorkoutDescription";
import Navbar from "components/organisms/NavBar";
import SwitchButton from "components/molecules/SwitchButton";
import Program from "interfaces/Program";
import DialogImportWorkout from "components/organisms/DialogImportWorkout";
import { copyWorkoutPlanning, resetWorkoutCopied } from "features/planningSlice";
import { toast } from "react-toastify";
import DialogDeleteWorkout from "components/molecules/DialogDeleteWorkout";
import User from "interfaces/User";
import calcWorkoutDuration from "function/calcWorkoutDuration";
import DialogWorkoutResults from "components/organisms/DialogWorkoutResults";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import WorkoutBuilderCover from "components/molecules/WorkoutBuilderCover";
import MoreButton from "components/atoms/Buttons/MoreButton";

const LEVEL_ICON = {
    beginner: <SignalCellularAlt1Bar sx={classes.tagInfoIcon} />,
    medium: <SignalCellularAlt2Bar sx={classes.tagInfoIcon} />,
    advanced: <SignalCellularAlt sx={classes.tagInfoIcon} />,

}

const days = ['monday', "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

/********* MAIN COMPONENT ****** */

export default function WorkoutBuilder() {
    const history = useHistory()
    const coachId = useSelector((state:RootState) => state.user.data._id)
    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation<{
        planningRange: {start:Date, end: Date}, 
        backToDashboard:boolean, 
        planningScroll: number,
        clientProfile?:User,
        date?:Date,
        workoutid: string
    }>()
    const params:{clientid:string, programid:string} = useParams()
    //const pendingWeek = useSelector((state:RootState)=> state.planning.requests.getWorkouts === "pending")
    //const workoutCopied = useSelector((state:RootState) => state.planning.builder.workoutCopied)
    const [clientId,setClientId] = useState<string>(null)
    const [client,setClient] = useState<User>(null)

    // Selectors
    const name: string = useSelector((state:RootState)=> state.workouts.builder.name)
    const isTemplate:boolean = useSelector((state:RootState)=> state.workouts.builder.template)
    const createdBy = useSelector((state:RootState)=> state.workouts.builder.createdBy)
    //const program = useSelector((state:RootState)=> state.planning.builder)
    const workoutId:string = useSelector((state:RootState)=> state.workouts.builder._id)
    const level:"beginner"|"medium"|"advanced" = useSelector((state:RootState)=> state.workouts.builder.level)
    const schedule:string = useSelector((state:RootState)=> state.workouts.builder.schedule)
    const blocks: WorkoutBlockType[] = useSelector((state:RootState)=> state.workouts.builder.blocks, shallowEqual)
    const cover:Image = useSelector((state:RootState)=> state.workouts.builder.cover, shallowEqual)
    const status:"enabled" | "disabled" | "done" | "missed" | "started" = useSelector((state:RootState)=> state.workouts.builder.status)
    const pending = useSelector((state:RootState)=> state.workouts.requests.update === "pending")

    const pendingDayWorkout = useSelector((state:RootState) => state.workouts.requests.get === "pending")
    const [anchorElMenuLevel, setAnchorElMenuLevel] = React.useState<null | HTMLElement>(null);
    //const pendingNewWorkout = useSelector((state:RootState)=> state.workouts.requests.create === "pending")
    //const [weekPrograms, setWeekPrograms] = useState<Program[]>([])
    const performed = useSelector((state:RootState) => state.workouts.builder.performed, shallowEqual)


    // Local state
    const [formIsReady, setFormIsReady] = useState<boolean>(false)
    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)
    const [openPlanningWaitinUpdate, setOpenPlanningWaitinUpdate] = useState<{date: Date, workouts:Workout[]}>(null)
    const [openActivateWorkout, setOpenActivateWorkout] = useState<boolean>(false)
    const [localName, setLocalName] = useState<string>('')
    const [localDescription, setLocalDescription] = useState<string>('')
    const [openSaveAsTemplate, setOpenSaveAsTemplate] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    /*const [weekStart, setWeekStart] = useState<Date>(
        params.programid ? 
            addWeeks(new Date(program.startDate) ,differenceInWeeks(location.state.date,new Date(program.startDate))) : 
            startOfWeek(location.state.date,{locale:fr})
    )*/
    const [weekWorkouts, setWeekWorkouts] = useState<Workout[]>([])
    //const [daySelected, setDaySelected] = useState<Date>(location.state.date)
    const [openImportWorkout, setOpenImportWorkout] = useState(false)
    const [openDeleteWorkout, setOpenDeleteWorkout] = useState(false)
    const mobile = useMediaQuery(MOBILE)
    const [canSave, setCanSave] = useState<boolean>(false)
    const canSaveRef = useRef<boolean>()
    canSaveRef.current = canSave
    const [openWorkoutResult, setOpenWorkoutResult] = useState<boolean>(false)


    useEffect(()=>{
        dispatch(resetWorkoutCopied())
    },[])

    useEffect(()=>{
        
        if(params.clientid){
            setClientId(params.clientid)
        } else {
            setClientId(coachId)
        }
    },[params.clientid])



     /*********************
      * INITIALISATION 
      * **************** */

     useEffect(() => {
        if(isTemplate){
            dispatch(selectDrawerItem(3)) // Séance de planning
        }

        return function cleanup() {
            dispatch(resetWorkoutBuilder())
        }
    }, [dispatch])


    /****** FERMETURE, LE WORKOUT N'EST PLUS READY ******** */
    useEffect(()=>{
        return function cleanup() {
            setFormIsReady(false)
        };
    },[dispatch])


    /************************ 
     * CHANGEMENT DU NOM 
     * **********************/

    const debounceOnChangeName = useRef(debounce((data:{newName:string,weekWorkouts:Workout[], workoutId:string})=> {
        const {newName} = data
        dispatch(handleName({ name : newName}))
        /*if(!templatePage){
            //const weekWorkoutIndex = weekWorkouts.findIndex((workout)=> workout._id === workoutId)
            //const workouts = JSON.parse(JSON.stringify([...weekWorkouts]))
            //workouts[weekWorkoutIndex].name = newName
            /setWeekWorkouts(workouts)
        }*/
    },INPUT_DEBOUNCE_TIMER)).current


    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>)=> {
        const newName:string = event.target.value
        setLocalName(newName)
        debounceOnChangeName({newName: newName, weekWorkouts, workoutId})
    }


    /******************************** 
     * CHANGEMENT DE LA DESCRIPTION 
     * *******************************/

    const debounceOnChangeDescription = useRef(debounce((data)=> {
        dispatch(onChangeDescription({description: data.description}))
    },500)).current


    const onChangeLocalDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDescription:string = event.target.value
        setLocalDescription(newDescription)
        debounceOnChangeDescription({description: newDescription})
    }



    /*************************************************************************************
     * RECUPERATION DES DONNEES DE LA SEANCE SELECTIONNE (PAR WORKOUTID) DANS LE PLANNING
     * ***********************************************************************************/

    useEffect(()=>{
        if(location.state.workoutid && !formIsReady){

        //if(location.state.workoutid && (workoutForm._id !== location.state.workoutid)){
            dispatch(getWorkout({id:location.state.workoutid})).unwrap().then((res)=>{
                if(res.workout){
                    console.log('workout recup', res.workout)
                    const workoutFetched = res.workout
                    setLocalName(workoutFetched.name)
                    setLocalDescription(workoutFetched.description)
                    if(workoutFetched.createdFor){
                        const user:User = res.workout.createdFor as User
                        setClient(user)
                    }

                    //setDaySelected(location.state?.date)

                    setTimeout(()=>{
                        setCanSave(true)
                    },1500)
                    
                    setFormIsReady(true)
                }
                // Aucune séance, template
                else if(!clientId){
                    history.push(`/dashboard`)
                    toast.error('Impossible de récupérer cette séance')
                }
            })
        } 
    },[location.state.workoutid, formIsReady])



     /******************************************
     * RECUPERATION DES WORKOUTS DE LA SEMAINE
     * *****************************************/

  /*  useEffect(()=>{
        if((clientId || params.programid) && !templatePage){
            setWeekPrograms([])
            var queryParams:{start:string, end: string, createdFor?: string, sleep?:boolean, programTemplate?:string} = {
                start:weekStart.toDateString(), 
                end: program ? addDays(weekStart, 7).toDateString() : endOfWeek(weekStart,{locale: fr}).toDateString(),
            }

            if(params.programid){
                queryParams.programTemplate = params.programid
            } else {
                queryParams.createdFor = clientId
            }

            dispatch(getWorkouts(queryParams)).unwrap().then((res)=>{
                // selection du jour de la semaine précédente
                if(isBefore(weekStart, daySelected) && formIsReady){
                    const newDay = addWeeks(daySelected,-1)
                    selectDay(newDay,res.workouts.filter((elem)=> isSameDay(new Date(elem.schedule), newDay)))
                } else if(formIsReady) {
                    const newDay = addWeeks(daySelected,1)
                    selectDay(newDay,res.workouts.filter((elem)=> isSameDay(new Date(elem.schedule), newDay)))
                }

                setWeekWorkouts(res.workouts)
                
                if(params.programid){
                    setFormIsReady(true)
                    setTimeout(()=>{
                        setCanSave(true)
                    },1500)
                }

                // Récupération du programme, si ce n'est pas un template de programme

                if(!params.programid){
                    dispatch(getPrograms({
                        start:weekStart, 
                        end: endOfWeek(weekStart,{locale: fr}),
                        createdFor:clientId,
                    })).unwrap().then((resProgram)=>{
                        setWeekPrograms(resProgram.programs)
                        setFormIsReady(true)
                        setTimeout(()=>{
                            setCanSave(true)
                        },1500)
                        
                    })
                }
                
                
            })
        }
    },[weekStart, clientId])
    */


     /*********************** 
      * AJOUT D'UN BLOC 
      * *********************/

     const onAddBlockToWorkout = () => {
        setAnchorEl(null)
        dispatch(addBlockToWorkout())
        //toast.success('Bloc ajouté')
    }

    /***************************** 
     * REORGANISATION DES BLOCKS 
     * ****************************/

    const reorderBlocks = (result:any) => {
        dispatch(reorderWorkout({ result }))
    }


    /*******************************************
     * MISE EN FORME DES DONNES DU FORMULAIRE 
     * *****************************************/

    const workoutForm = useMemo(()=>{
        const form:Workout={
            _id: workoutId,
            schedule: schedule,
            template:isTemplate,
            name,
            blocks,
            cover,
            status,
            description: localDescription,
            level,
            createdFor: client?._id || undefined,
            performed,
            createdBy
        }
        return form
    },[blocks, name, schedule, status, cover, localDescription, level, performed, client])



    /************ 
     * AUTOSAVE 
     * ******** */

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((workout)=> {
        dispatch(updateWorkout({workout}))

    }, AUTOSAVE_TIMER)).current


    // Appel du debounce à chaque changement
    useEffect(() => {
        //if(canSaveRef.current && !pendingDayWorkout && Boolean(workoutForm._id) && !performed){
        if(canSaveRef.current && Boolean(workoutForm._id)){
            dispatch(workoutIsSaving())
            debounceAutoSave(workoutForm)

        }
        // eslint-disable-next-line
    }, [blocks, name, status, localDescription, level, pendingDayWorkout])


    /**************************************************** 
     * VERIFICATION DU STATUS DE SAUVEGARDE DU WORKOUT 
     ****************************************************/

    useEffect(()=>{

        if(!pending && Boolean(openPlanningWaitinUpdate)){
            //selectDay(openPlanningWaitinUpdate.date, openPlanningWaitinUpdate.workouts)
            setOpenPlanningWaitinUpdate(null)
        }

        // Si le workout a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        else if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)

            // Back to dashboard
            if(location.state?.backToDashboard ){
                history.push(`/dashboard`)
            }
            // Back to program template
            else if(Boolean(params.programid)){
                history.push(`/templates/program/${params.programid}`)
            }
            // Back to modèles de séance
            else if(isTemplate  && !params.programid){
                history.push({
                    pathname:`/templates`,
                    state: {
                        tabs: 0, 
                    }
                })
            }
             // Retour au planning client
            else if(coachId !== client?._id && !params.programid){
                history.push(
                    {
                        pathname: `/clients/${client._id}`,
                        state: { 
                            tabs: 2, 
                            planningRange:{
                                start: location.state?.planningRange?.start,
                                end: location.state?.planningRange?.end,
                            },
                            planningScroll: location.state?.planningScroll,
                            date: location.state?.date
                        },
                    }
                )
            } 
            // Retour au planning coach
            else {
                history.push(
                    {
                        pathname: `/planning`,
                        state: { 
                            planningRange:{
                                start: location.state?.planningRange?.start,
                                end: location.state?.planningRange?.end,
                            },
                            planningScroll: location.state?.planningScroll,
                            date: location.state?.date
                        },
                    }
                )
            }   

            
        }
    },[openWaitinUpdate, pending, openPlanningWaitinUpdate])


    /******************************** 
     * RETOUR A LA PAGE PRECEDENTE 
     * *******************************/

    const backToPreviousScreen = () =>{
        if(pending){
            setOpenWaitinUpdate(true)
            return
        }

        if(location.state?.backToDashboard){
            history.push(`/dashboard`)
        }

        // Retour au programme template
        else if(params.programid){
            history.push(`/templates/program/${params.programid}`)
        }

        // Retour au template de séance
        else if(isTemplate  && !params.programid){
            history.push({
                pathname:`/templates`,
                state: {
                    tabs: 0, 
                }
            })
        }

        // Retour au planning client
        else if(coachId !== client?._id && !params.programid){
            //const planningStart =startOfWeek(startOfMonth(weekStart), {locale: fr})
            //const planningEnd = endOfWeek(endOfMonth(weekStart), {locale: fr})

            history.push(
                {
                    pathname: `/clients/${client._id}`,
                    state: { 
                        tabs: 2, 
                        planningRange:{
                            start: location.state?.planningRange?.start,
                            end: location.state?.planningRange?.end,
                        },
                        /*planningRange:{
                            start: planningStart,
                            end: planningEnd,
                        },*/
                        //planningScroll: isSameDay(location.state?.planningRange?.start,planningStart) ?location.state?.planningScroll : 0,
                        planningScroll: location.state?.planningScroll

                    },
                }
            )
        } 
        // Retour au planning coach
        else {
            history.push(
                {
                    pathname: `/planning`,
                    state: { 
                        planningRange:{
                            start: location.state?.planningRange?.start,
                            end: location.state?.planningRange?.end,
                        },
                        planningScroll: location.state?.planningScroll
                    },
                }
            )
        }   
    }


    const onOpenSaveAsTemplate = () => {
        setAnchorEl(null)
        setOpenSaveAsTemplate(true)
    }


    const handleCloseLevelMenu = (level: "advanced" | "medium" | "beginner") => ()=> {
        setAnchorElMenuLevel(null)
        dispatch(handleLevel({level}))
    }


    


   



    

    /************************ 
     * IMPORT D'UNE SEANCE 
     * **********************/

    const onOpenImportWorkout = () => {
        setOpenImportWorkout(true)
    }


    const onImportWorkoutFromBuilder = (newWorkout:Workout) => {
        var currentWorkouts = [...weekWorkouts]
        currentWorkouts.push(newWorkout)
        dispatch(defineNewWorkout({workout:newWorkout}))
        setWeekWorkouts(currentWorkouts)
        setLocalName(newWorkout.name)
        setLocalDescription(newWorkout.description)
    }




    const templatePage = useMemo(()=>{
        return window.location.pathname === "/templates/workoutbuilder"
    },[])


    


   
    /*************************** */
    /***       JSX        *** */
    /*************************** */


    if (formIsReady) {
        return (
            <React.Fragment>


                {/***********************
                 * NAVBAR
                 **********************/}
               
                <Navbar
                    pageTitle={!isTemplate ? "Séance d'entrainement" : "Modèle de séance"}
                    backButton={backToPreviousScreen}
                    showAutoSave={true}
                    fullWidth={true}
                    xlNotFull={true}
                    
                />

                {/**************
                 * NAVBAR MENU
                 **************/}

                {!templatePage && (
                <Menu 
                    disableScrollLock={true} 
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={()=>setAnchorEl(null)}
                >
                    <MenuItem 
                        onClick={onOpenSaveAsTemplate}>
                        <ListItemIcon>
                            <Save fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Enregistrer comme modèle
                        </ListItemText>
                    </MenuItem>
                </Menu>
                )}

                {/**************
                 * MAIN CONTENT
                 **************/}


                <MainContent 
                    padding={mobile? false : true}
                >


                    {/*************************
                     * CONTENU DE LA SEANCE
                     *************************/}
                    {workoutForm._id && (
                        <Grid 
                            container 
                            justifyContent="center" 
                            sx={{paddingBottom: 10}}
                        >
                            <Grid 
                                item
                                xs={12} 
                                sm={12} 
                                md={12} 
                                lg={11} 
                                xl={8}
                                sx={{position:"relative"}}
                            >

                                <Box
                                    sx={{justifyContent:"space-between", display:"flex", flexDirection:"row"}}
                                >

                                {/************* 
                                 * TAGS
                                 * ************/}
                                
                                    <Box>

                                        {(!templatePage && !params.programid && !mobile && (coachId !== client?._id)) && (
                                            <Box sx={[classes.tagInfo]}>
                                                <AssignmentIndIcon 
                                                    sx={classes.tagInfoIcon}
                                                /> 
                                                <Typography sx={classes.tagInfoLabel}>
                                                    Client :
                                                </Typography>
                                                <Typography sx={[classes.tagInfoValue, {fontWeight: 600}]}>
                                                        {client?.firstname} {client?.lastname}
                                                </Typography>
                                            </Box>
                                        )}

                                        {(!templatePage && !params.programid && !mobile) && (
                                            <Box sx={[classes.tagInfo]}>
                                                <Today 
                                                    sx={classes.tagInfoIcon}
                                                /> 
                                                <Typography sx={classes.tagInfoLabel}>
                                                    Date :
                                                </Typography>
                                                <Typography sx={[classes.tagInfoValue, {fontWeight: 600}]}>
                                                        {format(new Date(schedule), "eee dd MMM",{locale:fr})}
                                                </Typography>
                                            </Box>
                                        )}

                                    </Box>

                                    <MoreButton
                                        menuList={[{
                                            icon: <Save />,
                                            label:"Sauvegarder comme modèle",
                                            callback: ()=> setOpenSaveAsTemplate(true)

                                        }]}
                                    />


                                </Box>

                                


                                <Box
                                    sx={classes.section}
                                >

                                    {/*******
                                     * COVER
                                    *********/}
                                    <Box>
                                        <WorkoutBuilderCover/>

                                    </Box>

                                    <Box
                                        sx={{display:"flex", flex: 1, flexDirection:"column"}}
                                    >
                              

                                    {/*********************************
                                     * TITLE  + DESCRIPTION DE SEANCE
                                    ***********************************/}

                                    <Box
                                        sx={classes.headerTitle}
                                    >
                                        <Box>
                                            <TitleTextField 
                                                fullWidth 
                                                value={localName} 
                                                onChange={onChangeName}
                                                variant="standard" 
                                                sx={{
                                                    marginBottom: 3, 
                                                    "& .MuiInput-root": {
                                                        fontSize: "1.3rem !important",
                                                        width: {xs: "100%", sm: 550}
                                                }}}

                                            />
                                            
                                        </Box>

                                    </Box>

                                    {/****************
                                     * LISTE DES TAGS
                                     ****************/}

                                    <Box 
                                        sx={classes.tags}
                                    >


                                        {/************ 
                                         * ACTIVATION 
                                         * ***********/}
                                    
                                    

                                        {((!params.programid && !isTemplate) && !performed) && (
                                            <Box
                                                sx={{marginRight: 1.5}}
                                            >
                                                <SwitchButton 
                                                    activated={status==="enabled"}
                                                    onClick={()=>setOpenActivateWorkout(true)}
                                                />
                                            </Box>
                                        )}

                                        {/************* 
                                         * MENU LEVEL 
                                         * ************/}

                                        {isTemplate && (
                                            <Box sx={[classes.tagInfo, classes.tagInfoClickable]} onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorElMenuLevel(event.currentTarget)}}>
                                                {LEVEL_ICON[level] || <SignalCellularAlt/>}
                                                <Typography sx={classes.tagInfoLabel}>
                                                    Niveau
                                                </Typography>
                                                <Typography sx={classes.tagInfoValue}>
                                                    {TRAD_LEVEL[level]}
                                                </Typography>
                                                <ExpandMore />
                                            </Box>
                                        )}

                                        <Menu disableScrollLock={true} 
                                            anchorEl={anchorElMenuLevel}
                                            open={Boolean(anchorElMenuLevel)}
                                            onClose={()=> setAnchorElMenuLevel(null)}
                                        >
                                            <MenuItem onClick={handleCloseLevelMenu("beginner")} selected={level === "beginner"}>
                                                Débutant
                                            </MenuItem>
                                            <MenuItem onClick={handleCloseLevelMenu("medium")} selected={level === "medium"}>
                                                Intermédiaire
                                            </MenuItem>
                                            <MenuItem onClick={handleCloseLevelMenu("advanced")} selected={level === "advanced"}>
                                                Avancé
                                            </MenuItem>
                                        </Menu>

                                        {/************* 
                                         * PERFORMED
                                         * ************/}

                                        {(performed) && (
                                            <Box 
                                                sx={[
                                                    classes.tagInfo,
                                                    classes.tagInfoClickable, 
                                                    {
                                                        backgroundColor: "#a2eb8d"
                                                    }
                                            ]}
                                                onClick={()=>setOpenWorkoutResult(true)}
                                            
                                            >
                                                <Check 
                                                    sx={[classes.tagInfoIcon, {color: "rgb(18 80 0)"}]}
                                                /> 
                                                <Typography sx={classes.tagInfoLabel}>
                                                Séance effectuée
                                                </Typography>
                                                
                                            </Box>
                                        )}


                                        

                                        {/********************** 
                                         * Durée Approximative
                                         * ********************/}

                                        {(!performed && !templatePage && !params.programid && !mobile && (coachId !== client?._id)) && (
                                            <Box sx={[classes.tagInfo]}>
                                                <AccessTime 
                                                    sx={classes.tagInfoIcon}
                                                /> 
                                                <Typography sx={classes.tagInfoLabel}>
                                                    Durée
                                                </Typography>
                                                <Typography sx={[classes.tagInfoValue, {fontWeight: 600}]}>
                                                ≈ {calcWorkoutDuration(workoutForm)}
                                                </Typography>
                                            </Box>
                                        )}
                                        
                                    </Box>
                                    
                                    {/************ 
                                     * DESCRIPTION
                                     * ********** */}

                                    <WorkoutDescription 
                                        value={localDescription} 
                                        onChange={onChangeLocalDescription}
                                    />
                                    </Box>

                                </Box>

                                


                                {/***************************** 
                                 * LISTE DES BLOCS DE SEANCE 
                                 * ************************* */}

                                <Box 
                                    className="container"
                                >
                                    <DragDropContext onDragEnd={reorderBlocks}>
                                        {blocks.map((block,index)=>{

                                            return(
                                                <WorkoutBlock 
                                                    blockId={block.blockId}
                                                    blockIndex={index}
                                                    key={block.blockId}
                                                    onAddBlockToWorkout={onAddBlockToWorkout}
                                                    showAddBlock={index === blocks.length - 1}
                                                />
                                            )
                                        })}
                                    </DragDropContext>
                                </Box>

                                <Box
                                    sx={{display:"flex", alignItems:"center", justifyContent:"center"}}
                                >
                                    <SecondaryButton
                                        onClick={onAddBlockToWorkout}
                                        startIcon={<Add/>}
                                        sx={{
                                            borderRadius: "24px !important"
                                        }}
                                    
                                >
                                    Ajouter un bloc
                                    </SecondaryButton>
                                </Box>
                        
                            
                            </Grid>
                        </Grid>
                    )}


                    {/*************************
                     * AUCUNE SEANCE
                     ************************
                    {(!workoutForm._id && !pendingWeek && !pendingDayWorkout && formIsReady) && (
                        <Box
                            sx={classes.emptyWod}
                        >
                            <Box
                            sx={{width: 300}}
                            >
                                <Lottie 
                                    animationData={yoga} 
                                    loop={true} 
                                
                                />
                            </Box>

                            <Typography
                                sx={classes.restDayTitle}
                            >
                                Jour de repos
                            </Typography>
                            <ContainedButton
                                variant="contained"
                                startIcon={<Add />}
                                sx={{width: 280, marginBottom: 2}}
                                onClick={onAddNewWorkout}
                            >
                                Ajouter une nouvelle séance    
                            </ContainedButton>
                            <ContainedButton
                                variant="contained"
                                startIcon={<Download />}
                                sx={{width: 280, marginBottom: 2}}
                                onClick={onOpenImportWorkout}
                            >
                                Importer une séance    
                            </ContainedButton>
                            {Boolean(workoutCopied) && (
                            <ContainedButton
                                variant="contained"
                                startIcon={<ContentCopy />}
                                sx={[{width: 280}, !Boolean(workoutCopied) && classes.btDisabled]}
                                onClick={onPasteWorkout}
                                disabled={!Boolean(workoutCopied)}
                            >
                                Coller une séance    
                            </ContainedButton>
                            )}

                        </Box>
                    )}
                        */}


                   

                </MainContent>



                <DialogLoader
                    open={openWaitinUpdate || Boolean(openPlanningWaitinUpdate)}
                    text={"Sauvegarde en cours ..."}
                />

                {/*<DialogLoader
                    open={pendingDayWorkout}
                    text={"Chargement de la séance ..."}
                />

                {/*<DialogLoader
                    open={pendingWeek && formIsReady}
                    text={"Chargement de la semaine ..."}
                />

                <DialogLoader
                    open={pendingNewWorkout}
                    text={"Création de la séance ..."}
                />
                */}


                {(!templatePage && schedule) && (
                    <DialogActivateWorkout
                        open={openActivateWorkout}
                        onClose={()=>setOpenActivateWorkout(false)}
                        workout={workoutForm}
                    />
                )}

                <DialogSaveWorkoutAsTemplate
                    open={openSaveAsTemplate}
                    onClose={()=> setOpenSaveAsTemplate(false)}
                    workout={workoutForm}
                />

                {/*Boolean(daySelected) && (
                    <DialogImportWorkout
                        open={openImportWorkout}
                        onClose={()=> setOpenImportWorkout(false)}
                        date={daySelected}
                        fromWorkout={true}
                        onImportWorkoutFromBuilder={onImportWorkoutFromBuilder}
                        createdFor={client?._id}
                    />
                )}

           
                <DialogDeleteWorkout
                    open={openDeleteWorkout}
                    onClose={()=> setOpenDeleteWorkout(false)}
                    workout={workoutForm}
                    fromWorkout={true}
                    onDeleteWorkoutFromBuilder={onDeleteWorkoutFromBuilder}
                    createdFor={client?._id}

                />
                

                {performed && (
                    <DialogWorkoutResults
                        open={openWorkoutResult}
                        onClose={()=>setOpenWorkoutResult(false)}
                        workout={workoutForm}
                    />
                )}
                    */}
             


                
                

            </React.Fragment>

        )
    } 
    
   
    
    else {
        return (
            <Loader />
        )
    }

}