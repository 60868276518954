
import { lighten } from "@mui/material"
import {BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {


    container: {
        flex: 10,
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        //marginBottom: 3,
        marginLeft: {
            xs: 0,
            sm: 5
        }
    },

    card:{
        //marginBottom: 3, 
        padding: {
            xs: 2,
            sm: 3,
        },
        backgroundColor: "white",
        "&:hover" : {
            backgroundColor: lighten(theme.palette.primary.main, 0.98),
            cursor: "pointer"
        },
        justifyContent: "space-between",
        flexDirection: "column",
        border: `solid 1px ${BORDER_COLOR}`,
        flexGrow: 1,
        display:"flex",
        borderRadius: 3
        
    },

    cardInfos: {
        flexDirection: "row"
    },

    cardHeader:{
        display:"flex", 
        flexDirection:"row",
    },


    name: {
        fontSize: "0.8rem", 
        color: "rgba(0,0,0,0.65)"
    },

    eventIndicatorContainer: {
        position: "absolute",
        left: -2,
        borderRadius: 100,
        padding: 0.5,
        backgroundColor: "#fbfbfb",
        display:{
            xs: "none",
            sm: "block"
        }
    },

    eventIndicator: {
        width: 28,
        height: 28,
        borderRadius: 100,
        backgroundColor: "primary.main",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },


    content: {
        //marginTop: 3,
        display:"flex", 
        flexDirection: "column", 
    },

    eventInfos:{
        display:"flex", 
        flexDirection:"row", 
        marginTop: 3,
        alignItems:"center"
    },

    measureValue:{
        fontWeight: 700, 
        display:"inline", 
        marginRight: 1,
        fontSize: 26
    },

    evolutionMeasure:{
        ml:1, 
        fontWeight: 700, 
        color: "#9f9f9f"
    },

    measureContainer:  {
        display:"inline-flex",
        flexDirection: "row",
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingLeft:1.5,
        paddingRight: 1.5,
        marginTop: 2,
        backgroundColor: "#edf5ff",
        borderRadius: 3,
        fontSize: 14,
        alignSelf: "flex-start"
        
      
    },

    measure: {
        
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(15),
        
    },

    userNote : {
        color: "rgba(0,0,0,0.85)",
        fontStyle: "italic",
        marginTop: 2
    },

    new: {
        backgroundColor: "#ee4431",
        display:"inline-flex",
        marginBottom: 2,
        fontWeight: 700,
        fontSize: 13,
        paddingLeft: 1,
        paddingRight: 1,
        color: "white",
        position: "absolute",
        right: 0,
        top: 0
    },

    rating : {
        marginTop: 2
    },

    satisfaction: {
        fontSize: theme.typography.pxToRem(15),
        fontStyle: "italic",
        marginRight: "5px"

    },

    satisfactionValue: {
        fontSize: theme.typography.pxToRem(15),
        fontStyle: "italic",
        fontWeight: 500,
        marginRight: "5px"

    },

    photosContainer:{
        display:"flex", 
        flexDirection:"row", 
        marginTop: 2,
        position: "relative",
        marginLeft: "-60px",
        overflowX: {
            xs: "scroll",
            sm: "hidden",
        },
        width: {
            xs: "100%",
            sm: "auto"
        }
        
    },

    photo: {
        width: {
            xs: 200,
            sm: 150,
        }, 
        objectFit: "cover", 
        maxWidth:{
            xs: 200,
            sm: 150,
        }
    },

    photoContainer:{
        width: {
            xs: 200,
            sm: 250,
        },
        maxWidth:{
            xs: 200,
            sm: 250,
        }, 
        backgroundColor: "#ececec",
        maxHeight: 250,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        marginTop: 2,
        overflow: "hidden",
        borderRadius: 3,
    },

    mealPhoto : {
        width: {
            xs: 200,
            sm: 250,
        },
        maxWidth:{
            xs: 200,
            sm: 250,
        }, 
        borderRadius: 3,

        objectFit: "cover",
        
        
    },

   
}


export default classes