import { InputAdornment, SxProps, TextField } from "@mui/material";
import classes from "./styles";
import { useEffect, useRef, useState } from "react";
import { INPUT_DEBOUNCE_TIMER } from "utils/constants";
import debounce from "lodash.debounce";


interface Props {
    changeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
    startAdornment: string,
    endAdornment?: string,
    value: any,
    width?: number
}


export default function BlockTypeTextfield({changeValue, startAdornment, endAdornment, value, width}:Props){


    const [localValue, setLocalValue] = useState<number | string>("")

    /********************* 
     * VALEUR PAR DEFAULT 
     * ********************/

    useEffect(()=>{
        if(value !== localValue){
            setLocalValue(value)
        }
    },[value])




     /******************************** 
     * CHANGEMENT DE VALEUR 
     * ******************************/

     const debounceOnChange = useRef(debounce((data)=> {
        changeValue(data)
    },INPUT_DEBOUNCE_TIMER)).current


    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue:string = event.target.value
        setLocalValue(newValue)
        debounceOnChange(newValue)
    }



    return(
        <TextField
            size="small"
            onChange={onChangeValue}
            value={localValue}
            type={"number"}
            sx={[
                classes.prescriptionTextField, 
                {width: width ? width : 150, maxWidth : width ? width : 150}
            ]}
            InputProps={{
                startAdornment: <InputAdornment position="start" sx={{color:"rgba(0,0,0,0.85)", fontWeight: 600,}} disableTypography>
                    {startAdornment} 
                </InputAdornment>,
                endAdornment: 
                    Boolean(endAdornment) ? 
                        <InputAdornment position="end" sx={{color:"primary.main", fontWeight: 600}} disableTypography>
                            {endAdornment}
                        </InputAdornment>
                    : null
                
        
            }}
            
        
        />
            
    )
}