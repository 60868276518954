import { Button, Dialog, DialogActions, DialogContent, useMediaQuery } from "@mui/material";
import Meal from "interfaces/Meal";
import DialogAppBar from "components/molecules/DialogAppBar";
import MicroNutriments from "../MicrosNutriments";
import DialogTransition from "components/molecules/DialogTransition";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { MOBILE } from "utils/constants";


interface Props{
    open: boolean,
    onClose: ()=>void,
    meals: Meal[],
    template: boolean
}




export default function DialogMicrosNutriments({open, onClose, meals, template}:Props){

    const mobile = useMediaQuery(MOBILE)


    return(
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={onClose}
            TransitionComponent={DialogTransition}
            fullWidth
            maxWidth={"md"}
        >
            <DialogAppBar 
                onClose={onClose}
                title={"Répartition des nutriments"}
            />
    
            <DialogContent>
                <MicroNutriments 
                    meals={meals}
                    template={template}
                />
            </DialogContent>
            {!mobile && (
                <DialogActions>
                    <Button
                        onClick={onClose}
                    >
                        Fermer
                    </Button>
                </DialogActions>
            )}
    </Dialog>

    )
}
