
const classes = {
    comparatorIconContainer: {
        borderRadius: 100, 
        display:"inline-flex", 
        alignItems:"center", 
        justifyContent:"center",
        width: 18,
        height: 18,
        marginRight: 1
    },



    comparatorIcon:{
        color: "white",
        fontSize: 18
    },
}

export default classes