import { Box, InputAdornment, TextField, useMediaQuery } from "@mui/material"
import { AppDispatch } from "app/store"
import { changeRestValue } from "features/workoutSlice"
import debounce from "lodash.debounce"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { INPUT_DEBOUNCE_TIMER, MOBILE } from "utils/constants"
import classes from "./styles"
import TimeMaskInput from "components/molecules/TimeInput"

/******** INTERFACES ******* */


interface Props{
    restTextField?: boolean,
    defaultValue: any,
    blockId: string,
    width?: string,
    startAdornment?: string,
    changeValue?: (event: React.ChangeEvent<HTMLInputElement>)=> void,
    onBlurValue?: string, 
    blockSeconds?: boolean,
    disabled?: boolean
}





/******** MAIN COMPONENT ******* */

export function TimeTextfield({defaultValue, blockId, width, startAdornment, changeValue, onBlurValue, blockSeconds, disabled= false}:Props){

    const [value, setValue] = useState<number | string>("")
    const mobile = useMediaQuery(MOBILE)

    /********************* 
     * VALEUR PAR DEFAULT 
     * ********************/

    useEffect(()=>{
        if(defaultValue !== value){
            setValue(defaultValue)
        }
    },[defaultValue])


    /******************************** 
     * CHANGEMENT DU TEMPS DE REPOS 
     * ******************************/

    const debounceOnChange = useRef(debounce((data)=> {
        changeValue(data)
    },INPUT_DEBOUNCE_TIMER)).current


    const onChangeTimeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue:string = event.target.value
        setValue(newValue)
        debounceOnChange(newValue)
    }


    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value
        const regex = /^[0-5]?\d:[0-5]\d$/
        if(!regex.test(value)){
            setValue(onBlurValue)
            debounceOnChange({value: onBlurValue, blockId})
        }
        if(blockSeconds && regex.test(value) && (value[3] !== "0" || value[4] !== "0")){
            const newValue = value[0]+value[1] + ":" + "00"
            setValue(newValue)
            debounceOnChange({value: newValue, blockId})
        }
    }


    /********* JSX **********/

    return(
        <Box sx={[
            classes.prescriptionValue, 
            width && {maxWidth:width}]}>
 
            <TextField
                type={"text"}
                variant="outlined"
                fullWidth={true}
                size="small"
                value={value}
                sx={[classes.prescriptionTextField]}
                onChange={onChangeTimeValue}
                onBlur={onBlur}
                disabled={disabled}
                InputProps={{
                    startAdornment:
                    <InputAdornment position="start" sx={{color:"rgba(0,0,0,0.85)", fontWeight: 600}} disableTypography>
                        {startAdornment}
                    </InputAdornment>,
                    endAdornment: 
                    <InputAdornment position="end" sx={{color:"primary.main", fontWeight: 600}} disableTypography>
                        {mobile ? "" : "min"}
                    </InputAdornment>,
                    inputComponent: TimeMaskInput as any,
                }}
            />
            
        </Box>
    )
    
}


function areEqual(prev:Props,next:Props){
    return prev.blockId === next.blockId && 
    prev.blockId === next.blockId &&
    prev.defaultValue === next.defaultValue &&
    prev.onBlurValue === next.onBlurValue
}

export default React.memo(TimeTextfield, areEqual)