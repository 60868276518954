// MUI
import {  Dialog, DialogTitle, DialogActions, Button, DialogContent } from "@mui/material";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// INterfaces
import Program from "interfaces/Program";
import { AppDispatch, RootState } from "app/store";
// Shared components
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import {deleteProgram } from "api/programs";
import { useMemo } from "react";
import { toast } from "react-toastify";


//***************** INTERFACES ********* */

interface Props {
    open: boolean,
    onClose: () => void,
    startDate?: Date,
    program?: Program,
    deleteContent?: boolean
}


//***************** COMPONENT ********* */

export default function DialogDeleteProgram({ open, onClose, program, deleteContent}: Props) {

    const dispatch = useDispatch<AppDispatch>()
    const pendingDeleteCustom:boolean = useSelector((state:RootState)=> state.planning.requests.deleteProgram === "pending")
    const pendingDeleteTemplate: boolean = useSelector((state:RootState) => state.programs.requests.delete === "pending")


    /********** MISE A JOUR D'UN PROGRAMME ************* */

    const onDelete = () => {
        dispatch(deleteProgram({program, deleteContent})).unwrap().then((res:any) => {
            onClose()

        })
    }


    const PENDING = useMemo(()=>{
        if(program){
            if(!program.template){
                return pendingDeleteCustom
            } else {
                return pendingDeleteTemplate
            }
        } else {
            return null
        }
    },[program, pendingDeleteCustom, pendingDeleteTemplate])


    /********** JSX ************* */

    return (

        <Dialog open={open} fullWidth maxWidth={'xs'}>

            <DialogTitle>
                Supprimer un {program?.template? "modèle de programme" : "programme"}
            </DialogTitle>
            <DialogContent>
                Confirmer la suppression du {program?.template? "modèle de programme" : "programme"} ? {program?.template ? "" :"Les séances compris dans la plage de date ne seront pas supprimés ."}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={PENDING}>
                    Annuler
                </Button>
                <LoadingButton onClick={onDelete} disabled={PENDING} loading={PENDING}>
                    {PENDING ? "Suppression ..." : "Supprimer"}
                </LoadingButton>
                
            </DialogActions>
        </Dialog>
    )
}