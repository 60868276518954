import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from "@mui/material"
import { DEFAULT_PORTIONS, MOBILE } from "utils/constants"
import classes from "./styles"
import Meal from "interfaces/Meal"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import calcMacros from "function/calcMacros"
import { LocalFireDepartment } from "@mui/icons-material"
import DialogAppBar from "components/molecules/DialogAppBar"
import MacrosListMobile from "../MacrosListMobile"
import DialogTransition from "components/molecules/DialogTransition"
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { theme } from "utils/theme"

interface Props{
    open: boolean,
    meal:Meal,
    onClose: ()=> void
}

export default function DialogMealConsumed({meal, open, onClose}:Props){

    const mobile = useMediaQuery(MOBILE)


    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"sm"} 
            fullScreen={mobile? true:false}
            disableScrollLock={!mobile}
            TransitionComponent={DialogTransition}
        >

            <DialogAppBar
                title={meal.name}
                onClose={onClose}
            />

          
            <DialogContent>

                <Typography
                    sx={{marginBottom: 3}}
                >
                    Le {format(new Date(meal.date), "eee d MMM yyyy", {locale:fr})} à {meal.time}
                </Typography>

                <Box
                    sx={{display:"flex", flexDirection: "row"}}
                >
                    <Box sx={classes.photoContainer}>
                        {meal.photo?.cloudFlareId && (
                        
                            <Box
                                component={"img"}
                                src={meal.photo.url}
                                sx={classes.mealPhoto}
                            
                            />
                        )}

                        {!meal.photo?.cloudFlareId && (
                           
                                <NoPhotographyIcon 
                                    sx={{color: theme.palette.primary.main}}
                                />
                           
                        )}
                    </Box>

               
                    <Box
                        sx={{display:"flex", flexDirection:"column", padding: 3}}
                    >
                        <Box
                            sx={classes.kcalContainer}
                        >
                            <LocalFireDepartment 
                                sx={{ marginRight: 1, color: "#e75023", fontSize: 18}}
                            />
                            <Typography
                                sx={classes.kcal}
                            >
                                {calcMacros([{...meal}])?.kcal} kcal
                            </Typography>
                        </Box>
                    
                
                
                        <MacrosListMobile
                            meals={[{...meal}]}
                        />
                    </Box>
                
                
                </Box>
                {meal.content.map((elem,index)=> {
                    if(elem.type === "food"){

                        const foodPortions = [...DEFAULT_PORTIONS].concat(elem.food.portions)

                        return(
                            <Box
                                key={index}
                                sx={classes.mealContent}
                            >
                                <Box sx={classes.foodInfo}>
                                    <Typography
                                        style={classes.mealContentTitle}
                                    >
                                        {elem.food.name.fr}
                                    </Typography>
                                    <Typography>
                                        {elem.portion} {foodPortions.find((portion) => portion.label.fr === elem.label).label.fr}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    } else if(elem.type === "scanned"){
 

                        return(
                            <Box
                                key={index}
                                sx={classes.mealContent}
                            >

                             
                                <Box sx={classes.foodInfo}>
                                    <Typography
                                        style={classes.mealContentTitle}
                                    >
                                        {elem.foodScanned?.name?.fr ? elem.foodScanned?.name?.fr : "Produit scanné (sans nom)"}
                                    </Typography>
                                    <Typography>
                                        {elem.portion} {elem.label}
                                    </Typography>
                                    <Typography
                                        style={classes.brands}
                                    >
                                        Marque : {elem.foodScanned?.brands ? elem.foodScanned?.brands : "Non renseigné"}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    } else if(elem.type === "recipe"){

                        return(
                            <Box
                                key={index}
                                sx={classes.mealContent}
                            >
                                <Box sx={classes.foodInfo}>
                                    <Typography
                                        style={classes.mealContentTitle}
                                    >
                                        {elem.recipe.name.fr}
                                    </Typography>
                                    <Typography>
                                        {elem.portion} portion{elem.portion > 1 ?  "s" :""}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    }
                })}

            </DialogContent>

            {!mobile && (
            <DialogActions>
                <Button onClick={onClose}>
                    Fermer
                </Button>
            </DialogActions>
            )}
        </Dialog>
    )
}