import React, {useRef, useEffect, useState, useMemo} from "react"
// MUI
import { Grid, Typography, Box, TextField, InputAdornment, useMediaQuery, styled } from "@mui/material"
// REDUX
import { useDispatch,useSelector, shallowEqual } from "react-redux"
// INTERFACES
import { AppDispatch, RootState } from "app/store"
// CONSTANTS
import { AUTOSAVE_TIMER, EXERCICE_GROUPS, EXERCICE_GROUPS_TRAD, MOBILE } from "utils/constants"
import Exercice, {ExerciceType } from "interfaces/Exercice"
// REDUCERS
import { 
    handleExerciceNameFr, 
    handleExerciceType, 
    handleExerciceGroup,
    isSavingExercice,
    exerciceHasUnsavedChanges,
} from "features/exerciceSlice"

// API
import { 
    autoSaveExercice, 
    getExercice, 
    manualSaveExercice, 
    uploadExerciceImage,
} from "api/exercices"

// SHARED COMPONENTS
import TitleTextField from "components/molecules/TitleTextField"
// Function
import ChipSelector from "components/molecules/ChipSelector"
// STYLE
import classes from "./styles"
import debounce from "lodash.debounce";
import Image from "interfaces/Image"
import { toast } from "react-toastify"
import { Prompt, useHistory, useParams } from "react-router-dom"
import { definePageTitle, selectDrawerItem } from "features/appSlice"
import Loader from "components/molecules/Loader"
import DialogLoader from "components/molecules/DialogLoader"
import { Close, Save, YouTube } from "@mui/icons-material"
import {Editor, EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import DialogDeleteExerciceImage from "components/molecules/DialogDeleteExerciceImage"
import DialogSaveExercice from "components/molecules/DialogSaveExercice"
import Navbar from "components/organisms/NavBar"
import ContainedButton from "components/atoms/Buttons/ContainedButton"
import ImageVideoSelector from "components/organisms/ImageVideoSelector"
import { theme } from "utils/theme"
import checkVideoLink from "function/checkVideoLink"
import MainContent from "components/molecules/MainContent"


const weightLifting = "63e2583d4634b611780b1f9a"
const cardio = "63e25db34634b611780b1fab"

const GROUPS_SORTED = (values:string[]):{value:string, trad:string}[] => {
    const list:{value:string, trad:string}[] = []
    values.forEach((group)=>{
        list.push({trad: EXERCICE_GROUPS_TRAD[group].fr, value: group})
    })
    return list.sort((a,b) => {
        if( a.trad < b.trad ){
            return -1
        }
        if ( a.trad > b.trad ){
            return 1;
        }
      return 0
    })
}



const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
           
        }
    },
}))


/******************* EXERCICE BUILDER ******************* */

export function ExerciceBuilder(){

    const history = useHistory()
    const pending = useSelector((state:RootState)=> state.exercices.requests.autoSave === "pending")
    const pendingManualSave:boolean = useSelector((state: RootState) => state.exercices.requests.manualSave === "pending")
    const hasUnsavedChanges = useSelector((state:RootState) => state.exercices.hasUnsavedChanges)
    const dispatch = useDispatch<AppDispatch>()
    const params:any = useParams()
    const exerciceId = params.exerciceid
    const name:string = useSelector((state:RootState) => state.exercices.builder?.name.fr, shallowEqual)
    const groups:string[] = useSelector((state:RootState) => state.exercices.builder?.groups)
    const type:ExerciceType = useSelector((state:RootState) => state.exercices.builder?.type)
    const isDraft: boolean = useSelector((state:RootState) => state.exercices.builder?.draft, shallowEqual)
    const typesList:ExerciceType[] = useSelector((state: RootState) => state.exercices.types) // liste des types d'exercices
    const uploadPending:boolean = useSelector((state:RootState) => state.exercices.requests.uploadImage === "pending")
    const [openDeleteImage, setOpenDeleteImage] = useState<boolean>(false)
    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)

    // Local
    const [formIsReady, setFormIsReady] = useState<boolean>(false)
    const [openSaveExercice, setOpenSaveExercice] = useState<boolean>(false)
    const [videoUrl, setVideoUrl] = useState<string>('')
    const [imageSelected, setImageSelected] = useState<{url:string, cloudFlareId?:string}>({url:null, cloudFlareId: null})
    const [descriptionFr, setDescriptionFr] = useState({ editorState: EditorState.createEmpty() })
    const [descriptionEn] = useState({ editorState: EditorState.createEmpty() })
    const mobile = useMediaQuery(MOBILE)

    /************ RECUPERATION DES DONNEES DE L'EXERCICE *********** */

    useEffect(() => {
        // Si on modifie un modèle de bilan/brouillon
        if (Boolean(exerciceId) && !formIsReady) {
            dispatch(selectDrawerItem(2))
            dispatch(definePageTitle({pageTitle: isDraft? "Création d'un exercice (brouillon)" : "Modifier un exercice personnalisé"}))
            dispatch(getExercice(exerciceId)).unwrap().then((res) => {
                if(res.exercice){
                    setTimeout(()=>{
                        setFormIsReady(true)
                    },500)
                    
                    if(res.exercice.image){
                        setImageSelected(res.exercice.image)
                    }
                    if(res.exercice.video){
                        setVideoUrl(res.exercice.video.url)
                    }
                    setDescriptionFr({editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(res.exercice.description.fr)))})
                }else{
                    toast.error("Impossible de récupérer les données de l'exercice")

                }
            })
        } 
    }, [dispatch, exerciceId, formIsReady])



    /************* DONNEES CONDENSEES POUR L'AUTOSAVE **************** */

    const formData:Exercice = useMemo(()=>{
        return {
            _id: exerciceId,
            name:{fr:name}, 
            groups: groups, 
            description: {
                fr: JSON.stringify(convertToRaw(descriptionFr.editorState.getCurrentContent())),
                en: JSON.stringify(convertToRaw(descriptionEn.editorState.getCurrentContent()))
            },
            //equipments,
            type: type, 
            video:{
                platform: "youtube",
                url: videoUrl
            },
            draft: true,
            image: imageSelected,
            appExercice: false
        }
    },[isDraft, name, groups, videoUrl, type, exerciceId, imageSelected, descriptionFr, descriptionEn])


    /********** ***********************
     * CHANGEMENT DU NOM D'EXERCICE 
     * ******************************** */

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(handleExerciceNameFr({ name: event.currentTarget.value }))
    }

    /********** *********************
     * CHANGEMENT DU TYPE D'EXERCICE 
     * ******** *********************/

    const onHandleExerciceType = (newType:ExerciceType) => ()=> {
        if(!isDraft && (type._id === cardio || type._id === weightLifting)){
            toast.error("Impossible de modifier ce type d'exercice une fois publié")
            return 
        }

        else if(!isDraft && (newType._id === weightLifting || type._id === cardio)){
            toast.error("Impossible de modifier ce type d'exercice une fois publié")
            return 
        }

        else{

            dispatch(handleExerciceType({type: newType}))
        }
    }

    /************************************************ 
     * CHANGEMENT DES GROUPES MUSCULAIRE D'EXERCICE 
     * ***********************************************/

    const onHandleExerciceGroup = (group:string) => ()=> {
        dispatch(handleExerciceGroup({group}))
    }







    

    
    const onChangeLink = (e:React.ChangeEvent<HTMLInputElement>) => {
        setVideoUrl(e.target.value)
    }

     useEffect(()=>{
        checkVideoLink(videoUrl)
    },[videoUrl])

    

    const onSelectThumbnail = (thumbnail:{url:string, cloudFlareId?:string}) =>{
        setImageSelected(thumbnail)
    }


    const onChangeDescription = (e:EditorState) => {
        setDescriptionFr({editorState: e})
    }



    /*********************************************************** 
     * SELECTION D'UN FICHIER LOCAL - Téléchargement de l'image 
     * **********************************************************/


    const uploadImage = (newFile:Blob) => {
        dispatch(uploadExerciceImage({
            exerciceId:exerciceId, 
            file: newFile
        })).unwrap().then((res)=>{
            if(res.image){
                setImageSelected(res.image)
                if(!isDraft){
                    dispatch(manualSaveExercice({
                        exercice:{
                            ...formData, 
                            image: res.image
                        }
                    }))
                }
            }
            
        })
    }


    /*useEffect(()=>{
        if(!image?.url && videoIsCorrect){
            setImageSelected({url:thumbnailsList[0].url})
        }
    },[image])*/

     /*********** AUTOSAVE  ************** */

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((formData:Exercice)=> {
        dispatch(autoSaveExercice({exercice: formData}))
    }, AUTOSAVE_TIMER)).current


     useEffect(() => {
        if (formIsReady && isDraft) {
            dispatch(isSavingExercice())
            debounceAutoSave(formData)
        }else if(formIsReady && !isDraft){
            dispatch(exerciceHasUnsavedChanges())
        }
    }, [formData]) 



    const onSave = () =>{
        debounceAutoSave.cancel()
        dispatch(manualSaveExercice({exercice:formData}))
    }


    useEffect(() => {
        // the handler for actually showing the prompt
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
        const handler = (event: BeforeUnloadEvent) => {
          event.preventDefault();
          event.returnValue = "";
        };
    
        // if the form is NOT unchanged, then set the onbeforeunload
        if (hasUnsavedChanges) {
          window.addEventListener("beforeunload", handler);
          // clean it up, if the dirty state changes
          return () => {
            window.removeEventListener("beforeunload", handler);
          };
        }
        // since this is not dirty, don't do anything
        return () => {};
      }, [hasUnsavedChanges]);


    /****************************** 
     * RETOUR A l'ECRAN PRECEDENT 
     * ***************************/

    const backToPreviousScreen = () =>{
        if(pending){
            setOpenWaitinUpdate(true)
            return
        }
        else{
            history.push(`/exercices`)
        }
        
    }


    /*************************************************** 
      * VERIFICATION DU STATUS DE SAUVEGARDE DU WORKOUT 
      * **************************************************/

    useEffect(()=>{
        // Si l'exercice a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)

            
            history.push(
                {
                    pathname: `/exercices`,
                   
                }
            )
           
            
        }
    },[openWaitinUpdate, pending])


    /********* MAIN COMPONENT ********* */

    if (formIsReady) {

        return(
            
            <React.Fragment >

                <Navbar
                    pageTitle={"Création d'exercice"}
                    /*backButton={
                        ()=>history.push({pathname: '/exercices', state: {
                        tabs: 1, 
                        },})
                    }*/
                    backButton={backToPreviousScreen}
                    showAutoSave={isDraft}
                    showDraft={isDraft}
                />

                {/****** MAIN CONTENT ***** */}

                <MainContent>   
                    <Grid 
                        container 
                        justifyContent="center"
                    >
                        <Grid
                        item
                        xs={12} 
                        sm={12} 
                        md={12} 
                        lg={10} 
                        xl={8}
                    >

                        {/*****************************
                         * EXERCICE NAME + BUTTON SAVE
                         ******************************/}
                        <Box
                            sx={{display:"flex", flexDirection: "row", justifyContent:"space-between", alignItems:"flex-start",  marginBottom: 4, }}
                        >
                            <Box>
                                <TitleTextField 
                                    fullWidth 
                                    value={name} 
                                    onChange={onChangeName}
                                    variant="standard" 
                                    sx={{
                                    
                                        "& .MuiInput-root": {
                                            fontSize: "1.6rem !important",
                                            width: {xs: "auto", sm: 500}
                                    }}}

                                />

                            
                                
                            </Box>

                            {/*************************
                             * BOUTON SAVE
                             **************************/}

                            {!mobile && (

                                <Box 
                                    sx={{display:"flex", justifyContent:"flex-end"
                                    }}
                                >
                                    {isDraft && (
                                        <ContainedButton 
                                            onClick={()=> setOpenSaveExercice(true)}
                                            startIcon={<Save />}
                                            variant="contained"
                                            disableElevation={true}
                                        >
                                            Publier l'exercice
                                        </ContainedButton> 
                                    )}


                                    {!isDraft && (
                                        <ContainedButton 
                                            variant="contained"
                                            sx={{marginLeft: 3}} 
                                            onClick={onSave}
                                            startIcon={<Save/>}
                                        >
                                            Sauvegarder
                                        </ContainedButton>
                                    )}

                                </Box>

                            )}
                        </Box>
                      
                        {/****************** 
                         * TYPE D'EXERCICE 
                         * ****************/}

                        <Box 
                            sx={[{...classes.section},{marginTop: 3, width: "100%"}]}
                        >
                          
                                
                                    <Typography 
                                        variant="body1" 
                                        sx={classes.sectionLabel}
                                    >
                                        Type d'exercice
                                    </Typography>

                                    <Box 
                                        sx={classes.chips}
                                    >

                                        <Grid 
                                            container 
                                            spacing={2}
                                        >

                                        {typesList.map((elem:ExerciceType, index:number)=> {
                                            const selected = type._id === elem._id
                                            //const canChange = isDraft || (!isDraft && (type._id ===  isometric || type._id === flexibility || type._id === mobility))

                                            return (
                                                <ChipSelector 
                                                    label={elem.name.fr} 
                                                    key={index} 
                                                    selected={selected} 
                                                    onClick={onHandleExerciceType(elem)}
                                                    //disabled={!canChange}
                                                />
                                            )
                                        })}


                                        
                                        </Grid>
                                    </Box>
                         
                            {/*!isDraft && (
                                <Typography sx={{color :"#9f9f9f", fontSize: 14}}>
                                    Tu ne peux plus modifier le type d'un exercice après l'avoir publié
                                </Typography>
                            )*/}
                        </Box>
                        

                        {/********************** 
                         * GROUPES MUSCULAIRE 
                         * ********************/}

                        <Box sx={classes.section}>
                            <Typography variant="body1" sx={classes.sectionLabel}>
                                Groupes musculaires ciblés
                            </Typography>
                            <Box sx={classes.chips}>

                                <Grid 
                                    container 
                                    spacing={2}
                                >
                                {GROUPS_SORTED(EXERCICE_GROUPS).map((label, index:number)=> {

                                    const selected = groups.find((group:string)=> group === label.value)

                                    return (
                                        <ChipSelector 
                                            label={label.trad} 
                                            key={index} 
                                            selected={Boolean(selected)} 
                                            onClick={onHandleExerciceGroup(label.value)}

                                        />
                                    )
                                })}
                                </Grid>
                            </Box>
                        </Box>


                            {/****** DESCRIPTION ******* */}

                            <Box sx={classes.section}>
                                <Grid container spacing={6}>
                                    <Grid 
                                        item 
                                        xs={12} 
                                        sm={12}
                                        lg={6}
                                        xl={7}
                                        order={{xs: 2, md: 1}}
                                    >
                                        <Typography variant="body1" sx={classes.sectionLabel}>
                                            Description de l'exercice
                                        </Typography>
                                        <Box 
                                            sx={classes.draftJsEditor}
                                        >
                                            <Editor 
                                                editorState={descriptionFr.editorState} 
                                                onChange={onChangeDescription}
                                                placeholder="(Optionnel) Ajoute une description, des conseils d'éxécution etc ..."
                                                stripPastedStyles={true}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid 
                                        item 
                                        xs={12}
                                        sm={12}
                                        lg={6}
                                        xl={5}
                                        order={{xs: 1,  md: 2}}
                                        sx={{ paddingTop: {xs: "0px !important", sm: "48px !important"}}}
                                    >
                                        <DescriptionTextField 
                                            fullWidth
                                            value={videoUrl}
                                            label={"Lien Youtube"}
                                            onChange={onChangeLink}
                                            sx={{mt:{xs: 5, sm :3}, mb: {xs:3, sm: 3}}}
                                            variant="standard" 
                                            
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><YouTube sx={{color: "#ff0000"}} /></InputAdornment>,
                                                endAdornment: <InputAdornment position="end"> {checkVideoLink(videoUrl) ? "" : videoUrl !== "" ? <Close sx={{color: "red"}}/> : ""}</InputAdornment>
                                            }}
                                        />

                                        <ImageVideoSelector 
                                            formIsReady={formIsReady}
                                            imageSelected={imageSelected}
                                            onSelectThumbnail={onSelectThumbnail}
                                            uploadImage={uploadImage}
                                            openDeleteConfirmation={()=>setOpenDeleteImage(true)}
                                            videoUrl={videoUrl}
                                        />

                                        
                                       
                                    </Grid>

                                </Grid>
                            </Box>

                          
                        </Grid>
                    </Grid>
                    
                </MainContent>

                            
                    
                


                {/****************************** 
                 * LOADER ENREGISTREMENT AUTO 
                 * ***** **********************/}

                <DialogLoader
                    open={pendingManualSave && !isDraft}
                    text={"Enregistrement des modifications ..."}
                />

                <DialogLoader
                    open={openWaitinUpdate && pending}
                    text={"Sauvegarde en cours ..."}
                />

                {/****************************** 
                 * LOADER TELECHARGEMENT IMAGE 
                 * ***** **********************/}

                <DialogLoader
                    open={uploadPending}
                    text={"Téléchargement l'image ..."}
                />

                {/****************************** 
                 * DIALOG SUPPRESSION IMAGE
                 * ***** **********************/}

                <DialogDeleteExerciceImage
                    open={openDeleteImage}
                    onClose={()=> setOpenDeleteImage(false)}
                    onSelectThumbnail={onSelectThumbnail}

                />

                {/****************************** 
                 * DIALOG SAUVEGARDE EXERCICE
                 * ***** **********************/}

                <DialogSaveExercice 
                    open={openSaveExercice}
                    onClose={()=> setOpenSaveExercice(false)}
                    exercice={formData}
                    debounce={debounceAutoSave}
                />


                <Prompt
                    when={hasUnsavedChanges}
                    message="Tu n'as pas sauvegardé tes changements, veux-tu quitter la page ?"
                />


                
            </React.Fragment>

            
        )

    }else {
        return (
            <Loader />
        )
    }

}



export default ExerciceBuilder