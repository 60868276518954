import { createSelector } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "app/store"
import {  useDispatch, useSelector } from "react-redux"
import classes from "./styles"
import { WorkoutExercice as WorkoutExerciceType, ExerciceSet, WorkoutBlock } from "interfaces/Workout"
import { Badge, Box, Collapse, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Tooltip, Typography, styled, useMediaQuery } from "@mui/material"
import { KeyboardArrowDown, Close, LinkOff, AddLink, Add, Timer, TimerOff, MoveDown, NoteAdd, Note, Delete, Settings, Minimize, RemoveCircle, AddCircle, Remove, ShowChart, DragIndicator } from "@mui/icons-material"
import React, { useEffect, useState} from "react"
import { 
    addSetToExercice, 
    deleteExerciceSet, 
    onChangeExerciceMetric, 
    mergeGroups, 
    onDeleteExerciceMetric, 
    addMetricToExercice, 
    handleExerciceRest,
    removeExerciceFromWorkout, 
} from "features/workoutSlice"
import DialogExerciceDelete from "components/molecules/DialogExerciceDelete"
import ExerciceDetails from "components/organisms/DialogExercice"
import { SetTextField } from "components/molecules/SetTextField"
import { ExerciceMetrics } from "interfaces/Exercice"
import { RestTextField } from "components/molecules/RestTextField"
import SecondaryButton from "components/atoms/Buttons/Secondary"
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogDropset from "../DialogDropset"
import DialogExerciceNote from "components/molecules/DialogExerciceNote"
import { getExerciceDetails } from "function/getExerciceDetails"
import { MOBILE } from "utils/constants"
import DialogExerciceSettings from "../DialogExerciceSettings"
import DialogClientPerformances from "../DialogClientPerformances"



/************* INTERFACES ********** */

interface Props{
    _id: string,
    exerciceId: string,
    blockId: string,
    groupId?: string,
    isLastBlockElement: boolean,
    isLastGroupExercice?: boolean,
    nextElemIsGroup?: boolean,
    fromSuperset?:boolean,
    track: boolean,
    exerciceIndex: number,
    groupIndex: number,
    onOpenSwap: (exerciceId:string, groupId:string)=> void,
    type: string,
    showDragIcon?: boolean,
    provided?:any,
    blockType ? : "classic" | "AMRAP" | "EMOM" | "TABATA" | "FORTIME"
}





/****** REDUX SELECTORS ***** */

const nameSelector = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExerciceType = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.exercice.name.fr
    }
)


const imageSelector = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExerciceType = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.exercice.image
    }
)


const metricsSelectedSelector = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExerciceType = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId)
        var listOfMetrics = [...workoutExercice.metrics]
        
        return listOfMetrics

    }
)


const setsSelector = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExerciceType = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.sets
    }
)


const noteSelector = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExerciceType = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.note
    }
)


const restBetweenSetsSelector = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExerciceType = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.restBetweenSets
    }
)

const metricsSelector = createSelector(
    (state:RootState) => state.metrics,
    (metrics) => {
        const listOfMetrics = [...metrics.list]
        const list = listOfMetrics.sort((a, b) => {
            var textA = a.label.fr.toUpperCase();
            var textB = b.label.fr.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }); 

       return list
    }
)


const blockWorkSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlock = builder.blocks.find((elem:WorkoutBlock)=> elem.blockId === id)
        return block?.tabata?.work
    }
)





/****** MAIN COMPONENT ***** */

export function WorkoutExercice({
    _id,
    exerciceId, 
    blockId, 
    groupId, 
    fromSuperset, 
    isLastBlockElement, 
    isLastGroupExercice,
    onOpenSwap, 
    type, 
    showDragIcon=false, 
    provided,
    blockType
}:Props){
    const dispatch = useDispatch<AppDispatch>()
    const exerciceName = useSelector((state:RootState)=> nameSelector(state,groupId, exerciceId, blockId))
    const exerciceImage = useSelector((state:RootState)=> imageSelector(state,groupId, exerciceId, blockId))
    const note = useSelector((state:RootState)=> noteSelector(state,groupId, exerciceId, blockId))
    //const trackMetrics = useSelector((state:RootState)=> trackSelector(state,groupId, exerciceId, blockId))
    const metricsList:ExerciceMetrics[] = useSelector(metricsSelector)
    const exerciceMetricsSelected = useSelector((state:RootState)=> metricsSelectedSelector(state,groupId, exerciceId, blockId))
    const sets = useSelector((state:RootState)=> setsSelector(state,groupId, exerciceId, blockId))
    const restBetweenSets = useSelector((state:RootState)=> restBetweenSetsSelector(state,groupId, exerciceId,blockId))
    const [expand, setExpand] = useState<boolean>(false)
    const [openExerciceDelete, setOpenExerciceDelete] = useState<boolean>(false)
    const [openExerciceDetails, setOpenExerciceDetails] = useState<boolean>(false)
    const [openExerciceNote, setOpenExerciceNote] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDropsetModal, setOpenDropsetModal] = useState<boolean>(false)
    const [setToAddDropset, setSetToAddDropset] = useState<number>(null)
    const [openSettings, setOpenSettings] = useState<boolean>(false)
    const [openPerformances, setOpenPerformances] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const performed = useSelector((state:RootState)=> state.workouts.builder.performed)

    /******** CHANGEMENT DE METRIC ******* */

    const onChangeMetric = (metric:ExerciceMetrics,column:number) => () => {
        setTimeout(()=>dispatch(onChangeExerciceMetric({
            blockId, 
            exerciceId,
            column, 
            groupId,
            metric
        })), 50 )
    }
    
   
    /******** AJOUTER UNE SERIE ******* */
    const onAddSet = () => {
        dispatch(addSetToExercice({exerciceId, blockId, groupId, setIndex:null}))
    }



    const addDropSet = (setIndex:number) => ()=>{
        setOpenDropsetModal(true)
        setSetToAddDropset(setIndex)
        //dispatch(onAddDropSet({exerciceId, blockId, groupId, setIndex}))
    }

    // SUPPRESSION D'UNE SERIE
    const onDeleteSet = (setId:string, dropset?:boolean) => ()=> {
        dispatch(deleteExerciceSet({exerciceId, blockId, setId, groupId, dropset}))       
    }


    const onDeleteLastSet = () => {
        const lastSetIndex = sets.length - 1
        const setId = sets[lastSetIndex].exerciceSetId
        dispatch(deleteExerciceSet({exerciceId, blockId, setId, groupId, dropset: false}))       
    }


    /******* CREER UN GROUPE A PARTIR D'UN EXERCICE ***********/ 
    const onCreateGroup = () => {
        dispatch(mergeGroups({blockId, groupId}))
        setAnchorEl(null)
    }


    const onOpenSwapDialog = () =>{
        onOpenSwap(exerciceId, groupId)
        setAnchorEl(null)

    }


    /******* DEGROUPER UN EXERCICE ***********/ 
    const onRemove = () => {
        dispatch(removeExerciceFromWorkout({blockId, exerciceId, groupId}))
        setAnchorEl(null)
        

    }

    /******* SUPPRIMER UNE COLONNE ***********/ 
    const onDeleteColumn = (index:number) => () => {
        dispatch(onDeleteExerciceMetric({blockId,groupId,exerciceId,metricIndex: index}))
    }


    /******* AJOUTER UNE COLONNE ***********/ 
    const onAddMetric = () => {
        const listOfUsedMetrics:string[] = []
        // On récupère l'ID de tous les metrics utilisés
        exerciceMetricsSelected.forEach((elem)=>{
            listOfUsedMetrics.push(elem._id)
        })
        // ON cherche le premier metric qui n'est pas utilisé
        const findFirstMetric = metricsList.find((elem) => {
            if(!listOfUsedMetrics.includes(elem._id)) return elem
        })
        dispatch(addMetricToExercice({blockId,groupId,exerciceId, newMetric: findFirstMetric}))
    }


    const onHandleRest = () => {
        dispatch(handleExerciceRest({blockId,groupId,exerciceId, rest: !restBetweenSets}))
        setAnchorEl(null)
    }


    const onOpenExerciceNote = () => {
        setOpenExerciceNote(true)
        setAnchorEl(null)

    }


    const onDelete = () => {
        setOpenExerciceDelete(true)
        setAnchorEl(null)
    }


    const onOpenSettings = () => {
        setOpenSettings(true)
        setAnchorEl(null)

    }


    const onOpenExercicePerformances = () => {
        setAnchorEl(null)
        setOpenPerformances(true)
    }


    /************* JSX ********** */

    return(

        <React.Fragment>
            <Box sx={[
                classes.exercice, 
                (Boolean(groupId) && !isLastGroupExercice) && classes.exerciceFromGroup,
                 
            ]}>

                {/******* HEADER ********* */}

                <Box 
                    sx={classes.header}
                >
                    <Box 
                        sx={classes.headerInfos}
                    >
                        
                        <Box sx={{
                            flexDirection: "row", 
                            display:"flex", 
                            alignItems:"center"
                        }}>

                             {/********** 
                             * DRAG ICON 
                             * ***** */}

                             {showDragIcon && (
                                <Box
                                    {...provided.dragHandleProps}
                                    sx={{height: "70px", display:"flex", alignItems:"center", marginRight: {xs: 1, sm: 0}}}
                                >
                                    <DragIndicator
                                        sx={{color: "black"}}
                                    />
                                </Box>
                             )}

                            {!showDragIcon && (
                                <Box
                                    sx={{width: 23}}
                                >
                                    
                                </Box>
                             )}

                            {/**************************************** 
                             *                  IMAGE 
                             * ***************************************/}
                            
                            <Box sx={classes.thumbnailContainer}>
                                {exerciceImage?.url && (
                                    <Box 
                                        component={"img"} 
                                        src={exerciceImage?.url}
                                        sx={classes.thumbnail}
                                        onClick={()=> setOpenExerciceDetails(true)}
                                    />
                                )}

                                { /***** PAS D'IMAGE ***** */}
                                {(!exerciceImage?.url) && (
                                    <Box>
                                        {exerciceName[0]}{exerciceName[1]}
                                    </Box>
                                )}

                               


                            </Box>
                            
                            
                            {/********************* 
                            *   TITLE + NB SERIES 
                            * *********************/}

                                
                            <Box sx={{display:"flex", flexDirection: "row", marginLeft: 2, alignItems:"center"}}>
                                
                                {/**** NB SERIES **** */}

                                {(!mobile && (blockType === "classic" || !blockType)) && (
                                    <Typography sx={classes.sets}>
                                        {sets.length} * 
                                    </Typography>
                                )}

                                {/***** NOM ***** */}
                                
                                <Typography sx={classes.name}>
                                    {exerciceName}
                                </Typography>
                            </Box>
                           
                        </Box>

                        
                    </Box>

                    {/***************************** 
                     * LISTE DES BOUTONS D'ACTION 
                     * ************************** */}

                   
                    <Box sx={classes.headerButtons}>

                        {/********* NOTE ******** */}

                        {note && (
                            <IconButton 
                                onClick={onOpenExerciceNote}
                                sx={[classes.exerciceButton]}
                            >
                                <Badge 
                                    color="primary" 
                                    //variant="dot" 
                                    badgeContent={1}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}>
                                    <Note/>
                                </Badge>
                            </IconButton>
                        )}


                        {/************* 
                         * EXPAND 
                         * ******** */}
                    
                            <IconButton 
                                onClick={()=>setExpand(!expand)}
                                sx={[classes.dropDownButton(expand), classes.exerciceButton, {marginRight: 0}]}
                                className="collapse-exercice"
                            >
                                <KeyboardArrowDown />
                            </IconButton>
                        


                        

                        {/*****************
                         *    MORE MENU
                         * ****************/}

                        {(!Boolean(performed) && !mobile) && (
                            <IconButton 
                                sx={classes.exerciceButton} 
                                onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        )}


                            <Menu 
                                disableScrollLock={true} 
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={()=>setAnchorEl(null)}
                            >

                            

                                
                                {!note && (
                                    <MenuItem onClick={onOpenExerciceNote}>
                                        <ListItemIcon>
                                            <NoteAdd fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Ajouter une note
                                        </ListItemText>
                                    </MenuItem>
                                )}

                                {note && (
                                    <MenuItem onClick={onOpenExerciceNote}>
                                        <ListItemIcon>
                                            <NoteAdd fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Supprimer la note
                                        </ListItemText>
                                    </MenuItem>
                                )}

                                
                                <MenuItem onClick={onOpenExercicePerformances}>
                                    <ListItemIcon>
                                        <ShowChart fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Performances du client
                                    </ListItemText>
                                </MenuItem>



                                {((blockType ==="classic" || !blockType) && (!isLastBlockElement && isLastGroupExercice)) && (
                                    <MenuItem 
                                        onClick={onCreateGroup} 
                                        disabled={Boolean(blockType) && blockType !== "classic"}
                                    >
                                        <ListItemIcon>
                                            <AddLink />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Enchainer (Superset)
                                        </ListItemText>
                                    </MenuItem>
                                
                                )}

                                {fromSuperset && (
                                    <MenuItem onClick={onRemove}>
                                        <ListItemIcon>
                                            <LinkOff />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Dégrouper du superset
                                        </ListItemText>
                                    </MenuItem>

                                )}

                                <MenuItem onClick={onOpenSwapDialog}>
                                    <ListItemIcon>
                                    <SwapHorizIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Remplacer
                                    </ListItemText>
                                </MenuItem>

                            
                            
                                {!fromSuperset && (
                                    <MenuItem onClick={onDelete}>
                                        <ListItemIcon>
                                            <Delete fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Supprimer
                                        </ListItemText>
                                    </MenuItem>
                                )}

                                {mobile && (
                                    <MenuItem onClick={onHandleRest} disabled={!isLastGroupExercice}>
                                        <ListItemIcon>
                                            {restBetweenSets ? <TimerOff /> : <Timer/>}
                                        </ListItemIcon>
                                        <ListItemText>
                                            {restBetweenSets ? "Supprimer les temps de repos": "Ajouter des temps de repos"}
                                        </ListItemText>
                                    </MenuItem>
                                )}
                                
                            </Menu>
                    
                    </Box>
                 

                </Box>



                {/******* CONTENT ********* */}

                <Collapse 
                    in={expand} 
                    mountOnEnter 
                    unmountOnExit
                >
                    <Box 
                        sx={classes.content}
                    >

                        <Box 
                            sx={[
                                classes.contentRow, 
                                classes.headerTable,
                            ]} 
                        >
                            
                            {/**** *****************************************
                             * LISTE DES METRICS DE L'EXERCICE 
                            * ****** **************************************/}

                            

                            {/***************************** 
                             * INDEX (Tablette + desktop)
                             * ****************************/}

                            {(blockType == "classic" || !blockType) && (
                                <Box sx={[
                                    classes.rowIndex, 
                                    classes.setLabel
                                ]}>
                                    <Tooltip title={"Numéro de la série"}>
                                        <span>{mobile ? "#" : "Série"}</span>
                                    </Tooltip>
                                    
                                </Box>
                            )}
                            
                            {/******************************************************** 
                             * ON PARCOURT LES METRICS SELECTIONNEES PAR L'EXERCICE 
                             * ** ****************************************************/}
                            
                            {exerciceMetricsSelected.map((metric:ExerciceMetrics, metricIndex:number)=>(

                                <Box 
                                    sx={[
                                        classes.metric, 
                                        classes.metricHeader
                                    ]} 
                                    key={metric._id}>

                                        {/*********************** 
                                         * TEXTFIELD DU METRIC 
                                         * ******* *************/}

                                        <TextField
                                            sx={classes.metricTextField}
                                            defaultValue={metric._id}
                                            select={true}
                                            fullWidth={true}
                                            size="small"
                                            disabled={((blockType && blockType ==="TABATA") && (metric._id === "63e36e9c5882116100ce5a29")) || Boolean(performed)} // Disabled si durée et TABATA
                                        >

                                            {/***************************************** 
                                             * LISTE DES OPTIONS (LISTE DES METRICS) 
                                             * ****************************************/}

                                            {metricsList.map((optionMetric: ExerciceMetrics, optionIndex:number) => {

                                                // SI TABATA, IMPOSSIBLE DE METTRE DUREE
                                                if((optionMetric._id === "63e36e9c5882116100ce5a1f" || optionMetric._id === "63e36e9c5882116100ce5a23") && (blockType && (blockType ==="TABATA"))){
                                                    return null
                                                }

                                                if((optionMetric._id === "63e36e9c5882116100ce5a29") && (blockType && (blockType ==="EMOM"))){
                                                    return null
                                                }

                                                // ON Recherche les metrics déja utilisés par l'exercice pour les désactiver
                                                const disabled = exerciceMetricsSelected.find(elem => elem._id === optionMetric._id) ? true : false

                                                return (
                                                    <MenuItem 
                                                        key={optionIndex} 
                                                        value={optionMetric._id} 
                                                        disabled={disabled}
                                                        onClick={onChangeMetric(optionMetric,metricIndex)}
                                                    >
                                                        {optionMetric.label.fr}
                                                    </MenuItem>
                                                )
                                            })}
                                            
                                            {/********************************* 
                                                SUPPRESSION D'UNE COLONNE 
                                            ***********************************/}
                                            {metricIndex!==0 && (
                                                <MenuItem sx={{color: "primary.main", fontWeight: 700}} onClick={onDeleteColumn(metricIndex)}>
                                                    Supprimer
                                                </MenuItem>
                                            )}
                                        </TextField>
                                </Box>
                            ))}


                            {/*************************************** 
                             * TEMPS DE REPOS (DESKTOP + TABLETTE)
                             ***************************************/}

                            {(blockType === "classic" || !blockType || blockType === "TABATA") &&  (!mobile && restBetweenSets) && (
                                <Box 
                                    sx={classes.metric}
                                >
                                    <TextField
                                        sx={classes.metricTextField}
                                        defaultValue={"Récupération"}
                                        fullWidth={true}
                                        size="small"
                                        disabled={true}
                                    />
                                </Box>
                            )}


                            {/******************************************** 
                             * AJOUTER UNE COLONNE (DESKTOP + TABLETTE)
                             * ******* ***********************************/}
                            
                            {(!Boolean(performed) && !mobile) && (
                                <Box sx={classes.rowButtonsContainer}>
                                    
                                        <Tooltip 
                                            title={"Ajouter une colonne"} 
                                            placement={"left"}
                                            className={"add-metrics"}
                                        >
                                            <span>
                                                <IconButton 
                                                    onClick={onAddMetric} 
                                                    sx={classes.rowButton}
                                                    disabled={exerciceMetricsSelected.length === 3}
                                                >
                                                    <Add />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                

                                        <Tooltip 
                                            title={restBetweenSets ? "Supprimer les temps de repos" :"Ajouter des temps de repos"} 
                                            placement={"left"} 
                                            className={"add-rest"}>
                                                <span>
                                                    <IconButton 
                                                        onClick={onHandleRest} 
                                                        sx={classes.rowButton}
                                                        disabled={!isLastGroupExercice || (blockType && (blockType !=="classic"))}
                                                    >
                                                        {restBetweenSets ? <TimerOff /> : <Timer/>}
                                                    </IconButton>
                                                </span>
                                        </Tooltip>
                                </Box>
                            )}
                            
                        </Box>

                        {/**** *************************************
                         * LISTE DES SERIES  (CLASSIC BLOCK TYPE)
                         * *****************************************/}


                        <Box 
                            sx={classes.setsList}
                        >

                            {/****************** 
                             * Liste des séries 
                             *******************/}

                            {sets.map((set: ExerciceSet, setIndex: number) => {
                               
                                const prescriptions = set.prescriptions
                            
                                
                                return(
                                    <Box 
                                        key={set.exerciceSetId} 
                                        sx={{flexDirection: "column", width: "100%"}}
                                    >

                                        {/******************** 
                                            * SERIE 
                                        * *****************/}

                                        <Box sx={[classes.contentRow]}>

                                            {/**** Numéro de série **** */}
                                            
                                            {(blockType === "classic" || !blockType) && (
                                                <Box sx={classes.rowIndex}>
                                                    <Box component="span" sx={classes.setNumber}> 
                                                        #{setIndex + 1}
                                                    </Box>

                                                    {/*******************
                                                     * DROPSET (DESKTOP)
                                                     ********************/}

                                                    
                                                    {(!mobile && set.dropset) && (
                                                        <>
                                                            <Box sx={classes.dropsetIndicator} onClick={addDropSet(setIndex)}>
                                                                + {set.dropset.sets} dropset{set.dropset.sets > 1 ? "s":""} 
                                                            </Box>
                                                            
                                                        </>
                                                    )}
                                                    
                                                </Box>
                                            )}
                                            
                                            {/************************************************************* 
                                             * CHAQUE SERIES COMPORTE DES VALEURS BASEES SUR LES METRICS 
                                             *************************************************************/}

                                            <Box sx={{width: "100%", display:"flex", flexDirection:"row"}}>

                                                {prescriptions.map((prescriptionValue:any, columnIndex:number)=>{
                                                    const metricSelected:ExerciceMetrics = exerciceMetricsSelected[columnIndex]
                                            
                                   

                                                    // Recherche de l'unité du metric
                                                    const unit:string = metricSelected?.units?.fr 

                                                    return(
                                                        <SetTextField
                                                            key={columnIndex}
                                                            metricDefaultValue={((!blockType || (Boolean(blockType) && blockType !== "TABATA") ?  metricSelected?.defaultValue : prescriptionValue ))}
                                                            defaultValue={prescriptionValue}
                                                            unit={unit}
                                                            setIndex={setIndex}
                                                            groupId={groupId}
                                                            blockId={blockId}
                                                            exerciceId={exerciceId}
                                                            columnIndex={columnIndex}
                                                            minValue={metricSelected?.minValue}
                                                            maxValue={metricSelected?.maxValue}
                                                            metricId={metricSelected?._id}
                                                            type={metricSelected?.type}
                                                            format={metricSelected?.format}
                                                            fixedValue={metricSelected?.fixedValue}
                                                            regex={metricSelected?.regex}
                                                            disabled={(blockType === "TABATA" && metricSelected?._id === "63e36e9c5882116100ce5a29") || Boolean(performed)}
                                                        />
                                                    )
                                                })}

                                                {(blockType === "classic" || !blockType || blockType === "TABATA") &&  (!mobile && restBetweenSets) &&  (
                                                    <RestTextField
                                                        restTextField={true}
                                                        defaultValue={set.rest}
                                                        setIndex={setIndex}
                                                        groupId={groupId}
                                                        blockId={blockId}
                                                        exerciceId={exerciceId}
                                                        disabled={blockType === "TABATA" || Boolean(performed)}
                                                    />
                                                )}
                                            </Box>
                                        
                                            {/**************************************** 
                                             * LISTE DES BOUTONS D'ACTION (DESKTOP) 
                                             * **************************************/}

                                            {(!Boolean(performed) && !mobile) && (
                                            
                                                <Box sx={classes.rowButtonsContainer}>

                                                    {/********************** 
                                                     * AJOUTER UN DROPSET 
                                                     * ********************/}

                                                    <Tooltip title={type === "63e2583d4634b611780b1f9a" ? "Ajouter des séries dégressives" : "Impossible d'ajouter des séries dégressives à ce type d'exercice"} placement={"left"}>
                                                        <span>
                                                        <IconButton 
                                                            onClick={addDropSet(setIndex)} 
                                                            sx={classes.rowButton}
                                                            disabled={type !== "63e2583d4634b611780b1f9a"}
                                                        >
                                                            <MoveDown />
                                                        </IconButton>
                                                        </span>
                                                    </Tooltip>

                                                

                                                    {/*********************** 
                                                     * SUPPRIMER UNE SERIE 
                                                     * **********************/}
                                                    
                                                    <Tooltip title={"Supprimer la série"} placement={"right"}>
                                                        <span>
                                                            <IconButton 
                                                                sx={classes.rowButton}
                                                                onClick={onDeleteSet(set.exerciceSetId, false)}
                                                            >
                                                                <Close />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    
                                                </Box>

                                            )}
                                        </Box>

                                        {/********************************
                                         *     DROPSET (mobile)          *
                                         *******************************/}

                                        {(mobile && set.dropset) && (
                                         
                                            <Box sx={classes.dropsetIndicatorMobile} onClick={addDropSet(setIndex)}>
                                                + {set.dropset.sets} dropset{set.dropset.sets > 1 ? "s":""} 
                                            </Box>
                                         
                                        )}

                                        {/******************************** 
                                            * TEMPS DE REPOS (MOBILE) 
                                        * ******************************/}

                                        {(blockType === "classic" || !blockType) && (mobile && restBetweenSets)&& (
                                            <Box sx={classes.contentRow}>
                                                    <Box sx={classes.rowIndex}>
                                                        <Box component="span" sx={classes.setNumber}> 
                                                            
                                                        </Box>
                                                    </Box>
                                               
                                                    <RestTextField
                                                        restTextField={true}
                                                        defaultValue={set.rest}
                                                        setIndex={setIndex}
                                                        groupId={groupId}
                                                        blockId={blockId}
                                                        exerciceId={exerciceId}
                                                    />
                                                
                                            </Box>
                                        )}
                                    </Box>
                                
                                )
                            })}
                        </Box>


                         {/**** ************************
                         * BOUTONS AJOUTER / SUPPRIMER
                         * ************************** */}

                        <Box sx={classes.buttons}>

                            {/************** 
                             *    MOBILE 
                             * ************/}

                            {mobile && (
                                <IconButton 
                                    onClick={onAddSet} 
                                    sx={classes.mobileButton}
                                >
                                    <Add sx={{width: 30, height: 30, color: "rgba(0,0,0,0.85)"}}/>
                                </IconButton>
                            )}

                            
                            {mobile && (
                                <IconButton 
                                    onClick={onDeleteLastSet} 
                                    sx={classes.mobileButton}
                                >
                                    <Remove sx={{width: 30, height: 30, color: "rgba(0,0,0,0.85)"}}/>
                                </IconButton>
                            )}

                            {mobile && (
                                <IconButton 
                                    onClick={addDropSet(sets.length-1)} 
                                    sx={[classes.mobileButton]}
                                >
                                    <MoveDown sx={{color: "rgba(0,0,0,0.85)", fontSize: 20}}/>
                                </IconButton>
                            )}

                            {mobile && (
                                <IconButton 
                                    onClick={onOpenSettings}
                                    sx={[classes.mobileButton]}
                                >
                                    <Settings sx={{width: 25, height: 25, color: "rgba(0,0,0,0.85)"}}/>
                                </IconButton>
                            )}

                            {mobile && (
                                <IconButton 
                                    onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}} 
                                    sx={[classes.mobileButton, {marginRight:0}]}
                                >
                                    <MoreHorizIcon sx={{width: 30, height: 30,color: "rgba(0,0,0,0.85)"}}/>
                                </IconButton>
                            )}



                            {/**** Bureau *** */}

                            {(!mobile && (blockType === "classic" || !blockType) && !performed)&& (
                                <SecondaryButton 
                                    onClick={onAddSet} 
                                    sx={{ borderRadius: "24px !important", marginRight: mobile ? 1.5 : 0}}
                                    startIcon={<Add/>}
                                >
                                    Ajouter une série
                                </SecondaryButton>
                            )}

                            

                            
                            
                        </Box>
                    </Box>
                </Collapse>
                
            </Box>
        
            {/***************************** 
             * DIALOG SUPPRESSION D'EXO 
             * ***************************/}

            <DialogExerciceDelete 
                open={openExerciceDelete}
                onClose={()=>setOpenExerciceDelete(false)}
                exerciceId={exerciceId}
                blockId={blockId}
                groupId={groupId}
            />

            {/******* DIALOG EXERCICE DETAIL ****** */}

            <ExerciceDetails
                open={openExerciceDetails} 
                onClose={()=> setOpenExerciceDetails(false)}
                exerciceId={_id}
            
            />


            <DialogDropset 
                open={openDropsetModal}
                onClose={()=> setOpenDropsetModal(false)}
                blockId={blockId}
                groupId={groupId}
                exerciceId={exerciceId}
                setIndex={setToAddDropset}
                dropset={sets?.[setToAddDropset]?.dropset}
            />

            <DialogExerciceNote
                key={exerciceId}
                open={openExerciceNote}
                onClose={()=> setOpenExerciceNote(false)}
                exerciceName={exerciceName}
                exerciceId={exerciceId} 
                blockId={blockId} 
                groupId={groupId}
            />

            <DialogExerciceSettings 
                open={openSettings}
                onClose={()=> setOpenSettings(false)}
                exerciceId={exerciceId}
                groupId={groupId}
                blockId={blockId}
            />


            <DialogClientPerformances 
                open={openPerformances}
                exerciceName={exerciceName}
                exerciceId={_id} 
                onClose={()=> setOpenPerformances(false)}
                workoutExerciceId={exerciceId}
                groupId={groupId}
                blockId={blockId}
            />


        </React.Fragment>
    )
}

function areEqual(prev:Props, next:Props){
    return prev.exerciceId === next.exerciceId && 
        prev._id === next._id && 
        prev.blockId === next.blockId && 
        prev.groupId === next.groupId && 
        prev.isLastBlockElement === next.isLastBlockElement && 
        prev.isLastGroupExercice === next.isLastGroupExercice && 
        prev.nextElemIsGroup === next.nextElemIsGroup && 
        prev.track === next.track && 
        prev.exerciceIndex === next.exerciceIndex && 
        prev.groupIndex === next.groupIndex &&
        prev.type === next.type &&
        prev.blockType === next.blockType
}

export default React.memo(WorkoutExercice, areEqual)