import { BORDER_COLOR, DRAWER_WIDTH_MIN, LIGHT_BUTTON_COLOR, WOD_PANEL_WIDTH } from "utils/constants"
import { theme } from "utils/theme"

const LAYOUT_PADDING = 3 * 8 * 2
const WELCOME_HEIGHT = 112
const SECTION_TITLE_HEIGHT= 54
const SECTION_PADDING_TOP = 3 * 8

const classes ={


    header:  {
        display:"flex", 
        flexDirection: "row", 
        flexGrow:1, 
        justifyContent:"space-between",
        alignItems:"center", 
        paddingBottom: 2
    },


    date : {
        marginBottom: 3,
        
        //backgroundColor: "#ecf6ff",
        display:"inline-flex",
        padding: 1,
        borderRadius: 1,
        //border: `solid 1px ${BORDER_COLOR}`,
        alignItems:"center",
    },

    dateLabel:{
        color: "rgba(0,0,0,0.85)",
        fontWeight: 500,
        fontSize: 16,
    },

    blockEvents : {
        marginBottom: 6
    },


    mobileMessage : {
        marginBottom: 3,
        backgroundColor: "#d4f8f8",
        padding: 2,
        borderRadius: 2,
        display:"flex",
        flexDirection: "row"
    },


    oops: {
        fontWeight: 700,
        fontSize: "3rem"
    },


    feedHeader : {
        display:"flex",
        flexDirection: {
            xs: "column",
            sm: "row",
        },
        alignItems: {
            xs: "flex-start",
            sm: "center"
        },
        justifyContent:"space-between",
        marginBottom: {
            xs: 3,
            sm: 2
        }
    },


    sectionTitle: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        marginBottom: {
            xs: 1.5,
            sm: 0
        }
        
    },

    
    day:{
        fontSize: "0.9rem",
        fontWeight: 600, 
        //backgroundColor: "white",
        marginBottom: 3,
        display:"flex",
        width: "100%",
        padding: 2,
        paddingTop:0.5,
        paddingBottom: 0.5,
        zIndex: 2,
        color: "black",
        borderRadius: 1,
        justifyContent:"center"
        //border: `solid 1px ${BORDER_COLOR}`

    },

    eventContainer : {
        display:"flex",
        justifyContent:"center",
        flexDirection: "column",
        position: "relative",
        flex:1,
    },

    eventsTimeline : {
        display:{
            xs: "none",
            sm: "block"
        },
        position:"absolute",
        left: 15,
        top: 0,
        height: "100%",
        borderRight: "dashed 2px #e1e1e1"
    },


        historyContainer: {
            display:"flex",
            flexDirection: "column",
            width: "100%",
        },

    
        history:{
            display:"flex", 
            flexDirection:"column", 
        },
    
        cardEmptyEvent: {
            fontSize: {
                xs: "1rem",
                sm:"1.1rem",
            },
            padding:3,
            paddingLeft: 1,
            marginBottom: 2,
            marginLeft: 0,
            marginRight: 0,
            fontWeight: 400,
        },

        loadButton: {
            display:"flex", 
            alignItems:"center", 
            justifyContent:"center", 
            width: "100%",
            marginBottom: 3
        },

        noEvents: {
            backgroundColor: "white",
            justifyContent:"center",
            alignItems:"center",
            padding: 3,
            marginBottom: 3,
            zIndex:2,
            marginLeft: 5,
            width: "100%"
        },

       

        

    
}

export default classes