import Workout from "interfaces/Workout";
import convertSecondsToHours from "./convertSecondsToHours"
import convertFormatedTimeToSeconds from "./convertFormatedTimeToSeconds";


export default function calcWorkoutDuration(workout:Workout){

    var duration:number = 0
    workout.blocks.forEach((block, blockIndex)=> {

        /*******************
         * BLOC CLASSIC
         *******************/

        if((Boolean(block.blockType) && block.blockType === "classic") || !block.blockType){
            block.content.forEach((groupContent)=> {
                
                groupContent.exercices.forEach((exercice)=> {
                  
                    const repsIndex = exercice.metrics.findIndex((elem)=> elem._id === "63e36e9c5882116100ce5a1f")
                    const durationIndex = exercice.metrics.findIndex((elem)=> elem._id === "63e36e9c5882116100ce5a29")
                    const rirIndex = exercice.metrics.findIndex((elem)=> elem._id === "63e36e9c5882116100ce5a23")

                    /*console.log('repsIndex', repsIndex)
                    console.log('durationIndex', durationIndex)
                    console.log('rirIndex', rirIndex)*/

                    if(durationIndex !== -1){
                        exercice.sets.forEach((set)=>{
                            const setDuration:string = set.prescriptions[durationIndex]
                            if(setDuration){
                                const durationSeconds = convertFormatedTimeToSeconds(setDuration)
                                duration = duration + durationSeconds
                            }
                            
                        })
                    }

                    // REPS
                    else if(repsIndex !== -1){
                        exercice.sets.forEach((set)=>{
                            
                            const nbreps:number = set.prescriptions[repsIndex]
                            if(nbreps){
                                duration = duration + (nbreps * 4)
                                if(set.dropset){
                                    duration = duration + ((nbreps *3) * set.dropset.sets)
                                }
                            }
                        })
                    }

                    // RIR
                    else if(rirIndex !== -1){

                        exercice.sets.forEach((set)=>{
                            
                            const nbreps:number = 10 - set.prescriptions[rirIndex]
                            if(nbreps){
                            duration = duration + (nbreps * 4)
                                if(set.dropset){
                                    duration = duration + ((nbreps *3) * set.dropset.sets)
                                }
                            }
                        })
                    }

                    // DUREE
                   

                    
                    

                    // Temps de repos
                    exercice.sets.forEach((set)=>{
                        const rest = set.rest
                        if(Boolean(rest) && rest !== "00:00"){
                            duration = duration + convertFormatedTimeToSeconds(rest)
                        }
                    })
                })
            })
        }

        /*// TABATA
        if(Boolean(block.blockType) && block.blockType === "TABATA"){

            var oneRoundDuration = 0
            const work = convertFormatedTimeToSeconds(block.tabata?.work || "00:00")
            const rest = convertFormatedTimeToSeconds(block.tabata?.rest || "00:00")
            const exerciceDuration= work + rest
            block.content.forEach((groupContent)=> {
                groupContent.exercices.forEach((_)=> {
                    oneRoundDuration = oneRoundDuration + exerciceDuration
                })
            })
            duration = duration + ((block.tabata?.rounds || 0) * oneRoundDuration)
        }*/
    })


    const formated = convertSecondsToHours(duration)

    if(Boolean(formated) && formated.split(':')[0] === "NaN"){
        return ("00:00:00")
    }

    return formated
}