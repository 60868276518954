import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
// INterfaces
import Workout, { WorkoutExercice, ExerciceSet, WorkoutBlock, GroupExercice, ExercicePerformances } from 'interfaces/Workout'
// UUID
import { v4 as uuid } from "uuid"
// TOASTIFY
import { toast } from 'react-toastify'
// FUNCTIONS
import { addKeysToWorkout } from "function/addKeysToWorkout"
// API
import { 
  addCoachNoteToWorkout,
  createWorkout,
  deleteWorkout,
  duplicateWorkout,
  duplicateWorkoutTemplate,
  getWorkout,
  updateWorkout
} from 'api/workouts';
import Exercice, { ExerciceMetrics } from 'interfaces/Exercice';
import Image from 'interfaces/Image'
import addSetsToExercice from 'function/addSetsToExercice'
import { getTemplates } from 'api/templates'
import fuseSearch from 'function/fuseSearch'
import { getResultMetrics } from 'api/resultMetrics'


/******** BLOCKS DE SEANCES PAR DEFAULT ********** */
const defaultBlocks :WorkoutBlock[]= [{
  name: "Echauffement",
  content: [],
  blockId: uuid(),
  track: false,
  type: "text",
  blockType: "classic"
},
{
  name: "Corps de séance",
  content: [],
  blockId: uuid(),
  track: true,
  type: "builder",
  blockType: "classic"

},
]


/******** SEANCE BASIQUE ********** */

const builder:Workout ={
  _id: null,
  name: "",
  blocks: defaultBlocks,
  template: false,
  status: "disabled"
}


/************** INIT SLICE ****************/

interface WorkoutsSlice {
  builder: Workout,
  templates:{
    list: Workout[],
    filtered: Workout[]
  },
  resultMetrics: [],
  unSavedChanges: boolean,
  requests:{
    get:"pending"|"idle",
    create:"pending"|"idle",
    update: "pending" | "idle",
    sendNotif : "pending" | "idle",
    delete: "pending" | "idle",
    deleteMultiple: "pending" | "idle",
    duplicate: "pending" | "idle",
    updateMultiple: "pending" | "idle",
    saveAsTemplate: "pending" | "idle"
  },
}


/******** INIT STATE ********** */
const initialState:WorkoutsSlice = {
  builder: builder,
  templates:{
    list: [],
    filtered: []
  },
  resultMetrics: [],
  requests:{
    get:"idle",
    create:"idle",
    update: "idle",
    sendNotif: "idle",
    delete:"idle",
    deleteMultiple: "idle",
    duplicate: "idle",
    updateMultiple: "idle",
    saveAsTemplate: "idle"

  },
  unSavedChanges: false,
} 




/*********************************************** */


// Slice
export const workoutBuilder = createSlice({
  name: 'workout-builder',
  initialState,

  /*********************** REDUCERS ******************** */

  reducers: {
    resetWorkoutBuilder: (state) => {
      state.builder = builder
    },

    defineNewWorkout:(state, action:{payload:{workout:Workout}}) => {
      const workout = action.payload.workout
      state.builder = addKeysToWorkout(workout)
    },

    editWorkout: (state, action) => {
      const workout:Workout = action.payload.workout
      state.builder = addKeysToWorkout(workout)
    },

    handleWorkoutStatus : (state,action) => {
      state.builder.status = action.payload.status
    },

    workoutIsSaving: (state) => {
      state.requests.update = "pending"
    },

    workoutIsUnsaved: (state) => {
      state.unSavedChanges = true
    },

    workoutIsSaved: (state) => {
      state.unSavedChanges = false
    },

    handleName: (state, action:{payload:{name:string}}) => {
      state.builder.name = action.payload.name
    },

    updateWorkoutBuilderCover : (state,action:{payload:{cover:Image}}) => {
        const cover:Image = action.payload.cover
        state.builder.cover = cover
    },


    handleLevel: (state,action:{payload:{level:"advanced" | "medium" | "beginner"}}) => {
      state.builder.level = action.payload.level
    },

    handleTrack:(state,action:{payload:{blockId:string,track:boolean}})=> {
      const blockId:string = action.payload.blockId
      const track:boolean = action.payload.track
      const blockIndex = state.builder.blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      state.builder.blocks[blockIndex].track = track
    },


    onChangeDescription:(state, action:{payload:{description:string}}) => {
      const description = action.payload.description
      state.builder.description = description
    },


    /*********************************************** */
    /************** BLOCKS ********* */
    /*********************************************** */

    addBlockToWorkout:(state) =>{
      state.builder.blocks.push({
        name: "Nouveau bloc",
        content:[],
        blockId: uuid(),
        type: "builder",
        track: true,
        blockType: "classic"
      })
    },

    deleteWorkoutBlock:(state,action:{payload:{blockId:string}}) =>{
      if(state.builder.blocks.length === 1){
        toast.error('La séance doit contenir au moins un bloc')
        return
      }
      const blockId:string = action.payload.blockId

      const blockIndex = state.builder.blocks.findIndex((block:WorkoutBlock) => block.blockId === blockId)
      state.builder.blocks.splice(blockIndex,1)
    },


    handleWorkoutBlockName:(state, action:{payload:{blockId:string, name:string}}) => {
      const blockId = action.payload.blockId
      const index = state.builder.blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const name:string = action.payload.name
      state.builder.blocks[index].name = name
    },


   


    handleBlockInstructions:(state, {payload}:PayloadAction<{blockId:string, instructions:string}>) => {
      const blockId = payload.blockId
      const index = state.builder.blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const instructions = payload.instructions
      state.builder.blocks[index].instructions = instructions
    },


    /******************
     * BLOCK TYPE
     *****************/


    onChangeBlockType: (state, {payload}:PayloadAction<{blockId:string, blockType:"classic" | "AMRAP" | "EMOM" | "TABATA" | "FORTIME", metricsList?:ExerciceMetrics[]}>) => {
      const blockType = payload.blockType // Nouveau block type
      const blockId = payload.blockId
      const blockIndex = state.builder.blocks.findIndex((elem)=> elem.blockId === blockId)

      state.builder.blocks[blockIndex].blockType = blockType

      /********************************************
       * CHANGEMENT EN BLOCK DIFFERENT DE CLASSIC
       ********************************************/

      /**************************
       * REDUCTION DE UNE SERIE
       *************************/

      if(blockType !== "classic"){

        var newContent = state.builder.blocks[blockIndex].content
        var exercices:WorkoutExercice[] = []


        state.builder.blocks[blockIndex].content.forEach((group, groupIndex)=> {
          group.exercices.forEach((exercice,exoIndex)=> {
            
            const sets = exercice.sets
            if(sets.length>1){
              group.exercices[exoIndex].sets.splice(1,sets.length -1)
            }
          })

        })

      /*****************************
       * SUPPRESSION DES SUPERSETS
       *****************************/

        state.builder.blocks[blockIndex].content.forEach((group, groupIndex)=> {
            // Il y'a des superset, on les détruit
            if(group.exercices.length > 1 ){  
              group.exercices.forEach((exercice, exericeIndex)=> {
                  const newGroup:GroupExercice = {
                    exercices: [{...exercice}],
                    groupId: uuid(),
                  }
                  
                  state.builder.blocks[blockIndex].content.splice(groupIndex + (exericeIndex + 1), 0, newGroup)
              })

              state.builder.blocks[blockIndex].content.splice(groupIndex, 1)
          }
        })

        /******************
         *    TABATA
         *****************/

        if(blockType === "TABATA"){
          state.builder.blocks[blockIndex].tabata = {
            work: "00:20",
            rest: "00:20",
            rounds: 8
          }

          
          state.builder.blocks[blockIndex].content.forEach((group, groupIndex)=> {

              group.exercices.forEach((exercice)=> {
                const exo = {...exercice}
                var metrics = [...exo.metrics]
                var sets = [...exo.sets]

                // Si durée existe déja on supprime
                const durationFound = exo.metrics.findIndex((elem)=> elem._id === "63e36e9c5882116100ce5a29")

                if(durationFound !== -1 && durationFound !== 0){
                  var newPrescriptions = [...sets[0].prescriptions]

                  newPrescriptions.splice(durationFound,1)
                  sets[0].prescriptions = newPrescriptions

                  metrics.splice(durationFound,1)
                }



                // Metrics Durée
                metrics[0] = payload.metricsList.find((elem)=> elem._id === "63e36e9c5882116100ce5a29" )

                // Valeur Metrics à 00:20
                const prescriptions = [...sets[0].prescriptions]
                prescriptions[0] = "00:20"

                // ON remplace la valeur 
                sets[0] = {
                  ...sets[0],
                  prescriptions,
                  rest: "00:20"
                }

                console.log('metrics', metrics[0])
                console.log('new sets', sets[0])

                exercices.push({
                  ...exo,
                  metrics: [...metrics],
                  sets : [...sets],
                  restBetweenSets: true,
                })
              })

              newContent[groupIndex].exercices = exercices
              exercices = []

          })


          state.builder.blocks[blockIndex] = {
            ...state.builder.blocks[blockIndex],
            content:newContent,
            EMOM: undefined,
            AMRAP: undefined,
            FORTIME: undefined
          }
        }

        else if(blockType === "EMOM"){
          state.builder.blocks[blockIndex].EMOM = {
            rounds: 8,
          }

          var newContent = state.builder.blocks[blockIndex].content
          var exercices:WorkoutExercice[] = []

          state.builder.blocks[blockIndex].content.forEach((group, groupIndex)=> {

            group.exercices.forEach((exercice)=> {
              const exo = {...exercice}
              var metrics = [...exo.metrics]
              var sets = [...exo.sets]

              var prescriptions = [...sets[0].prescriptions]
              

              // Si durée existe déja on supprime
              const durationFound = exo.metrics.findIndex((elem)=> elem._id === "63e36e9c5882116100ce5a29")


              if(durationFound !== -1){
                var newPrescriptions = [...sets[0].prescriptions]

                newPrescriptions.splice(durationFound,1)
                sets[0].prescriptions = newPrescriptions

                metrics.splice(durationFound,1)

       
                // Musculation, on ajoute reps et RPE
                if(exercice.exercice.type._id === "63e2583d4634b611780b1f9a"){
                  metrics[0] = payload.metricsList.find((elem)=> elem._id === "63e36e9c5882116100ce5a1f" ) // reps
                  metrics[1] = payload.metricsList.find((elem)=> elem._id === "63e36e9c5882116100ce5a24" ) // RPE
                  prescriptions = [10,8]
                
                } 
                // Sinon RPE
                else {
                  metrics[0] = payload.metricsList.find((elem)=> elem._id === "63e36e9c5882116100ce5a24" ) // RPE
                  prescriptions = [8]
                }
                
              }

              

              // ON remplace la valeur 
              sets[0] = {
                ...sets[0],
                prescriptions,
              }

              exercices.push({
                ...exo,
                metrics: [...metrics],
                sets : [...sets],
              })
            })

            newContent[groupIndex].exercices = exercices
            exercices = []

        })


        state.builder.blocks[blockIndex] = {
          ...state.builder.blocks[blockIndex],
          content:newContent,
          tabata: undefined,
          AMRAP: undefined,
          FORTIME: undefined
        }
        }

        else if(blockType === "AMRAP"){
          state.builder.blocks[blockIndex].AMRAP = {
            work: "10:00",
          }
          state.builder.blocks[blockIndex].tabata = undefined
          state.builder.blocks[blockIndex].FORTIME = undefined
          state.builder.blocks[blockIndex].EMOM = undefined

        }

        else if(blockType === "FORTIME"){
          state.builder.blocks[blockIndex].FORTIME = {
            work: "10:00",
          }
          state.builder.blocks[blockIndex].tabata = undefined
          state.builder.blocks[blockIndex].AMRAP = undefined
          state.builder.blocks[blockIndex].EMOM = undefined
        }
        
      } else {
        state.builder.blocks[blockIndex].tabata = undefined
        state.builder.blocks[blockIndex].EMOM = undefined
        state.builder.blocks[blockIndex].FORTIME = undefined
      }
    },

    onChangeBlockRounds : (state, {payload}:PayloadAction<{blockId:string, rounds:number}>) => {
      const blockId = payload.blockId
      const blockIndex = state.builder.blocks.findIndex((elem)=> elem.blockId === blockId)
      const rounds = payload.rounds

      state.builder.blocks[blockIndex].tabata.rounds = rounds
    },
    

    onChangeBlockRest : (state, {payload}:PayloadAction<{blockId:string, rest:string}>) => {
      const blockId = payload.blockId
      const blockIndex = state.builder.blocks.findIndex((elem)=> elem.blockId === blockId)
      const rest = payload.rest

      // Changement du rest pour tous les exercices
      state.builder.blocks[blockIndex].content.forEach((group, groupIndex)=> {
        group.exercices.forEach((_,exerciceIndex) => {
          state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[0].rest = rest
        })
      })

      state.builder.blocks[blockIndex].tabata.rest = rest
    },

    onChangeBlockWork : (state, {payload}:PayloadAction<{blockId:string, work:string}>) => {
      const blockId = payload.blockId
      const blockIndex = state.builder.blocks.findIndex((elem)=> elem.blockId === blockId)
      const work = payload.work

      // Changement du rest pour tous les exercices
      state.builder.blocks[blockIndex].content.forEach((group, groupIndex)=> {
        group.exercices.forEach((_,exerciceIndex) => {
          state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[0].prescriptions[0] = work
        })
      })

      state.builder.blocks[blockIndex].tabata.work = work
    },

    onChangeEMOMDuration : (state, {payload}:PayloadAction<{blockId:string, work:string}>) => {
      const blockId = payload.blockId
      const blockIndex = state.builder.blocks.findIndex((elem)=> elem.blockId === blockId)
      const work = payload.work
      state.builder.blocks[blockIndex].EMOM.work = work
    },

    onChangeAMRAPDuration : (state, {payload}:PayloadAction<{blockId:string, work:string}>) => {
      const blockId = payload.blockId
      const blockIndex = state.builder.blocks.findIndex((elem)=> elem.blockId === blockId)
      const work = payload.work

      state.builder.blocks[blockIndex].AMRAP.work = work
    },


    onChangeFORTIMEDuration : (state, {payload}:PayloadAction<{blockId:string, work:string}>) => {
      const blockId = payload.blockId
      const blockIndex = state.builder.blocks.findIndex((elem)=> elem.blockId === blockId)
      const work = payload.work

      state.builder.blocks[blockIndex].FORTIME.work = work
    },


    /*********************************************** */
    /************** EXERCICES ********* */
    /*********************************************** */

    /*********************************************** */
    /************** HANDLE TRACKING ********* */
    /*********************************************** */

    handleExerciceTrackingType :(state, { payload }:PayloadAction<{groupId:string, exerciceId:string,blockId:string,tracking: "weight"|"duration"|"distance"|"reps"}>) => {
        /*const blockId: string = payload.blockId
        const groupId: string = payload.groupId
        const exerciceId:string = payload.exerciceId
        const blocks = state.builder.blocks

        // Recherche de l'index du block
        const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)

        // Le groupe
        const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice)=> group.groupId === groupId)
        const groupedExercices = blocks[blockIndex].content[groupIndex].exercices

        // Recherche de l'index de l'exercice cliqué
        const exerciceIndex:number = groupedExercices.findIndex((exercice:WorkoutExercice)=> exercice.exerciceId === exerciceId)
        const current = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].track*/

    },

    /*********************************************** */
    /************** ADD EXERCICE TO WORKOUT ********* */
    /*********************************************** */

    addExerciceToWorkout: (state, action:{payload:{exercice:Exercice,blockId:string,metricsValue:any[],metrics:ExerciceMetrics[] }}) => {
      const exercice:Exercice = action.payload.exercice
      const blockId:string = action.payload.blockId;
      const defaultValues:any[] = action.payload.metricsValue
      const metrics:ExerciceMetrics[] = action.payload.metrics
      // Recherche du block
      const blockIndex:number = state.builder.blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const blockType = state.builder.blocks[blockIndex].blockType
      
      var newContent:WorkoutExercice = null 


      if(blockType === "classic" || !blockType){
        newContent = {
          exercice: exercice,
          metrics: metrics, // defaults metrics
          sets:[{
            prescriptions: defaultValues, // default values
            exerciceSetId: uuid(),
            rest: "01:00",
          }], 
          restBetweenSets: true,
          exerciceId: uuid(),
        }

      } else if(blockType === "TABATA"){
        
        const tabataRest = state.builder.blocks[blockIndex].tabata?.rest

        newContent= {
          exercice: exercice,
          metrics: metrics, // defaults metrics
          sets:[{
            prescriptions: ['00:20'], // default values
            exerciceSetId: uuid(),
            rest: tabataRest,
          }], 
          restBetweenSets: true,
          exerciceId: uuid(),
        }
      }

      

      
      // ajout d'un nouveau groupe d'exercice (contenant un seul exercice) au block
      state.builder.blocks[blockIndex].content.push({
        exercices:[{...newContent}],
        groupId: uuid()
      })
    },


    /*********************************************** */
    /************** SWAP EXERCICE TO WORKOUT ********* */
    /*********************************************** */

    swapExerciceToWorkout: (state, action:{payload:{blockId:string, groupId: string, exerciceId:string, newExercice: Exercice}}) => {
      const exerciceId:string = action.payload.exerciceId
      const blockId:string = action.payload.blockId;

      const blockIndex:number = state.builder.blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const groupIndex = state.builder.blocks[blockIndex].content.findIndex((group:GroupExercice)=> group.groupId === action.payload.groupId)

      const exercieIndex:number = state.builder.blocks[blockIndex].content[groupIndex].exercices.findIndex((elem)=> elem.exerciceId === exerciceId)
      
      state.builder.blocks[blockIndex].content[groupIndex].exercices[exercieIndex].exercice = action.payload.newExercice

    },


    /*********************************************** */
    /********** REMOVE EXERCICE FROM WORKOUT ******* */
    /*********************************************** */

    removeExerciceFromWorkout: (state,action:{payload:{blockId:string,groupId:string,exerciceId:string}}) => {
      const blockId: string = action.payload.blockId
      const groupId: string = action.payload.groupId
      const exerciceId:string = action.payload.exerciceId
      const blocks = state.builder.blocks

      // Recherche de l'index du block
      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      // Le groupe
      const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice)=> group.groupId === groupId)
      const groupedExercices = blocks[blockIndex].content[groupIndex].exercices
      const groupLength = groupedExercices.length

      // Recherche de l'index de l'exercice cliqué
      const exerciceIndex:number = groupedExercices.findIndex((exercice:WorkoutExercice)=> exercice.exerciceId === exerciceId)

      // Suppression du groupe entier (1 seul exercice)
      if(groupLength === 1){
        state.builder.blocks[blockIndex].content.splice(groupIndex,1)

      } 
      // Suppression d'un exercice, recréation d'un ou plusieurs groupes
      
      else {
  
        // L'exercice qu'on va séparer
        const newExercice:WorkoutExercice = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex]
        const newGroupExercice = {exercices: [{...newExercice}], groupId: uuid()}

        // Premier exercice, on ajoute l'exercice avant le groupe
        if(exerciceIndex === 0){
          // Suppression de l'exo du groupe
          state.builder.blocks[blockIndex].content[groupIndex].exercices.splice(exerciceIndex,1)
          const insertIndex = groupIndex === 0 ? 0 : (groupIndex)
          state.builder.blocks[blockIndex].content.splice(insertIndex,0,newGroupExercice)
        } 
        // Dernier exercice
        else if(exerciceIndex === groupLength-1){
          // Suppression de l'exo du groupe
          state.builder.blocks[blockIndex].content[groupIndex].exercices.splice(exerciceIndex,1)
          state.builder.blocks[blockIndex].content.splice(groupIndex + 1,0,newGroupExercice)
        } 

        // Exercice au milieu, on casse le groupe complet
        else {
          const exercicesList = state.builder.blocks[blockIndex].content[groupIndex].exercices
          // ON ajoute chaque exercice
          exercicesList.map((workoutExercice:WorkoutExercice, exerciceIndex:number)=> {
            const newGroup = {exercices: [{...workoutExercice}], groupId: uuid()}
            state.builder.blocks[blockIndex].content.splice(groupIndex + 1 + exerciceIndex,0,newGroup)
          })
          // Puis on supprime le groupe
          state.builder.blocks[blockIndex].content.splice(groupIndex,1)
        }
      }
    },

    

    /*********************************************** */
    /********** REORDONNER DES GROUPES  ************ */
    /*********************************************** */

    reorderWorkout: (state, action:{
      payload:{
        result:{
          draggableId:string,
          destination:{index:number, droppableId:string}, 
          source:{index:number, droppableId:string}
        }
      }}) => {
        const {draggableId, destination, source} = action.payload.result
        const blocks:WorkoutBlock[] = state.builder.blocks

        // Indexs des potitions de départ et de destination
        const sourceIndex:number = source.index
        const destinationIndex:number = destination.index

        // Block source ID
        const blockSourceId:string = source.droppableId
        // Block destination ID
        const blockDestinationId:string = destination.droppableId
        
        // Index du block source
        const blockSourceIndex:number = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockSourceId)
        // Index du block destination
        const blockDestinationIndex:number = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockDestinationId)
        // Récupération de l'élément à bouger (exercice ou groupe)
        const draggableElement:GroupExercice = state.builder.blocks[blockSourceIndex].content.find((elem:GroupExercice)=> elem.groupId === draggableId)

        state.builder.blocks[blockSourceIndex].content.splice(sourceIndex,1)
        state.builder.blocks[blockDestinationIndex].content.splice(destinationIndex,0, draggableElement)
    },


    /*********************************************** */
    /********** GESTION DES REPOS  ************ */
    /*********************************************** */

    handleExerciceRest: (state,{ payload }: PayloadAction<{exerciceId:string,blockId:string,groupId:string,rest:boolean }>) => {
        
      const blockId:string = payload.blockId
        const groupId:string = payload.groupId
        const exerciceId:string = payload.exerciceId
        const rest:boolean = payload.rest
        const blocks = state.builder.blocks
        const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)

        const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId === groupId )
        const exerciceIndex = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice) => exercice.exerciceId === exerciceId)
        state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].restBetweenSets = rest
    },


    /*********************************************** */
    /********** AJOUT D'UNE SERIE  ************ */
    /*********************************************** */

    addSetToExercice: (state, { payload }: PayloadAction<{exerciceId:string,blockId:string,groupId:string,setIndex:number}>) => {
      const blockId:string = payload.blockId
      const groupId:string = payload.groupId
      const blocks = state.builder.blocks

      // Recherche du block
      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId === groupId )

      // AJOUT D'UNE SERIE NON ENCHAINEE

      // ON ajoute une série à chaque exercice du groupe
      blocks[blockIndex].content[groupIndex].exercices.forEach((_:WorkoutExercice, exerciceIndex:number)=>{
        // Récupération de la dernière série de l'exercice pour la dupliquer
          const setsLength:number = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets.length
          const allSetsLength:number = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets.length
          const setToCopy:ExerciceSet = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setsLength-1]
          const previousSet = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[allSetsLength-1]

          

          state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets.push({...setToCopy, exerciceSetId: uuid(), rest : previousSet.rest})
      })
      
       /* else {
          const exerciceIndex = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice)=> exercice.exerciceId === exerciceId)
          const setToCopy:ExerciceSet = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex]
          state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets.splice(setIndex + 1,0,{...setToCopy, dropset:linked, exerciceSetId: uuid()})
        }*/
    },

    onHandleDropSet : (state, {payload}: PayloadAction<{exerciceId:string,blockId:string,groupId:string, setIndex:number, sets: number, weight: number}>) => {

      const {blockId, groupId, exerciceId, setIndex, sets, weight} = payload
      const blocks = state.builder.blocks


      // Recherche du block
      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId === groupId )
      const exerciceIndex = blocks[blockIndex].content[groupIndex].exercices.findIndex((elem)=> elem.exerciceId === exerciceId)

      const currentSet = current(state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex])

      state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex].dropset=sets > 0 ? {sets, weight} : undefined
    },


    /*********************************************** */
    /********** SUPPRIMER SERIE  ************ */
    /*********************************************** */

    deleteExerciceSet: (state, { payload }: PayloadAction<{exerciceId:string,blockId:string,groupId:string,setId:string,dropset:boolean}>) => {
      const setId:string = payload.setId // ID du set, permet de retrouver son Index selon les cas (séries enchainée ou non)
      const blockId:string = payload.blockId
      const groupId:string = payload.groupId
      const exerciceId:string = payload.exerciceId
      

      const blocks = state.builder.blocks

      // Recherche de l'index du bloc
      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
    
      const groupIndex:number = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId === groupId )
      const currentExerciceIndex:number = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice)=> exercice.exerciceId === exerciceId)

      const nbSets = state.builder.blocks[blockIndex].content[groupIndex].exercices[currentExerciceIndex].sets.length

      if(nbSets === 1){
        toast.error("L'exercice doit avoir au moins une série")
        return
      }

        // Si la série est la première d'une suite de dropset
        // on boucle pour supprimer les séries les dropsets qui suivent
        /*if(!currentSetToDelete.dropset && nextSet?.dropset){
          // Tant qu'il ya des dropsets qui suivent, on les supprime
          var toDelete = setIndex + 1
          var loopEnd = false
          while(!loopEnd){
            var currentSetIsLinked = blocks[blockIndex].content[groupIndex].exercices[currentExerciceIndex].sets[toDelete]?.dropset
            if(currentSetIsLinked){
              blocks[blockIndex].content[groupIndex].exercices[currentExerciceIndex].sets.splice(toDelete,1)
            } else{
              loopEnd = true
            }
          }
        }

        // Si c'est un dropset, on le supprime simplement
        /*if(dropset){
            const indexToDelete:number = state.builder.blocks[blockIndex].content[groupIndex].exercices[currentExerciceIndex].sets.findIndex((elem:ExerciceSet) => elem.exerciceSetId === setId)
            state.builder.blocks[blockIndex].content[groupIndex].exercices[currentExerciceIndex].sets.splice(indexToDelete,1)
            return 
        }*/

        // SINON

        /***** SUPPRESSION DE LA SERIE DANS TOUS LES EXERCICES DU GROUPE**** */
        // Liste des séries sans enchainement (pour rechercher la position de la série à supprimer, hors séries d'enchainement)
        const sets:ExerciceSet[] = blocks[blockIndex].content[groupIndex].exercices[currentExerciceIndex].sets
        // Position de la série dans l'exercice (permet de retrouver les séries à la meme position dans les autres exercices)
        const setPositionToDelete = sets.findIndex((elem:ExerciceSet)=> elem.exerciceSetId === setId)

        
        // ON Parcourt chaque exercice du groupe pour supprimer la série à la position indiquée
        blocks[blockIndex].content[groupIndex].exercices.forEach((_:WorkoutExercice, exerciceIndex:number)=>{
          // ON trie les sets de l'exercice (uniquement ceux qui ne sont pas des dropsets)
          const setsList = blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets
          // ON recherche l'ID du set ayant la position de setPositionToDelete
          const setIdToDelete:string = setsList[setPositionToDelete].exerciceSetId
          // On recherche l'index de cette série dans la liste complète
          const indexToDelete:number = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets.findIndex((elem:ExerciceSet) => elem.exerciceSetId === setIdToDelete)
          // ON supprime la série
          state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets.splice(indexToDelete,1)
        })
    },


    /*********************************************** */
    /********** AJOUT DE METRICS  ************ */
    /*********************************************** */

    addMetricToExercice : (state,{ payload }: PayloadAction<{exerciceId:string,blockId:string,groupId:string,newMetric:ExerciceMetrics}>) => {
        const blockId:string = payload.blockId
        const groupId:string =payload.groupId
        const exerciceId:string = payload.exerciceId
        const newMetric:ExerciceMetrics = payload.newMetric
        const blocks = state.builder.blocks

        // Recherche du block
        const blockIndex:number = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
        const groupIndex:number = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId === groupId )
        const exerciceIndex:number = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice) => exercice.exerciceId === exerciceId)
        state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].metrics.push(newMetric)
        state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets.map((_, setIndex:number)=>{
          state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex].prescriptions.push(newMetric.defaultValue)
        })
        
       
    },

    onChangeExerciceMetric: (state,action:{payload:{blockId:string,groupId:string,exerciceId:string,metric:ExerciceMetrics,column:number}}) => {
      const blockId:string = action.payload.blockId
      const groupId:string = action.payload.groupId
      const exerciceId:string = action.payload.exerciceId
      const metricSelected:ExerciceMetrics = action.payload.metric
      const column:number = action.payload.column
      const blocks = state.builder.blocks

      // Recherche du block
      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const groupIndex:number = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId=== groupId )
      const exerciceIndex:number = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice) => exercice.exerciceId === exerciceId)
      state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].metrics[column] = metricSelected
      const sets = state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets
      // ON parcourt les séries (sans dropset) et on change la valeur par défaut
      sets.forEach((_,setIndex)=> {
          state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex].prescriptions[column] = metricSelected.defaultValue
      })

    },

    onChangeExerciceNote : (state,action:{payload:{blockId:string,groupId:string,exerciceId:string,note: string}}) => {
      const blockId:string = action.payload.blockId
      const groupId:string = action.payload.groupId
      const exerciceId:string = action.payload.exerciceId
      const blocks = state.builder.blocks
      const note:string = action.payload.note

      // Recherche du block
      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const groupIndex:number = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId=== groupId )
      const exerciceIndex:number = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice) => exercice.exerciceId === exerciceId)
      state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].note = note

    },


    onDeleteExerciceMetric : (state,action:{payload:{blockId:string,groupId:string,exerciceId:string,metricIndex:number}}) => {
        const blockId:string = action.payload.blockId
        const groupId:string = action.payload.groupId
        const exerciceId:string = action.payload.exerciceId
        const metricIndex:number = action.payload.metricIndex
        const blocks = state.builder.blocks


        // Recherche de l'index du bloc
        const blockIndex:number = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
        const groupIndex:number = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId=== groupId )
        const exerciceIndex:number = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice) => exercice.exerciceId === exerciceId)
        state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].metrics.splice(metricIndex,1)
        state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets.map((_, setIndex:number)=>{
          state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex].prescriptions.splice(metricIndex,1)
        })
    },


    mergeGroups:(state,action:{payload:{blockId:string,groupId:string}}) => {
      const blockId = action.payload.blockId
      const groupId = action.payload.groupId
      const blocks = state.builder.blocks

      // Recherche de l'index du bloc
      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)

      // INdex du groupe source
      const sourceGroupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId=== groupId )
      const sourceGroup:GroupExercice = blocks[blockIndex].content[sourceGroupIndex] // GROUPE SOURCE (cliqué)
      const nextGroup:GroupExercice = blocks[blockIndex].content[sourceGroupIndex+1] // GROUPE SUIVANT

      // Mise à niveau des séries de l'exercice avec le groupe
      const differenceOfSets:number = sourceGroup.exercices[0].sets.length - nextGroup.exercices[0].sets.length
       
    /********* Le groupe source a moins de séries, on les ajoute à chaque exercice ********/

      if(differenceOfSets < 0 ){
        sourceGroup.exercices.forEach((elem:WorkoutExercice, exerciceIndex:number)=> {
              // Ajout de l'exercice avec les nouvelles séries au groupe clické et supp du temps de repos
              const exoToGroup = addSetsToExercice(-differenceOfSets, {...elem})
              blocks[blockIndex].content[sourceGroupIndex].exercices[exerciceIndex] = exoToGroup
          })
        
      } 
      
      /********* Le groupe à plus de séries, on les ajoute à chaque exercice ********/
      else if(differenceOfSets > 0 ){
          nextGroup.exercices.forEach((elem:WorkoutExercice, exerciceIndex:number)=> {
            // Ajout de l'exercice avec les nouvelles séries au groupe suivant
            const exoToGroup = addSetsToExercice(differenceOfSets, {...elem})
            blocks[blockIndex].content[sourceGroupIndex+1].exercices[exerciceIndex] = exoToGroup
          })
      }

      const toConcat = current(blocks[blockIndex].content[sourceGroupIndex+1].exercices)
     
      // Ajout des exercices du Next Group au Source group
      toConcat.forEach((elem:WorkoutExercice)=>{
          state.builder.blocks[blockIndex].content[sourceGroupIndex].exercices.push({...elem}) // Concaténation
      })

      // Suppression des temps de repos des exercices du groupe source
      
      state.builder.blocks[blockIndex].content.splice(sourceGroupIndex + 1,1) // Suppression du groupe

      const mergedGroup = current(state.builder.blocks[blockIndex].content[sourceGroupIndex].exercices)

      // Remise a zéro des temps de repos
      mergedGroup.forEach((_,exoIndex)=>{
        if(exoIndex < mergedGroup.length - 1){
          state.builder.blocks[blockIndex].content[sourceGroupIndex].exercices[exoIndex].restBetweenSets = false
        }
      })


    },

    // Changement d'une valeur
    changePrescriptionValue: (state, action:{payload:{blockId:string,groupId:string,exerciceId:string,setIndex:number,columnIndex:number,value:any}}) => {
        const blockId: string = action.payload.blockId
        const groupId: string = action.payload.groupId
        const exerciceId:string = action.payload.exerciceId
        const setIndex:number = action.payload.setIndex
        const columnIndex:number = action.payload.columnIndex
        const value:any = action.payload.value

        const blocks = state.builder.blocks

        // Recherche de l'index du block
        const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
        const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice)=> group.groupId=== groupId)
        const exerciceIndex = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice)=> exercice.exerciceId === exerciceId)
        state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex].prescriptions[columnIndex] = value
    },


    onChangeDropSetValue : (state, action: {payload:{blockId:string,groupId:string,exerciceId:string,setIndex:number,value:any}}) => {
      const blockId: string = action.payload.blockId
        const groupId: string = action.payload.groupId
        const exerciceId:string = action.payload.exerciceId
        const setIndex:number = action.payload.setIndex
        const value:any = action.payload.value

        const blocks = state.builder.blocks

        // Recherche de l'index du block
        const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
        const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice)=> group.groupId=== groupId)
        const exerciceIndex = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice)=> exercice.exerciceId === exerciceId)
        //state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex].dropset.subtract = value
    },


    // Changement d'une valeur
    changeRestValue: (state, action:{payload:{blockId:string,groupId:string,exerciceId:string,setIndex:number,value:any}}) => {
      const blockId: string = action.payload.blockId
      const groupId: string = action.payload.groupId
      const exerciceId:string = action.payload.exerciceId
      const setIndex:number = action.payload.setIndex
      const value = action.payload.value

      const blocks = state.builder.blocks

      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice)=> group.groupId=== groupId)
      const exerciceIndex = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice)=> exercice.exerciceId === exerciceId)
      state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex].rest = value
  },

    importTemplate: (state,action:{payload:{workout:Workout}}) => {
        const workout:Workout = addKeysToWorkout(action.payload.workout)
        const currentId:string = state.builder._id
        const isTemplate:boolean= state.builder.template

        state.builder = {
          ...workout,
          _id: currentId,
          template: isTemplate,
        }
    },


    handleExerciceNote: (state,action:{payload:{blockId:string,groupId:string,exerciceId:string,note:string}}) => {
      const blockId:string = action.payload.blockId
      const groupId:string = action.payload.groupId
      const exerciceId:string = action.payload.exerciceId
      const note:string = action.payload.note
      const blocks = state.builder.blocks
      

      // Recherche du block
      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId=== groupId )
      const exerciceIndex = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice) => exercice.exerciceId === exerciceId)
      state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].note = note
      
    },


    copySetsFromPerformances: (state,action:{payload:{blockId:string,groupId:string,exerciceId:string,sets:ExercicePerformances[]}}) => {
      const blockId:string = action.payload.blockId
      const groupId:string = action.payload.groupId
      const exerciceId:string = action.payload.exerciceId
      const blocks = state.builder.blocks

      const blockIndex = blocks.findIndex((block:WorkoutBlock)=> block.blockId === blockId)
      const groupIndex = blocks[blockIndex].content.findIndex((group:GroupExercice) => group.groupId=== groupId )
      const exerciceIndex = blocks[blockIndex].content[groupIndex].exercices.findIndex((exercice:WorkoutExercice) => exercice.exerciceId === exerciceId)
      const currentRest = blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[0].rest

      const pasteSets = action.payload.sets
      var setsPrescriptions:ExerciceSet[] = []

      pasteSets.forEach((set)=> {
        const weight = set.weight
        const reps = set.reps

      })
     

      //state.builder.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets = action.payload.sets

    },


    // FUSE SEARCH
    searchWorkoutsTemplates: (state, action) => {
      const search:string = action.payload.search

      const keys:string[] = [
        "name",
      ]

      state.templates.filtered = search !== "" ? fuseSearch(current(state.templates.list), search, keys) : state.templates.list
    },

    resetSearchWorkouts: (state) => {
      state.templates.filtered = state.templates.list
    }
  },

  /*********************** EXTRA REDUCERS ******************** */

  extraReducers(builder) {
    builder

    /********* GET TEMPLATES ********* */
    .addCase(getTemplates.fulfilled, (state, {payload})=>{
        const templates = payload.templates
        if(templates.workouts){
          state.templates.list = templates.workouts
          state.templates.filtered = templates.workouts
        }
    })

    /********* GET WORKOUT ******** */
    .addCase(getWorkout.pending, (state)=> {
      state.requests.get = "pending"
    })

    .addCase(getWorkout.fulfilled, (state, {payload})=> {
        state.requests.get = "idle"
        const workout:Workout = payload.workout
        if(workout){
          state.builder = addKeysToWorkout(workout)
        }
    })

    .addCase(getWorkout.rejected, (state,{payload}) => {
      state.requests.get = "idle"
      toast.error('Impossible de récupérer les données de la séance pour le moment')
    })

    /******** CREATE WORKOUT ******* */
    .addCase(createWorkout.pending,(state)=>{
      state.requests.create = "pending"
    })

    .addCase(createWorkout.fulfilled,(state,{payload})=>{
      state.requests.create = "idle"
      const {workout} = payload
      
      if(workout?.template && !workout.programTemplate){ // template
        const customList = [...current(state.templates.list)]
        customList.push(workout)
        const sortedList = customList.sort((a,b)=> {
          if(a.name > b.name){
            return 1
          } else if(a.name < b.name){
            return -1
          }else return 0
        })

        state.templates.list = sortedList
        state.templates.filtered = sortedList
      
       
    
      }
      else if(workout && !workout.template){
        state.builder = addKeysToWorkout(workout)
      }
      
      else{
        toast.error(payload.error)
      }
    })

    .addCase(createWorkout.rejected, (state) => {
      state.requests.create = "idle"
      toast.error('Impossible de créer une nouvelle séance pour le moment')
    })


    /******** DUPLICATE WORKOUT ******* */
    .addCase(duplicateWorkoutTemplate.pending,(state)=>{
      state.requests.create = "pending"
    })

    .addCase(duplicateWorkoutTemplate.fulfilled,(state,{payload})=>{
      state.requests.create = "idle"
      const {workout} = payload
      const customList = [...current(state.templates.list)]
        customList.push(workout)
        const sortedList = customList.sort((a,b)=> {
          if(a.name > b.name){
            return 1
          } else if(a.name < b.name){
            return -1
          }else return 0
        })

        state.templates.list = sortedList
        state.templates.filtered = sortedList
    })

    /******** UPDATE WORKOUT ******* */
    .addCase(updateWorkout.pending, (state) => {
      state.requests.update = "pending"
    })
    .addCase(updateWorkout.fulfilled, (state,{payload}) => {
      state.requests.update = "idle"
      if(payload.workout){
        const workout:Workout = {...payload.workout, createdAt: payload.workout.createdAt}
        if(workout?.template){ // Mise à jour dans la liste des templates
            const indexList = state.templates.list.findIndex((elem:Workout)=> elem._id === workout._id)
            state.templates.list[indexList] = workout
            const indexFiltered = state.templates.filtered.findIndex((elem:Workout)=> elem._id === workout._id)
            state.templates.filtered[indexFiltered] = workout
        }
        
        if(state.builder?._id){ // Mise à jour dans lle builder
          console.log('mise a jour dans le builder')
          state.builder.status=workout.status
          state.builder.cover = workout.cover
        }
      }
    })


    /******** DELETE WORKOUT ******* */
    .addCase(deleteWorkout.pending, (state) => {
      state.requests.delete = "pending"
    })

    // template
    .addCase(deleteWorkout.fulfilled, (state,{payload}) => {
      state.requests.delete = "idle"
      const {workout} = payload
      if(workout?.template && !workout.programTemplate){ 
        const listIndex = state.templates.list.findIndex((elem:Workout)=> elem._id === workout._id)
        state.templates.list.splice(listIndex,1)
        const filteredIndex = state.templates.filtered.findIndex((elem:Workout)=> elem._id === workout._id)
        state.templates.filtered.splice(filteredIndex,1)
      }
    })
    
    .addCase(deleteWorkout.rejected, (state) => {
      state.requests.delete = "idle"
      toast.error('Impossible de supprimer cette séance')
    })

    /******** DUPLICATE WORKOUT ******* */
    .addCase(duplicateWorkout.pending, (state)=>{
      state.requests.duplicate = "pending"
    })

    .addCase(duplicateWorkout.fulfilled, (state,{payload})=>{
      state.requests.duplicate = "idle"
      const {workout} = payload

      if(workout){
        if(workout.template){
          state.templates.list.splice(0,0,workout)
          state.templates.filtered.splice(0,0,workout)
        }
      }else{
        toast.error(payload.error)
      }
    })

    .addCase(duplicateWorkout.rejected, (state)=>{
      state.requests.duplicate = "pending"
      toast.error('Impossible de dupliquer cette séance pour le moment')
    })

    
    .addCase(getResultMetrics.fulfilled, (state, {payload})=>{
      if(payload.metrics){
        state.resultMetrics = payload.metrics
      }
    })


    /******** COMMENTAIRE DE SEANCE ******* */

    .addCase(addCoachNoteToWorkout.pending, (state)=> {
      state.requests.sendNotif = "pending"
    })

    .addCase(addCoachNoteToWorkout.fulfilled, (state)=> {
      state.requests.sendNotif = "idle"
    })




  }
})



export const {
  handleName,
  defineNewWorkout,
  resetWorkoutBuilder,
  addExerciceToWorkout,
  removeExerciceFromWorkout,
  reorderWorkout,
  addSetToExercice,
  changePrescriptionValue,
  deleteExerciceSet,
  onChangeExerciceMetric,
  editWorkout,
  importTemplate,
  workoutIsSaving,
  workoutIsSaved,
  workoutIsUnsaved,
  addBlockToWorkout,
  handleBlockInstructions,
  handleWorkoutBlockName,
  deleteWorkoutBlock,
  mergeGroups,
  handleTrack,
  handleExerciceNote,
  handleWorkoutStatus,
  onDeleteExerciceMetric,
  addMetricToExercice,
  changeRestValue,
  handleExerciceRest,
  updateWorkoutBuilderCover,
  searchWorkoutsTemplates,
  resetSearchWorkouts,
  handleExerciceTrackingType,
  onHandleDropSet,
  onChangeDropSetValue,
  swapExerciceToWorkout,
  onChangeExerciceNote,
  handleLevel,
  onChangeDescription,
  copySetsFromPerformances,
  onChangeBlockType,
  onChangeBlockRounds,
  onChangeBlockRest,
  onChangeBlockWork,
  onChangeAMRAPDuration,
  onChangeEMOMDuration,
  onChangeFORTIMEDuration
 } = workoutBuilder.actions

export default workoutBuilder.reducer