
const photoSizeXl = 300
const photoSizeLg = 250
const photoSizeMd = 200
const photoSizeSm = 200

const classes = {

    album : {
        display:"flex",
        flexDirection: "column",
        maxWidth: "100%",
        
    },

    photosContainer: {
        display:"flex",
        flexDirection: "row",
        marginBottom: 6,
        position:"relative",
        maxWidth: "100%",
        overflowX: {
            xs: "scroll",
            sm: "hidden"
        },
        
    },

    photosList:{
        display:"flex",
        flexDirection: "row",
        position:"relative",
        justifyContent:"space-between",
        width: {
            xs: 700,
            sm: "100%"
        },
        minWidth: {
            xs: 700,
            sm: "100%"
        }
    },

    photo: {
        width: {
            xs: photoSizeSm,
            md: photoSizeMd,
            lg:photoSizeLg,
            xl: photoSizeXl,
        },

        maxWidth: {
            xs: photoSizeSm,
            md: photoSizeMd,
            lg:photoSizeLg,
            xl: photoSizeXl,
        },
        objectFit: "cover"
        
    },



    date : {
        
       
        color: "rgba(0,0,0,0.85)",
        fontWeight: 600,
        fontSize: 18
    },

    header : {
        display:"flex",
        flexDirection:"column",
        marginTop: 3,
        marginBottom: 1.5,
    },

    weight: {
        fontWeight: 700,
        fontSize: "1.6rem",
        color: "black"
    },

    labelNoPhoto : {
        textAlign:"center"
    },

    emptyPhoto : {
        width: {
            xs: photoSizeSm,
            md: photoSizeMd,
            lg:photoSizeLg,
            xl: photoSizeXl,
        },
        maxWidth: {
            xs: photoSizeSm,
            md: photoSizeMd,
            lg:photoSizeLg,
            xl: photoSizeXl,
        },
        display:"flex",
        flex:1,
        backgroundColor: "white",
        border: "dashed 5px #ededed",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column"
    },

    view: {
        fontWeight: 700,
        fontSize: "1.6rem"
    }
}

export default classes