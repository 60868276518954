import { useState, useEffect } from "react";
//MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, useMediaQuery } from "@mui/material";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
// INTERFACES
import { AppDispatch, RootState } from "app/store";
// REDUCERS
// DATE PICKER
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// CONSTANTS
import { DIALOG_WIDTH, MOBILE } from "utils/constants";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { updateUserData } from "api/user";


/***** INTERFACES ***** */

interface Props {
    open: boolean,
    onClose: ()=> void,
}


// MAIN CONTENT

export default function DialogInstagramAccount({open,onClose}:Props){
    
    const pending = useSelector((state:RootState) => state.user.requests.update === "pending")
    const user = useSelector((state:RootState) => state.user.data)
    const dispatch = useDispatch<AppDispatch>()
    const [instagram, setInstagram] = useState<string>("")
    const mobile = useMediaQuery(MOBILE)
    

    useEffect(()=>{
        if(open){
            setInstagram(user.instagram)
        }
    },[open])


    const submit = () => {
        dispatch(updateUserData({instagram})).unwrap().then((res)=>{
            if(res.user) onClose()
        })
    }


    return(
        <Dialog 
            open={open} 
        >
                <DialogTitle>
                    Ton compte Instagram
                </DialogTitle>
                <DialogContent sx={{width:mobile ? "100%" : DIALOG_WIDTH}}>
                <TextField
                    fullWidth
                    label="Compte Instagram"
                    onChange={(e)=> setInstagram(e.target.value)}
                    value={instagram}
                    sx={{marginTop: 1, marginBottom: 2}}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" sx={{color:"rgba(0,0,0,0.85)", fontWeight: 600}} disableTypography>
                            @ 
                        </InputAdornment>
                
                    }}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={pending}>
                        Annuler
                    </Button>
                    <LoadingButton disabled={pending} loading={pending} onClick={submit}>
                        {pending? "Modification" : "Valider"}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
    )
}