
import { lighten } from "@mui/material"
import { BORDER_COLOR, CARD_BORDER_RADIUS, CARD_HOVER_COLOR, HOVER_COLOR } from "utils/constants"
import { theme } from "utils/theme"



const classes = {

   section : {
    flexDirection: "column"
   },

   stat : {
    borderRadius: CARD_BORDER_RADIUS,
    backgroundColor: "white",
    border: `solid 1px ${BORDER_COLOR}`,
    display:"flex",
    flexDirection: "row",
    padding: 1,
    paddingRight: 2,

    "&:hover" : {
        backgroundColor: CARD_HOVER_COLOR,
        cursor: "pointer"
    }

   },

   statLabel : {
    fontWeight: 400,
    color: "rgba(0,0,0,0.65)",
    fontSize: 15
   },

   statValue: {
        fontWeight: 600,
        color: "black",
        fontSize: {xs: 18, xl: 20}
   },

   statBy: {
    fontWeight: 400,
    color: "rgba(0,0,0,0.65)",
    fontSize: 13,
    marginTop: 0.5
   },

   sectionCard : {
    backgroundColor: "white",
    padding: 3,
    border: `solid 1px ${BORDER_COLOR}`
   },

    buttonContainer: {
        display:"flex",
        alignItems:"center",
        jusstifyContent:"flex-end"

    },

    measureRow : {
        display:"flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 35,
        alignItems:"center"
    },


    clickable: {
        cursor: "pointer",
        "&:hover" : {
            backgroundColor: HOVER_COLOR
        }
    },

    measureRowValue: {
        fontWeight: 600
    },

    sectionTitle : {
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 3
    },

    selectedRange:{
        backgroundColor: lighten(theme.palette.primary.main,0.90)
    },

    rangeButtons:{
        display:"inline-flex", 
        alignItems:"center", 
        flexDirection: "row", 
        marginBottom: 3,
        marginTop: 3
    },
    
}





export default classes