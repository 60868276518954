import axios from "utils/axios"
import sleep from "function/sleep"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { Notifications } from "interfaces/Notification"
import { toast } from "react-toastify"

/************** API ****************/

  // Création d'un checkup
export const postNotification = createAsyncThunk('notification/post', async (data:Notifications) => {

    if(Boolean(data.tokens[0])){

    try {
        const response = await axios.post(`notifications`, data)
        const notification:Notifications = response.data.notification
        await sleep(sleep? 200:0)
        return { notification }
    
      } catch (err: any) {
    
        return {
          error: err.response.data?.message[0]
        }
      }
    }else{
      toast.error("Ton client n'a pas accepté les notifications sur l'app mobile")
      return null
    }
})
    