import { TextField, } from "@mui/material"
import { styled } from "@mui/system"
import { theme } from "utils/theme"


const TitleTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    minWidth: 300,
    maxWidth: 500,
    //marginBottom: theme.spacing(1),
    //marginBottom: theme.spacing(4),
    /*[theme.breakpoints.down('sm')]: {
        marginBottom: 0
    },*/
    border: 0,
    //borderBottom: "dashed 1px",
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 700,
        fieldset: {
            border: 0,
            //borderBottom: "1px dashed",

        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
            

        }
    },
}))

export default TitleTextField