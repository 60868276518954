import { Box, List, ListItem, ListItemButton, ListItemIcon, useMediaQuery, ListItemText, ListItemAvatar, Avatar, Tooltip, Zoom} from "@mui/material"
import { DESKTOP, DRAWER_ACTIVE_ITEM_TEXT_COLOR, DRAWER_ITEM_LABEL_COLOR, MENU, MOBILE } from "utils/constants"
import Icon from "components/atoms/Icon"
import DrawerItem from 'interfaces/DrawerItem';
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { handleDrawer, handleSubscriptionPresentation } from "features/appSlice";
import classes from "./styles"
import { theme } from "utils/theme";

interface Props {
    redirectTo: (path:string) => ()=> void,
    drawerIsOpen: boolean,
    horizontal?:boolean
}

export default function DrawerItems({redirectTo, horizontal}:Props){

    const itemSelected:number = useSelector((state:RootState) => state.app.drawerItemSelected)
    const user = useSelector((state:RootState) => state.user.data)
    const dispatch = useDispatch<AppDispatch>()
    const desktop = useMediaQuery(DESKTOP);
    const mobile = useMediaQuery(MOBILE);

    

    /******** OUVERTURE DU DIALOGUE DE PRESENTATION ******* */

    const onOpenDialogPresentation = (needSubscription:boolean, needNutrition:boolean) => (e:any) => {
        if((needSubscription && !needNutrition) || !user.trialIsDone){
            dispatch(handleSubscriptionPresentation({open:true}))
        }
        
        if(!desktop){
            dispatch(handleDrawer({open: false}))
        }
    }


    return(
        <Box 
        sx={classes.drawerContent}>
            <List 
                disablePadding 
            >
                    {MENU.map((elem:DrawerItem, index: number) => {

                        const {path, icon, label, needSubscription, needNutrition, showOnMobile} = elem
                        const disabled:boolean = (needSubscription && !user.subscription?.current?.subscriptionId) || (needNutrition && !user.modules?.nutrition?.active)
                        const itemIsActive:boolean = index === itemSelected

                        if((mobile && showOnMobile) || !mobile){
                            return (
                                <Box 
                                    sx={[
                                        classes.itemContainer, 
                                        itemIsActive && classes.itemContainerSelected,
                                        horizontal && classes.itemContainerHorizontal
                                    ]} 
                                    key={index}
                                >
                                    <ListItemButton 
                                        key={index} 
                                        sx={classes.item}
                                        onClick={!disabled ? redirectTo(path) : onOpenDialogPresentation(needSubscription,needNutrition)} 
                                    >
                                        
                                        {/********** 
                                         * ICON 
                                         * **** */}

                                        <ListItemIcon sx={[
                                            classes.iconContainer,
                                        ]}>
                                            <Icon 
                                                icon={disabled ? "premium" : icon} 
                                                size={20} 
                                                color={disabled? "#ffbb00" :itemIsActive ? DRAWER_ACTIVE_ITEM_TEXT_COLOR : DRAWER_ITEM_LABEL_COLOR} 
                                                
                                            />
                                        </ListItemIcon>
                                    

                                        {/********* 
                                         * LABEL 
                                         * **** */}
                                   
                                       
                                        <ListItem 
                                                key={index} 
                                                disablePadding 
                                                sx={{ display: 'block'}}
                                            >
                                                <ListItemText 
                                                    primary={label} 
                                                    sx={[
                                                        classes.itemLabel, 
                                                        itemIsActive && classes.itemLabelActive, 
                                                        disabled && {color: "#ffbb00"}
                                                    ]} />
                                        </ListItem>
                                      
                                    

                         
                                                
                                        </ListItemButton>
                                        
                                </Box>
                            
                            )
                        }
                    })}
            </List>

        </Box>
    )
}