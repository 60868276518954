import { darken } from "@mui/material"
import { BORDER_COLOR, CARD_BORDER_RADIUS, CARD_HOVER_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {

    row : {
        display:"flex",
        flexShrink: 1,
        flexDirection: "row",
        alignItems:"center",
        justifyContent:"center"
    },

    nameContainer : {
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        width: {xs: "100%", sm: 400},
    },

    name : {
        fontWeight: 600
    },

    email:{
        fontSize: 14
    },

    avatarContainer: {
        borderRadius: "100%",
        width: 60,
        height: 60, 
        maxWidth: 60,
        minWidth: 60,
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center",
        position: "relative",
        overflow:"hidden",
        marginRight: 3,
        border: "solid 5px white",
        backgroundColor: "#f1f1f1", 

        
    },

    avatar: {
        objectFit: "cover",
        width: "60px",
        height: "60px",
    },

    avatarLetters : {
        fontWeight: 700, 
        fontSize: theme.typography.pxToRem(18), 
        color: "rgb(88 88 88)"
    },

    statusContainer : {
        width: 190,
        display:{xs: "none", sm:"flex"},
        flexDirection:"row",

    },


    statusBox: {
        top: 15, 
        left: 10,
        display:"flex",
        flexDirection: "row"
    },

    status : {
        backgroundColor: "#61c9b2",
        paddingLeft: 1.5,
        paddingRight: 1.5,
        color: "white",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(12),
        borderRadius: 2,
        width: 65,
        textAlign: "center"
    },


    inactif : {
        backgroundColor: "#ef7b69"
    },


    nutritionContainer : {
        backgroundColor: "#77cc77",
        marginLeft: 1,
        width: 80
    },

    invitationStatus : {
        backgroundColor: "#465cb1",
        width: 90
    },

    nutritionLabel : {
        fontSize: 13,
        fontWeight: 400
    },

    program : {
        display: "flex",
        alignItems:"flex-start",
        flexDirection: "column",
        width: 250,
  
    },

    programmed: {
        fontWeight: 400, 
        color: "rgba(0,0,0,0.75)", 
        fontSize: theme.typography.pxToRem(15)
    },


    programLabel : {
        fontWeight: 600, 
        color: "rgba(0,0,0,0.75)",  
        fontSize: theme.typography.pxToRem(14)
    },

    programLabelLight: {
        color: "rgba(0,0,0,0.35)"
    },

    programIcon:{
        fontSize: 18, 
        marginRight: 1
    },


    programContainer : {
        display:{xs: "none", md: "flex"}, 
        alignItems:"flex-start", 
        justifyContent:"flex-start", 
        borderRadius: 3, 
        flex: 1,
        flexDirection:"column",
        marginLeft: 3,
        marginRight: 3
    },

    moreButton : {
        width: 50,
    }


}


export default classes