import { Box } from "@mui/material";
import classes from "./styles";
import { useEffect, useRef, useState } from "react";
import calcBoxHeight from "function/calcBoxHeight";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "app/store";
import DialogUpdateCover from "components/organisms/DialogUpdateCover";


export default function WorkoutBuilderCover(){

        const workout = useSelector((state:RootState)=> state.workouts.builder)
        const cover = useSelector((state:RootState)=> state.workouts.builder.cover, shallowEqual)
        const [mediaHeight, setMediaHeight] = useState<number>(0)
        const imageRef = useRef<any>(null);
        const [openUpdateCover, setOpenUpdateCover] = useState<boolean>(false)


        useEffect(() => {
            if (imageRef.current) {
                const boundingRect = imageRef.current.getBoundingClientRect()
                const videoWidth = boundingRect.width;
                const height = calcBoxHeight(1080, 1920, videoWidth)
                setMediaHeight(height)
            }
        }, [imageRef.current]);
    

    return(
        <Box
            sx={[classes.cover, {height: mediaHeight, marginRight: 6}]}
            ref={imageRef}
        >

            <Box
                component={"img"}
                src={cover.url}
                sx={classes.image}
                onClick={()=>setOpenUpdateCover(true)}
            />


            <DialogUpdateCover
                open={openUpdateCover}
                onClose={()=>setOpenUpdateCover(false)}
                workout={workout}
            />

            
        </Box>
    )
}