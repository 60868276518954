import React from "react"
// MUI
import {Typography, Box, useMediaQuery} from "@mui/material"
// REDUX
import { useSelector } from 'react-redux'
// ROUTER
import { BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
// app
import { RootState } from 'app/store'
// Features
import Home from 'pages/Home'
import Clients from 'pages/Clients'
import Profile from 'pages/ClientProfile'
import Exercices from 'pages/Exercices'
import Templates from "pages/Templates"
import WorkoutBuilder from 'pages/WorkoutBuilder'
import CheckupBuilder from "pages/CheckupBuilder"
import ProgramBuilder from "pages/ProgramBuilder"
import Account from "pages/Profile"
import DashboardLayout from "components/templates/DashboardLayout"
import Subscriptions from "pages/Subscriptions"
//import Checkout from "pages/checkout"
import Signin from "pages/Signin"
import Signup from "pages/Signup"
import { ExerciceBuilder } from "pages/ExerciceBuilder"
import ForgotPassword from "pages/ForgotPassword"
import CoachPlanning from "pages/CoachPlanning"
import Lottie from "lottie-react"
import maintenanceAnimation from "../lottie/maintenance.json";
import { MOBILE } from "utils/constants"
import Recipes from "pages/Recipes"
import RecipeBuilder from "pages/RecipeBuilder"
import MealPlanBuilder from "pages/MealPlanBuilder"
import Food from "pages/Food"


const LOGIN_PATHS = [
    '/login', 
    '/register', 
    '/forgotpassword'
]
const PRIVATE_PATHS = [
    '/dashboard',
    '/clients/:id',
    '/clients',
    '/exercices/:exerciceid', 
    '/exercices',
    '/templates',
    '/clients/:clientid/workoutbuilder',
    "/planning/workoutBuilder",
    '/templates/workoutbuilder',
    "/templates/checkup/:checkupid",
    "/templates/program/:id",
    "/templates/program/:id/workoutbuilder",
    "/templates/mealplans/:mealplanid",
    "/account",
    "/subscriptions",
    "/menus",
    '/food',
    "/recipes/:recipeid",
    "/recipes",
    "/me",
    "/planning",
    
    "/food"
    
]

const PRIVATE_PATHS_WITHOUT_SUBSCRIPTIONS = [
    '/dashboard',
    '/clients',
    "/account",
    "/subscriptions",
]

interface RouteTypes{
    component: any,
    path: string
}


/********* LOGIN ROUTE - No need to be Auth ******** */

function LoginRoute ({component, path} : RouteTypes){
    const Component = component
    const isAuth = useSelector((state: RootState) => state.user.isAuth)

    if(!isAuth){
        return  (
            <Route path={path} exact >
                <Component />
            </Route>
        )
    }else{
        return <Redirect to="/dashboard" />
    }  
}


/********* PRIVATE ROUTE - Need Auth ******** */

function PrivateRoute ({component, path} : RouteTypes){

    const Component = component
    const isAuth = useSelector((state: RootState) => state.user.isAuth)

    if(isAuth){
        return  (
            <Route path={path} exact >
                <Component />
            </Route>
        )
    }else{
        return <Redirect to="/login" />
    }  
}


export default function AppRoutes() {

    const user = useSelector((state: RootState) => state.user)
    const serverIsOffline = useSelector((state:RootState) => state.app.status === "offline")
    const mobile = useMediaQuery(MOBILE)

    if(serverIsOffline){

        return(
            <Box sx={{ width: "100%", height: "100vh", display:"flex", alignItems:"center", justifyContent:"center", overflow: "hidden"}}>
           
                    <Box sx={{display:'flex', flexDirection: "column", alignItems:"center", justifyContent:"center", padding: 6}}>
                        <Box component='img' src={"/assets/logo.svg"} sx={{width: 200, marginBottom: 2}} />
                        <Typography variant="h6" sx={{fontSize: 20,marginTop: 1}}>
                            Notre serveur est actuellement en maintenance.
                        </Typography>
                        <Typography>
                            Nous revenons très vite avec une version de l'application plus musclée !
                        </Typography>
                        <Box style={{width: mobile ? "100%" : 280}}>
                            <Lottie animationData={maintenanceAnimation} loop={true}/>
                        </Box>
                    </Box>
                
            </Box>
        )
    } else {
        return (
            <React.Fragment>
                <Router basename={process.env.REACT_APP_BASE_URL}>
                {user.isReady && (
                    <Switch>
                        <Route exact path={LOGIN_PATHS}>
                            <LoginRoute path={"/login"} component={Signin} />
                            <LoginRoute path={"/register"} component={Signup} />
                            <LoginRoute path={"/forgotpassword"} component={ForgotPassword} />
                        </Route>

                        <Route exact path={(user.data.subscription?.current?.status === "active" || user.data.subscription?.current?.status === "trialing") ? PRIVATE_PATHS : PRIVATE_PATHS_WITHOUT_SUBSCRIPTIONS}>
                            <DashboardLayout>
                                <PrivateRoute path="/dashboard" component={Home}/>
                                <PrivateRoute path="/me" component={Profile}/>
                                <PrivateRoute path="/clients" component={Clients}/>
                                <PrivateRoute path="/clients/:clientid" component={Profile}/>
                                <PrivateRoute path="/planning/workoutBuilder" component={WorkoutBuilder}/>
                                <PrivateRoute path="/planning" component={CoachPlanning}/>
                                <PrivateRoute path='/exercices' component={Exercices}/>
                                <PrivateRoute path='/exercices/:exerciceid' component={ExerciceBuilder}/>
                                <PrivateRoute path='/templates' component={Templates}/>
                                <PrivateRoute path="/clients/:clientid/workoutbuilder" component={WorkoutBuilder}/>
                                
                                <PrivateRoute path='/templates/checkup/:checkupid' component={CheckupBuilder}/>
                                <PrivateRoute path='/templates/workoutbuilder' component={WorkoutBuilder}/>
                                <PrivateRoute path='/templates/program/:programid/workoutbuilder' component={WorkoutBuilder}/>
                                <PrivateRoute path='/templates/program/:programid' component={ProgramBuilder}/>
                                <PrivateRoute path="/templates/mealplans/:mealplanid" component={MealPlanBuilder} />

                                <PrivateRoute path='/account' component={Account}/>
                                <PrivateRoute path='/subscriptions' component={Subscriptions} />
                                <PrivateRoute path='/recipes/:recipeid' component={RecipeBuilder} />
                                <PrivateRoute path='/recipes' component={Recipes} />
                                
                                <PrivateRoute path="/food" component={Food} />
                            </DashboardLayout>
                        </Route>

                       

                        <Route path="*">
                            <Redirect to={user.isAuth ? "/dashboard" : "/login"} strict />
                        </Route>
                    </Switch>
                )}
                </Router>

                
            </React.Fragment>

        )
    }

}