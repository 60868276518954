import { Add, FilterList } from "@mui/icons-material"
import { Box, Grid, IconButton, SvgIconTypeMap, useMediaQuery } from "@mui/material"
import CustomIconButton from "components/atoms/Buttons/IconButton"
import SearchTextfield from "../Search"
import ContainedPrimaryButton from "components/atoms/Buttons/ContainedButton";
import { DESKTOP, INPUT_BG_COLOR, MOBILE, TABLET } from "utils/constants";
import classes from "./styles";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface Props{
    onSearch: (e: any)=> void,
    onOpenFilterList?: (event: React.MouseEvent<HTMLButtonElement>)=>void,
    onSubmit : (e:any)=>void,
    badge?: number,
    submitLabel: string,
    filter?:boolean,
    searchLabel: string,
    withTabs?: boolean,
    actions?: {
        Icon:OverridableComponent<SvgIconTypeMap<{}, "svg">>,
        action: ()=> void ,
        selected?:boolean
    }[]
}

export default function FixedSearchHeader({searchLabel, onSearch,onOpenFilterList,onSubmit, badge, submitLabel, filter=true, withTabs= true, actions}:Props){

    const desktop = useMediaQuery(DESKTOP);
    const mobile = useMediaQuery(MOBILE);

    return(

             <Box sx={[
                    classes.listHeader, 
                    !withTabs && {marginTop: "70px"}
            ]}>
                <Grid 
                    container 
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
                        <Box
                            sx={{justifyContent: "space-between", display:"flex"}}
                        >

                            {/****************
                             * SEARCH BAR
                             ****************/}
   
                            <Box 
                                sx={{display:"flex", flexDirection:"row", width: "100%"}}
                            >
                                

                                <SearchTextfield
                                    onChange={onSearch}
                                    placeholder={searchLabel}
                                    fullWidth={true}
                                    color={desktop? "white" : null}
                                
                                />

                                {filter && (
                                    <CustomIconButton
                                        Icon={FilterList}
                                        onClick={onOpenFilterList}
                                        marginLeft={1}
                                        //badge={groupFilters.length + typesFilters.length}
                                        badge={badge}
                                        white={desktop}
                                        showLabel={!mobile}
                                        label={"Filter"}
                                
                                    />
                                )}

                                {mobile && (
                                    <CustomIconButton
                                    Icon={Add}
                                    onClick={onSubmit}
                                    marginLeft={1}
                                />
                                )}


                                {actions?.length>0 && actions.map(({Icon, selected, action}, index)=>{

                                    return(
                                        <CustomIconButton
                                            key={index}
                                            Icon={Icon}
                                            onClick={action}
                                            marginLeft={1}
                                            white={desktop}
                                            selected={selected}
                                            
                                        />
                                    )
                                })}



                            </Box>


                            {/****************
                             * BOUTON
                             ****************/}
                        
                            {!mobile && (
                                <ContainedPrimaryButton
                                    variant="contained"
                                    onClick={onSubmit}
                                    disableElevation={true}
                                    startIcon={<Add />}
                                    sx={{minWidth: 200}}
                                >
                                    {submitLabel}
                                </ContainedPrimaryButton>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                        
           
            
         </Box>
    )
}