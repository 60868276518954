import { theme } from "utils/theme"

const classes = {
 date : {
        backgroundColor: "#eaf4ff",
        display:"inline-flex",
        //border: `solid 1px ${BORDER_COLOR}`,
        alignItems:"center",
        marginLeft: "2px",
        paddingRight: 2,
        borderRadius: 100
    },

    dateIcon : {
        backgroundColor: theme.palette.primary.main,
        marginRight: 1, 
        width: 40,
        height: 40,
        borderRadius: 100,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position: "relative",
        left: "-7px"
    },

    
    dateLabel:{
        color: "rgba(0,0,0,0.85)",
        fontWeight: 500,
        fontSize: 15,
    },


    blockEvents : {
        paddingBottom: 0
    },

    eventContainer : {
        display:"flex",
        justifyContent:"center",
        flexDirection: "column",
        position: "relative",
        flex:1,
        paddingTop: 4,
        paddingBottom: 0
    },

    eventsTimeline : {
        display:{
            xs: "none",
            sm: "block"
        },
        position:"absolute",
        left: 15,
        top: 0,
        height: "100%",
        borderRight: "dashed 2px #e1e1e1"
    },
}

export default classes