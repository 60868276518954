import { Box, styled, Typography } from "@mui/material";
import calcMacros from "function/calcMacros";
import MealInterface, { MealContent } from "interfaces/Meal";
import { useMemo } from "react";
import classes from "./styles";
import { Ingredient } from "interfaces/Recipe";
import { MACROS_COLORS } from "utils/constants";
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';
import { theme } from "utils/theme";
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from "@mui/x-charts/hooks";

interface Props{
    meals?: MealInterface[],
    ingredients?: {
        ingredient: Ingredient
    }[],
    macros?: {
        kcal: number,
        proteins:number,
        carbs: number,
        lipids:number
    },
    fullWidth?: boolean
}


const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: "1rem",
    fontWeight: 600
  }));
  
  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }


function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number, grams:number, kcal?:boolean },
  ) {
    return (
    <Box
        sx={{position: "absolute", top: 0}}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ fontWeight: 600, fontSize: 16, color: "rgba(0,0,0,0.85)" }}
          >
            {`${Math.round(props.grams)}${!props.kcal? "g" :""}`}
        </Typography>
        </Box>
      </Box>
          </Box>
    );
}


function Macro({color,value, grams, label, kcal}:{color:string, value:number, grams: number, label: string, kcal?: boolean}){
    return(
        <Box
            sx={classes.macro}
        >   
        <CircularProgress
            variant="determinate"
            sx={{
                color: theme.palette.grey[200]
            }}
            size={60}
            thickness={3.5}
            value={100}
        />
        <CircularProgressWithLabel
            value={value}
            grams={grams}
            thickness={3.5}
            size={60}
            sx={{color}}
            kcal={kcal}
        />
        
        <Typography
            sx={classes.macroLabel}
        >
            {label}
        </Typography>
    </Box>

    )
}

export default function MacrosListMobile({meals, ingredients, macros, fullWidth= false}:Props){


    const MACROS = useMemo(()=>{

        var total = null

        // MEAL
        
    

       if(macros){
            var labels = [
                { value: macros.proteins, label: 'Protéines'},
                { value: macros.lipids, label: 'Lipides'},
                { value: macros.carbs, label: 'Glucides'},
                { value: 0, label: `Fibres`},
                {value: macros.kcal, label: "Kcal"},
            ]

            return labels
        } else {
                total = calcMacros(meals)
                var labels = [
                    { value: total.proteins, label: 'Protéines'},
                    { value: total.lipids, label: 'Lipides'},
                    { value: total.carbs, label: 'Glucides'},
                    { value: total.fibers, label: `Fibres - ${total.fibers}g`},
                    {value: total.kcal, label: "Kcal"},
                ]
        
                return labels
            
        }
        
        

    },[meals, ingredients, macros])


    const KCAL_DATA = useMemo(()=>{
        return [
            { value: MACROS[0].value === 0 ? 0 : MACROS[0].value * 4 / MACROS[4].value * 100, color: MACROS_COLORS["proteins"]},
            { value: MACROS[2].value === 0 ? 0 : MACROS[2].value * 4 / MACROS[4].value * 100, color: MACROS_COLORS["carbs"]},
            { value: MACROS[1].value === 0 ? 0 : MACROS[1].value * 9 / MACROS[4].value * 100, color: MACROS_COLORS["lipids"]},
           
        ]
    },[MACROS])



    return(

    <Box
        sx={[
            classes.mealMacros, 
            //fullWidth && {width: "100% !important"}
        ]}
    >

        {/** KCAL */}
        <Box
            sx={{dislay:"flex",flexDirection:"column", alignItems:"center", justifyContent:"flex-end", height: 60, width: 60}}
        >
            <PieChart
                slotProps={{
                    legend: { hidden: true },
                }}
                margin={{
                    top:0,
                    left:0,
                    right:0,
                    bottom: 0
                }}
                series={[
                {
                    data: KCAL_DATA,
                    innerRadius: 24,
                    paddingAngle: 5,
                    
                    
                },
                ]}
            >
                <PieCenterLabel>
                    {MACROS[4].value}
                </PieCenterLabel>
            </PieChart>
            <Typography
            sx={classes.macroLabel}
        >
            kcal
        </Typography>

        </Box>
        
        {/** PROT */}
        <Macro
            value={MACROS[0].value === 0 ? 0 : MACROS[0].value * 4 / MACROS[4].value * 100} 
            label="Protéines"
            color={MACROS_COLORS["proteins"]}
            grams={MACROS[0].value}
        />  

        {/** CARBS */}
        <Macro
            value={MACROS[2].value === 0 ? 0 : MACROS[2].value * 4 / MACROS[4].value * 100} 
            label="Glucides"
            color={MACROS_COLORS["carbs"]}
            grams={MACROS[2].value}
        />  

        {/** FATS */}

        <Macro
            value={MACROS[1].value === 0 ? 0 : MACROS[1].value * 9 / MACROS[4].value * 100} 
            label="Lipides"
            color={MACROS_COLORS["lipids"]}
            grams={MACROS[1].value}
        />  

            

</Box>
    )
}