import {  Delete, Event } from "@mui/icons-material";
import { Box, Radio } from "@mui/material";
import { RootState } from "app/store";
import RowCard from "components/atoms/Box/RowCard";
import RowCardSubTitle from "components/atoms/Typography/RowCardSubTitle";
import RowCardTitle from "components/atoms/Typography/RowCardTitle";
import { differenceInCalendarWeeks } from "date-fns";
import Program from "interfaces/Program";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ViewportList } from "react-viewport-list";
import classes from "./style"
import MoreButton from "components/atoms/Buttons/MoreButton";
import DialogDeleteProgram from "components/molecules/DialogDeleteProgram";
import { useHistory } from "react-router-dom";


interface Props{
    fromImport?: boolean,
    onSelectProgram?: (program:Program)=> void ,
    programSelectedForImport?:Program
}

export default function TemplatePrograms({fromImport, onSelectProgram, programSelectedForImport}:Props){

    const navigate = useHistory()
    const listRefPrograms = useRef(null);
    const programs:Program[] = useSelector((state:RootState) => state.programs.templates.filtered)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [programSelected, setProgramSelected] = useState<Program>(null)


    /********** SUPPRESSION ********** */

    const selectProgramToDelete = (program:Program) => ()=>{
        //e.stopPropagation()
        setOpenDeleteDialog(true)
        setProgramSelected(program)
    }

    /********** REDIRECTION ********** */

    const onClick = (program: Program) => {
        if(!fromImport){
            navigate.push(`/templates/program/${program._id}`)
        } else {
            onSelectProgram(program)
        }
    }

    return(
        <Box ref={listRefPrograms}>
            <ViewportList
                ref={listRefPrograms}
                items={programs}
                initialPrerender={15}
                itemSize={95}
            >
                {(item)=> (
                    <RowCard 
                        key={item._id}
                        onClick={()=>onClick(item)}
                    >
                        <Box sx={classes.rowInfos}>
                            <Event sx={{marginRight: 2, color: "primary.main", fontSize:30}}/>
                            <Box>
                                <RowCardTitle>
                                    {item.name || "Aucun nom"}
                                </RowCardTitle>
                                <RowCardSubTitle>
                                    {differenceInCalendarWeeks(new Date(item.endDate), new Date(item.startDate))} semaine{differenceInCalendarWeeks(new Date(item.endDate), new Date(item.startDate))>1 ? "s" : ""}
                                </RowCardSubTitle>
                            </Box>
                        </Box>

                        {/**** TEMPLATE - Options horizontales **** */}
                        {!fromImport && (
                            <MoreButton
                                menuList={[
                                    {
                                        label:"Supprimer",
                                        icon:<Delete/>,
                                        callback: selectProgramToDelete(item)
                                    }
                                ]}
                            />
                        )}

                        {/**** IMPORT - CHECKBOX **** */}
                        {fromImport && (
                            <Radio checked={programSelectedForImport?._id === item._id} />
                        )}
                    </RowCard>
                        
                )}
            </ViewportList>


            {/*** DIALOG DELETE PROGRAM **** */}
            {!fromImport && (
                <DialogDeleteProgram
                    open={openDeleteDialog}
                    onClose={()=> setOpenDeleteDialog(false)}
                    program={programSelected}
                    deleteContent={true}
                />
            )}
        </Box>
    )
}