import { Box, TextField, Typography, styled } from "@mui/material"
import { theme } from "utils/theme"
import classes from "./styles"
import { useSelector } from "react-redux"
import { RootState } from "app/store"


const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
            
        }
    },
}))


interface Props{
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}


export default function WorkoutDescription({value, onChange}:Props){

    const performed = useSelector((state:RootState) => state.workouts.builder.performed)

    return(


        <Box 
            sx={classes.container}
        >
            <Typography 
                variant="body1" 
                sx={classes.sectionLabel}
            >
                Description de la séance
            </Typography>
            <DescriptionTextField
                multiline={true}
                fullWidth
                value={value}
                onChange={onChange}
                disabled={Boolean(performed)}
                placeholder={`(Optionnel) Entre une description ou des instructions générales pour cette séance`}
                variant="standard" 
            />
        </Box>
    )
}