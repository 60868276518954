import { Box, Button, Dialog, DialogActions, DialogContent,Typography, useMediaQuery } from "@mui/material";
import Workout from "interfaces/Workout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { addCoachNoteToWorkout } from "api/workouts";
import { DESKTOP, MOBILE, TABLET } from "utils/constants";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import User from "interfaces/User";
import { toast } from "react-toastify";
import { postNotification } from "api/notifications";

interface Props{
    open: boolean,
    onClose: ()=> void,
    currentWorkout: Workout,
}



export default function DialogSendWorkoutResultsNotification({open,onClose,currentWorkout}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState)=> state.user.data)
    const mobile = useMediaQuery(MOBILE)
    const pending = useSelector((state:RootState)=> state.notifications.requests.create === "pending")

    

       /******************************
     * ENVOIE DE NOTIFICATION
     *****************************/
  
       const onSendNotification = () => {
        const workoutUser = currentWorkout.createdFor as User
        const tokenData = workoutUser.notificationsToken


        dispatch(postNotification({
            title: currentWorkout.name,
            body: `Ton coach ${user.firstname} a consulté les résultats de ta séance et a laissé un commentaire.`,
            data:{
                type: 'workout_comments',
                deeplink:`/results/${currentWorkout._id}`
            },
            android: {
                channelId: 'default',
                smallIcon: 'ic_small_icon',
                color: '#2979FF',
            },
            tokens: [{
                FCM: tokenData?.FCM,
                user: workoutUser._id
            }]
        })).unwrap().then((res)=>{
            if(res.notification){
                toast.success('Notification envoyée')
                onClose()
            }
            else {
                toast.error("Erreur d'envoie")
            }
        })
    }

    
    


    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"sm"}
            fullScreen={mobile? true:false}
            TransitionComponent={DialogTransition}
            disableScrollLock={!mobile}

        >
            <DialogAppBar
                title={"Envoyer une notification"}
                onClose={onClose}
            />

            <DialogContent>
                    <Typography>
                        Tu peux notifier ton client que tu as ajouter des commentaires sur cette séance. Avant d'envoyer ta notification, vérifie que tu as laissé tous les commentaires que tu souhaitais sur cette séance afin d'éviter le spam.
                    </Typography>

                    <Box>

                    </Box>
                        
            </DialogContent>

         
                <DialogActions>
                    
                    {!mobile && (
                        <Button 
                        onClick={onClose}
                        disabled={pending}
                        >
                            Annuler
                        </Button>
                     )}
                     <LoadingButton
                        onClick={onSendNotification}
                        loading={pending}
                    >
                        Envoyer
                    </LoadingButton>
                </DialogActions>
           
        </Dialog>
    )
}