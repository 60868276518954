import { Box, Grid, Typography } from "@mui/material"
import classes from "./styles"
import calcMacros from "function/calcMacros"
import { useSelector } from "react-redux"
import { RootState } from "app/store"
import { useEffect, useRef, useState } from "react"

const days = ['monday', "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
const trads = ['Lundi', "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]


interface Props{
    dayIndex: number,
    daySelected: string,
    onChangeDaySelected: (day:number)=> ()=> void,
    template?: boolean
}

export default function NutritionHeaderDays({dayIndex, onChangeDaySelected}:Props){

    const mealPlan = useSelector((state:RootState) => state.mealPlans.mealPlan)
    const daysLabel = useRef(null)
    const [isFixed, setIsFixed] = useState<boolean>(false)

    /*useEffect(()=>{
            if(daysLabel?.current){
                // Changement de la position du Header planning
                const handleScroll = () => {
                    
                    //if(daysLabel.current.getBoundingClientRect().y + daysLabel.current.offsetHeight < 0){
                    if(daysLabel.current.getBoundingClientRect().y <= 0 ){
                        if(!isFixed) {
                            setIsFixed(true)
                            console.log('on passe à fixed')
                        }
                    } else {
                        if(isFixed) {
                            setIsFixed(false)
                            console.log('pas fixed')
    
                        }
                    }
                }
    
                if(daysLabel.current){
                    window.addEventListener("scroll", handleScroll);
                    return () => {
                        window.removeEventListener("scroll", handleScroll);
                    };
                }
            }
    
           
        },[daysLabel?.current, isFixed])
    */

    return(
        <Box
            ref={daysLabel}
            //sx={{position: isFixed? "fixed" : "relative"}}
        >
        <Grid 
            container 
            spacing={3}
            justifyContent="center" 
            
        >
            <Grid item xs={12} lg={11} xl={8}>
                <Box
                    sx={classes.menu}>
                        {days.map((day, index)=> {

                            const thisDay = day as 'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday"

                            return(
                                <Box
                                    onClick={onChangeDaySelected(index)}
                                    key={index}
                                    sx={[
                                        classes.day,
                                        dayIndex === index && classes.daySelected,
                                        index === (days.length - 1) && {marginRight: "0 !important"}
                                    ]}>
                                        <Typography
                                            sx={[
                                                classes.dayLabel,
                                                index === dayIndex && classes.dayLabelSelected
                                            ]}
                                        >
                                            {trads[index]}
                                        </Typography>
                                        <Typography
                                            sx={[
                                                classes.dayLabel,
                                                classes.kcal,
                                                index === dayIndex && classes.kcalSelected
                                            ]}
                                        >
                                            {mealPlan && calcMacros(mealPlan[thisDay]).kcal} kcal
                                        </Typography>
                                </Box>
                            )
                        })}
                        
                </Box>
            </Grid>
        </Grid>
        </Box>
    )
}