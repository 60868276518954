import { Dialog, DialogContent, Grid } from "@mui/material";
import Workout from "interfaces/Workout";
import { useEffect, useState } from "react";
import classes from "./styles";
import { addWeeks, isBefore, isSameDay } from "date-fns";
import { useDispatch } from "react-redux";
import { AppDispatch } from "app/store";
import { getWorkoutResult, getWorkouts } from "api/workouts";
import DialogAppBar from "components/molecules/DialogAppBar";
import DialogTransition from "components/molecules/DialogTransition";
import WorkoutResults from "../WorkoutResults";



interface Props{
    workout: Workout,
    open: boolean,
    onClose: ()=> void
}

export default function DialogWorkoutResults({workout,open,onClose}:Props){


    const dispatch = useDispatch<AppDispatch>()
    const [currentWorkout, setCurrentWorkout] = useState<Workout>(null) // Current workout selected
    const [workoutsList, setWorkoutsList] = useState<Workout[]>([]) // Workout List fetched for analys
    const [lastWeekWorkout, setLastWeekWorkout] = useState<Workout>(null)
    const [selectedWorkoutComparaison, setSelectedWorkoutComparaison] = useState<Workout>()

    useEffect(()=>{
        console.log(currentWorkout)
    },[currentWorkout])

    // Fetch workoutLists for comparison
    useEffect(()=>{
        if(currentWorkout && currentWorkout.createdFor && open){
            const performedDate = new Date(currentWorkout?.performed.date)
            const start = addWeeks(performedDate,-4).toDateString() // 4 weeks soustraction
            const end = new Date(performedDate).toDateString() // End is today
            const createdFor = typeof currentWorkout.createdFor === "string" ? currentWorkout.createdFor : currentWorkout.createdFor._id
            const queryParams:{start:string, end: string, createdFor?: string, sleep?:boolean, programTemplate?: string} = {start, end, createdFor:createdFor}
           
            dispatch(getWorkouts(queryParams)).unwrap().then((res)=>{
                // filter performed and not current workout
                const performedWorkouts = res.workouts.filter((elem)=> elem.performed && isBefore(new Date(elem.performed.date), new Date(currentWorkout.performed.date)))
                setWorkoutsList(performedWorkouts)

                // try to find last week workout
                const workoutFound = performedWorkouts.filter((elem)=> {
                    const performedSameDay = isSameDay(new Date(elem.performed?.date), addWeeks(new Date(currentWorkout.performed?.date), -1))
                    const scheduledSameDay = isSameDay(new Date(elem.schedule), addWeeks(new Date(currentWorkout.schedule), -1))

                    return performedSameDay || scheduledSameDay
                })

                // if found last week workout
                if(workoutFound.length === 1){
                    setLastWeekWorkout(workoutFound[0])
                }


            })
        }
    },[currentWorkout, open])

    useEffect(()=>{
        if(open){
            dispatch(getWorkoutResult({id: workout._id})).unwrap().then((res)=> {
                if(res.workout){
                    setCurrentWorkout(res.workout)
                }
            })
        } else if(!open){
            setTimeout(()=>{
                setWorkoutsList(null)
                setSelectedWorkoutComparaison(null)
            },500)
            
        }
    },[workout,open])


    const onSelectWorkoutForComparison = (workout:Workout) =>{
        setSelectedWorkoutComparaison(workout)
    }



    return(

        <>

        <Dialog 
            open={open} 
            fullScreen={true}
            TransitionComponent={DialogTransition}

        >
            <DialogAppBar
                title={"Résultats de séance"}
                onClose={onClose}
                isFullScreen={true}
            />

            <DialogContent 
                sx={classes.content}>

                <Grid 
                    container 
                    spacing={3}
                    sx={{justifyContent:"center"}}
                >
                    
                    <Grid item xs={12} lg={8} xl={5}>

                       
                        {currentWorkout && (
                            <WorkoutResults
                                workout={currentWorkout}
                                workoutComparision={selectedWorkoutComparaison}
                                onSelectWorkoutForComparison={onSelectWorkoutForComparison}
                                lastWeekWorkout={lastWeekWorkout}
                                workoutsList={workoutsList}
                                isSelected={true}

                            />
                        )}
           

                    </Grid>

                </Grid>
            </DialogContent>

            
        </Dialog>


        

        </>
    )
}